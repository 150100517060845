import { Attachment } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class AttachmentApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listAttachments(
		entity: string,
		parentId: number
	): Promise<Attachment[]> {
		const response = await this.client.get<Attachment[]>(
			`/attachment/${entity}/${parentId}`
		);
		return response.result;
	}

	public async createAttachment(
		entity: string,
		parentId: number,
		data: Partial<Attachment>
	): Promise<Attachment> {
		const response = await this.client.post<
			Partial<Attachment>,
			Attachment
		>(`/attachment/${entity}/${parentId}`, data);
		return response.result;
	}

	public async getAttachment(attachmentId: number): Promise<Attachment> {
		const response = await this.client.get<Attachment>(
			`/attachment/${attachmentId}`
		);
		return response.result;
	}

	public async updateAttachment(data: Attachment): Promise<Attachment> {
		const response = await this.client.put<Attachment, Attachment>(
			`/attachment/${data.attachmentId}`,
			data
		);
		return response.result;
	}

	public async removeAttachment(attachmentId: number): Promise<void> {
		await this.client.del<Attachment>(`/attachment/${attachmentId}`);
	}
}
