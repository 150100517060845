import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useMeasure } from 'react-use';

// Icons
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const StyledDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<AppBar position="relative">
			<Toolbar variant="dense" disableGutters>
				<MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
					{children}
					{onClose ? (
						<IconButton
							aria-label="close"
							onClick={onClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: 'primary.contrastText',
							}}
						>
							<Icon path={mdiClose} size={1} />
						</IconButton>
					) : null}
				</MuiDialogTitle>
			</Toolbar>
		</AppBar>
	);
};

type ModalDialogProps = {
	open: boolean;
	onClose: () => void;
	label: string;
	children: React.ReactNode;
	actions?: React.ReactNode;
	padding?: number;
	minWidth?: number;
	fullScreen?: boolean;
};

export const ModalDialog = (props: ModalDialogProps) => {
	const {
		open,
		onClose,
		label,
		children,
		actions,
		padding = 0,
		minWidth: initialMinWidth = 450,
		fullScreen,
	} = props;
	const theme = useTheme();
	const [minWidth, setMinWidth] = useState(initialMinWidth);
	const [minHeight, setMinHeight] = useState(300);
	const [ref, { width, height }] = useMeasure();

	useEffect(() => {
		if (width > minWidth) {
			setMinWidth(width);
		}
	}, [width, minWidth]);

	useEffect(() => {
		if (height > minHeight) {
			setMinHeight(height);
		}
	}, [height, minHeight]);

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullScreen={fullScreen}
			sx={{
				'& .MuiDialogActions-root': {
					padding: theme.spacing(1),
				},
				'& .MuiPaper-root': {
					// display: 'flex',
					// position: 'relative',
					// height: '100%',
					// overflow: 'hidden',
					minWidth: fullScreen === true ? '100%' : `${minWidth}px`,
				},
			}}
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={onClose}>
				{label}
			</StyledDialogTitle>
			<MuiDialogContent
				dividers
				ref={ref}
				sx={{
					display: 'flex',
					position: 'relative',
					height: '100%',
					overflow: 'hidden',
					flexDirection: 'column',
					flexGrow: 1,
					minWidth: `${minWidth}px`,
					minHeight: `${minHeight}px`,
					p: padding,
				}}
			>
				{children}
			</MuiDialogContent>
			{actions && <MuiDialogActions>{actions}</MuiDialogActions>}
		</Dialog>
	);
};
