import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import {
	FilterItem,
	FilterOperators,
	DateCalculations,
	FieldTypes,
	FieldDefinition,
} from '@constituenthub/common';

// Components
import {
	AutoComplete,
	AutoCompleteAsync,
	AutoCompleteSelect,
	TextField,
} from '../common';
import { FieldDate } from '../fields/FieldDate';
import { FieldUser } from '../fields/FieldUser';
import { FieldApproach } from '../fields/FieldApproach';
import { FilterEditorControllerHook } from './useFilterEditorController';

type FilterItemProps = {
	item: FilterItem;
	definition: Record<string, FieldDefinition>;
	conditionIndex: number;
	itemIndex: number;
	controller: FilterEditorControllerHook;
};

const dateCalculationOptions = Object.values(DateCalculations).map((x) => ({
	label: x,
	value: x,
}));

const autoCompleteDateOps: string[] = [
	FilterOperators.Equals,
	FilterOperators.NotEquals,
];

export const FilterItemEditor = ({
	item,
	definition,
	conditionIndex,
	itemIndex,
	controller,
}: FilterItemProps) => {
	const {
		onFieldChange,
		getFieldOptions,
		onOperatorChange,
		getOperatorOptions,
		onValueChange,
		onLoadValues,
	} = controller;

	const field = Object.values(definition).find(
		(x) => x.property === item.field
	);
	const fieldType = field?.type || '';

	const hasValueRow =
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue;

	const freeForm =
		item.op === FilterOperators.StartsWith ||
		item.op === FilterOperators.EndsWith ||
		item.op === FilterOperators.Contains;

	const showAutoComplete =
		(fieldType === FieldTypes.Text ||
			fieldType === FieldTypes.SelectList ||
			fieldType === FieldTypes.YesNo ||
			(fieldType === FieldTypes.Date &&
				autoCompleteDateOps.includes(item.op))) &&
		freeForm === false &&
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue;

	const showCalculatedAs =
		fieldType === FieldTypes.Date &&
		item.op === FilterOperators.CalculatedAs;

	const showDatePicker =
		fieldType === FieldTypes.Date &&
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue &&
		item.op !== FilterOperators.Equals &&
		item.op !== FilterOperators.NotEquals &&
		item.op !== FilterOperators.CalculatedAs;

	const showUser =
		fieldType === FieldTypes.User &&
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue;

	const showApproach =
		fieldType === FieldTypes.Approach &&
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue;

	const showPlainText =
		(freeForm === true || fieldType === FieldTypes.Content) &&
		item.op !== FilterOperators.HasNoValue &&
		item.op !== FilterOperators.HasValue;

	// const showYesNo =
	// 	fieldType === FieldTypes.YesNo &&
	// 	item.op !== FilterOperators.HasNoValue &&
	// 	item.op !== FilterOperators.HasValue;

	// const showInteger =
	// 	fieldType === FieldTypes.Integer &&
	// 	item.op !== FilterOperators.HasNoValue &&
	// 	item.op !== FilterOperators.HasValue;

	// const showDecimal =
	// 	fieldType === FieldTypes.Decimal &&
	// 	item.op !== FilterOperators.HasNoValue &&
	// 	item.op !== FilterOperators.HasValue;

	return (
		<Grid
			container
			direction="row"
			alignItems="center"
			spacing={2}
			flexGrow={1}
			wrap="nowrap"
		>
			<Grid item sx={{ flexGrow: 1, width: '33%' }}>
				<AutoCompleteSelect
					label="Field"
					value={item.field || ''}
					required={true}
					autoOpen={false}
					delay={0}
					onChange={(value) =>
						onFieldChange({
							value,
							item,
							conditionIndex,
							itemIndex,
						})
					}
					options={getFieldOptions({
						item,
						conditionIndex,
						itemIndex,
					})}
				/>
			</Grid>
			<Grid item sx={{ flexGrow: 1, width: hasValueRow ? '33%' : '66%' }}>
				<AutoCompleteSelect
					label="Operator"
					value={item.op || ''}
					required={true}
					autoOpen={false}
					delay={0}
					disabled={!item.field}
					onChange={(value) =>
						onOperatorChange({
							value,
							item,
							conditionIndex,
							itemIndex,
						})
					}
					options={getOperatorOptions({
						item,
						conditionIndex,
						itemIndex,
					})}
				/>
			</Grid>
			{showAutoComplete && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<AutoCompleteAsync
						key={`${conditionIndex}_${itemIndex}_${item.field}_${item.op}`}
						label="Value"
						value={item.val}
						required={true}
						fixed={true}
						autoOpen={true}
						delay={300}
						disabled={!item.field || !item.op}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
						onLoadAsync={(search) =>
							onLoadValues({
								search,
								item,
								conditionIndex,
								itemIndex,
							})
						}
					/>
				</Grid>
			)}
			{showCalculatedAs && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<AutoCompleteSelect
						label="Value"
						value={item.val}
						required={true}
						autoOpen={false}
						delay={0}
						disabled={!item.field || !item.op}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
						options={dateCalculationOptions}
					/>
				</Grid>
			)}
			{showDatePicker && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<FieldDate
						label="Value"
						disabled={!item.field || !item.op}
						value={item.val as string}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
						required={true}
					/>
				</Grid>
			)}
			{showUser && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<FieldUser
						label="Value"
						disabled={!item.field || !item.op}
						value={item.val as string}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
						required={true}
					/>
				</Grid>
			)}
			{showApproach && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<FieldApproach
						label="Value"
						disabled={!item.field || !item.op}
						value={item.val as number}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
						required={true}
					/>
				</Grid>
			)}
			{showPlainText && (
				<Grid item sx={{ flexGrow: 1, width: '33%' }}>
					<TextField
						label="Value"
						disabled={!item.field || !item.op}
						value={item.val as string}
						error={
							item.val === '' ||
							item.val === null ||
							item.val === undefined
						}
						required={true}
						onChange={(val) =>
							onValueChange({
								value: val,
								item,
								conditionIndex,
								itemIndex,
							})
						}
					/>
				</Grid>
			)}
		</Grid>
	);
};
