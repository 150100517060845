import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';

// Monorepo
import { formatDateTime, Note } from '@constituenthub/common';

// Lib
import { useUser } from '../../hooks/useUser';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	note: Note;
	onEditNote?: (note: Note) => void;
};

export const NoteListItem = ({ note, onEditNote }: Props) => {
	const { user } = useAppContext();
	const { user: noteUser } = useUser(note.createdBy);
	const displayName = noteUser ? `${noteUser.first} ${noteUser.last}` : '';

	const isNoteUser = noteUser?.userId === user?.userId;

	return (
		<ListItem>
			<ListItemText
				primary={`${formatDateTime(note.createdAt)} by: ${displayName}`}
				secondary={note.content}
				sx={{ flexGrow: 1 }}
				primaryTypographyProps={{
					variant: 'caption',
					color: 'GrayText',
					style: { wordBreak: 'break-all' },
				}}
				secondaryTypographyProps={{
					variant: 'body2',
					color: 'CaptionText',
					style: { wordBreak: 'break-all' },
				}}
			/>
			{isNoteUser && onEditNote && (
				<ListItemSecondaryAction>
					<IconButton
						sx={{ opacity: 0.5 }}
						size="small"
						onClick={() => onEditNote(note)}
					>
						<Icon path={mdiPencil} size={1} />
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};
