import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

// Icons
import Icon from '@mdi/react';
import { mdiFilterOutline, mdiClose } from '@mdi/js';

// Monorepo
import { Filter } from '@constituenthub/common';
import { Tooltip } from '@mui/material';

type Props = {
	filter: Filter | undefined;
	onClear: () => void;
	onClick: () => void;
};

export const FilterButton = ({ filter, onClear, onClick }: Props) => {
	let count = 0;
	if (filter) {
		filter.forEach((a) => {
			a.forEach((o) => {
				count += 1;
			});
		});
	}

	return (
		<ButtonGroup variant="outlined" aria-label="filters">
			<Tooltip title="Filter Results">
				<Button
					startIcon={<Icon path={mdiFilterOutline} size={1} />}
					onClick={onClick}
					sx={{ fontWeight: count > 0 ? 600 : 500 }}
				>
					Filters {count > 0 ? ` (${count})` : ''}
				</Button>
			</Tooltip>
			{count > 0 && (
				<Tooltip title="Clear Filters">
					<Button size="small" onClick={onClear}>
						<Icon path={mdiClose} size={1} />
					</Button>
				</Tooltip>
			)}
		</ButtonGroup>
	);
};
