import React from 'react';
import Box from '@mui/material/Box';

// Components
import { AppToolbar } from './AppToolbar';
import { TOOLBAR_HEIGHT } from '../../lib';

export interface LayoutProps {
	children: React.ReactNode;
}

export const MainLayout = (props: LayoutProps) => {
	const { children } = props;

	return (
		<>
			<AppToolbar />
			<Box
				data-component="MainLayout"
				sx={{
					position: 'absolute',
					top: `${TOOLBAR_HEIGHT}px`,
					left: 0,
					right: 0,
					bottom: 0,
					height: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
					overflow: 'hidden',
					backgroundColor: '#f6f6f6',
				}}
			>
				{children}
			</Box>
		</>
	);
};
