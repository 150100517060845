import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import Box from '@mui/material/Box';

type Props = {
	name?: string;
	children: React.ReactNode;
	sx?: SxProps<Theme>;
	fill?: boolean;
	nowrap?: boolean;
};

const fillSx: SxProps<Theme> = {
	flexGrow: 1,
	width: '100%',
	overflow: 'hidden',
};

export const FlexRow = ({ name, children, fill, nowrap, sx = {} }: Props) => {
	let style: SxProps<Theme> = {
		position: 'relative',
		display: 'flex',
		flexFlow: nowrap ? 'row nowrap' : 'row wrap',
	};

	if (fill) {
		style = {
			...style,
			...fillSx,
		};
	}

	return (
		<Box
			data-component={name}
			sx={{
				...style,
				...sx,
			}}
		>
			{children}
		</Box>
	);
};
