import React from 'react';

// Components
import { HeaderBase } from '../HeaderBase';

// Lib
import { HeaderRenderProps } from '../../types';

interface Props extends HeaderRenderProps {}

export const RowNumberHeaderRenderer = (props: Props) => {
	return (
		<HeaderBase
			{...props}
			style={{
				...props.style,
				justifyContent: 'flex-end',
			}}
		>
			#&nbsp;
		</HeaderBase>
	);
};
