import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';

import { ToastContainer } from 'react-toastify';
import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// Package Styles
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';

// Font
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';

// Components
import Routes from './Routes';
import { NetworkStatus } from './contexts/NetworkStatusContext';
import { AppContext } from './contexts/AppContext';
import { AppDataContextProvider } from './contexts/AppDataContext';
import { ProjectContextProvider } from './contexts/ProjectContext';

// Lib
import { store } from './store';
import awsconfig from './aws-exports';
import { defaultTheme } from './lib';

import { ApplicationEventContextProvider } from './contexts/ApplicationEventContext';

Amplify.configure(awsconfig);

const inputGlobalStyles = <GlobalStyles styles={{}} />;

ReactDOM.render(
	<ThemeProvider theme={defaultTheme}>
		<CssBaseline />
		{inputGlobalStyles}
		<BrowserRouter>
			<ReduxProvider store={store}>
				<ApplicationEventContextProvider>
					<NetworkStatus>
						<AppContext>
							<AppDataContextProvider>
								<>
									<ProjectContextProvider>
										<LocalizationProvider
											dateAdapter={DateAdapter}
										>
											<Routes />
										</LocalizationProvider>
									</ProjectContextProvider>
									<ToastContainer
										position="bottom-right"
										autoClose={2500}
										hideProgressBar={false}
										newestOnTop={false}
										closeOnClick
										rtl={false}
										pauseOnFocusLoss
										draggable
										pauseOnHover
									/>
								</>
							</AppDataContextProvider>
						</AppContext>
					</NetworkStatus>
				</ApplicationEventContextProvider>
			</ReduxProvider>
		</BrowserRouter>
	</ThemeProvider>,
	document.getElementById('root')
);
