import React from 'react';
import Checkbox from '@mui/material/Checkbox';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps } from '../../types';

interface Props extends CellRenderProps {
	isChecked: boolean;
	onToggleChecked: () => void;
}

export const SelectionCellRenderer = (props: Props) => {
	if (props.getId() === -1) {
		return (
			<CellBase {...props} style={props.style}>
				&nbsp;
			</CellBase>
		);
	}

	return (
		<CellBase onDoubleClick={() => {}} {...props} style={props.style}>
			<Checkbox
				checked={props.isChecked}
				onChange={props.onToggleChecked}
			/>
		</CellBase>
	);
};
