import {
	ActionCard,
	ActionCardView,
	Entity,
	ActionCardListOptions,
	buildUrlListOptions,
} from '@constituenthub/common';
import { ApplicationEvent } from '../lib/ApplicationEvent';
import { ApiClientInterface } from './ApiClient';

export default class ActionCardApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listActionCards(
		options: ActionCardListOptions
	): Promise<ActionCardView[] | number> {
		const url = buildUrlListOptions('/action', options);
		const response = await this.client.get<ActionCardView[] | number>(url);
		return response.result;
	}

	public async createActionCard(
		data: Partial<ActionCard>
	): Promise<ActionCard> {
		const response = await this.client.post<
			Partial<ActionCard>,
			ActionCard
		>(`/action`, data);
		ApplicationEvent.NewEntity(Entity.ActionCard, response.result);
		return response.result;
	}

	public async getActionCard(actionId: number): Promise<ActionCard> {
		const response = await this.client.get<ActionCard>(
			`/action/${actionId}`
		);
		ApplicationEvent.UpdateEntity(Entity.ActionCard, response.result);
		return response.result;
	}

	public async updateActionCard(data: ActionCard): Promise<ActionCard> {
		const response = await this.client.put<ActionCard, ActionCard>(
			`/action/${data.actionId}`,
			data
		);
		ApplicationEvent.UpdateEntity(Entity.ActionCard, response.result);
		return response.result;
	}

	public async updateActionCards(data: ActionCard[]): Promise<ActionCard[]> {
		const response = await this.client.put<ActionCard[], ActionCard[]>(
			`/action`,
			data
		);
		ApplicationEvent.UpdateMultipleEntities(
			Entity.ActionCard,
			response.result
		);
		return response.result;
	}

	public async removeActionCard(actionId: number): Promise<void> {
		await this.client.del<ActionCard>(`/action/${actionId}`);
		ApplicationEvent.RemoveEntity(Entity.ActionCard, actionId);
	}

	public async removeActionCards(actionIds: number[]): Promise<void> {
		await this.client.del<void, number[]>(`/action`, actionIds);
		ApplicationEvent.RemoveMultipleEntities(Entity.ActionCard, actionIds);
	}
}
