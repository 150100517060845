import { useAppSelector } from '../../store';

// Monorepo
import {
	Approach,
	FieldDefinition,
	FieldTypes,
	Filter,
	formatDate,
	User,
} from '@constituenthub/common';

// Lib
import { selectApproaches, selectUsers } from '../../store/application';

type Props = {
	filter: Filter | undefined;
	definition: Record<string, FieldDefinition>;
};

const getFieldName = (
	field: string,
	definition: Record<string, FieldDefinition>
): string => {
	console.log('getFieldName', field, definition);
	const item = Object.values(definition).find((x) => x.property === field);
	return item?.label || 'Unknown Field';
};

type ValueData = {
	users: User[];
	approaches: Approach[];
};

const getValue = (
	data: ValueData,
	definition: Record<string, FieldDefinition>,
	field: string,
	value: any
): string => {
	if (!value) return '';
	const def = Object.values(definition).find((x) => x.property === field);
	if (def?.type === FieldTypes.User) {
		const user = data.users.find((x) => x.userId === value);
		return user ? `${user.first} ${user.last}` : 'Unknown User';
	} else if (def?.type === FieldTypes.Approach) {
		const approach = data.approaches.find((x) => x.approachId === value);
		return approach ? `${approach.name}` : 'Unknown Approach';
	}
	return value as string;
};

export const FilterDescription = ({ filter, definition }: Props) => {
	const users = useAppSelector(selectUsers);
	const approaches = useAppSelector(selectApproaches);

	if (!filter) {
		return <span>None</span>;
	}

	const ands: string[] = [];
	filter.forEach((and) => {
		const ors: string[] = [];
		and.forEach((or) => {
			const fieldName = getFieldName(or.field, definition);
			const value = getValue(
				{ users, approaches },
				definition,
				or.field,
				or.val
			);
			ors.push(
				`<strong>${fieldName}</strong> <span class="op">${or.op}</span> <strong>${value}</strong>`
			);
		});
		ands.push(`${ors.join(' <br/><span class="condition">OR</span> ')}`);
	});

	const desc = `<span class="condition">WHERE</span> ${ands.join(
		' <br/><span class="condition">AND</span> '
	)}`;
	return (
		<span
			className="filter-description"
			dangerouslySetInnerHTML={{ __html: desc }}
		></span>
	);
};
