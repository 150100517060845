import React, { useContext, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';

// Lib
import { Splash } from '../features/common';

export interface NetworkStatusContextData {
	offline: boolean;
}

const initialValue: NetworkStatusContextData = {
	offline: false,
};

export const NetworkStatusContext =
	React.createContext<NetworkStatusContextData>(initialValue);

export interface NetworkStatusContextProps {
	children: React.ReactNode;
}

export const NetworkStatus = (props: NetworkStatusContextProps) => {
	const { children } = props;
	const [state, setState] = useState<NetworkStatusContextData>(initialValue);

	const updateOnlineStatus = useCallback(() => {
		if (window && navigator) {
			setState((s) => ({ ...s, offline: navigator.onLine !== true }));
		}
	}, []);

	useEffect(() => {
		if (window && navigator) {
			window.addEventListener('online', updateOnlineStatus);
			return () =>
				window.removeEventListener('online', updateOnlineStatus);
		}
	}, [updateOnlineStatus]);

	useEffect(() => {
		if (window && navigator) {
			window.addEventListener('offline', updateOnlineStatus);
			return () =>
				window.removeEventListener('offline', updateOnlineStatus);
		}
	}, [updateOnlineStatus]);

	return (
		<NetworkStatusContext.Provider value={{ ...state }}>
			{state.offline === true && (
				<Splash hideLinks={state.offline}>
					<Box
						sx={{
							padding: 2,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<h3>Offline</h3>
						<p>
							The application is offline. Please check your
							internet connection.
						</p>
					</Box>
				</Splash>
			)}
			{state.offline === false && <>{children}</>}
		</NetworkStatusContext.Provider>
	);
};

export const useNetworkStatusContext = (): NetworkStatusContextData => {
	return useContext(NetworkStatusContext);
};
