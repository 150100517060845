import React, { useRef } from 'react';

// Components
import { HeaderRenderer } from '../HeaderRenderer';
import { TextRenderer } from './TextRenderer';

// Lib
import {
	DataColumn,
	RendererApi,
	CellRenderProps,
	CellProps,
	HeaderRenderProps,
	SortDirection,
} from '../../types';

export const TextRendererName = 'Text Renderer';

export interface TextRendererOptions extends DataColumn {
	onToggleSort?: (direction: SortDirection) => void;
	onChange?: (props: CellProps, value: string) => void;
	onEditComplete?: (
		props: CellProps,
		value: string,
		keyboardEvent: React.KeyboardEvent<HTMLDivElement> | null
	) => void;
	onCancelEdit?: (props: CellProps) => void;
	onAddClick?: (props: CellProps) => void;
	isEditing?: (props: CellProps) => boolean;
	getEditValue?: (props: CellProps) => string;
	onDetailClick?: (props: CellProps) => void;
	onDoubleClick?: (
		event: React.MouseEvent<HTMLDivElement>,
		props: CellProps
	) => void;
}

export interface TextRendererApi extends RendererApi {}

export const useTextRenderer = (
	options: TextRendererOptions
): TextRendererApi => {
	const { field, label, width, sort, isEditing } = options;
	const inputRef = useRef<HTMLInputElement>(null);

	const handleOnChange = (props: CellProps, value: string) => {
		if (options.onChange) options.onChange(props, value);
	};

	const handleOnCancelEdit = (props: CellProps) => {
		if (options.onCancelEdit) options.onCancelEdit(props);
	};

	const handleOnAddClick = (props: CellProps) => {
		if (options.onAddClick) options.onAddClick(props);
	};

	const handleOnComplete = (
		props: CellProps,
		value: string,
		keyboardEvent: React.KeyboardEvent<HTMLDivElement> | null
	) => {
		if (options.onEditComplete)
			options.onEditComplete(props, value, keyboardEvent);
	};

	const renderHeader = (props: HeaderRenderProps) => {
		return (
			<HeaderRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				onToggleSort={options.onToggleSort}
				align="left"
				{...props}
			/>
		);
	};

	const renderCell = (props: CellRenderProps) => {
		return (
			<TextRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				inputRef={inputRef}
				isEditing={isEditing ? isEditing(props) : false}
				onChange={handleOnChange}
				onComplete={handleOnComplete}
				onCancelEdit={handleOnCancelEdit}
				onAddClick={options.onAddClick ? handleOnAddClick : undefined}
				onDetailClick={options.onDetailClick}
				onDoubleClick={options.onDoubleClick}
				editValue={
					options.getEditValue ? options.getEditValue(props) : ''
				}
				{...props}
			/>
		);
	};

	return {
		name: TextRendererName,
		type: 'Data',
		field,
		label,
		width,
		sort,
		renderHeader,
		renderCell,
	};
};
