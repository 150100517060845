
export class ResponseError extends Error {
  type: string;
  status: number;

  constructor(message: string, type: string, status: number) {
    super(message);
    Error.captureStackTrace(this, this.constructor);
    this.name = this.constructor.name;
    this.type = type;
    this.status = status;
  }
}

export class BadRequestException extends ResponseError {
  constructor(message: string) {
    super(message, 'Bad Request', 400);
  }
}

export class UnauthorizedException extends ResponseError {
  constructor(message: string = 'Unauthorized') {
    super(message, 'Authentication Required', 401);
  }
}

export class ForbiddenException extends ResponseError {
  constructor(message: string = 'Insufficient permissions') {
    super(message, 'Insufficient Permissions', 403);
  }
}

export class NotFoundException extends ResponseError {
  constructor(message: string = 'Resource not found') {
    super(message, 'Not Found', 404);
  }
}

export class ApplicationException extends ResponseError {
  constructor(message: string) {
    super(message, 'Application Error', 500);
  }
}

export class NotImplementedException extends ResponseError {
  constructor(message: string) {
    super(message, 'Application Error', 501);
  }
}

export class ServiceException extends ResponseError {
  constructor(service: string) {
    super(`The ${service} is unavailable`, 'Service Unavailable', 503);
  }
}

export class DatabaseNameUniqueException extends ResponseError {
  constructor(name: string) {
    super(`The name '${name}' is not unique`, 'Bad Request', 400);
  }
}

export class DatabaseSingleResultException extends ResponseError {
  constructor() {
    super('A single result was expected', 'Bad Request', 400);
  }
}

export class DataException extends ResponseError {
  constructor(message: string) {
    super(`There is an issue with the associated data: ${message}`, 'Not Found', 404);
  }
}