import React from 'react';

// Components
import { SelectionCellRenderer } from './SelectionCellRenderer';
import { SelectionHeaderRenderer } from './SelectionHeaderRenderer';

// Lib
import {
	RendererApi,
	CellProps,
	HeaderProps,
	CellRenderProps,
	HeaderRenderProps,
} from '../../types';

export const CheckboxSelectionRendererName = 'Checkbox Selection Renderer';

export interface CheckboxSelectionRendererOptions {
	isChecked: (props: CellProps) => boolean;
	toggleChecked: (props: CellProps) => void;
	isAllChecked: (props: HeaderProps) => boolean;
	toggleAllChecked: (props: HeaderProps) => void;
}

export interface CheckboxSelectionRendererApi extends RendererApi {}

export const useCheckboxSelectionRenderer = (
	options: CheckboxSelectionRendererOptions
): CheckboxSelectionRendererApi => {
	const renderHeader = (props: HeaderRenderProps) => {
		return (
			<SelectionHeaderRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				isChecked={options.isAllChecked(props)}
				onToggleChecked={() => options.toggleAllChecked(props)}
				{...props}
			/>
		);
	};

	const renderCell = (props: CellRenderProps) => {
		return (
			<SelectionCellRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				isChecked={options.isChecked(props)}
				onToggleChecked={() => options.toggleChecked(props)}
				{...props}
			/>
		);
	};

	return {
		name: CheckboxSelectionRendererName,
		type: 'Action',
		field: '_selection',
		label: 'Selection',
		width: 50,
		sort: 'off',
		renderHeader,
		renderCell,
	};
};
