import React, { useState } from 'react';
import { get } from 'lodash';

// Monorepo
import { ChangeItem } from '@constituenthub/common';

// Components
import {
	Table,
	CellProps,
	useCheckboxSelectionRenderer,
	useRowNumberRenderer,
	useImpactRenderer,
	useTextRenderer,
	useRowHoverPlugin,
	useRowSelectPlugin,
	useUserRenderer,
} from '../table';

// Lib
import { ChangeItemsHook } from './useChangeItemsController';

type ChangeState = {
	rowIndex: number | null;
	value: string;
};

const initialState: ChangeState = {
	rowIndex: null,
	value: '',
};

type Props = {
	controller: ChangeItemsHook;
};

export const ChangeItemList = (props: Props) => {
	const {
		definition,
		changeItems,
		isChangeItemSelected,
		isAllChangeItemsSelected,
		toggleSelectedChangeItem,
		toggleAllSelectedChangeItems,
		selectOneChangeItem,
		onAddNewChangeItem,
		onChangeItemUpdated,
		onChangeItemRemoved,
		onLoadMoreChangeItems,
	} = props.controller;
	const [state, setState] = useState<ChangeState>(initialState);

	const handleOnChange = (props: CellProps, value: string) => {
		setState({ rowIndex: props.rowIndex, value });
	};

	const handleOnEditComplete = (
		props: CellProps,
		value: string,
		keyboardEvent: React.KeyboardEvent<HTMLDivElement> | null
	) => {
		console.log('handleOnEditComplete');
		if (state.rowIndex !== props.rowIndex) {
			console.error('Row Mismatch');
			return;
		}

		// If the last item only has a name and it is empty, delete it
		if (props.rowIndex + 1 === props.rows.length && !value) {
			const fields = (props.row as ChangeItem).fields;
			if (Object.keys(fields).length === 1 && !fields.name) {
				onChangeItemRemoved(props.row as ChangeItem);
			}
			setState(initialState);
			return;
		}

		onChangeItemUpdated({
			...props.row,
			fields: { ...props.row.fields, name: value },
		});

		if (keyboardEvent && keyboardEvent.key === 'Enter') {
			if (props.rowIndex + 1 === props.rows.length) {
				handleOnAddClick(props);
				return;
			}
		}

		setState(initialState);
	};

	const handleOnEditCanceled = async (props: CellProps) => {
		// TODO: Remove Item?
		console.log('handleOnEditCanceled');

		// If the last item only has a name and it is empty, delete it
		if (props.rowIndex + 1 === props.rows.length) {
			const fields = (props.row as ChangeItem).fields;
			if (Object.keys(fields).length === 1 && fields.name === '') {
				onChangeItemRemoved(props.row as ChangeItem);
			}
		}

		setState(initialState);
	};

	const handleOnAddClick = async (props: CellProps) => {
		const item = await onAddNewChangeItem();
		if (item) {
			setState({
				rowIndex: props.rowIndex + 1,
				value: get(item, props.column.field, ''),
			});
		}
	};

	// Renderers
	const selectionColumn = useCheckboxSelectionRenderer({
		isChecked: (props) => isChangeItemSelected(props.getId() as number),
		toggleChecked: (props) =>
			toggleSelectedChangeItem(props.getId() as number),
		isAllChecked: () => isAllChangeItemsSelected(),
		toggleAllChecked: () => toggleAllSelectedChangeItems(),
	});

	const rowNumber = useRowNumberRenderer();

	const name = useTextRenderer({
		field: `fields.${definition.Name.property}`,
		label: definition.Name.label,
		sort: 'off',
		width: 400,
		onAddClick: handleOnAddClick,
		onCancelEdit: handleOnEditCanceled,
		onChange: handleOnChange,
		onEditComplete: handleOnEditComplete,
		isEditing: (props) => state.rowIndex === props.rowIndex,
		getEditValue: () => state.value || '',
		onDetailClick: (props) => selectOneChangeItem(props.getId() as number),
		onDoubleClick: (_event, props) => {
			setState({
				rowIndex: props.rowIndex,
				value: get(props.row, props.column.field, ''),
			});
		},
	});

	const area = useTextRenderer({
		field: `fields.${definition.ChangeArea.property}`,
		label: definition.ChangeArea.label,
		sort: 'off',
		width: 300,
	});

	const impact = useImpactRenderer({
		field: `fields.${definition.ChangeImpact.property}`,
		label: definition.ChangeImpact.label,
		sort: 'off',
		width: 140,
	});

	const teamMember = useUserRenderer({
		field: `fields.${definition.ResponsibleTeamMember.property}`,
		label: definition.ResponsibleTeamMember.label,
		sort: 'off',
		width: 200,
	});

	// Plugins
	const rowHover = useRowHoverPlugin();

	const rowSelect = useRowSelectPlugin({
		isRowSelected: (props) => isChangeItemSelected(props.getId() as number),
	});

	// Virtual Add Item
	const rows = [...changeItems];
	rows.push({ changeItemId: -1, fields: {} } as ChangeItem);

	return (
		<Table
			rows={rows}
			getId={(item: ChangeItem) => item.changeItemId}
			columns={[
				selectionColumn,
				rowNumber,
				name,
				area,
				impact,
				teamMember,
			]}
			plugins={[rowHover, rowSelect]}
			onScrollBottom={onLoadMoreChangeItems}
			rowHeight={50}
			show={true}
		/>
	);
};
