import * as React from 'react';

// Monorepo
import { ChangeItem, ActionCard } from '@constituenthub/common';

// Components
import { MultipleAffected } from '../common';
import { useAppContext } from '../../contexts/AppContext';
import { AddTaskDialog } from '../cards/AddTaskDialog';

type Props = {
	changeItems: ChangeItem[];
	onContinue: (cards: ActionCard[]) => Promise<any>;
	onClose: () => void;
};

export const AddChangeItemActionsDialog = (props: Props) => {
	const { api } = useAppContext();
	const { changeItems, onContinue, onClose } = props;

	const handleOnContinue = async (action: Partial<ActionCard>) => {
		try {
			const cards = await api.changeItemAction.createActionCards(
				action,
				changeItems
			);
			return onContinue(cards);
		} catch (error) {
			throw error;
		}
	};

	const fields = {
		assignedto:
			changeItems.length === 1
				? changeItems[0].fields.teamMember
				: undefined,
	};

	return (
		<AddTaskDialog
			bypassCreate={true}
			onClose={onClose}
			title="Add Change Item Action Cards"
			defaults={fields}
			onContinue={handleOnContinue}
		>
			{changeItems.length > 1 && (
				<MultipleAffected
					expandByDefault
					items={changeItems}
					message={`Action Cards will be created for each of the following`}
					getText={(item: ChangeItem) => item.fields.name as string}
					getId={(item: ChangeItem) => item.changeItemId}
				/>
			)}
		</AddTaskDialog>
	);
};
