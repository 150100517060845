/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Monorepo
import { Approach, User } from '@constituenthub/common';

// Lib
import type { RootState } from '.';

export interface State {
	users: User[];
	approaches: Approach[];
	error?: any;
}

const initialState: State = {
	users: [],
	approaches: [],
};

export const slice = createSlice({
	name: 'application',
	initialState,
	reducers: {
		loadApplicationData: (
			state,
			action: PayloadAction<{ users: User[]; approaches: Approach[] }>
		) => {
			state.approaches = action.payload.approaches;
			state.users = action.payload.users;
		},
		loadApplicationError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		updateUser: (state, action: PayloadAction<User>) => {
			const index = state.users.findIndex(
				(x) => x.userId === action.payload.userId
			);
			if (index !== -1) {
				state.users.splice(index, 1, action.payload);
			}
		},
		updateApproach: (state, action: PayloadAction<Approach>) => {
			const index = state.approaches.findIndex(
				(x) => x.approachId === action.payload.approachId
			);
			if (index !== -1) {
				state.approaches.splice(index, 1, action.payload);
			}
		},
		removeApproach: (state, action: PayloadAction<number>) => {
			const index = state.approaches.findIndex(
				(x) => x.approachId === action.payload
			);
			if (index !== -1) {
				state.approaches.splice(index, 1);
			}
		},
		addApproach: (state, action: PayloadAction<Approach>) => {
			state.approaches.push(action.payload);
		},
	},
});

export const {
	loadApplicationData,
	loadApplicationError,
	updateUser,
	updateApproach,
	removeApproach,
	addApproach,
} = slice.actions;

export const selectApplicationError = (state: RootState) =>
	state.application.error;

export const selectUsers = (state: RootState) => state.application.users;

export const selectApproaches = (state: RootState) =>
	state.application.approaches;

export default slice.reducer;
