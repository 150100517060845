/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'us-east-2',
	aws_cognito_identity_pool_id:
		'us-east-2:30ff1e99-5648-411a-8928-05745dc5d688',
	aws_cognito_region: 'us-east-2',
	aws_user_pools_id: 'us-east-2_b8Uh62Wrm',
	aws_user_pools_web_client_id: 'mof82bvgeeut2uqh81of4qvi9',
	oauth: {},
	aws_cognito_username_attributes: ['EMAIL'],
	aws_cognito_social_providers: [],
	aws_cognito_signup_attributes: ['EMAIL'],
	aws_cognito_mfa_configuration: 'OFF',
	aws_cognito_mfa_types: ['SMS'],
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength: 8,
		passwordPolicyCharacters: [],
	},
	aws_cognito_verification_mechanisms: ['EMAIL'],
	// "aws_content_delivery_bucket": "changeimpactplus-app-hosting-dev",
	// "aws_content_delivery_bucket_region": "us-east-2",
	// "aws_content_delivery_url": "https://d3cgfx2qwd4yra.cloudfront.net",
	aws_cloud_logic_custom: [
		{
			name: 'api',
			// endpoint: 'http://localhost:8080',
			endpoint:
				'https://6uz6ec7f8j.execute-api.us-east-2.amazonaws.com/prod',
			region: 'us-east-2',
		},
	],
	aws_cognito_login_mechanisms: ['EMAIL'],
	Storage: {
		AWSS3: {
			bucket: 'cip-prod-files-bucket',
			region: 'us-east-2',
		},
	},
};

export default awsmobile;
