import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Monorepo
import { JobRole, JobRoleApproachAction } from '@constituenthub/common';

// Components
import { ErrorMessage, Loading, NothingHere } from '../common';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { ActionCardTile } from '../cards/ActionCardTile';
import { TaskDialog } from '../cards/TaskDialog';

type Props = {
	selectedJobRoles: JobRole[];
};

type State = {
	status: 'idle' | 'loading' | 'loaded' | 'error';
	error?: Error;
	jobRoleApproaches: JobRoleApproachAction[];
};

const initialState: State = {
	status: 'idle',
	jobRoleApproaches: [],
};

export const AffectedJobRolesHistory = (props: Props) => {
	const { api } = useAppContext();
	const { selectedJobRoles } = props;
	const [state, setState] = React.useState<State>(initialState);
	const [openCardDetail, setOpenCardDetail] = React.useState<number>();

	const loadApproaches = React.useCallback(async () => {
		setState((s) => ({ ...s, status: 'loading' }));
		try {
			const data = await api.jobRoleApproach.listJobRoleApproaches(
				selectedJobRoles.map((x) => x.jobRoleId),
				true
			);
			setState((s) => ({
				...s,
				status: 'loaded',
				jobRoleApproaches: data,
				error: undefined,
			}));
		} catch (error) {
			setState((s) => ({ ...s, status: 'error', error: error as Error }));
		}
	}, [api.jobRoleApproach, selectedJobRoles]);

	React.useEffect(() => {
		if (state.status === 'idle') {
			loadApproaches();
		}
	}, [loadApproaches, state.status]);

	return (
		<>
			<Grid
				container
				direction="column"
				flexGrow={1}
				wrap="nowrap"
				sx={{ height: '100%', overflow: 'hidden' }}
			>
				<Loading
					enabled={state.status === 'loading'}
					text="Working..."
				/>
				<ErrorMessage
					error={state.error}
					sx={{ minWidth: '320px' }}
					onRetry={() => loadApproaches()}
				/>
				{state.jobRoleApproaches.length > 0 && (
					<Grid
						container
						direction="column"
						flexGrow={1}
						spacing={2}
						wrap="nowrap"
						sx={{
							position: 'relative',
							height: '100%',
							overflow: 'hidden',
						}}
					>
						{state.jobRoleApproaches.map((item) => (
							<Grid item key={item.jobRoleApproachId}>
								<ActionCardTile
									action={item.action}
									onOpenCard={() =>
										setOpenCardDetail(item.actionId)
									}
								/>
							</Grid>
						))}
					</Grid>
				)}
				{state.status === 'loaded' &&
					state.jobRoleApproaches.length === 0 && (
						<NothingHere>
							<Typography variant="caption">
								There is no history at this time
							</Typography>
						</NothingHere>
					)}
			</Grid>
			{!!openCardDetail && (
				<TaskDialog
					onClose={() => setOpenCardDetail(undefined)}
					onContinue={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(setOpenCardDetail(undefined));
					}}
					actionId={openCardDetail as number}
				/>
			)}
		</>
	);
};
