import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Monorepo
import { Group, JobRole } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { SelectGroupList } from './SelectGroupList';
import { GroupForm } from './GroupForm';
import { useEditGroup } from './useEditGroup';
import { useSelection } from '../../hooks/useSelection';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	open: boolean;
	onClose: () => void;
	onGroupsSelected: (groups: Group[]) => Promise<JobRole[]>;
};

type State = {
	working: boolean;
	error?: Error;
};

const initialState = {
	working: false,
};

export const SelectGroupDialog = (props: Props) => {
	const { api } = useAppContext();
	const { open, onClose, onGroupsSelected } = props;
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [groups, setGroups] = useState<Group[]>([]);
	const [state, setState] = useState<State>(initialState);
	const selection = useSelection();

	const {
		isGroupValid,
		onCreateGroup,
		onGroupChanged,
		onFlagsChange,
		validationErrors,
		group,
		isWorking,
		error,
		onResetError,
		flags,
	} = useEditGroup({
		onGroupCreated: (group) => {
			selection.toggleSelected(group.groupId);
			setSelectedTabIndex(0);
			setGroups((s) => {
				const ns = [...s];
				ns.push(group);
				return ns;
			});
		},
	});

	const handleClose = () => {
		onClose();
	};

	const handleResetError = () => {
		if (!!error) {
			onResetError();
		} else if (!!state.error) {
			setState((s) => ({ ...s, error: undefined }));
		}
	};

	const handleAction = async () => {
		if (selectedTabIndex === 0) {
			const selected = selection.getSelected();
			const selectedGroups = groups.filter((x) =>
				selected.includes(x.groupId)
			);
			setState((s) => ({ working: true }));
			try {
				await onGroupsSelected(selectedGroups);
				setState((s) => ({ working: false, error: undefined }));
			} catch (error) {
				setState((s) => ({ working: false, error: error as Error }));
			}
		} else if (selectedTabIndex === 1) {
			setState((s) => ({ working: true }));
			try {
				await onCreateGroup();
				setState((s) => ({ working: false, error: undefined }));
			} catch (error) {
				setState((s) => ({ working: false, error: error as Error }));
			}
		}
	};

	const loadGroups = useCallback(async () => {
		try {
			const results = await api.group.listGroups();
			setGroups(results);
		} catch (error) {
			console.log(error);
		}
	}, [api.group]);

	useEffect(() => {
		loadGroups();
	}, [loadGroups]);

	const hasErrors = !!error || !!state.error;
	const currentError = error || state.error;
	const isBusy = isWorking || state.working;

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			minWidth={600}
			label={selectedTabIndex === 0 ? 'Select Group(s)' : 'Add Group'}
			actions={
				<>
					{!hasErrors && selectedTabIndex === 0 && (
						<Button
							variant="contained"
							onClick={handleAction}
							disabled={isBusy}
						>
							Select
						</Button>
					)}
					{!hasErrors && selectedTabIndex === 1 && (
						<Button
							variant="contained"
							onClick={handleAction}
							disabled={isBusy || !isGroupValid()}
						>
							Create Group
						</Button>
					)}
					{hasErrors && (
						<Button variant="contained" onClick={handleResetError}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Tabs
					value={selectedTabIndex}
					onChange={(_event, index) => setSelectedTabIndex(index)}
					aria-label="Group Options"
				>
					<Tab disabled={isBusy} label="Groups" />
					<Tab disabled={isBusy} label="Add Group" />
				</Tabs>
				<Grid
					container
					direction="column"
					flexGrow={1}
					wrap="nowrap"
					sx={{ p: 2, height: '100%', overflow: 'hidden' }}
				>
					<Loading enabled={isBusy} text="Working..." />
					<ErrorMessage
						error={currentError}
						sx={{ minWidth: '320px' }}
					/>

					{selectedTabIndex === 0 && !hasErrors && !isBusy && (
						<SelectGroupList
							groups={groups}
							selectedGroups={selection.getSelected()}
							onToggleSelected={selection.toggleSelected}
						/>
					)}

					{selectedTabIndex === 1 && !hasErrors && !isBusy && (
						<GroupForm
							group={group}
							flags={flags}
							onChange={onGroupChanged}
							onFlagsChange={onFlagsChange}
							errors={validationErrors}
						/>
					)}
				</Grid>
			</>
		</ModalDialog>
	);
};
