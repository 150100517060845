import * as React from 'react';
import List from '@mui/material/List';
import { SxProps, Theme } from '@mui/system';
import { orderBy } from 'lodash';
import { Storage } from 'aws-amplify';

// Monorepo
import { Attachment, BrowserSupportedTypes } from '@constituenthub/common';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { ErrorMessage, FlexColumn, Loading } from '../common';
import { AttachmentListItem } from './AttachmentListItem';
import { AttachmentDialog } from './AttachmentDialog';
import { Upload } from './Upload';

type Props = {
	parentId?: number;
	entity?: string;
	onAttachmentCount?: (count: number) => void;
	sx?: SxProps<Theme>;
};

type State = {
	loaded: boolean;
	working: boolean;
	items: Attachment[];
	error?: any;
};

const initialState: State = {
	loaded: false,
	working: true,
	items: [],
};

export const getDownloadUrl = async (key: string): Promise<string> => {
	const url = await Storage.get(key, {
		download: false,
		level: 'public',
	});
	return url as string;
};

export const download = async (attachment: Attachment) => {
	const result = await Storage.get(attachment.path, {
		download: true,
		level: 'public',
	});

	if (!result.Body) {
		return;
	}

	let filename = attachment.name.endsWith(attachment.ext)
		? attachment.name
		: `${attachment.name}.${attachment.ext}`;

	const url = URL.createObjectURL(result.Body as Blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename || 'download';
	const clickHandler = () => {
		setTimeout(() => {
			URL.revokeObjectURL(url);
			a.removeEventListener('click', clickHandler);
		}, 150);
	};
	a.addEventListener('click', clickHandler, false);
	a.click();
};

export const AttachmentContainer = ({
	parentId,
	entity,
	onAttachmentCount,
	sx = {},
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);
	const [dialogAttachment, setDialogAttachment] =
		React.useState<Attachment>();

	const loadItems = React.useCallback(
		async (entity: string, parentId: number) => {
			setState((s) => ({
				...s,
				working: true,
				loaded: false,
				error: undefined,
			}));
			try {
				const attachments = await api.attachment.listAttachments(
					entity,
					parentId
				);
				setState((s) => ({
					...s,
					working: false,
					loaded: true,
					items: attachments,
				}));
			} catch (error) {
				console.error(error);
				setState((s) => ({
					...s,
					working: false,
					loaded: true,
					error: error,
				}));
			}
		},
		[api.attachment]
	);

	const handleAttachmentUpdated = (attachment: Attachment) => {
		setState((s) => {
			const ns = { ...s };
			const items = [...ns.items];
			const index = items.findIndex(
				(x) => x.attachmentId === attachment.attachmentId
			);
			if (index !== -1) {
				items[index] = { ...attachment };
			}
			ns.items = items;
			return ns;
		});
		setDialogAttachment(undefined);
	};

	const handleAttachmentDeleted = (attachment: Attachment) => {
		setState((s) => {
			const ns = { ...s };
			const items = [...ns.items];
			const index = items.findIndex(
				(x) => x.attachmentId === attachment.attachmentId
			);
			if (index !== -1) {
				items.splice(index, 1);
			}
			ns.items = items;
			return ns;
		});
		setDialogAttachment(undefined);
	};

	const handleAddAttachments = React.useCallback(
		async (attachments: Attachment[]) => {
			setState((s) => {
				const items = [...s.items];
				attachments.forEach((x) => items.push(x));
				return {
					...s,
					items: orderBy(items, ['createdAt'], ['desc']),
				};
			});
		},
		[]
	);

	const handleOnClick = (attachment: Attachment) => {
		if (BrowserSupportedTypes.includes(attachment.ext)) {
			getDownloadUrl(attachment.path)
				.then((url) => {
					window.open(url);
				})
				.catch(console.error);
		} else {
			download(attachment);
		}
	};

	const handleOnEdit = (attachment: Attachment) => {
		setDialogAttachment(attachment);
	};

	React.useEffect(() => {
		if (parentId && entity) {
			loadItems(entity, parentId);
		}
	}, [parentId, entity, loadItems]);

	React.useEffect(() => {
		if (onAttachmentCount) {
			onAttachmentCount(state.items.length);
		}
	}, [onAttachmentCount, state.items.length]);

	if (!entity || !parentId) {
		return null;
	}

	return (
		<>
			<FlexColumn name="attachment-container" fill sx={sx}>
				<Upload
					entity={entity}
					parentId={parentId}
					onUploadComplete={handleAddAttachments}
					onError={(err) => {
						setState((s) => ({
							...s,
							working: false,
							loaded: true,
							error: err,
						}));
					}}
				/>
				<FlexColumn
					fill
					scroll
					sx={{ height: 'calc(100% - 120px)', pt: 1 }}
				>
					<ErrorMessage
						error={state.error}
						onRetry={() => loadItems(entity, parentId)}
					/>
					<Loading text="Loading..." enabled={state.working} />
					<List
						disablePadding
						dense
						sx={{
							ml: -2,
							mr: -1,
							display:
								state.loaded && !!state.items.length
									? 'initial'
									: 'none',
						}}
					>
						{state.items.map((item) => (
							<AttachmentListItem
								key={item.attachmentId}
								attachment={item}
								onClick={handleOnClick}
								onEdit={handleOnEdit}
							/>
						))}
					</List>
				</FlexColumn>
			</FlexColumn>
			{dialogAttachment && (
				<AttachmentDialog
					attachment={dialogAttachment}
					onClose={() => setDialogAttachment(undefined)}
					onAttachmentUpdated={handleAttachmentUpdated}
					onAttachmentDeleted={handleAttachmentDeleted}
				/>
			)}
		</>
	);
};
