import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import { View, ValidationError } from '@constituenthub/common';

// Components
import { TextField } from '../common/TextField';

type Props = {
	view: Partial<View>;
	onChange: (view: Partial<View>) => void;
	errors?: ValidationError[];
};

export const ViewForm = (props: Props) => {
	const { view, onChange, errors = [] } = props;

	const handleChange = (prop: string, value: string | null) => {
		onChange({ ...view, [prop]: value });
	};

	return (
		<Grid container direction="column" flexGrow={1} spacing={2}>
			<Grid item>
				<TextField
					label="Name"
					value={view.name || ''}
					onChange={(value) => handleChange('name', value)}
					error={!!errors.find((x) => x.name === 'name')}
					required={true}
				/>
			</Grid>
		</Grid>
	);
};
