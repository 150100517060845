import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';

// Icons
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';

// Monorepo
import { Group } from '@constituenthub/common';

// Components
import { MainLayout } from '../shell';
import { Drawer, NothingHere, Loading } from '../common';
import { GroupDetail } from './GroupDetail';
import { AddGroupDialog } from './AddGroupDialog';
import { GroupTable } from './GroupTable';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { TOOLBAR_HEIGHT } from '../../lib';
import { useAppSelector } from '../../store';
import { selectProject } from '../../store/project';

export const GroupPage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { api } = useAppContext();
	const project = useAppSelector(selectProject);
	const [isAddGroupOpen, setIsAddGroupOpen] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [groups, setGroups] = useState<Group[]>([]);
	const [, setError] = useState<Error>();

	const selectedGroupId = params.groupId ? parseInt(params.groupId) : null;

	const handleGroupSelected = (group: Group) => {
		navigate(
			`/project/${project?.projectId}/groups/${group.groupId}/${
				params.tab || 'detail'
			}`
		);
	};

	const handleGroupUpdated = (group: Group) => {
		setGroups((s) => {
			const ns = [...s];
			const index = ns.findIndex((x) => x.groupId === group.groupId);
			if (index !== -1) {
				ns.splice(index, 1, group);
			}
			return ns;
		});
	};

	const loadGroups = async () => {
		try {
			const data = await api.group.listGroups();
			setGroups(data);
		} catch (err) {
			setError(err as Error);
		} finally {
			setHasLoaded(true);
		}
	};

	useEffect(() => {
		loadGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const handleGroupCreated = () => {
		setIsAddGroupOpen(false);
		loadGroups();
	};

	return (
		<MainLayout>
			<Toolbar>
				<Button
					startIcon={<Icon path={mdiPlusCircleOutline} size={1} />}
					onClick={() => setIsAddGroupOpen(true)}
				>
					Add Group
				</Button>
			</Toolbar>
			<Drawer
				open={!!selectedGroupId}
				name="GroupPage"
				align="right"
				scrollable
				toolbar
				minWidth={450}
				variant={!!selectedGroupId ? 'permanent' : 'persistent'}
				content={
					selectedGroupId ? (
						<GroupDetail
							groupId={selectedGroupId}
							onGroupUpdated={handleGroupUpdated}
							onClose={() =>
								navigate(
									`/project/${project?.projectId}/groups`
								)
							}
						/>
					) : null
				}
			>
				<Box
					data-component="GroupPageContent"
					sx={{
						position: 'relative',
						width: '100%',
						height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						p: 2,
					}}
				>
					<>
						<Loading enabled={!hasLoaded} />
						{hasLoaded && groups.length === 0 && (
							<NothingHere>
								<Box sx={{ p: 2, textAlign: 'center' }}>
									<Typography component="div" variant="body1">
										It looks like you don't have any groups
										yet.
									</Typography>
								</Box>
								<Box sx={{ p: 2, textAlign: 'center' }}>
									<Button
										variant="outlined"
										onClick={() => setIsAddGroupOpen(true)}
									>
										Add your first group
									</Button>
								</Box>
							</NothingHere>
						)}
						{hasLoaded && groups.length !== 0 && (
							<GroupTable
								items={groups}
								onGroupSelected={handleGroupSelected}
								selectedGroupId={selectedGroupId}
							/>
						)}
					</>
				</Box>
			</Drawer>
			<AddGroupDialog
				open={isAddGroupOpen}
				onClose={() => setIsAddGroupOpen(false)}
				onGroupCreated={handleGroupCreated}
			/>
		</MainLayout>
	);
};
