import { ApplicationApiClient, ApiClientResponseError } from './ApiClient';
import ApiRetryClient from './ApiRetryClient';

import CommonApi from './common';
import UserApi from './user';
import ProjectApi from './project';
import ApproachApi from './approach';
import GroupApi from './group';
import ChangeItemApi from './changeitem';
import JobRoleApi from './jobrole';
import JobRoleApproachApi from './jobRoleApproach';
import ActionCardApi from './action';
import ActionBoardApi from './actionboard';
import ChangeItemActionApi from './changeItemAction';
import FieldValueApi from './fieldvalue';
import ViewApi from './view';
import NoteApi from './note';
import AttachmentApi from './attachment';

const client = new ApiRetryClient(ApplicationApiClient);

export const api = {
	ApiClientResponseError,
	setAccountHeader: (accountId: number) => {
		client.setAccountHeader(accountId);
	},
	setProjectHeader: (projectId: number) => {
		client.setProjectHeader(projectId);
	},
	common: new CommonApi(client),
	user: new UserApi(client),
	project: new ProjectApi(client),
	approach: new ApproachApi(client),
	group: new GroupApi(client),
	changeitem: new ChangeItemApi(client),
	jobrole: new JobRoleApi(client),
	jobRoleApproach: new JobRoleApproachApi(client),
	action: new ActionCardApi(client),
	actionboard: new ActionBoardApi(client),
	changeItemAction: new ChangeItemActionApi(client),
	fieldvalue: new FieldValueApi(client),
	view: new ViewApi(client),
	note: new NoteApi(client),
	attachment: new AttachmentApi(client),
};
