import React, { useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useDebounce } from 'react-use';
import { toast } from 'react-toastify';

// Icons
import { mdiClose } from '@mdi/js';

// Monorepo
import { Group, Flag } from '@constituenthub/common';

// Components
import { ErrorMessage, Loading, NothingHere, IconButton } from '../common';
import { GroupForm } from './GroupForm';
import { useAppContext } from '../../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { selectProject, selectProjectTheme } from '../../store/project';

type Props = {
	groupId: number;
	onGroupUpdated: (group: Group) => void;
	onClose?: () => void;
	sx?: SxProps<Theme>;
};

const getTabIndexFromParam = (params: any): number => {
	if (params?.tab === 'changeitems') return 1;
	if (params?.tab === 'approaches') return 2;
	return 0;
};

export const GroupDetail = (props: Props) => {
	const params = useParams();
	const navigate = useNavigate();
	const project = useAppSelector(selectProject);
	const theme = useAppSelector(selectProjectTheme);
	const { groupId, onGroupUpdated, onClose } = props;
	const { api } = useAppContext();
	const [group, setGroup] = useState<Group>();
	const [changes, setChanges] = useState<Partial<Group>>({});
	const [flags, setFlags] = useState<Flag[]>([]);
	const [error, setError] = useState<Error>();
	const selectedTabIndex = getTabIndexFromParam(params);

	const handleTabChange = (index: number) => {
		const tab =
			index === 0 ? 'detail' : index === 1 ? 'changeitems' : 'approaches';
		navigate(`/project/${project?.projectId}/groups/${groupId}/${tab}`);
	};

	const updateGroup = async () => {
		try {
			if (group && Object.keys(changes).length > 0) {
				const data = await api.group.updateGroup({
					...group,
					...changes,
				});
				setGroup(data);
				onGroupUpdated(data);
				toast(`${data.name} Updated`);
			}
		} catch (err) {
			setError(err as Error);
		}
	};

	const loadGroup = async () => {
		try {
			const data = await api.group.getGroup(groupId);
			setGroup(data);
			setChanges({});
			if (error) {
				setError(undefined);
			}
		} catch (err) {
			setError(err as Error);
		}
	};

	useDebounce(updateGroup, 1000, [changes]);

	useEffect(() => {
		loadGroup();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupId]);

	return (
		<>
			<AppBar position="relative">
				<Toolbar
					variant="dense"
					sx={{
						position: 'relative',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '100%',
					}}
				>
					<span style={{ flexGrow: 1 }}>
						{group?.name || 'Loading...'}
					</span>
					{!!onClose && (
						<IconButton
							onClick={onClose}
							path={mdiClose}
							title="Close"
							variant="small"
							edge="end"
							color={theme.palette.primary.contrastText}
						/>
					)}
				</Toolbar>
			</AppBar>
			<Tabs
				value={selectedTabIndex}
				onChange={(_event, index) => handleTabChange(index)}
				aria-label="Group Details"
			>
				<Tab label="Details" />
				<Tab label="Change Items" />
				<Tab label="Approaches" />
			</Tabs>
			<Grid container direction="column" flexGrow={1} sx={{ p: 2 }}>
				<ErrorMessage error={error} />
				<Loading enabled={!error && !group} text="Loading" />
				{!error && !!group && (
					<>
						{selectedTabIndex === 0 && (
							<GroupForm
								group={{ ...group, ...changes }}
								flags={flags}
								onChange={setChanges}
								onFlagsChange={setFlags}
							/>
						)}
						{selectedTabIndex === 1 && (
							<NothingHere>
								<Typography variant="caption">None</Typography>
							</NothingHere>
						)}
						{selectedTabIndex === 2 && (
							<NothingHere>
								<Typography variant="caption">None</Typography>
							</NothingHere>
						)}
					</>
				)}
			</Grid>
		</>
	);
};
