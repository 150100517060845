import * as React from 'react';
import Box from '@mui/material/Box';
import { lighten } from '@mui/system'; // Icons

import { mdiCheckboxBlankCircleOutline, mdiCircle } from '@mdi/js';

// Monorepo
import { FieldValue, ActionCardStatus } from '@constituenthub/common';

// Components
import { IconButton } from '../common';
import { useTheme } from '@mui/material';

type Props = {
	property: string;
	value: FieldValue;
	onChange: (value: FieldValue) => void;
	mixedValue?: boolean;
};

const getStatusColor = (status?: FieldValue): string => {
	if (status === ActionCardStatus.Green) return 'green';
	if (status === ActionCardStatus.Yellow) return 'orange';
	if (status === ActionCardStatus.Red) return 'red';
	return 'rgba(0,0,0,0.3)';
};

export const ButtonTaskStatus = ({
	property,
	value,
	onChange,
	mixedValue,
}: Props) => {
	const theme = useTheme();
	const mixedColor = lighten(theme.palette.primary.main, 0.9);
	return (
		<Box
			sx={{
				display: 'flex',
				flexFlow: 'row nowrap',
				alignItems: 'center',
				justifyContent: 'flex-end',
				mr: 3,
			}}
		>
			<IconButton
				path={
					value === ActionCardStatus.None
						? mdiCircle
						: mdiCheckboxBlankCircleOutline
				}
				title={
					value === ActionCardStatus.None
						? 'Current Status'
						: 'Set Status: None'
				}
				onClick={() => onChange(ActionCardStatus.None)}
				sx={{ mr: 1 }}
				color={
					mixedValue
						? mixedColor
						: getStatusColor(ActionCardStatus.None)
				}
			/>
			<IconButton
				path={
					value === ActionCardStatus.Green
						? mdiCircle
						: mdiCheckboxBlankCircleOutline
				}
				title={
					value === ActionCardStatus.Green
						? 'Current Status'
						: 'Set Status: Green'
				}
				onClick={() => onChange(ActionCardStatus.Green)}
				sx={{ mr: 1 }}
				color={
					mixedValue
						? mixedColor
						: getStatusColor(ActionCardStatus.Green)
				}
			/>
			<IconButton
				path={
					value === ActionCardStatus.Yellow
						? mdiCircle
						: mdiCheckboxBlankCircleOutline
				}
				title={
					value === ActionCardStatus.Yellow
						? 'Current Status'
						: 'Set Status: Yellow'
				}
				onClick={() => onChange(ActionCardStatus.Yellow)}
				sx={{ mr: 1 }}
				color={
					mixedValue
						? mixedColor
						: getStatusColor(ActionCardStatus.Yellow)
				}
			/>
			<IconButton
				path={
					value === ActionCardStatus.Red
						? mdiCircle
						: mdiCheckboxBlankCircleOutline
				}
				title={
					value === ActionCardStatus.Red
						? 'Current Status'
						: 'Set Status: Red'
				}
				onClick={() => onChange(ActionCardStatus.Red)}
				sx={{ mr: 1 }}
				color={
					mixedValue
						? mixedColor
						: getStatusColor(ActionCardStatus.Red)
				}
			/>
		</Box>
	);
};
