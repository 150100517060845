import React from 'react';
import { useLocation } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from 'react-toastify';

// Monorepo
import {
	ActionCardView,
	Entity,
	Filter,
	formatDate,
	parseFilterFromUrl,
	User,
} from '@constituenthub/common';

// Components
import { PrintLayout } from '../shell/PrintLayout';
import { ReportHeader } from '../common/ReportHeader';
import { ReportFilters } from '../common/ReportFilters';

import { useAppSelector } from '../../store';
import { selectUsers } from '../../store/application';
import { useAppContext } from '../../contexts/AppContext';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';

const cellSx: SxProps<Theme> = {
	whiteSpace: 'nowrap',
};

const getUser = (users: User[], id?: string) => {
	const user = users.find((x) => x.userId === id);
	return user ? `${user.first} ${user.last}` : 'Inactive User';
};

export const ActionCardReportTable = () => {
	const location = useLocation();
	const { api } = useAppContext();
	const users = useAppSelector(selectUsers);
	const definition = useFieldDefinitions(Entity.ActionCard);
	const [cards, setCards] = React.useState<ActionCardView[]>([]);

	const onLoadActionCards = React.useCallback(
		async (filter: Filter | undefined) => {
			try {
				const items = (await api.action.listActionCards({
					filter,
				})) as ActionCardView[];
				setCards(items);
			} catch (err) {
				toast.error(`Unable to load items: ${(err as Error).message}`);
			}
		},
		[api.action]
	);

	React.useEffect(() => {
		onLoadActionCards(parseFilterFromUrl(location.search));
	}, [location.search, onLoadActionCards]);

	return (
		<PrintLayout>
			<ReportHeader title="Action Card Table" />
			<ReportFilters definition={definition} />
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>
								<strong>Tash Short Name</strong>
							</TableCell>
							<TableCell>
								<strong>Type</strong>
							</TableCell>
							<TableCell align="center">
								<strong>Start</strong>
							</TableCell>
							<TableCell align="center">
								<strong>End</strong>
							</TableCell>
							<TableCell align="center">
								<strong>Complete</strong>
							</TableCell>
							<TableCell align="center">
								<strong>Stage</strong>
							</TableCell>
							<TableCell align="center">
								<strong>Status</strong>
							</TableCell>
							<TableCell align="center">
								<strong>Assigned To</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{cards.map((card) => (
							<TableRow
								key={card.actionId}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell component="th" scope="row">
									{card.fields.name}
								</TableCell>
								<TableCell sx={cellSx}>{card.entity}</TableCell>
								<TableCell align="center" sx={cellSx}>
									{formatDate(card.fields.start as string)}
								</TableCell>
								<TableCell align="center" sx={cellSx}>
									{formatDate(card.fields.end as string)}
								</TableCell>
								<TableCell align="center" sx={cellSx}>
									{formatDate(card.fields.complete as string)}
								</TableCell>
								<TableCell align="center" sx={cellSx}>
									{card.fields.stage}
								</TableCell>
								<TableCell align="center" sx={cellSx}>
									{card.fields.status}
								</TableCell>
								<TableCell align="center" sx={cellSx}>
									{getUser(
										users,
										card.fields.assignedto as string
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</PrintLayout>
	);
};
