import { View } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class ViewApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listViews(feature: string): Promise<View[]> {
		const response = await this.client.get<View[]>(
			`/view?feature=${feature}`
		);
		return response.result;
	}

	public async createView(data: Partial<View>): Promise<View> {
		const response = await this.client.post<Partial<View>, View>(
			`/view`,
			data
		);
		return response.result;
	}

	public async getView(viewId: number): Promise<View> {
		const response = await this.client.get<View>(`/view/${viewId}`);
		return response.result;
	}

	public async isNameUnique(
		feature: string,
		name: string,
		viewId?: number
	): Promise<boolean> {
		const response = await this.client.get<boolean>(
			`/view/validate/name/${feature}/${name}/${viewId}`
		);
		return response.result;
	}

	public async updateView(data: View): Promise<View> {
		const response = await this.client.put<View, View>(
			`/view/${data.viewId}`,
			data
		);
		return response.result;
	}

	public async removeView(viewId: number): Promise<void> {
		await this.client.del<View>(`/view/${viewId}`);
	}
}
