import React from 'react';
import { SxProps, Theme } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Monorepo
import {
	FieldDefinition,
	FieldTypes,
	FieldValue,
} from '@constituenthub/common';

// Component
import { AutoCompleteSelect } from '../common';
import { Editor } from '../editor';
import { FieldText } from './FieldText';
import { FieldDate } from './FieldDate';
import { FieldUser } from './FieldUser';
import { FieldApproach } from './FieldApproach';

type FieldProps = {
	entity: string;
	definition: FieldDefinition;
	value: FieldValue;
	onChange: (value: FieldValue) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	label?: string;
	placeholder?: string;
	sx?: SxProps<Theme>;
};

export const Field = (props: FieldProps) => {
	const {
		entity,
		definition,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
		label,
		placeholder,
		sx = {},
	} = props;

	if (definition.type === FieldTypes.User) {
		return (
			<FieldUser
				label={label || definition.label}
				value={(value as string) || ''}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
			/>
		);
	}

	if (definition.type === FieldTypes.Approach) {
		return (
			<FieldApproach
				label={label || definition.label}
				value={(value as number) || null}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
			/>
		);
	}

	if (definition.type === FieldTypes.Date) {
		return (
			<FieldDate
				label={label || definition.label}
				value={(value as string) || ''}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
			/>
		);
	}

	if (definition.type === FieldTypes.Text) {
		return (
			<FieldText
				entity={entity}
				definition={definition}
				label={label || definition.label}
				value={(value as string) || ''}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
				placeholder={
					placeholder !== undefined && placeholder !== null
						? placeholder
						: label
				}
			/>
		);
	}

	if (definition.type === FieldTypes.SelectList) {
		return (
			<AutoCompleteSelect
				label={label || definition.label}
				value={value as string}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
				error={error}
				autoOpen={false}
				delay={0}
				onChange={onChange}
				options={
					definition.options?.map((x) => ({
						label: x.value,
						value: x.value,
					})) || []
				}
				renderOption={(props, option, state) => (
					<ListItem {...props} key={`${option.value}`} disableGutters>
						<ListItemText primary={option.label} />
					</ListItem>
				)}
			/>
		);
	}

	if (definition.type === FieldTypes.Content) {
		return (
			<Editor
				label={label || definition.label}
				initialValue={value as string}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
				placeholder={
					placeholder !== undefined && placeholder !== null
						? placeholder
						: label
				}
				sx={sx}
			/>
		);
	}

	return <span>{definition.label}</span>;
};
