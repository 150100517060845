import { useState, useCallback } from 'react';
// import { useDebounce } from 'react-use';

// Monorepo
import {
	FieldsModel,
	FieldValue,
	FieldDefinition,
	getMixedValueFields,
} from '@constituenthub/common';

// Lib
import { mergeFields, mergeChanges } from './utils';
import { isEqual } from 'lodash';

export interface BatchEditFieldsOptions {
	definition: Record<string, FieldDefinition>;
	excludeProperties: string[];
}

export interface BatchEditFieldsResult {
	changes: Record<string, FieldValue>;
	mixedValues: string[];
	hasChanges: () => boolean;
	loadItems: (items: FieldsModel[]) => void;
	onChange: (prop: string, value: FieldValue) => void;
	onMultipleChanges: (fields: Record<string, FieldValue>) => void;
	getUpdates: () => FieldsModel[];
	// saveChanges: () => void;
}

type ChangeState = {
	// The original items
	items: FieldsModel[];

	// Initial fields wo changes
	original: Record<string, FieldValue>;

	// Calculated Changes
	data: Record<string, FieldValue>;

	// Field/Manual Changes
	changes: Record<string, FieldValue>;

	// Fields that contain more than one value (that are not null/undefined/empty)
	mixedValues: string[];
};

const emptyChangeState: ChangeState = {
	items: [],
	original: {},
	data: {},
	changes: {},
	mixedValues: [],
};

export const useBatchEditFields = (
	options: BatchEditFieldsOptions
): BatchEditFieldsResult => {
	const { definition, excludeProperties } = options;
	const [state, setState] = useState<ChangeState>(emptyChangeState);

	const onChange = useCallback((prop: string, value: FieldValue) => {
		setState((s) => ({
			...s,
			changes: {
				...s.changes,
				[prop]: value,
			},
		}));
	}, []);

	const onMultipleChanges = useCallback(
		(fields: Record<string, FieldValue>) => {
			setState((s) => ({
				...s,
				changes: {
					...fields,
				},
			}));
		},
		[]
	);

	const loadItems = useCallback(
		(items: FieldsModel[]) => {
			const { data, mixedValues } = getMixedValueFields(
				definition,
				excludeProperties,
				items.map((x) => x.fields)
			);
			setState({
				items,
				data,
				original: { ...data },
				changes: { ...data },
				mixedValues,
			});
		},
		[definition, excludeProperties]
	);

	const getUpdates = () => {
		if (state.items.length === 1) {
			const update = mergeFields(
				definition,
				[],
				[],
				state.items[0].fields,
				state.changes
			);
			return update ? [{ ...state.items[0], fields: update }] : [];
		} else if (state.items.length > 1) {
			const { data, mixedValues } = getMixedValueFields(
				definition,
				excludeProperties,
				state.items.map((x) => ({ ...x.fields, ...state.changes }))
			);
			const updates = mergeChanges(
				definition,
				excludeProperties,
				mixedValues,
				state.items,
				data
			);
			return updates;
		}
		return [];
	};

	return {
		changes: state.changes,
		mixedValues: state.mixedValues,
		hasChanges: () => !isEqual(state.original, state.changes),
		onMultipleChanges,
		onChange,
		loadItems,
		getUpdates,
	};
};
