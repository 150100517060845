import { uniq } from 'lodash';

import { Entity } from './common';

export type FieldValue = undefined | null | string | boolean | number;

export const FieldTypes = {
	Text: 'Text',
	Content: 'Content',
	Integer: 'Integer',
	Decimal: 'Decimal',
	YesNo: 'Yes/No',
	SelectList: 'Select List',
	Date: 'Date',
	User: 'User',
	Approach: 'Approach',
} as const;

export type FieldType =
	| 'Text'
	| 'Content'
	| 'Integer'
	| 'Decimal'
	| 'Yes/No'
	| 'Select List'
	| 'Date'
	| 'User'
	| 'Approach';

export interface FieldsModel {
	fields: Record<string, FieldValue>;
}

export interface FieldOption {
	label: string;
	value: string;
}

/**
 * FieldDefintiion
 * @interface FieldDefinition
 * @property {Entity} entity The entity that the definition belongs too
 * @property {string} property The key of the js object stored on the fields property
 * @property {string} label The label to display to the user for the field
 * @property {FieldType} type The type of data the field will contain
 * @property {FieldOption[]} options The fixed options to use when the field is a Select List
 * @property {boolean} autoComplete Instructs the field renderer to retreive unique values for this field across the field's Entity for the user to select from
 * @property {boolean} allowMultiple Not supported yet; Instructs the field renderer to support multiple values
 * @property {boolean} system Wheter-or-not this field is required (hard-coded) by the system code
 * @property {boolean} managed Wheter-or-not this field's value is automatically set by the system.  If true, the user will not see this field
 *
 */
export interface FieldDefinition {
	entity: Entity;
	property: string;
	label: string;
	type: FieldType;
	options?: FieldOption[];
	autoComplete?: boolean;
	allowMultiple?: boolean;
	system?: boolean;
	managed?: boolean;
}

export interface MixedValueFields {
	data: Record<string, FieldValue>;
	mixedValues: string[];
}

export const getMixedValueFields = (
	definition: Record<string, FieldDefinition>,
	excludeProperties: string[],
	items: Record<string, FieldValue>[]
): MixedValueFields => {
	if (!items || !items.length) return { data: {}, mixedValues: [] };

	if (items.length === 1) {
		return {
			data: { ...items[0] },
			mixedValues: [],
		};
	}

	let data: Record<string, FieldValue> = {};
	const mixedValues: string[] = [];

	Object.keys(definition).forEach((key) => {
		const d = definition[key];
		if (!excludeProperties.includes(d.property)) {
			const values = uniq(items.map((x) => x[d.property] || null));
			if (values.length === 1) {
				if (
					values[0] !== null &&
					values[0] !== undefined &&
					values[0] !== ''
				) {
					data[d.property] = values[0];
				}
			} else if (values.length > 1) {
				mixedValues.push(d.property);
			}
		}
	});

	return {
		data,
		mixedValues,
	};
};
