import React from 'react';

// Components
import { HeaderRenderer } from '../HeaderRenderer';
import { UserRenderer } from './UserRenderer';

// Lib
import {
	DataColumn,
	RendererApi,
	CellRenderProps,
	HeaderRenderProps,
	SortDirection,
} from '../../types';

export const UserRendererName = 'User Renderer';

export interface UserRendererOptions extends DataColumn {
	onToggleSort?: (direction: SortDirection) => void;
}

export interface UserRendererApi extends RendererApi {}

export const useUserRenderer = (
	options: UserRendererOptions
): UserRendererApi => {
	const { field, label, width, sort } = options;

	const renderHeader = (props: HeaderRenderProps) => {
		return (
			<HeaderRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				onToggleSort={options.onToggleSort}
				align="left"
				{...props}
			/>
		);
	};

	const renderCell = (props: CellRenderProps) => {
		return (
			<UserRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				{...props}
			/>
		);
	};

	return {
		name: UserRendererName,
		type: 'Data',
		field,
		label,
		width,
		sort,
		renderHeader,
		renderCell,
	};
};
