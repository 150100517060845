import * as React from 'react';
import { Divider, useTheme } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import {
	mdiCalendar,
	mdiCalendarBlank,
	mdiCalendarCheck,
	mdiCalendarClock,
	mdiViewColumn,
	mdiDotsHorizontalCircleOutline,
} from '@mdi/js';

// Monorepo
import {
	ActionCardStages,
	Entity,
	FieldValue,
	formatDateTime,
	getUTCDateNow,
	toDateTimeString,
} from '@constituenthub/common';

// Components
import { FlexRow, FlexColumn } from '../common';

import { ButtonFieldDate } from '../common/ButtonFieldDate';
import { ButtonFieldList } from '../common/ButtonFieldList';
import { ButtonFieldApproach } from '../approaches/ButtonFieldApproach';
import { ButtonFieldValues } from '../fields/ButtonFieldValues';

// Lib
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { ButtonFieldUsers } from '../fields/ButtonFieldUsers';
import { TaskForm } from './TaskForm';

const NoValueText = 'Select a value';

type Props = {
	fields: Record<string, FieldValue>;
	mixedValues: string[];
	onChange: (fields: Record<string, FieldValue>) => void;
	showApproach?: boolean;
	showName?: boolean;
	sx?: SxProps<Theme>;
};

export const BatchTaskForm = ({
	onChange,
	fields,
	mixedValues,
	showApproach,
	showName,
	sx = {},
}: Props) => {
	const theme = useTheme();
	const cardDefinition = useFieldDefinitions(Entity.ActionCard);

	const onActionCardChange = (property: string, value: FieldValue) => {
		if (property === cardDefinition.Stage.property) {
			let complete = fields.complete;
			if (value === ActionCardStages.Complete && !fields.complete) {
				complete = toDateTimeString(getUTCDateNow());
			}
			if (value !== ActionCardStages.Complete && !!fields.complete) {
				complete = null;
			}
			onChange({
				...fields,
				[property]: value,
				complete,
			});
			return;
		}

		if (property === cardDefinition.Complete.property) {
			let stage = fields.stage;
			let end = fields.end;

			if (!value && fields.stage === ActionCardStages.Complete) {
				stage = ActionCardStages.ToDo;
			}

			if (!!value) {
				stage = ActionCardStages.Complete;
			}

			if (!end && !!value) {
				end = value;
			}
			onChange({
				...fields,
				[property]: value,
				stage,
				end,
			});
			return;
		}
		onChange({
			...fields,
			[property]: value,
		});
	};

	return (
		<FlexColumn fill sx={sx}>
			<FlexColumn
				name="header"
				sx={{
					borderBottom: `1px solid ${theme.palette.divider}`,
					p: 1,
				}}
			>
				<FlexRow fill>
					<ButtonFieldUsers
						caption="Assigned To"
						value={
							(fields?.[
								cardDefinition.AssignedTo.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.AssignedTo.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.AssignedTo.property
						)}
					/>
					<ButtonFieldValues
						caption="Team Member"
						entity={Entity.ActionCard}
						property={cardDefinition.TeamMember.property}
						value={
							(fields?.[
								cardDefinition.TeamMember.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.TeamMember.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.TeamMember.property
						)}
					/>
					<span style={{ flexGrow: 1 }} />
					<ButtonFieldDate
						caption="Start"
						primary={
							fields?.[cardDefinition.Start.property]
								? formatDateTime(
										fields?.[
											cardDefinition.Start.property
										] as string
								  )
								: NoValueText
						}
						value={
							(fields?.[
								cardDefinition.Start.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.Start.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.Start.property
						)}
					/>
					<ButtonFieldDate
						caption="End"
						primary={
							fields?.[cardDefinition.End.property]
								? formatDateTime(
										fields?.[
											cardDefinition.End.property
										] as string
								  )
								: NoValueText
						}
						value={
							(fields?.[cardDefinition.End.property] as string) ||
							null
						}
						onChange={(val) =>
							onActionCardChange(cardDefinition.End.property, val)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.End.property
						)}
					/>
					<ButtonFieldDate
						caption="Complete"
						primary={
							fields?.[cardDefinition.Complete.property]
								? formatDateTime(
										fields?.[
											cardDefinition.Complete.property
										] as string
								  )
								: NoValueText
						}
						value={
							(fields?.[
								cardDefinition.Complete.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.Complete.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.Complete.property
						)}
					/>
					<ButtonFieldList
						caption="Stage"
						primary={
							(fields?.[
								cardDefinition.Stage.property
							] as string) || NoValueText
						}
						icon={mdiViewColumn}
						value={
							(fields?.[
								cardDefinition.Stage.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.Stage.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.Stage.property
						)}
						options={[
							{
								value: 'To Do',
								primary: 'ToDo',
								icon: mdiCalendar,
							},
							{
								value: 'In Progress',
								primary: 'InProgress',
								icon: mdiCalendarClock,
							},
							{
								value: 'Complete',
								primary: 'Complete',
								icon: mdiCalendarCheck,
							},
							{
								value: 'Ongoing',
								primary: 'Ongoing',
								icon: mdiCalendarBlank,
							},
						]}
					/>
				</FlexRow>
				<FlexRow fill sx={{ py: 1 }}>
					<ButtonFieldValues
						caption="Participants"
						entity={Entity.ActionCard}
						multiselect
						property={cardDefinition.Participants.property}
						value={
							(fields?.[
								cardDefinition.Participants.property
							] as string) || null
						}
						onChange={(val) =>
							onActionCardChange(
								cardDefinition.Participants.property,
								val
							)
						}
						mixedValue={mixedValues.includes(
							cardDefinition.Participants.property
						)}
					/>
					<span style={{ flexGrow: 1 }} />
				</FlexRow>
			</FlexColumn>
			<FlexColumn fill name="content">
				{showApproach && (
					<>
						<FlexColumn sx={{ p: 1 }}>
							<ButtonFieldApproach
								value={
									(fields?.[
										cardDefinition.Approach.property
									] as number) || null
								}
								icon={mdiDotsHorizontalCircleOutline}
								onChange={(val) =>
									onActionCardChange(
										cardDefinition.Approach.property,
										val
									)
								}
								mixedValue={mixedValues.includes(
									cardDefinition.Approach.property
								)}
								sx={{ flexGrow: 1 }}
							/>
						</FlexColumn>
						<Divider />
					</>
				)}
				<FlexColumn fill>
					<FlexColumn
						scroll
						sx={{
							p: 2,
							pl: 3,
							pt: 3,
						}}
					>
						<TaskForm
							fields={fields || {}}
							onChange={onActionCardChange}
							mixedValues={mixedValues}
							showName={showName}
						/>
					</FlexColumn>
				</FlexColumn>
			</FlexColumn>
		</FlexColumn>
	);
};
