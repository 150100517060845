import { JobRoleApproach, JobRoleApproachAction } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class JobRoleApproachApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listJobRoleApproaches(
		jobRoleIds: number[],
		historic?: boolean
	): Promise<JobRoleApproachAction[]> {
		const response = await this.client.get<JobRoleApproachAction[]>(
			`/jobroleapproach?roles=${jobRoleIds.join(',')}&historic=${
				historic || false
			}`
		);
		return response.result;
	}

	// public async createJobRoleApproach(
	// 	data: Partial<JobRoleApproach>
	// ): Promise<JobRoleApproach> {
	// 	const response = await this.client.post<
	// 		Partial<JobRoleApproach>,
	// 		JobRoleApproach
	// 	>(`/jobroleapproach`, data);
	// 	return response.result;
	// }

	public async createJobRoleApproaches(
		data: Partial<JobRoleApproach>[]
	): Promise<JobRoleApproachAction[]> {
		const response = await this.client.post<
			Partial<JobRoleApproach>[],
			JobRoleApproachAction[]
		>(`/jobroleapproach`, data);
		return response.result;
	}

	public async getJobRoleApproach(
		jobRoleApproachId: number
	): Promise<JobRoleApproach> {
		const response = await this.client.get<JobRoleApproach>(
			`/jobroleapproach/${jobRoleApproachId}`
		);
		return response.result;
	}

	public async updateJobRoleApproach(
		data: JobRoleApproach
	): Promise<JobRoleApproach> {
		const response = await this.client.put<
			JobRoleApproach,
			JobRoleApproach
		>(`/jobroleapproach/${data.jobRoleApproachId}`, data);
		return response.result;
	}

	// public async updateJobRolesApproaches(
	// 	data: JobRoleApproach[]
	// ): Promise<JobRoleApproach[]> {
	// 	const response = await this.client.put<
	// 		JobRoleApproach[],
	// 		JobRoleApproach[]
	// 	>(`/jobroleapproach`, data);
	// 	return response.result;
	// }

	// public async removeJobRoleApproach(
	// 	jobRoleApproachId: number
	// ): Promise<void> {
	// 	await this.client.del<JobRoleApproach>(
	// 		`/jobroleapproach/${jobRoleApproachId}`
	// 	);
	// }

	public async deleteJobRoleApproaches(
		jobRoleApproachIds: number[]
	): Promise<void> {
		await this.client.del(`/jobroleapproach`, jobRoleApproachIds);
	}
}
