import React from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { Group } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { GroupForm } from './GroupForm';
import { useEditGroup } from './useEditGroup';

type AddGroupDialogProps = {
	open: boolean;
	onClose: () => void;
	onGroupCreated: (group: Group) => void;
};

export const AddGroupDialog = (props: AddGroupDialogProps) => {
	const { open, onClose, onGroupCreated } = props;

	const {
		isGroupValid,
		onCreateGroup,
		onGroupChanged,
		onFlagsChange,
		validationErrors,
		group,
		isWorking,
		error,
		flags,
	} = useEditGroup({
		onGroupCreated: onGroupCreated,
	});

	const handleClose = () => {
		onClose();
	};

	const handleCreateGroup = async () => {
		try {
			await onCreateGroup();
			onClose();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Add Group"
			minWidth={600}
			padding={2}
			actions={
				<>
					{!error && (
						<Button
							variant="contained"
							onClick={handleCreateGroup}
							disabled={isWorking || !isGroupValid()}
						>
							Create Group
						</Button>
					)}
					{!!error && (
						<Button variant="contained" onClick={handleCreateGroup}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={isWorking} text="Working..." />
				<ErrorMessage error={error} sx={{ minWidth: '320px' }} />
				{!error && !isWorking && (
					<GroupForm
						group={group}
						flags={flags}
						onChange={onGroupChanged}
						onFlagsChange={onFlagsChange}
						errors={validationErrors}
					/>
				)}
			</>
		</ModalDialog>
	);
};
