import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

// Icons
import { mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';

// Monorepo
import { JobRole, Approach } from '@constituenthub/common';

// Components
import { IconButton, NothingHere } from '../common';
import { SelectApproachesDialog } from '../approaches/SelectApproachesDialog';
import { AffectedJobRolesApproachList } from './AffectedJobRolesApproachList';
import { DeleteJobRoleApproachesDialog } from './DeleteJobRoleApproachesDialog';
import { useJobRoleApproachesController } from './useJobRoleApproachesController';
import { TOOLBAR_HEIGHT } from '../../lib';

type Props = {
	selectedJobRoles: JobRole[];
};

export const AffectedJobRolesApproaches = (props: Props) => {
	const [isAddApproachOpen, setIsAddApproachOpen] = React.useState(false);
	const [isDeleteApproachOpen, setIsDeleteApproachOpen] =
		React.useState(false);

	const { selectedJobRoles } = props;
	const controller = useJobRoleApproachesController({ selectedJobRoles });

	const {
		approaches,
		selectedJobRoleApproaches,
		jobRoleApproaches,
		onToggleAllSelected,
		onDeleteSelectedApproaches,
		onAddApproaches,
	} = controller;

	const handleOnApproachesSelected = async (adds: Approach[]) => {
		try {
			const results = await onAddApproaches(adds);
			setIsAddApproachOpen(false);
			return results;
		} catch (error) {
			// Allow the dialog to handle
			throw error;
		}
	};

	const handleOnDeleteApproaches = async () => {
		try {
			await onDeleteSelectedApproaches();
			setIsDeleteApproachOpen(false);
		} catch (error) {
			// Allow the dialog to handle
			throw error;
		}
	};

	if (controller.jobRoleApproaches.length === 0) {
		return (
			<>
				<Grid item flexGrow={1}>
					<NothingHere>
						<Box sx={{ p: 2, textAlign: 'center' }}>
							<Typography component="div" variant="body1">
								It looks like you don't have any Approaches yet.
							</Typography>
						</Box>
						<Box sx={{ p: 2, textAlign: 'center' }}>
							<Button
								variant="outlined"
								onClick={() => setIsAddApproachOpen(true)}
							>
								Add Approaches
							</Button>
						</Box>
					</NothingHere>
				</Grid>
				<SelectApproachesDialog
					open={isAddApproachOpen}
					onClose={() => setIsAddApproachOpen(false)}
					onApproachesSelected={handleOnApproachesSelected}
				/>
			</>
		);
	}

	return (
		<>
			<Grid
				container
				direction="column"
				flexGrow={1}
				wrap="nowrap"
				sx={{
					position: 'relative',
					height: `calc(100% - ${TOOLBAR_HEIGHT})`,
					overflow: 'hidden',
				}}
			>
				<Toolbar variant="dense" disableGutters sx={{ pl: 2, pr: 1 }}>
					<Checkbox
						checked={
							selectedJobRoleApproaches.length !== 0 &&
							selectedJobRoleApproaches.length ===
								jobRoleApproaches.length
						}
						onChange={onToggleAllSelected}
						sx={{ ml: 0.1, mr: 2 }}
					/>
					<Typography variant="body2" flexGrow={1}>
						{selectedJobRoleApproaches.length} Approaches Selected
					</Typography>
					<IconButton
						path={mdiPlusCircleOutline}
						size={0.9}
						variant="small"
						title="Add Approach"
						onClick={() => setIsAddApproachOpen(true)}
						sx={{ mx: 1 }}
					/>
					<IconButton
						path={mdiTrashCanOutline}
						size={1}
						variant="small"
						title="Delete Selected"
						disabled={selectedJobRoleApproaches.length === 0}
						onClick={() => setIsDeleteApproachOpen(true)}
						sx={{ mx: 1 }}
					/>
				</Toolbar>

				<Grid
					container
					direction="column"
					spacing={2}
					sx={{
						position: 'relative',
						width: '100%',
						height: `calc(100% - ${TOOLBAR_HEIGHT})`,
						overflow: 'hidden',
						overflowY: 'auto',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						flexGrow: 1,
						mt: 0.1,
						p: 1,
						pl: 3,
					}}
				>
					<AffectedJobRolesApproachList controller={controller} />
				</Grid>
			</Grid>
			<SelectApproachesDialog
				open={isAddApproachOpen}
				onClose={() => setIsAddApproachOpen(false)}
				onApproachesSelected={handleOnApproachesSelected}
			/>
			<DeleteJobRoleApproachesDialog
				approaches={approaches}
				jobRoleApproaches={selectedJobRoleApproaches}
				open={isDeleteApproachOpen}
				onClose={() => setIsDeleteApproachOpen(false)}
				onDeletedApproaches={handleOnDeleteApproaches}
			/>
		</>
	);
};
