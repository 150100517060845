import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/system';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Icons
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { take } from 'lodash';

type Props = {
	items: any[];
	expandByDefault?: boolean;
	message: string;
	getText: (item: any) => string;
	getId: (item: any) => number;
	onClick?: (item: any) => void;
	hideSingle?: boolean;
	sx?: SxProps<Theme>;
};

const ExpandCount = 0;
const EnableMoreLess = false;

export const MultipleAffected = (props: Props) => {
	const {
		items,
		message,
		getText,
		getId,
		onClick,
		expandByDefault = false,
		hideSingle = false,
		sx = {},
	} = props;
	const [expanded, setExpanded] = useState(expandByDefault);

	if ((!items || items.length <= 1) && hideSingle === true) return null;

	const displayItems = expanded ? items : take(items, ExpandCount);

	return (
		<Alert
			variant="standard"
			severity="warning"
			sx={{
				mb: 2,
				py: 0,
				px: 1,
				width: '100%',
				'& .MuiAlert-message': { width: '100%' },
				...sx,
			}}
			action={
				items.length > ExpandCount ? (
					<IconButton
						size="small"
						onClick={() => setExpanded(!expanded)}
						sx={{ mr: 1 }}
					>
						<Icon
							path={expanded ? mdiChevronUp : mdiChevronDown}
							size={1}
						/>
					</IconButton>
				) : undefined
			}
		>
			{message}
			<List dense disablePadding sx={{ mt: 1, width: '100%' }}>
				{displayItems.map((item, index) => (
					<ListItem key={getId(item)} disablePadding>
						<ListItemButton
							sx={{ py: 0, px: 1 }}
							onClick={() => (onClick ? onClick(item) : {})}
						>
							<ListItemText
								primary={
									<>
										<Typography
											variant="caption"
											component="span"
											sx={{ mr: 2, textAlign: 'right' }}
										>
											{index + 1}
										</Typography>
										<Typography
											variant="inherit"
											component="span"
										>
											{getText(item)}
										</Typography>
									</>
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
				{EnableMoreLess && items.length > ExpandCount && (
					<ListItem disablePadding>
						<ListItemButton
							sx={{ py: 0, px: 1 }}
							onClick={() => setExpanded(!expanded)}
						>
							<ListItemText
								sx={{ color: 'primary.main' }}
								primary={expanded ? 'less...' : 'more...'}
							/>
						</ListItemButton>
					</ListItem>
				)}
			</List>
		</Alert>
	);
};
