import React from 'react';
import Box from '@mui/material/Box';

// Monorepo
import { getPaletteValue, Hue, PaletteName } from '@constituenthub/common';

type ColorSwatchProps = {
	palette: PaletteName;
	hue: Hue;
	onClick: (palette: PaletteName, hue: Hue) => void;
	size?: number;
	selected?: boolean;
};

export const ColorSwatch = (props: ColorSwatchProps) => {
	const { palette, hue, size = 32, selected = false, onClick } = props;
	const backgroundColor = getPaletteValue(palette, hue).color;

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ' ' || event.key === 'Enter') {
			onClick(palette, hue);
		}
	};

	return (
		<Box
			role="button"
			tabIndex={0}
			onClick={() => onClick(palette, hue)}
			onKeyPress={handleKeyPress}
			sx={{
				cursor: 'pointer',
				width: `${size}px`,
				height: `${size}px`,
				border: selected
					? `3px solid ${backgroundColor}`
					: `3px solid transparent`,
				p: 0.5,
				borderRadius: '50%',
			}}
		>
			<Box
				sx={{
					position: 'relative',
					width: `100%`,
					height: `100%`,
					backgroundColor,
					borderRadius: '50%',
				}}
			/>
		</Box>
	);
};
