import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import { lighten, SxProps, Theme } from '@mui/system';
import { uniqueId } from 'lodash';

// Icons
import Icon from '@mdi/react';
import { MixedFieldContent } from '@constituenthub/common';

interface StyledButtonProps extends ButtonProps {
	mixedvalue?: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
	({ theme, mixedvalue }) => ({
		border: '1px solid transparent',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		textAlign: 'left',
		backgroundColor: mixedvalue
			? lighten(theme.palette.primary.main, 0.9)
			: 'transparent',
		marginRight: mixedvalue ? 3 : 'initial',
		marginLeft: mixedvalue ? 3 : 'initial',
		'&:hover': {
			border: `1px solid ${lighten(theme.palette.primary.main, 0.4)}`,
			backgroundColor: mixedvalue
				? lighten(theme.palette.primary.main, 0.8)
				: 'transparent',
		},
		'& svg': {
			opacity: 0,
		},
		'&:hover svg': {
			opacity: 1,
		},
	})
);

export type ButtonFieldEvent = {
	name: 'opened' | 'closed';
	onCloseCallback: () => void;
};

type Props = {
	caption: string;
	primary: string;
	secondary?: string;
	tip?: string;
	icon?: string;
	readOnly?: boolean;
	mixedValue?: boolean;
	sx?: SxProps<Theme>;
	children?: React.ReactNode;
	onClick?: () => void;
	onEvent?: (event: ButtonFieldEvent) => void;
};

export const ButtonField = ({
	caption,
	primary,
	secondary,
	tip,
	icon,
	readOnly,
	children,
	mixedValue,
	onClick,
	onEvent,
	sx = {},
}: Props) => {
	const id = `button_field_${uniqueId()}`;
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (readOnly) {
			if (onClick) {
				onClick();
			}
			return;
		}

		setAnchorEl(event.currentTarget);
		if (!!onEvent) {
			onEvent({
				name: 'opened',
				onCloseCallback: () => setAnchorEl(null),
			});
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		if (!!onEvent) {
			onEvent({
				name: 'closed',
				onCloseCallback: () => setAnchorEl(null),
			});
		}
	};

	if (!readOnly && !children && !onClick) {
		throw new Error('Either children or onClick is required');
	}

	const tooltipText = mixedValue ? MixedFieldContent : tip || '';

	return (
		<Tooltip title={tooltipText} enterDelay={400}>
			<>
				<StyledButton
					variant="outlined"
					mixedvalue={mixedValue}
					id={id}
					aria-controls={`${id}_menu`}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={(event) =>
						!!children
							? handleMenuClick(event)
							: !!onClick
							? onClick()
							: () => {}
					}
					sx={{ ...sx, pr: icon ? 1 : 2 }}
				>
					<Box
						sx={{
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
						}}
					>
						<Typography
							variant="caption"
							color={theme.palette.primary.main}
							sx={{ display: 'block' }}
						>
							{caption}
						</Typography>
						<Typography
							variant="body2"
							sx={{ display: 'block' }}
							color="black"
						>
							{primary}
						</Typography>
						{secondary && (
							<Typography
								variant="caption"
								sx={{ display: 'block' }}
								color="black"
							>
								{secondary}
							</Typography>
						)}
					</Box>
					{icon && (
						<Box
							sx={{
								pl: 1,
								display: 'flex',
								flexGrow: 1,
								justifyContent: 'flex-end',
							}}
						>
							<Icon
								path={icon}
								size={0.75}
								color={lighten(theme.palette.primary.main, 0.4)}
							/>
						</Box>
					)}
				</StyledButton>
				{children && (
					<Menu
						id={`${id}_menu`}
						anchorEl={anchorEl}
						open={open}
						onClose={handleMenuClose}
						MenuListProps={{
							'aria-labelledby': id,
						}}
					>
						{children}
					</Menu>
				)}
			</>
		</Tooltip>
	);
};
