import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useLocation, useNavigate } from 'react-router-dom';

// Icons
import {
	mdiPlusCircleOutline,
	mdiCardMultipleOutline,
	mdiTable,
	mdiFilePdfBox,
	mdiPrinter,
} from '@mdi/js';

// Monorepo
import {
	encodeFilter,
	Entity,
	Filter,
	parseFilterFromUrl,
	View,
} from '@constituenthub/common';

import { TOOLBAR_HEIGHT } from '../../lib';

// Components
import { IconButton } from '../common';
import { MainLayout } from '../shell';
import { CardView } from './CardView';
import { CardTableView } from './CardTableView';
import { useActionCardsController } from './useActionCardsController';
import { FilterButton } from '../filters/FilterButton';
import { EditFilterDialog } from '../filters/EditFilterDialog';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { useAppContext } from '../../contexts/AppContext';
import { ViewButton } from '../view/ViewButton';
import { TaskDialog } from './TaskDialog';
import { AddTaskDialog } from './AddTaskDialog';

type ComponentView = 'table' | 'card';

type DialogState = {
	key: string;
	parentId?: number;
	actionId?: number;
};

export const ActionCardsPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const definition = useFieldDefinitions(Entity.ActionCard);
	const { api } = useAppContext();

	const [dialog, setDialog] = React.useState<DialogState>();
	const [componentView, setComponentView] =
		React.useState<ComponentView>('card');

	const filter = parseFilterFromUrl(location.search);
	const controller = useActionCardsController();

	const openCard = (entity: string, parentId: number, actionId: number) => {
		setDialog({ parentId, key: entity || Entity.ActionCard, actionId });
	};

	const clearFilters = () => {
		setTimeout(() => {
			navigate(location.pathname);
		});
	};

	const handleOpenView = (view: View) => {
		const url =
			view && view.data.filter
				? `${location.pathname}?filter=${encodeFilter(
						view.data.filter as Filter
				  )}`
				: location.pathname;
		setTimeout(() => {
			navigate(url);
		});
	};

	const handleApplyFilters = (data: Filter | undefined) => {
		const url =
			data && data.length > 0
				? `${location.pathname}?filter=${encodeFilter(data)}`
				: location.pathname;
		setDialog(undefined);
		setTimeout(() => {
			navigate(url);
		});
	};

	const openFilters = () => {
		setDialog({ key: 'EditFilters' });
	};

	const handleOpenReport = () => {
		const url =
			filter && filter.length > 0
				? `${location.pathname}/report?filter=${encodeFilter(filter)}`
				: `${location.pathname}/report`;
		window.open(url, '_blank');
	};

	const getFilterCount = async (filter: Filter): Promise<number> => {
		try {
			console.log('filter changed', filter);
			const count = await api.action.listActionCards({
				filter,
				count: true,
			});
			return count as number;
		} catch (error) {
			console.error(error);
			return Promise.resolve(0);
		}
	};

	return (
		<MainLayout>
			<Toolbar>
				<ViewButton
					definition={definition}
					feature={Entity.ActionCard}
					data={{ filter }}
					onViewClick={handleOpenView}
					sx={{ pr: 2 }}
					canSave={filter !== undefined}
					noSaveMessage="Apply some filters to save a view"
				/>
				<FilterButton
					filter={filter}
					onClear={clearFilters}
					onClick={openFilters}
				/>
				<span style={{ flexGrow: 1 }} />
				<IconButton
					path={mdiPlusCircleOutline}
					title="Add Action"
					onClick={() => setDialog({ key: 'AddAction' })}
					sx={{ mr: 1 }}
				/>
				<IconButton
					path={
						componentView === 'card'
							? mdiCardMultipleOutline
							: mdiTable
					}
					title={
						componentView === 'card'
							? 'Switch to Table'
							: 'Switch to Cards'
					}
					onClick={() =>
						setComponentView(
							componentView === 'card' ? 'table' : 'card'
						)
					}
					sx={{ mr: 1 }}
				/>
				<IconButton
					path={mdiPrinter}
					title="View Report"
					onClick={handleOpenReport}
				/>
			</Toolbar>
			<Box
				data-component="ActionCardPageContent"
				sx={{
					position: 'relative',
					width: '100%',
					height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					p: 2,
				}}
			>
				<CardView
					show={componentView === 'card'}
					controller={controller}
					openCard={openCard}
				/>
				<CardTableView
					show={componentView === 'table'}
					controller={controller}
				/>
			</Box>
			{dialog && dialog.key === 'EditFilters' && (
				<EditFilterDialog
					filter={filter}
					definition={definition}
					entity={Entity.ActionCard}
					onClose={() => setDialog(undefined)}
					onContinue={handleApplyFilters}
					onGetFilterCount={getFilterCount}
				/>
			)}
			{dialog && dialog.key === 'AddAction' && (
				<AddTaskDialog
					bypassCreate={false}
					onContinue={async () => {
						return Promise.resolve(setDialog(undefined));
					}}
					onClose={() => setDialog(undefined)}
				/>
			)}
			{dialog && dialog.key === Entity.ActionCard && dialog.actionId && (
				<TaskDialog
					onContinue={async () => {
						return Promise.resolve(setDialog(undefined));
					}}
					onDelete={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(setDialog(undefined));
					}}
					onClose={() => setDialog(undefined)}
					actionId={dialog.actionId}
				/>
			)}
			{dialog && dialog.actionId && dialog.key === Entity.ChangeItem && (
				<TaskDialog
					onClose={() => setDialog(undefined)}
					onContinue={async () => {
						return Promise.resolve(setDialog(undefined));
					}}
					onDelete={async () => {
						return Promise.resolve(setDialog(undefined));
					}}
					actionId={dialog.actionId}
				/>
			)}
			{dialog &&
				dialog.actionId &&
				dialog.key === Entity.JobRoleApproach && (
					<TaskDialog
						onClose={() => setDialog(undefined)}
						onContinue={async () => {
							// await controller.onUpdateApproaches();
							return Promise.resolve(setDialog(undefined));
						}}
						onDelete={async () => {
							// await controller.onUpdateApproaches();
							return Promise.resolve(setDialog(undefined));
						}}
						actionId={dialog.actionId}
					/>
				)}
		</MainLayout>
	);
};
