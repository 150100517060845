/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Monorepo
import { ActionCard, ActionCardView } from '@constituenthub/common';

// Lib
import type { RootState } from '.';
import { orderBy } from 'lodash';

export interface ActionCardState {
	actions: ActionCardView[];
}

const initialState: ActionCardState = {
	actions: [],
};

export const slice = createSlice({
	name: 'actions',
	initialState,
	reducers: {
		/**
		 * Load State
		 */
		loadActionCards: (state, action: PayloadAction<ActionCardView[]>) => {
			state.actions = orderBy(
				action.payload,
				['fields.position', 'updatedAt'],
				['asc', 'desc']
			);
		},
		setClearActionCardState: (state) => {
			state.actions = [];
		},

		/**
		 * CRUD
		 */
		setActionsAdded: (state, action: PayloadAction<ActionCard[]>) => {
			state.actions = orderBy(
				[...state.actions, ...action.payload],
				['fields.position', 'updatedAt'],
				['asc', 'desc']
			);
		},
		setActionsUpdated: (state, action: PayloadAction<ActionCard[]>) => {
			const updates = action.payload;
			updates.forEach((item) => {
				const index = state.actions.findIndex(
					(x) => x.actionId === item.actionId
				);
				if (index !== -1) {
					state.actions.splice(index, 1, {
						...state.actions[index],
						...item,
					});
				} else {
					state.actions.push(item);
				}
			});
		},
		setActionsRemoved: (state, action: PayloadAction<ActionCard[]>) => {
			const removes = action.payload;
			removes.forEach((item) => {
				const index = state.actions.findIndex(
					(x) => x.actionId === item.actionId
				);
				if (index !== -1) {
					state.actions.splice(index, 1);
				}
			});
		},
		setActionsRemovedById: (state, action: PayloadAction<number[]>) => {
			const removes = action.payload;
			removes.forEach((id) => {
				const index = state.actions.findIndex((x) => x.actionId === id);
				if (index !== -1) {
					state.actions.splice(index, 1);
				}
			});
		},
		/**
		 * Move
		 */
		actionCardReordered: (
			state,
			action: PayloadAction<{
				sourceIndex: number;
				targetIndex: number;
				stage: string;
			}>
		) => {
			const { sourceIndex, targetIndex, stage } = action.payload;
			const cards = state.actions.filter((x) => x.fields.stage === stage);
			const otherCards = state.actions.filter(
				(x) => x.fields.stage !== stage
			);

			// Remove old position
			const item = cards.splice(sourceIndex, 1)[0];

			// Update position
			item.fields.position = targetIndex;

			// Add new position
			cards.splice(targetIndex, 0, item);

			// Update state
			state.actions = [...cards, ...otherCards];
		},
		actionCardMoved: (
			state,
			action: PayloadAction<{
				sourceIndex: number;
				sourceStage: string;
				targetIndex: number;
				targetStage: string;
			}>
		) => {
			const { sourceIndex, sourceStage, targetIndex, targetStage } =
				action.payload;

			const sourceCards = state.actions.filter(
				(x) => x.fields.stage === sourceStage
			);
			const targetCards = state.actions.filter(
				(x) => x.fields.stage === targetStage
			);

			// Remove from previous stage
			const item = sourceCards.splice(sourceIndex, 1)[0];
			console.log('Removed Item', item);

			// Update item
			item.fields.stage = targetStage;
			item.fields.position = targetIndex;

			// Add to new stage
			targetCards.splice(targetIndex, 0, item);
		},
	},
});

export const {
	loadActionCards,
	setClearActionCardState,
	setActionsAdded,
	setActionsUpdated,
	setActionsRemoved,
	setActionsRemovedById,
	actionCardReordered,
	actionCardMoved,
} = slice.actions;

export const selectActionCards = (state: RootState) => state.actions.actions;

// export const selectActionCardsByStage = (stage: string) =>
// 	createSelector(
// 		[
// 			(state: RootState) =>
// 				state.actions.actions.filter((x) => x.fields.stage === stage),
// 		],
// 		(items) =>
// 			orderBy(items, ['fields.position', 'updatedAt'], ['asc', 'desc'])
// 	);

export const selectActionCardsByStage = (stage: string) => (state: RootState) =>
	orderBy(
		state.actions.actions.filter(
			(x) => x.fields.stage === stage && !x.deletedAt
		),
		['fields.position', 'updatedAt'],
		['asc', 'desc']
	);

export default slice.reducer;
