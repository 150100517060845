import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Icons
// import Icon, { Stack } from '@mdi/react';
// import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js';

// Monorepo
import { Project } from '@constituenthub/common';
import { useNavigate } from 'react-router-dom';

type ToolbarLogoProps = {
	project: Project | null;
};

export const ToolbarLogo = (props: ToolbarLogoProps) => {
	const { project } = props;
	const navigate = useNavigate();

	return (
		<>
			<Tooltip title="Home">
				<Button onClick={() => navigate('/')}>
					{/* <Icon path={mdiChevronLeft} size={1} /> */}
					<img
						src="/logo.svg"
						alt="Menu"
						width="24"
						height="24"
						style={{
							position: 'relative',
							marginRight: '-4px',
							marginLeft: '-4px',
						}}
					/>
					{/* <Icon path={mdiChevronRight} size={1} /> */}
				</Button>
			</Tooltip>
			{!!project && (
				<Tooltip title="Switch Project">
					<Button
						onClick={() => navigate('/')}
						sx={{ lineHeight: 0 }}
					>
						<Grid
							container
							direction="column"
							alignItems="flex-start"
						>
							<Grid item>
								<Typography variant="caption" component="span">
									Change Impact Plus
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant="body2"
									fontWeight="bold"
									component="span"
								>
									{project.name}
								</Typography>
							</Grid>
						</Grid>
					</Button>
				</Tooltip>
			)}
			{!project && (
				<Tooltip title="Select Project">
					<Button>
						<Grid container direction="column">
							Change Impact Plus
						</Grid>
					</Button>
				</Tooltip>
			)}
		</>
	);
};
