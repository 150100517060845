import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Monorepo
import {
	Filter,
	FieldDefinition,
	FilterValidation,
	isFilterValid,
	validateFilters,
} from '@constituenthub/common';

// Components
import { ModalDialog } from '../common';
import { FilterEditor } from './FilterEditor';

type Props = {
	filter: Filter | undefined;
	definition: Record<string, FieldDefinition>;
	entity: string;
	onClose: () => void;
	onContinue: (data: Filter | undefined) => void;
	onGetFilterCount: (data: Filter) => Promise<number>;
};

type State = {
	count: number;
	filter: Filter | undefined;
	validation: FilterValidation;
};

export const EditFilterDialog = ({
	filter,
	definition,
	entity,
	onClose,
	onContinue,
	onGetFilterCount,
}: Props) => {
	const [state, setState] = React.useState<State>({
		count: 0,
		filter,
		validation: validateFilters(definition, filter),
	});

	const onFilterChanged = (
		working: Filter | undefined,
		validationData: FilterValidation
	) => {
		setState((s) => ({
			...s,
			filter: working,
			validation: validationData,
		}));
	};

	const handleClearFilter = async () => {
		try {
			const count = await onGetFilterCount([]);
			setState((s) => ({ count, validation: [], filter: [] }));
			return count;
		} catch (error) {
			setState((s) => ({ count: 0, validation: [], filter: [] }));
			return 0;
		}
	};

	const handleOnGetFilterCount = async (data: Filter): Promise<number> => {
		try {
			const count = await onGetFilterCount(data);
			setState((s) => ({ ...s, count }));
			return count;
		} catch (error) {
			setState((s) => ({ ...s, count: 0 }));
			return 0;
		}
	};

	const isValid =
		state.filter?.length === 0 || isFilterValid(state.validation);

	return (
		<ModalDialog
			open={true}
			onClose={onClose}
			label="Edit Filters"
			padding={2}
			minWidth={900}
			actions={
				<>
					<Typography variant="body2" sx={{ pl: 2 }}>
						Total Results:
					</Typography>
					<Typography
						flexGrow={1}
						variant="body2"
						fontWeight={600}
						sx={{ pl: 1 }}
					>
						{state.count}
					</Typography>
					<Button variant="text" onClick={handleClearFilter}>
						Clear
					</Button>
					<Button
						variant="contained"
						onClick={() => onContinue(state.filter)}
						disabled={!isValid}
					>
						Apply Filters
					</Button>
				</>
			}
		>
			<FilterEditor
				filter={state.filter || []}
				validation={state.validation}
				definition={definition}
				entity={entity}
				onChange={onFilterChanged}
				onGetFilterCount={handleOnGetFilterCount}
			/>
		</ModalDialog>
	);
};
