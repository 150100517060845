import React, { useState } from 'react';
import { SxProps, Theme } from '@mui/system';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material';
import { toast } from 'react-toastify';

// Icons
import { mdiClose } from '@mdi/js';

// Monorepo
import { Approach } from '@constituenthub/common';

// Components
import {
	ErrorMessage,
	IconButton,
	FlexColumn,
	ConfirmDeleteDialog,
} from '../common';
import { ApproachForm } from './ApproachForm';
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch } from '../../store';
import { updateApproach, removeApproach } from '../../store/application';

type Props = {
	approach: Approach;
	onClose?: () => void;
	sx?: SxProps<Theme>;
};

export const ApproachDetail = ({
	approach: initialApproach,
	onClose,
	sx = {},
}: Props) => {
	const theme = useTheme();
	const { api } = useAppContext();
	const dispatch = useAppDispatch();
	const [approach, setApproach] =
		useState<Partial<Approach>>(initialApproach);

	const [error, setError] = useState<Error>();
	const [confirmDelete, setConfirmDelete] = useState(false);

	const handleChange = (data: Partial<Approach>) => {
		setApproach((s) => ({ ...s, ...data }));
	};

	const saveChanges = async () => {
		try {
			const data = await api.approach.update(approach as Approach);
			dispatch(updateApproach(data));
			toast.info(`${data.name} Updated`);
		} catch (err) {
			setError(err as Error);
		}
	};

	const handleDelete = async () => {
		try {
			if (approach.approachId) {
				await api.approach.remove(approach.approachId);
				dispatch(removeApproach(approach.approachId));
				toast.info(`${approach.name} Deleted`);
			}
		} catch (err) {
			setError(err as Error);
		}
	};

	React.useEffect(() => {
		setApproach(initialApproach);
	}, [initialApproach]);

	return (
		<>
			<AppBar position="relative">
				<Toolbar
					variant="dense"
					sx={{
						position: 'relative',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '100%',
					}}
				>
					<span style={{ flexGrow: 1 }}>
						{approach.name || 'Loading...'}
					</span>
					{!!onClose && (
						<IconButton
							onClick={onClose}
							path={mdiClose}
							title="Close"
							variant="small"
							edge="end"
							color={theme.palette.primary.contrastText}
						/>
					)}
				</Toolbar>
			</AppBar>
			<FlexColumn fill scroll sx={sx}>
				<ErrorMessage error={error} />
				{!error && (
					<ApproachForm approach={approach} onChange={handleChange} />
				)}
			</FlexColumn>
			<Toolbar>
				{!error && (
					<Button
						variant="text"
						onClick={() => setConfirmDelete(true)}
						color="error"
					>
						Delete
					</Button>
				)}
				<span style={{ flexGrow: 1 }} />
				{!error && (
					<Button
						variant="contained"
						onClick={saveChanges}
						disabled={!approach.name || !approach.description}
					>
						Save Changes
					</Button>
				)}
				{!!error && (
					<Button
						variant="contained"
						onClick={() => setError(undefined)}
					>
						Try Again
					</Button>
				)}
			</Toolbar>
			{!!confirmDelete && (
				<ConfirmDeleteDialog
					title="Confirm Delete"
					message="Are you sure you want to delete this approach?  If this approach is associated with any Action Cards or Change Items it will not be deleted."
					onClose={() => setConfirmDelete(false)}
					onContinue={handleDelete}
					open={true}
				/>
			)}
		</>
	);
};
