import { ActionCard, ChangeItem, Entity } from '@constituenthub/common';
import React, { useEffect } from 'react';

// Lib
import { ApplicationEvent } from '../lib/ApplicationEvent';
import { useAppDispatch } from '../store';
import {
	setActionsAdded,
	setActionsUpdated,
	setActionsRemovedById,
} from '../store/actions';

import {
	setItemsAdded,
	setItemsUpdated,
	setItemsRemovedById,
} from '../store/items';

export interface ApplicationEventContextValue {}

export const ApplicationEventContext =
	React.createContext<ApplicationEventContextValue>({});

export interface ApplicationEventContextProps {
	children: React.ReactNode;
}

export const ApplicationEventContextProvider = (
	props: ApplicationEventContextProps
) => {
	const { children } = props;
	const dispatch = useAppDispatch();

	useEffect(() => {
		//*	Actions */
		ApplicationEvent.EntityCreated<ActionCard>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsAdded([data]));
			}
		);
		ApplicationEvent.MultipleEntityCreated<ActionCard[]>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsAdded(data));
			}
		);
		ApplicationEvent.EntityChanged<ActionCard>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsUpdated([data]));
			}
		);
		ApplicationEvent.MultipleEntityChanged<ActionCard[]>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsUpdated(data));
			}
		);
		ApplicationEvent.EntityRemoved<number>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsRemovedById([data]));
			}
		);
		ApplicationEvent.MultipleEntityRemoved<number[]>(
			Entity.ActionCard,
			({ payload: { data } }) => {
				if (data) dispatch(setActionsRemovedById(data));
			}
		);

		//*	Change Items */
		ApplicationEvent.EntityCreated<ChangeItem>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsAdded([data]));
			}
		);
		ApplicationEvent.MultipleEntityCreated<ChangeItem[]>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsAdded(data));
			}
		);
		ApplicationEvent.EntityChanged<ChangeItem>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsUpdated([data]));
			}
		);
		ApplicationEvent.MultipleEntityChanged<ChangeItem[]>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsUpdated(data));
			}
		);
		ApplicationEvent.EntityRemoved<number>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsRemovedById([data]));
			}
		);
		ApplicationEvent.MultipleEntityRemoved<number[]>(
			Entity.ChangeItem,
			({ payload: { data } }) => {
				if (data) dispatch(setItemsRemovedById(data));
			}
		);
	}, [dispatch]);

	return (
		<ApplicationEventContext.Provider value={{}}>
			{children}
		</ApplicationEventContext.Provider>
	);
};
