import { useState } from 'react';

export type SelectionHook = {
	isSelected: (id: number) => boolean;
	isAllSelected: (length: number) => boolean;
	isMultipleSelected: () => boolean;
	toggleSelected: (id: number) => void;
	toggleAllSelected: (ids: number[]) => void;
	clearAll: () => void;
	getSelected: () => number[];
	hasSelection: () => boolean;
}

export const useSelection = (): SelectionHook => {
    const [selected, setSelected] = useState<Record<number, boolean>>({});

	const isSelected = (id: number) => {
		return selected[id] === true;
	};

	const toggleSelected = (id: number) => {
		setSelected((s) => {
			const ns = { ...s };
			if (ns[id] === true) {
				delete ns[id];
			} else {
				ns[id] = true;
			}
			return ns;
		});
	};

	const isMultipleSelected = () => {
		return Object.keys(selected).length > 1;
    };

	const isAllSelected = (length: number) => {
		return Object.keys(selected).length === length;
	};

	const toggleAllSelected = (ids: number[]) => {
		setSelected((s) => {
			let ns = { ...s };
			if (isAllSelected(ids.length)) {
				ns = {};
			} else {
				ids.forEach((id) => {
					ns[id] = true;
				});
			}
			return ns;
		});
	};

	const getSelected = (): number[] => {
		return Object.keys(selected).map((key) => parseInt(key, 10));
	};

	const clearAll = () => {
		setSelected({});
	};

	const hasSelection = () => {
		return Object.keys(selected).length > 0;
    };

    return {
        isSelected,
        isAllSelected,
        isMultipleSelected,
        toggleSelected,
        toggleAllSelected,
        clearAll,
		getSelected,
		hasSelection
    }
}