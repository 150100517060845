import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { ListItem } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { orderBy } from 'lodash';

// Icons
import Icon from '@mdi/react';
import { mdiClose, mdiChevronDown, mdiFloppy } from '@mdi/js';

// Monorepo
import { FieldDefinition, View } from '@constituenthub/common';

// Lib
import { ConfirmDeleteDialog } from '../common';
import { useAppContext } from '../../contexts/AppContext';
import { SaveViewDialog } from './SaveViewDialog';
import { FilterDescription } from './FilterDescription';

type Props = {
	feature: string;
	definition: Record<string, FieldDefinition>;
	data: Record<string, any>;
	onViewClick: (view: View) => void;
	onViewSaved?: (view: View) => void;
	onViewDeleted?: (view: View) => void;
	sx?: SxProps<Theme>;
	canSave?: boolean;
	noSaveMessage?: string;
};

type DialogOptions = {
	key: 'SaveView' | 'ConfirmDelete';
	data: any;
};

export const ViewButton = ({
	feature,
	data,
	onViewClick,
	onViewSaved,
	onViewDeleted,
	sx,
	canSave,
	noSaveMessage,
	definition,
}: Props) => {
	const { api } = useAppContext();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [views, setViews] = React.useState<View[]>([]);
	const [dialog, setDialog] = React.useState<DialogOptions>();

	const loadViews = React.useCallback(async () => {
		try {
			const data = await api.view.listViews(feature);
			setViews(data);
		} catch (error) {
			console.log(error);
			setViews([]);
		}
	}, [api.view, feature]);

	React.useEffect(() => {
		loadViews();
	}, [loadViews]);

	const handleSaveView = React.useCallback(
		async (view: Partial<View>) => {
			try {
				const result = await api.view.createView({
					...view,
					feature,
					data,
				});
				setViews((s) => orderBy([...s, result], ['name']));
				if (onViewSaved) {
					onViewSaved(result);
				}
				setAnchorEl(null);
				return result;
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		[api.view, data, feature, onViewSaved]
	);

	const handleDeleteView = React.useCallback(
		async (view: View) => {
			try {
				await api.view.removeView(view.viewId);
				setViews((s) => {
					const ns = [...s];
					const index = ns.findIndex((x) => x.viewId === view.viewId);
					if (index !== -1) {
						ns.splice(index, 1);
					}
					return ns;
				});
				if (onViewDeleted) {
					onViewDeleted(view);
				}
				setAnchorEl(null);
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		[api.view, onViewDeleted]
	);

	const handleOpenSaveView = () => {
		if (canSave) {
			setDialog({ key: 'SaveView', data: null });
			console.info(`Open saved views dialog`);
		}
	};

	// const handleOpenManageViewDialog = () => {
	// 	console.info(`Open view management dialog`);
	// };

	const handleOpenDeleteConfirmationDialog = (view: View) => {
		setDialog({ key: 'ConfirmDelete', data: view });
	};

	const handleViewClicked = (view: View | undefined) => {
		if (view) {
			onViewClick(view);
			setAnchorEl(null);
		}
	};

	return (
		<React.Fragment>
			<ButtonGroup variant="outlined" aria-label="split button" sx={sx}>
				<Button
					id="savedview-button"
					aria-controls={open ? 'savedview-button' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={(event) =>
						views.length > 0
							? setAnchorEl(event.currentTarget)
							: setAnchorEl(null)
					}
					variant="outlined"
					endIcon={<Icon path={mdiChevronDown} size={1} />}
				>
					Saved Views
				</Button>
				<Menu
					id="savedview-button"
					aria-labelledby="savedview-button"
					anchorEl={anchorEl}
					open={open}
					onClose={() => setAnchorEl(null)}
					sx={{ mt: '38px' }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					elevation={1}
					PaperProps={{
						sx: {
							border: `1px solid ${theme.palette.primary.main}`,
							borderRadius: 0,
						},
					}}
				>
					{views.map((view) => (
						<MenuItem
							key={view.viewId}
							sx={{ width: '550px', textOverflow: 'ellipsis' }}
						>
							<ListItem
								dense
								button
								onClick={() => handleViewClicked(view)}
							>
								<ListItemText
									primary={view.name}
									secondary={
										<FilterDescription
											definition={definition}
											filter={view.data.filter}
										/>
									}
									sx={{
										whiteSpace: 'normal',
									}}
								/>
							</ListItem>
							<IconButton
								size="small"
								onClick={() =>
									handleOpenDeleteConfirmationDialog(view)
								}
							>
								<Icon path={mdiClose} size={0.75} />
							</IconButton>
						</MenuItem>
					))}
				</Menu>
				<Tooltip
					title={
						canSave === true
							? 'Saved the current view'
							: noSaveMessage ||
							  'There is currently nothing to save'
					}
				>
					<Button
						size="small"
						onClick={handleOpenSaveView}
						color="primary"
						sx={{ mr: 2 }}
					>
						<Icon path={mdiFloppy} size={1} />
					</Button>
				</Tooltip>
			</ButtonGroup>
			<SaveViewDialog
				open={dialog?.key === 'SaveView'}
				onClose={() => setDialog(undefined)}
				onCreateView={handleSaveView}
			/>
			<ConfirmDeleteDialog
				open={dialog?.key === 'ConfirmDelete'}
				title="Delete View"
				message={`Are you sure you want to delete the view '${dialog?.data?.name}'`}
				onContinue={() => handleDeleteView(dialog?.data as View)}
				onClose={() => setDialog(undefined)}
			/>
		</React.Fragment>
	);
};
