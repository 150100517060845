import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

type LoadingProps = {
	enabled: boolean;
	text?: string;
};

export const Loading = (props: LoadingProps) => {
	const { enabled, text } = props;
	if (!enabled) return null;
	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				position: 'relative',
				width: '100%',
				height: '100%',
				flexGrow: 1,
			}}
			spacing={2}
		>
			<Grid item>
				<CircularProgress />
			</Grid>
			{text && <Grid item>{text}</Grid>}
		</Grid>
	);
};
