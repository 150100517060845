import { JobRole } from './types';

export const createAffectedJobRole = (
	groupId: number,
	changeItemId: number
): Partial<JobRole> => {
	return {
		changeItemId: changeItemId,
		groupId: groupId,
		fields: {},
	};
};

export const createAffectedJobRoles = (
	groupIds: number[],
	changeItemIds: number[]
): Partial<JobRole>[] => {
	const roles: Partial<JobRole>[] = [];

	changeItemIds.forEach((changeItemId) => {
		groupIds.forEach((groupId) => {
			roles.push(createAffectedJobRole(groupId, changeItemId));
		});
	});

	return roles;
};
