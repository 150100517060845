import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Monorepo
import { User } from '@constituenthub/common';

import { useAppSelector } from '../../store';
import { selectUsers } from '../../store/application';

// Components
import { AutoCompleteSelect, TextField } from '../common';

type Props = {
	label: string;
	value: string;
	onChange: (value: string | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
};

const formatUser = (user: User): string => `${user.first} ${user.last}`;

export const FieldUser = (props: Props) => {
	const {
		label,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
	} = props;
	const users = useAppSelector(selectUsers);
	const selectedUser = users.find((x) => x.userId === value);
	const options = users.map((x) => ({
		label: formatUser(x),
		value: x.userId,
	}));

	if (!readOnly) {
		return (
			<AutoCompleteSelect
				label={label}
				value={selectedUser ? selectedUser.userId : ''}
				required={required}
				disabled={disabled}
				readOnly={readOnly}
				mixedValue={mixedValue}
				error={error}
				autoOpen={false}
				delay={0}
				onChange={(val) => onChange(val as string)}
				options={options}
				renderOption={(props, option, state) => (
					<ListItem {...props} key={`${option.value}`} disableGutters>
						<ListItemText primary={option.label} />
					</ListItem>
				)}
			/>
		);
	}

	return (
		<TextField
			label={label}
			value={value}
			onChange={onChange}
			error={error}
			required={required}
			disabled={disabled}
			readOnly={readOnly}
			mixedValue={mixedValue}
		/>
	);
};
