import React from 'react';
import { Splash } from '../common';

// Components
import { Authenticator } from './Authenticator';

export const Login = () => {
	return (
		<Splash>
			<Authenticator />
		</Splash>
	);
};
