import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import { Entity, FieldDefinition, FieldValue } from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';

type Props = {
	definition: Record<string, FieldDefinition>;
	fields: Record<string, FieldValue>;
	onChange: (property: string, value: FieldValue) => void;
	mixedValues: string[];
};

export const AffectedJobRolesForm = (props: Props) => {
	const { definition, fields, onChange, mixedValues } = props;

	return (
		<Grid
			item
			sx={{
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				overflowY: 'auto',
				pr: 1,
				my: 1,
			}}
		>
			<Grid item>
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.JobRole}
							definition={definition.Impact}
							value={fields[definition.Impact.property]}
							mixedValue={mixedValues.includes(
								definition.Impact.property
							)}
							onChange={(value: FieldValue) =>
								onChange(definition.Impact.property, value)
							}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Field
					entity={Entity.JobRole}
					definition={definition.Reaction}
					value={fields[definition.Reaction.property]}
					mixedValue={mixedValues.includes(
						definition.Reaction.property
					)}
					onChange={(value: FieldValue) =>
						onChange(definition.Reaction.property, value)
					}
				/>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.JobRole}
					definition={definition.Response}
					value={fields[definition.Response.property]}
					mixedValue={mixedValues.includes(
						definition.Response.property
					)}
					onChange={(value: FieldValue) =>
						onChange(definition.Response.property, value)
					}
				/>
			</Grid>
		</Grid>
	);
};
