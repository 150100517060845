import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

// Monorepo
import {
	ChangeItem,
	ChangeItemListOptions,
	Entity,
	Filter,
	parseFilterFromUrl,
} from '@constituenthub/common';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch, useAppSelector } from '../../store';
import {
	selectItems,
	selectSelectedItems,
	setItems,
	setItemsSelectedToggleAll,
	setItemsSelectedToggle,
	setItemsSelected,
} from '../../store/items';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { selectProject } from '../../store/project';

export const useChangeItemsController = () => {
	// Should not change except on reload or navigate
	const { api } = useAppContext();
	const location = useLocation();
	const project = useAppSelector(selectProject);
	const dispatch = useAppDispatch();
	const definition = useFieldDefinitions(Entity.ChangeItem);
	const [filter, setFilter] = useState<Filter>();
	const [loaded, setLoaded] = useState(false);

	// Changes frequently
	const changeItems = useAppSelector(selectItems);
	const selectedItems = useAppSelector(selectSelectedItems);

	const getSelectedItems = useCallback(() => {
		return changeItems.filter(
			(x) => selectedItems[x.changeItemId] === true
		);
	}, [changeItems, selectedItems]);

	const onAddNewChangeItem = useCallback(async () => {
		try {
			const item = await api.changeitem.createChangeItem({
				fields: { name: 'New Change Item' },
			});
			return item;
		} catch (err) {
			toast.error(
				`Unable to create a new item: ${(err as Error).message}`
			);
		}
	}, [api]);

	const onChangeItemUpdated = useCallback(
		async (data: ChangeItem) => {
			try {
				const item = await api.changeitem.updateChangeItem(data);
				return item;
			} catch (err) {
				toast.error(`Unable to update item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	const onChangeItemsUpdated = useCallback(
		async (data: ChangeItem[]) => {
			try {
				const items = await api.changeitem.updateChangeItems(data);
				return items;
			} catch (err) {
				toast.error(`Unable to update item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	const onChangeItemRemoved = useCallback(
		async (data: ChangeItem) => {
			try {
				await api.changeitem.removeChangeItem(data.changeItemId);
			} catch (err) {
				toast.error(`Unable to remove item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	const onLoadChangeItems = useCallback(
		async (options: ChangeItemListOptions) => {
			try {
				// TODO: Filters, GroupBy, OrderBy
				const data = (await api.changeitem.listChangeItems(
					options
				)) as ChangeItem[];
				setLoaded(true);
				dispatch(setItems(data));
			} catch (err) {
				toast.error(`Unable to load items: ${(err as Error).message}`);
			}
		},
		[api, dispatch]
	);

	const onLoadMoreChangeItems = useCallback(async () => {
		return Promise.resolve();
	}, []);

	const selectChangeItems = useCallback(
		(ids: number[]) => {
			dispatch(setItemsSelected(ids));
		},
		[dispatch]
	);

	useEffect(() => {
		if (project) {
			onLoadChangeItems({ filter });
		}
	}, [project, onLoadChangeItems, filter]);

	useEffect(() => {
		setFilter(parseFilterFromUrl(location.search));
	}, [location.search]);

	const selectedChangeItems = getSelectedItems();

	return {
		loaded,
		project,
		definition,
		changeItems,
		selectedChangeItems,
		changeItemSelectionCount: selectedChangeItems.length,
		isChangeItemSelected: (id: number) => selectedItems[id] === true,
		isAllChangeItemsSelected: () =>
			selectedChangeItems.length === changeItems.length,
		toggleSelectedChangeItem: (id: number) =>
			dispatch(setItemsSelectedToggle(id)),
		toggleAllSelectedChangeItems: () =>
			dispatch(setItemsSelectedToggleAll()),
		selectOneChangeItem: (id: number) => dispatch(setItemsSelected([id])),
		selectChangeItems,
		onClearSelectedChangeItems: () => dispatch(setItemsSelected([])),
		onAddNewChangeItem,
		onChangeItemUpdated,
		onChangeItemsUpdated,
		onChangeItemRemoved,
		onLoadChangeItems,
		onLoadMoreChangeItems,
	};
};

export type ChangeItemsHook = ReturnType<typeof useChangeItemsController>;
