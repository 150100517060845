import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/system';

// Monorepo
import { ActionCard, Entity } from '@constituenthub/common';

// Lib
import { ActionCardsHook } from './useActionCardsController';
import { useBatchEditFields } from '../fields/useBatchEditFields';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { BatchTaskForm } from './BatchTaskForm';

type Props = {
	controller: ActionCardsHook;
	actions: ActionCard[];
	showName?: boolean;
	showApproach?: boolean;
	onChangesSaved: () => void;
	sx?: SxProps<Theme>;
};

export const ActionCardBatchForm = ({
	controller,
	actions,
	showName,
	showApproach,
	onChangesSaved,
	sx,
}: Props) => {
	const definition = useFieldDefinitions(Entity.ActionCard);
	const { onActionCardsUpdated } = controller;

	const {
		changes,
		mixedValues,
		onMultipleChanges,
		loadItems,
		hasChanges,
		getUpdates,
	} = useBatchEditFields({
		definition,
		excludeProperties: [],
	});

	const saveChanges = async () => {
		const updates = getUpdates();
		await onActionCardsUpdated(updates as ActionCard[]);
		onChangesSaved();
	};

	useEffect(() => {
		loadItems(actions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actions]);

	const hasBeenChanged = hasChanges();

	return (
		<Grid
			container
			direction="column"
			flexGrow={1}
			wrap="nowrap"
			sx={{
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				'& .MuiGrid-item': {
					mb: 0.75,
				},
			}}
		>
			<BatchTaskForm
				fields={changes}
				onChange={onMultipleChanges}
				mixedValues={mixedValues}
				showName={showName}
				showApproach={showApproach}
				sx={sx}
			/>
			<Grid item>
				<Toolbar variant="dense" disableGutters>
					<span style={{ flexGrow: 1 }} />
					<Button
						variant="contained"
						disabled={!hasBeenChanged}
						onClick={() => saveChanges()}
					>
						Save Changes
					</Button>
				</Toolbar>
			</Grid>
		</Grid>
	);
};
