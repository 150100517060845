import React from 'react';
import { Theme } from '@mui/material/styles';

const cell: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	flexGrow: 1,
	flexShrink: 1,
	alignItems: 'center',
	justifyContent: 'flex-start',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	border: '1px solid transparent',
	outline: 'none',
	cursor: 'pointer',
};

const header: React.CSSProperties = {
	...cell,
	fontWeight: 'bold',
};

/**
 *
 * CUSTOMIZE
 *
 * */

export const DEFAULT_ROW_HEIGHT = 38;

export const getHeaderStyle = (
	gridStyle: React.CSSProperties,
	theme: Theme
): React.CSSProperties => {
	return {
		...gridStyle,
		...header,
		padding: theme.spacing(0.5, 0.5, 0.5, 1),
		fontSize: theme.typography.caption.fontSize,
	};
};

export const getCellStyle = (
	gridStyle: React.CSSProperties,
	theme: Theme
): React.CSSProperties => {
	return {
		...gridStyle,
		...cell,
		padding: theme.spacing(0.5, 0.5, 0.5, 1),
		fontSize: theme.typography.body2.fontSize,
	};
};

export const getMultiGridStyle = (theme: Theme): React.CSSProperties => {
	return {
		border: `1px solid ${theme.palette.grey[300]}`,
		borderRadius: theme.spacing(1),
		outline: 'none',
	};
};

export const getContainerStyle = (theme: Theme): React.CSSProperties => {
	return {
		outline: 'none',
	};
};

export const getBottomLeftGridStyle = (theme: Theme): React.CSSProperties => {
	return {
		backgroundColor: `#ffffff`,
		borderBottomLeftRadius: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	};
};

export const getBottomRightGridStyle = (theme: Theme): React.CSSProperties => {
	return {
		border: 'none',
		outline: 'none',
	};
};

export const getTopLeftGridStyle = (theme: Theme): React.CSSProperties => {
	return {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		outline: 'none',
	};
};

export const getTopRightGridStyle = (theme: Theme): React.CSSProperties => {
	return {
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		outline: 'none',
	};
};
