import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Components
import { NothingHere } from '../common';
import { SelectGroupDialog } from '../groups/SelectGroupDialog';
import { DeleteJobRolesDialog } from './DeleteJobRolesDialog';
import { AffectedJobRolesList } from './AffectedJobRolesList';
import { AffectedJobRolesBatchForm } from './AffectedJobRolesBatchForm';
import { AffectedJobRolesHistory } from './AffectedJobRolesHistory';
import { AffectedJobRolesApproaches } from './AffectedJobRolesApproaches';

// Lib
import { useJobRolesController } from './useJobRolesController';
import { ChangeItem, Group } from '@constituenthub/common';
import { TOOLBAR_HEIGHT } from '../../lib';

const tabs = [
	{ index: 0, key: 'details', label: 'Details' },
	{ index: 1, key: 'approaches', label: 'Approaches' },
	{ index: 2, key: 'history', label: 'History' },
];

type Props = {
	selectedChangeItems: ChangeItem[];
};

export const AffectedJobRoles = (props: Props) => {
	const { selectedChangeItems } = props;
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [isSelectGroupsOpen, setIsSelectGroupsOpen] = useState(false);
	const [isDeleteJobRolesOpen, setIsDeleteJobRolesOpen] = useState(false);
	const controller = useJobRolesController(selectedChangeItems);

	const handleGroupsSelected = async (groups: Group[]) => {
		try {
			const roles = await controller.addGroups(groups);
			setIsSelectGroupsOpen(false);
			return roles;
		} catch (error) {
			// Allow the dialog to handle the error
			throw error;
		}
	};

	const handleRemoveJobRoles = async () => {
		try {
			const roles = await controller.deleteSelectedGroups();
			setIsDeleteJobRolesOpen(false);
			return roles;
		} catch (error) {
			// Allow the dialog to handle the error
			throw error;
		}
	};

	return (
		<>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				flexGrow={1}
				sx={{
					position: 'relative',
					height: '100%',
					overflow: 'hidden',
				}}
			>
				{controller.roles.length > 0 && (
					<Grid
						item
						flexGrow={1}
						sx={{
							maxHeight:
								controller.selectedGroups.length > 0
									? '40%'
									: '100%',
						}}
					>
						<AffectedJobRolesList
							controller={controller}
							onSelectGroups={() => setIsSelectGroupsOpen(true)}
							onDeleteGroups={() => setIsDeleteJobRolesOpen(true)}
						/>
					</Grid>
				)}
				{controller.roles.length === 0 && (
					<Grid item flexGrow={1}>
						<NothingHere>
							<Box sx={{ p: 2, textAlign: 'center' }}>
								<Typography component="div" variant="body1">
									It looks like you don't have any Job Roles
									yet.
								</Typography>
							</Box>
							<Box sx={{ p: 2, textAlign: 'center' }}>
								<Button
									variant="outlined"
									onClick={() => setIsSelectGroupsOpen(true)}
								>
									Add Affected Job Roles
								</Button>
							</Box>
						</NothingHere>
					</Grid>
				)}
				{controller.selectedGroups.length > 0 && (
					<Grid item flexGrow={1} sx={{ maxHeight: '60%' }}>
						<Tabs
							value={selectedTabIndex}
							onChange={(_event, index) =>
								setSelectedTabIndex(index)
							}
							aria-label="Change Item Details"
						>
							{tabs.map((tab) => (
								<Tab
									key={tab.key}
									label={tab.label}
									value={tab.index}
								/>
							))}
						</Tabs>
						<Grid
							container
							direction="column"
							wrap="nowrap"
							flexGrow={1}
							sx={{
								position: 'relative',
								// height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
								height: `98%`,
								overflow: 'hidden',
							}}
						>
							{selectedTabIndex === 0 && (
								<Grid
									item
									sx={{
										p: 2,
										flexGrow: 1,
										position: 'relative',
										height: '100%',
										overflow: 'hidden',
									}}
								>
									<AffectedJobRolesBatchForm
										controller={controller}
									/>
								</Grid>
							)}
							{selectedTabIndex === 1 && (
								<AffectedJobRolesApproaches
									selectedJobRoles={
										controller.selectedJobRoles
									}
								/>
							)}
							{selectedTabIndex === 2 && (
								<AffectedJobRolesHistory
									selectedJobRoles={
										controller.selectedJobRoles
									}
								/>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
			<SelectGroupDialog
				open={isSelectGroupsOpen}
				onClose={() => setIsSelectGroupsOpen(false)}
				onGroupsSelected={handleGroupsSelected}
			/>
			<DeleteJobRolesDialog
				groups={controller.selectedGroups}
				open={isDeleteJobRolesOpen}
				onClose={() => setIsDeleteJobRolesOpen(false)}
				onDeleteJobRoles={handleRemoveJobRoles}
			/>
		</>
	);
};
