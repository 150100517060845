import { JobRole } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class JobRoleApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listJobRoles(changeItemIds: number[]): Promise<JobRole[]> {
		const response = await this.client.get<JobRole[]>(
			`/jobrole?items=${changeItemIds.join(',')}`
		);
		return response.result;
	}

	public async createJobRole(data: Partial<JobRole>): Promise<JobRole> {
		const response = await this.client.post<Partial<JobRole>, JobRole>(
			`/jobrole`,
			data
		);
		return response.result;
	}

	public async createJobRoles(data: Partial<JobRole>[]): Promise<JobRole[]> {
		const response = await this.client.post<Partial<JobRole>[], JobRole[]>(
			`/jobrole`,
			data
		);
		return response.result;
	}

	public async getJobRole(jobRoleId: number): Promise<JobRole> {
		const response = await this.client.get<JobRole>(
			`/jobrole/${jobRoleId}`
		);
		return response.result;
	}

	public async updateJobRole(data: JobRole): Promise<JobRole> {
		const response = await this.client.put<JobRole, JobRole>(
			`/jobrole/${data.jobRoleId}`,
			data
		);
		return response.result;
	}

	public async updateJobRoles(data: JobRole[]): Promise<JobRole[]> {
		const response = await this.client.put<JobRole[], JobRole[]>(
			`/jobrole`,
			data
		);
		return response.result;
	}

	public async removeJobRole(jobRoleId: number): Promise<void> {
		await this.client.del<JobRole>(`/jobrole/${jobRoleId}`);
	}

	public async deleteJobRoles(jobRoleIds: number[]): Promise<void> {
		await this.client.del(`/jobrole`, jobRoleIds);
	}
}
