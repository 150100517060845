import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

// Monorepo
import { Approach } from '@constituenthub/common';

import { useAppSelector } from '../../store';
import { selectApproaches } from '../../store/application';

// Components
import { AutoCompleteSelect } from '../common';

type Props = {
	label: string;
	value: number | null;
	onChange: (value: number | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
};

type ApproachItemProps = {
	approaches: Approach[];
	props: any;
	selected: boolean;
};

const ApproachItem = ({ props, selected, approaches }: ApproachItemProps) => {
	console.log('Approach Field ID', props);

	const approach = approaches.find((x) => x.name === props.key);
	if (approach) {
		return (
			<ListItem
				{...props}
				key={props.key}
				disableGutters
				selected={selected}
			>
				<ListItemText
					primary={approach.name}
					secondary={approach.description}
				/>
			</ListItem>
		);
	}

	return (
		<ListItem
			{...props}
			key={`approachfield_none`}
			disableGutters
			selected={true}
		>
			<ListItemText primary="None" />
		</ListItem>
	);
};

export const FieldApproach = (props: Props) => {
	const {
		label,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
	} = props;
	const approaches = useAppSelector(selectApproaches);
	const selectedApproach = approaches.find((x) => x.approachId === value);
	const options = approaches.map((x) => ({
		label: x.name,
		value: x.approachId,
	}));

	return (
		<AutoCompleteSelect
			label={label}
			value={selectedApproach ? selectedApproach.approachId : ''}
			required={required}
			disabled={disabled || readOnly}
			readOnly={readOnly}
			mixedValue={mixedValue}
			error={error}
			autoOpen={false}
			delay={0}
			onChange={(val) => onChange(!!val ? parseInt(`${val}`, 10) : null)}
			options={options}
			renderOption={(props, option, state) => (
				<ApproachItem
					key={`${option.value}`}
					props={props}
					approaches={approaches}
					selected={state.selected}
				/>
			)}
		/>
	);
};
