import {
	ChangeItem,
	JobRoleApproach,
	ChangeItemListOptions,
	buildUrlListOptions,
	Entity,
} from '@constituenthub/common';
import { ApplicationEvent } from '../lib/ApplicationEvent';
import { ApiClientInterface } from './ApiClient';

export default class ChangeItemApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listChangeItems(
		options: ChangeItemListOptions
	): Promise<ChangeItem[] | number> {
		const url = buildUrlListOptions('/changeitem', options);
		const response = await this.client.get<ChangeItem[] | number>(url);
		return response.result;
	}

	public async listChangeItemApproaches(
		changeItemIds: number[],
		historic?: boolean
	): Promise<JobRoleApproach[]> {
		const response = await this.client.get<JobRoleApproach[]>(
			`/jobroleapproach?items=${changeItemIds.join(',')}&historic=${
				historic || false
			}`
		);
		return response.result;
	}

	public async createChangeItem(
		data: Partial<ChangeItem>
	): Promise<ChangeItem> {
		const response = await this.client.post<
			Partial<ChangeItem>,
			ChangeItem
		>(`/changeitem`, data);
		ApplicationEvent.NewEntity(Entity.ChangeItem, response.result);
		return response.result;
	}

	public async copyChangeItems(data: ChangeItem[]): Promise<ChangeItem[]> {
		const response = await this.client.patch<ChangeItem[], ChangeItem[]>(
			`/changeitem`,
			data
		);
		ApplicationEvent.NewMultipleEntities(
			Entity.ChangeItem,
			response.result
		);
		return response.result;
	}

	public async getChangeItem(changeItemId: number): Promise<ChangeItem> {
		const response = await this.client.get<ChangeItem>(
			`/changeitem/${changeItemId}`
		);
		ApplicationEvent.UpdateEntity(Entity.ChangeItem, response.result);
		return response.result;
	}

	public async updateChangeItem(data: ChangeItem): Promise<ChangeItem> {
		const response = await this.client.put<ChangeItem, ChangeItem>(
			`/changeitem/${data.changeItemId}`,
			data
		);
		ApplicationEvent.UpdateEntity(Entity.ChangeItem, response.result);
		return response.result;
	}

	public async updateChangeItems(data: ChangeItem[]): Promise<ChangeItem[]> {
		const response = await this.client.put<ChangeItem[], ChangeItem[]>(
			`/changeitem`,
			data
		);
		ApplicationEvent.UpdateMultipleEntities(
			Entity.ChangeItem,
			response.result
		);
		return response.result;
	}

	public async removeChangeItem(changeItemId: number): Promise<void> {
		await this.client.del<ChangeItem>(`/changeitem/${changeItemId}`);
		ApplicationEvent.RemoveEntity(Entity.ChangeItem, changeItemId);
	}

	public async deleteChangeItems(changeItemIds: number[]): Promise<void> {
		await this.client.del(`/changeitem`, changeItemIds);
		ApplicationEvent.RemoveMultipleEntities(
			Entity.ChangeItem,
			changeItemIds
		);
	}
}
