import React from 'react';
import Checkbox from '@mui/material/Checkbox';

// Components
import { HeaderBase } from '../HeaderBase';

// Lib
import { HeaderRenderProps } from '../../types';

interface Props extends HeaderRenderProps {
	isChecked: boolean;
	onToggleChecked: () => void;
}

export const SelectionHeaderRenderer = (props: Props) => {
	return (
		<HeaderBase {...props} style={props.style}>
			<Checkbox
				checked={props.isChecked}
				onChange={props.onToggleChecked}
			/>
		</HeaderBase>
	);
};
