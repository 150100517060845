import React from 'react';

// Monorepo
import { ActionCardStages } from '@constituenthub/common';

// Components
import { ActionCardsHook } from './useActionCardsController';
import { CardColumn } from './CardColumn';
import { Container, Draggable } from 'react-smooth-dnd';
import { TOOLBAR_HEIGHT } from '../../lib';

type Props = {
	controller: ActionCardsHook;
	show: boolean;
	openCard: (entity: string, parentId: number, actionId: number) => void;
};

const defaultStages: string[] = [
	ActionCardStages.ToDo,
	ActionCardStages.InProgress,
	ActionCardStages.Complete,
	ActionCardStages.Ongoing,
];

export const CardView = ({ show, controller, openCard }: Props) => {
	// TODO: This state should be part of overall view
	const [stages, setStages] = React.useState(defaultStages);

	const onColumnDrop = (
		sourceIndex: number | null,
		targetIndex: number | null
	) => {
		if (sourceIndex !== null && targetIndex !== null) {
			setStages((s) => {
				const ns = [...s];
				const stage = ns.splice(sourceIndex, 1)[0];
				ns.splice(targetIndex, 0, stage);
				return ns;
			});
		}
	};

	return (
		<Container
			orientation="horizontal"
			lockAxis="x"
			style={{
				position: 'relative',
				height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
				flexGrow: 1,
				display: show ? 'flex' : 'none',
			}}
			onDrop={({ removedIndex, addedIndex }) =>
				onColumnDrop(removedIndex, addedIndex)
			}
			dragHandleSelector=".actioncard-column-drag-handle"
			dropPlaceholder={{
				animationDuration: 150,
				showOnTop: true,
				className: 'actioncard-column-drop-preview',
			}}
		>
			{stages.map((stage) => (
				<Draggable key={stage} className="actioncard-column">
					<CardColumn
						controller={controller}
						stage={stage}
						openCard={openCard}
					/>
				</Draggable>
			))}
		</Container>
	);
};
