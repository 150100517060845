import React from 'react';
import { get } from 'lodash';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps } from '../../types';
import { User } from '@constituenthub/common';
import { useUser } from '../../../../hooks/useUser';

interface Props extends CellRenderProps {
	user?: User;
}

export const UserRenderer = (props: Props) => {
	const value = get(props.row, props.column.field, '');
	const { user } = useUser(value);
	const val = user ? `${user.first} ${user.last}` : '';

	return (
		<CellBase
			{...props}
			onDoubleClick={() => {}}
			style={{
				...props.style,
			}}
		>
			<span
				style={{
					flexGrow: 1,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{val}
			</span>
		</CellBase>
	);
};
