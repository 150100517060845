import { isDate, isString } from 'lodash';
import { DateTime } from 'luxon';
import { NotImplementedException } from '.';

export const DateCalculations = {
	BeginningOfWeek: 'From Beginning of the Week',
	BeginningOfMonth: 'From Beginning of the Month',
	EndOfWeek: 'To End of the Week',
	EndOfMonth: 'To End of the Month',
} as const;

export type DateCalculation =
	| 'From Beginning of the Week'
	| 'From Beginning of the Month'
	| 'To End of the Week'
	| 'To End of the Month';

export const getUTCDateNow = () => {
	const dt = DateTime.utc();
	return dt.toJSDate();
};

export const toDateTimeString = (value?: Date | null): string | null => {
	if (!value) return null;
	const dt = DateTime.fromJSDate(value);
	return dt.isValid ? dt.setZone('utc').toISO() : null;
};

export const fromDateTimeString = (value?: string | null): Date | null => {
	if (!value) return null;
	const dt = DateTime.fromISO(value, { zone: 'utc' });
	return dt.isValid ? dt.toJSDate() : null;
};

export const formatDate = (value?: Date | string | null): string => {
	if (isString(value)) {
		var dt = DateTime.fromISO(value);
		return dt.toLocaleString(DateTime.DATE_SHORT);
	} else if (isDate(value)) {
		var dt = DateTime.fromJSDate(value);
		return dt.toLocaleString(DateTime.DATE_SHORT);
	}
	return '';
};
export const formatDateTime = (value?: Date | string | null): string => {
	if (isString(value)) {
		var dt = DateTime.fromISO(value);
		return dt.toLocaleString(DateTime.DATETIME_SHORT);
	} else if (isDate(value)) {
		var dt = DateTime.fromJSDate(value);
		return dt.toLocaleString(DateTime.DATETIME_SHORT);
	}
	return '';
};

export const getCalculatedDate = (calculation: DateCalculation): Date => {
	if (calculation === DateCalculations.BeginningOfWeek) {
		return DateTime.now().startOf('week').toJSDate();
	}
	if (calculation === DateCalculations.EndOfWeek) {
		return DateTime.now().endOf('week').toJSDate();
	}
	if (calculation === DateCalculations.BeginningOfMonth) {
		return DateTime.now().startOf('month').toJSDate();
	}
	if (calculation === DateCalculations.EndOfMonth) {
		return DateTime.now().endOf('month').toJSDate();
	}
	throw new NotImplementedException(
		`Unsupported date calculation: '${calculation}'`
	);
};
