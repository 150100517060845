import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import { Hue, PaletteName } from '@constituenthub/common';

// Components
import { ColorSwatch } from './ColorSwatch';

type ColorPaletteProps = {
	palette?: string | null;
	hue?: string | null;
	onChange: (palette: string, hue: string) => void;
};

export const AvailablePaletteNames: PaletteName[] = [
	'Brown',
	'Blue Grey',
	'Blue',
	'Light Blue',
	'Cyan',
	'Teal',
	'Green',
	'Light Green',
	'Lime',
	'Yellow',
	'Amber',
	'Orange',
	'Deep Orange',
	'Red',
	'Pink',
	'Purple',
	'Deep Purple',
];

export const ColorPalette = (props: ColorPaletteProps) => {
	const { palette, hue, onChange } = props;
	return (
		<Grid container direction="row" wrap="wrap" spacing={1}>
			{AvailablePaletteNames.map((name) => (
				<Grid item key={name}>
					<ColorSwatch
						size={48}
						palette={name}
						hue={Hue.Hue700}
						selected={palette === name && hue === Hue.Hue700}
						onClick={onChange}
					/>
				</Grid>
			))}
		</Grid>
	);
};

// export const ColorPalette = (props: ColorPaletteProps) => {
// 	const { palette, hue, onChange } = props;
// 	return (
// 		<Grid container direction="column" spacing={1}>
// 			{AvailablePaletteNames.map((name) => (
// 				<Grid item key={name}>
// 					<Grid container direction="row" wrap="nowrap" spacing={1}>
// 						{PaletteIndex[name].colors.map((color) => (
// 							<Grid item key={`${name}_${color.key}`}>
// 								<ColorSwatch
// 									size={24}
// 									palette={name}
// 									hue={color.key}
// 									selected={
// 										palette === name && hue === color.key
// 									}
// 									onClick={onChange}
// 								/>
// 							</Grid>
// 						))}
// 					</Grid>
// 				</Grid>
// 			))}
// 		</Grid>
// 	);
// };
