import { useState, useEffect, useCallback } from 'react';

// Monorepo
import {
	Approach,
	JobRole,
	JobRoleApproach,
	JobRoleApproachAction,
	createAffectedJobRoleApproaches,
} from '@constituenthub/common';

// Lib
import { useSelection } from '../../hooks/useSelection';
import { useAppContext } from '../../contexts/AppContext';

export interface JobRoleApproachesHook {
	jobRoles: JobRole[];
	approaches: Approach[];
	jobRoleApproaches: JobRoleApproachAction[];
	selectedJobRoleApproaches: JobRoleApproachAction[];
	onSelectNone: () => void;
	onToggleAllSelected: () => void;
	onToggleSelectedApproach: (id: number) => void;
	onDeleteSelectedApproaches: () => Promise<void>;
	onAddApproaches: (approaches: Approach[]) => Promise<JobRoleApproach[]>;
	isApproachSelected: (id: number) => boolean;
}

export interface JobRoleApproachesProps {
	selectedJobRoles: JobRole[];
}

type State = {
	approaches: Approach[];
	jobRoleApproaches: JobRoleApproachAction[];
};

const initialState: State = {
	approaches: [],
	jobRoleApproaches: [],
};

export const useJobRoleApproachesController = (
	props: JobRoleApproachesProps
): JobRoleApproachesHook => {
	const { selectedJobRoles } = props;
	const { api } = useAppContext();
	const [state, setState] = useState<State>(initialState);
	const selection = useSelection();

	const onAddApproaches = async (
		approaches: Approach[]
	): Promise<JobRoleApproach[]> => {
		try {
			const data = createAffectedJobRoleApproaches(
				selectedJobRoles,
				approaches.map((x) => x.approachId)
			);
			const adds = await api.jobRoleApproach.createJobRoleApproaches(
				data
			);
			setState((s) => {
				const ns = { ...s };
				adds.forEach((added) => {
					ns.jobRoleApproaches.push(added);
				});
				return ns;
			});
			return adds;
		} catch (error) {
			throw error;
		}
	};

	const onDeleteSelectedApproaches = async (): Promise<void> => {
		try {
			const jobRoleApproachesToRemove = state.jobRoleApproaches
				.filter((x) => selection.isSelected(x.jobRoleApproachId))
				.map((x) => x.jobRoleApproachId);
			await api.jobRoleApproach.deleteJobRoleApproaches(
				jobRoleApproachesToRemove
			);
			setState((s) => {
				const ns = { ...s };
				jobRoleApproachesToRemove.forEach((removedId) => {
					const index = ns.jobRoleApproaches.findIndex(
						(x) => x.jobRoleApproachId === removedId
					);
					if (index !== -1) {
						ns.jobRoleApproaches.splice(index, 1);
					}
				});
				return ns;
			});
		} catch (error) {
			throw error;
		}
	};

	const loadApproaches = useCallback(async () => {
		try {
			const approaches = await api.approach.listApproaches();
			setState((s) => ({ ...s, approaches }));
		} catch (error) {
			console.log(error);
		}
	}, [api.approach]);

	const loadJobRoleApproaches = useCallback(async () => {
		try {
			const jobRoleApproaches =
				await api.jobRoleApproach.listJobRoleApproaches(
					selectedJobRoles.map((x) => x.jobRoleId)
				);
			setState((s) => ({ ...s, jobRoleApproaches }));
		} catch (error) {
			console.log(error);
		}
	}, [api.jobRoleApproach, selectedJobRoles]);

	useEffect(() => {
		if (state.approaches.length === 0) {
			loadApproaches();
		}
	}, [loadApproaches, selectedJobRoles, state.approaches.length]);

	useEffect(() => {
		if (selectedJobRoles.length > 0) {
			loadJobRoleApproaches();
		} else {
			setState((s) => ({ ...s, jobRoleApproaches: [] }));
		}
	}, [loadJobRoleApproaches, selectedJobRoles]);

	return {
		jobRoles: selectedJobRoles,
		approaches: state.approaches,
		jobRoleApproaches: state.jobRoleApproaches,
		selectedJobRoleApproaches: state.jobRoleApproaches.filter((x) =>
			selection.isSelected(x.jobRoleApproachId)
		),
		onSelectNone: () => selection.clearAll(),
		onToggleAllSelected: () =>
			selection.toggleAllSelected(
				state.jobRoleApproaches.map((x) => x.jobRoleApproachId)
			),
		onToggleSelectedApproach: (id) => selection.toggleSelected(id),
		onAddApproaches,
		onDeleteSelectedApproaches,
		isApproachSelected: (id) => selection.isSelected(id),
	};
};
