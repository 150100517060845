import React, { useState } from 'react';
import { get } from 'lodash';

// Monorepo
import { ActionCard } from '@constituenthub/common';

// Components
import {
	Table,
	CellProps,
	useRowNumberRenderer,
	useTextRenderer,
	useRowHoverPlugin,
	useDateRenderer,
	useUserRenderer,
} from '../table';
import { ActionCardsHook } from './useActionCardsController';
import { useAppSelector } from '../../store';
import { selectActionCards } from '../../store/actions';
import { TaskDialog } from './TaskDialog';

// Lib

type State = {
	rowIndex: number | null;
	value: string;
};

const initialState: State = {
	rowIndex: null,
	value: '',
};

type Props = {
	controller: ActionCardsHook;
	show: boolean;
};

export const CardTableView = ({ show, controller }: Props) => {
	const {
		definition,
		onAddNewActionCard,
		onActionCardUpdated,
		onActionCardRemoved,
		onLoadMoreActionCards,
	} = controller;
	const actionCards = useAppSelector(selectActionCards);
	const [state, setState] = useState<State>(initialState);
	const [selectedActionId, setSelectedActionId] = useState<number>();

	const handleOnChange = (props: CellProps, value: string) => {
		setState({ rowIndex: props.rowIndex, value });
	};

	const handleOnEditComplete = (
		props: CellProps,
		value: string,
		keyboardEvent: React.KeyboardEvent<HTMLDivElement> | null
	) => {
		console.log('handleOnEditComplete');
		if (state.rowIndex !== props.rowIndex) {
			console.error('Row Mismatch');
			return;
		}

		// If the last item only has a name and it is empty, delete it
		if (props.rowIndex + 1 === props.rows.length && !value) {
			const fields = (props.row as ActionCard).fields;
			if (Object.keys(fields).length === 1 && !fields.name) {
				onActionCardRemoved(props.row as ActionCard);
			}
			setState(initialState);
			return;
		}

		onActionCardUpdated({
			...props.row,
			fields: { ...props.row.fields, name: value },
		});

		if (keyboardEvent && keyboardEvent.key === 'Enter') {
			if (props.rowIndex + 1 === props.rows.length) {
				handleOnAddClick(props);
				return;
			}
		}

		setState(initialState);
	};

	const handleOnEditCanceled = async (props: CellProps) => {
		// TODO: Remove Item?
		console.log('handleOnEditCanceled');
		// If the last item only has a name and it is empty, delete it
		if (props.rowIndex + 1 === props.rows.length) {
			const fields = (props.row as ActionCard).fields;
			if (Object.keys(fields).length === 1 && fields.name === '') {
				onActionCardRemoved(props.row as ActionCard);
			}
		}
		setState(initialState);
	};

	const handleOnAddClick = async (props: CellProps) => {
		const item = await onAddNewActionCard();
		if (item) {
			setState({
				rowIndex: props.rowIndex + 1,
				value: get(item, props.column.field, ''),
			});
		}
	};

	// Renderers
	const rowNumber = useRowNumberRenderer();

	const name = useTextRenderer({
		field: `fields.${definition.Name.property}`,
		label: definition.Name.label,
		sort: 'off',
		width: 400,
		onAddClick: handleOnAddClick,
		onCancelEdit: handleOnEditCanceled,
		onChange: handleOnChange,
		onEditComplete: handleOnEditComplete,
		isEditing: (props) => state.rowIndex === props.rowIndex,
		getEditValue: () => state.value || '',
		onDetailClick: (props) => setSelectedActionId(props.getId() as number),
		onDoubleClick: (_event, props) => {
			setState({
				rowIndex: props.rowIndex,
				value: get(props.row, props.column.field, ''),
			});
		},
	});

	// TODO: DateRenderer
	// TODO: SelectListRenderer
	// TODO: Action Cards need Names
	// TODO: Assigned To needs to be username or we need User Field type and renderer

	const type = useTextRenderer({
		field: `entity`,
		label: 'Type',
		sort: 'off',
		width: 150,
	});
	const start = useDateRenderer({
		field: `fields.${definition.Start.property}`,
		label: definition.Start.label,
		sort: 'off',
		width: 100,
	});
	const end = useDateRenderer({
		field: `fields.${definition.End.property}`,
		label: definition.End.label,
		sort: 'off',
		width: 100,
	});
	const complete = useDateRenderer({
		field: `fields.${definition.Complete.property}`,
		label: definition.Complete.label,
		sort: 'off',
		width: 100,
	});
	const stage = useTextRenderer({
		field: `fields.${definition.Stage.property}`,
		label: definition.Stage.label,
		sort: 'off',
		width: 160,
	});
	const status = useTextRenderer({
		field: `fields.${definition.Status.property}`,
		label: definition.Status.label,
		sort: 'off',
		width: 160,
	});
	const assignedTo = useUserRenderer({
		field: `fields.${definition.AssignedTo.property}`,
		label: definition.AssignedTo.label,
		sort: 'off',
		width: 200,
	});
	const teamMember = useTextRenderer({
		field: `fields.${definition.TeamMember.property}`,
		label: definition.TeamMember.label,
		sort: 'off',
		width: 200,
	});

	// Plugins
	const rowHover = useRowHoverPlugin();

	// Virtual Add Item
	const rows: ActionCard[] = actionCards;
	// rows.push({ changeItemId: -1, fields: {} } as ActionCard);

	return (
		<>
			<Table
				show={show}
				rows={rows}
				getId={(item: ActionCard) => item.actionId}
				columns={[
					rowNumber,
					name,
					type,
					start,
					end,
					complete,
					stage,
					status,
					assignedTo,
					teamMember,
				]}
				plugins={[rowHover]}
				onScrollBottom={() => onLoadMoreActionCards()}
				rowHeight={50}
			/>
			{!!selectedActionId && (
				<TaskDialog
					onClose={() => setSelectedActionId(undefined)}
					onContinue={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(setSelectedActionId(undefined));
					}}
					onDelete={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(setSelectedActionId(undefined));
					}}
					actionId={selectedActionId}
				/>
			)}
		</>
	);
};
