import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

// Monorepo
import { ChangeItem } from '@constituenthub/common';

// Components
import { ChangeItemForm } from './ChangeItemForm';

// Lib
import { ChangeItemsHook } from './useChangeItemsController';
import { useBatchEditFields } from '../fields/useBatchEditFields';

type Props = {
	controller: ChangeItemsHook;
};

export const ChangeItemBatchForm = (props: Props) => {
	const {
		definition,
		selectedChangeItems,
		onChangeItemsUpdated,
		changeItemSelectionCount,
	} = props.controller;

	const {
		changes,
		mixedValues,
		onChange,
		loadItems,
		hasChanges,
		getUpdates,
	} = useBatchEditFields({
		definition,
		excludeProperties: [definition.Name.property],
	});

	const saveChanges = () => {
		const updates = getUpdates();
		onChangeItemsUpdated(updates as ChangeItem[]);
	};

	useEffect(() => {
		loadItems(selectedChangeItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedChangeItems]);

	const hasBeenChanged = hasChanges();

	return (
		<Grid
			container
			direction="column"
			flexGrow={1}
			spacing={0}
			wrap="nowrap"
			sx={{
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				overflowY: 'auto',
			}}
		>
			<ChangeItemForm
				showName={changeItemSelectionCount === 1}
				definition={definition}
				fields={changes}
				onChange={onChange}
				mixedValues={mixedValues}
			/>
			<Grid item>
				<Toolbar variant="dense" disableGutters>
					<span style={{ flexGrow: 1 }} />
					<Button
						variant="contained"
						disabled={!hasBeenChanged}
						onClick={() => saveChanges()}
					>
						Save Changes
					</Button>
				</Toolbar>
			</Grid>
		</Grid>
	);
};
