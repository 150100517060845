import { Approach } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class ProjectApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listApproaches(): Promise<Approach[]> {
		const response = await this.client.get<Approach[]>(`/approach`);
		return response.result;
	}

	public async getApproach(approachId: number): Promise<Approach> {
		const response = await this.client.get<Approach>(
			`/approach/${approachId}`
		);
		return response.result;
	}

	public async update(approach: Approach): Promise<Approach> {
		const response = await this.client.put<Approach, Approach>(
			`/approach/${approach.approachId}`,
			approach
		);
		return response.result;
	}

	public async add(approach: Partial<Approach>): Promise<Approach> {
		const response = await this.client.post<Partial<Approach>, Approach>(
			`/approach`,
			approach
		);
		return response.result;
	}

	public async remove(approachId: number): Promise<any> {
		const response = await this.client.del(`/approach/${approachId}`);
		return response.result;
	}
}
