import React from 'react';
import { SxProps, Theme } from '@mui/system';

// Monorepo
import { FieldValue, Entity } from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { FlexColumn, FlexRow } from '../common';

type Props = {
	fields: Record<string, FieldValue>;
	onChange: (property: string, value: FieldValue) => void;
	mixedValues: string[];
	showName?: boolean;
	sx?: SxProps<Theme>;
};

export const TaskForm = ({
	fields,
	onChange,
	mixedValues,
	showName = true,
	sx = {},
}: Props) => {
	const definition = useFieldDefinitions(Entity.ActionCard);

	return (
		<FlexRow fill sx={sx}>
			<FlexColumn fill scroll sx={{ pr: 1 }}>
				{showName && (
					<Field
						entity={Entity.ActionCard}
						definition={definition.Name}
						value={fields[definition.Name.property]}
						onChange={(value: FieldValue) =>
							onChange(definition.Name.property, value)
						}
						mixedValue={mixedValues.includes(
							definition.Name.property
						)}
					/>
				)}
				<Field
					entity={Entity.ActionCard}
					definition={definition.Description}
					value={fields[definition.Description.property]}
					onChange={(value: FieldValue) =>
						onChange(definition.Description.property, value)
					}
					mixedValue={mixedValues.includes(
						definition.Description.property
					)}
				/>
			</FlexColumn>
			<FlexColumn fill scroll sx={{ pl: 1 }}>
				<Field
					entity={Entity.ActionCard}
					definition={definition.Result}
					value={fields[definition.Result.property]}
					onChange={(value: FieldValue) =>
						onChange(definition.Result.property, value)
					}
					mixedValue={mixedValues.includes(
						definition.Result.property
					)}
				/>
			</FlexColumn>
		</FlexRow>
	);
};
