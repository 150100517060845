export interface ValidationError {
	name: string;
	error: string;
}

export const hasTrueValue = (val: any): boolean => {
	if (val === null || val === undefined) return false;
	return [true, 'TRUE', 'True', 'true', 'YES', 'Yes', 'yes'].includes(val);
};

export const hasFalseValue = (val: any): boolean => {
	if (val === null || val === undefined) return false;
	return [false, 'FALSE', 'False', 'false', 'NO', 'No', 'no'].includes(val);
};

export const hasBooleanValue = (val: any): boolean => {
	return hasTrueValue(val) || hasFalseValue(val);
};

export const hasValue = (val: any): boolean => {
	return val !== '' && val !== null && val !== undefined;
};

export const validateEmail = (email: string | null | undefined) => {
	if (!email) return false;
	// eslint-disable-next-line no-useless-escape
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
};

export const validatePhoneNumber = (value: string | null | undefined) => {
	if (!value) return false;
	// eslint-disable-next-line no-useless-escape
	const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
	return re.test(value.toLowerCase());
};
