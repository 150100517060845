/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthUser } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class CommonApi {
  private client: ApiClientInterface;

  constructor(client: ApiClientInterface) {
    this.client = client;
  }

  public async healthCheck(): Promise<any> {
    return this.client.get('/');
  }

  public async getAuthUser(): Promise<AuthUser> {
    const response = await this.client.get<AuthUser>('/authuser');
    return response.result;
  }
}
