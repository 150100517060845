import { createTheme } from '@mui/material/styles';
import { indigo, grey } from '@mui/material/colors';

// Lib
import { TOOLBAR_HEIGHT } from './index';

export const defaultTheme = createTheme({
	palette: {
		primary: indigo,
		secondary: grey,
	},
	typography: {
		fontFamily: ['Open Sans'].join(','),
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				rounded: true,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
			},
		},
		MuiToolbar: {
			defaultProps: {
				variant: 'dense',
			},
			styleOverrides: {
				root: {
					minHeight: `${TOOLBAR_HEIGHT}px`,
					maxHeight: `${TOOLBAR_HEIGHT}px`,
				},
				dense: {
					minHeight: `${TOOLBAR_HEIGHT}px`,
					maxHeight: `${TOOLBAR_HEIGHT}px`,
				},
			},
		},
	},
});
