import React from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Icons
import { mdiClose, mdiPlusCircleOutline, mdiTrashCanOutline } from '@mdi/js';

// Components
import { IconButton } from '../common';
import { AffectedJobRoleTable } from './AffectedJobRoleTable';
import { JobRolesControllerHook } from './useJobRolesController';

type Props = {
	controller: JobRolesControllerHook;
	onSelectGroups: () => void;
	onDeleteGroups: () => void;
};

export const AffectedJobRolesList = (props: Props) => {
	const { controller, onSelectGroups, onDeleteGroups } = props;
	const {
		groups,
		selectedGroups,
		toggleAllSelected,
		onSelectNone,
		isGroupSelected,
		onToggleGroupSelected,
		onToggleSingleGroupSelected,
	} = controller;

	return (
		<Grid
			container
			direction="column"
			flexGrow={1}
			wrap="nowrap"
			sx={{ height: '100%', overflow: 'hidden' }}
		>
			<Grid item>
				<Toolbar variant="dense" disableGutters sx={{ pl: 3, pr: 1 }}>
					<IconButton
						path={mdiClose}
						size={1}
						variant="small"
						title="Clear Selected"
						disabled={selectedGroups.length === 0}
						onClick={() => onSelectNone()}
						sx={{ ml: 0.7, mr: 2 }}
					/>
					<Typography variant="body2" flexGrow={1}>
						{selectedGroups.length} Affected Job Roles Selected
					</Typography>
					<IconButton
						path={mdiPlusCircleOutline}
						size={0.9}
						variant="small"
						title="Add Affected Job Role"
						onClick={() => onSelectGroups()}
						sx={{ mx: 1 }}
					/>
					<IconButton
						path={mdiTrashCanOutline}
						size={1}
						variant="small"
						title="Delete Selected"
						disabled={selectedGroups.length === 0}
						onClick={() => onDeleteGroups()}
						sx={{ mx: 1 }}
					/>
				</Toolbar>
			</Grid>
			<Grid
				item
				sx={{
					position: 'relative',
					width: '100%',
					height: `100%`,
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					px: 2,
					pb: 2,
				}}
			>
				<AffectedJobRoleTable
					groups={groups}
					isGroupSelected={isGroupSelected}
					onToggleGroupSelected={onToggleGroupSelected}
					onToggleAllSelected={toggleAllSelected}
					onToggleSingleGroupSelected={onToggleSingleGroupSelected}
					selectionCount={selectedGroups.length}
				/>
			</Grid>
		</Grid>
	);
};
