import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Icons
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

// Monorepo
import {
	Filter,
	FieldDefinition,
	FilterValidation,
	isFilterValid,
} from '@constituenthub/common';

// Components
import { useFilterEditorController } from './useFilterEditorController';
import { FilterItemEditor } from './FilterItemEditor';
import { Button } from '@mui/material';

type EditorProps = {
	filter: Filter;
	entity: string;
	definition: Record<string, FieldDefinition>;
	validation: FilterValidation;
	onChange: (
		working: Filter | undefined,
		validation: FilterValidation
	) => void;
	onGetFilterCount: (data: Filter) => Promise<number>;
};

export const FilterEditor = ({
	filter,
	definition,
	entity,
	validation,
	onChange,
	onGetFilterCount,
}: EditorProps) => {
	const controller = useFilterEditorController({
		filter,
		definition,
		entity,
		onChange,
		onGetFilterCount,
		validation,
	});

	console.log('validation', validation);

	return (
		<Grid
			container
			direction="column"
			flexGrow={1}
			spacing={2}
			wrap="nowrap"
		>
			{filter.map((conditions, conditionIndex) => (
				<Grid item key={`and_${conditionIndex}`}>
					<Grid container direction="row" spacing={2} wrap="nowrap">
						<Grid item sx={{ mt: 2 }}>
							<Typography variant="body2" fontWeight={500}>
								{conditionIndex === 0 ? 'WHERE' : 'AND'}
							</Typography>
						</Grid>
						<Grid item sx={{ flexGrow: 1 }}>
							<Grid
								container
								direction="column"
								flexGrow={1}
								spacing={2}
								wrap="nowrap"
							>
								{conditions.map((item, itemIndex) => (
									<Grid
										item
										key={`or_${itemIndex}`}
										sx={{ flexGrow: 1 }}
									>
										<Grid
											container
											direction="row"
											alignItems="center"
											spacing={2}
											flexGrow={1}
											wrap="nowrap"
										>
											{itemIndex !== 0 && (
												<Grid item>
													<Typography
														variant="body2"
														fontWeight={500}
													>
														OR
													</Typography>
												</Grid>
											)}
											<Grid item sx={{ flexGrow: 1 }}>
												<FilterItemEditor
													item={item}
													definition={definition}
													conditionIndex={
														conditionIndex
													}
													itemIndex={itemIndex}
													controller={controller}
												/>
											</Grid>
											{(itemIndex !== 0 ||
												conditionIndex !== 0) && (
												<Grid item>
													<IconButton
														size="small"
														edge="end"
														onClick={() =>
															controller.removeCondition(
																conditionIndex,
																itemIndex
															)
														}
													>
														<Icon
															path={mdiClose}
															size={1}
														/>
													</IconButton>
												</Grid>
											)}
										</Grid>
									</Grid>
								))}
								<Grid item>
									<Button
										variant="text"
										onClick={() =>
											controller.addOrCondition(
												conditionIndex,
												conditions.length - 1
											)
										}
										disabled={!isFilterValid(validation)}
										sx={{
											mt: -2,
											opacity: 0.4,
											'&:hover': {
												opacity: 1,
											},
										}}
									>
										+ OR
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			))}
			<Grid item>
				<Button
					variant="text"
					onClick={() => controller.addAndCondition(filter.length)}
					disabled={filter.length > 0 && !isFilterValid(validation)}
					sx={{
						opacity: 0.4,
						'&:hover': {
							opacity: 1,
						},
					}}
				>
					{filter.length === 0
						? 'Select fields to use in filtering'
						: '+ AND'}
				</Button>
			</Grid>
		</Grid>
	);
};
