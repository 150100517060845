import React from 'react';
import { get } from 'lodash';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps } from '../../types';
import { formatDate } from '@constituenthub/common';

interface Props extends CellRenderProps {}

export const DateRenderer = (props: Props) => {
	const value = get(props.row, props.column.field, '');
	const val = formatDate(value);

	return (
		<CellBase
			{...props}
			onDoubleClick={() => {}}
			style={{
				...props.style,
				justifyContent: 'flex-end',
				textAlign: 'right',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}}
		>
			{value && <span style={{ flexGrow: 1 }}>{val}</span>}
		</CellBase>
	);
};
