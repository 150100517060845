import React, { useState } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';
import { useDebounce } from 'react-use';

// Monorepo
import { MixedFieldContent } from '@constituenthub/common';

// Components
import { SelectOption, errorOption } from './AutoComplete';

type AutoCompleteAsyncProps = {
	label?: string;
	fixed?: boolean;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	autoOpen?: boolean;
	emptyOption?: boolean;
	delay?: number;
	value?: string | number | boolean | null | undefined;
	onChange: (value: string | number | boolean | null) => void;
	onLoadAsync: (text: string) => Promise<SelectOption[]>;
};

type OptionState = {
	loading: boolean;
	options: SelectOption[];
};

export const AutoCompleteAsync = (props: AutoCompleteAsyncProps) => {
	const {
		label,
		fixed = true,
		required = false,
		disabled = false,
		error = false,
		readOnly = false,
		mixedValue = false,
		autoOpen = false,
		emptyOption = false,
		delay = 0,
		value,
		onChange,
		onLoadAsync,
	} = props;
	const theme = useTheme();
	const [state, setState] = useState<OptionState>({
		loading: true,
		options: [],
	});
	const [textValue, setTextValue] = useState('');

	const handleLoadOptions = async () => {
		try {
			setState((s) => ({ ...s, loading: true }));
			const options = (await onLoadAsync(textValue)) || [];
			if (emptyOption) {
				options.splice(0, 0, {
					value: null,
					label: 'None',
				});
			}
			setState((s) => ({
				...s,
				loading: false,
				options,
			}));
		} catch (error) {
			setState((s) => ({
				...s,
				loading: false,
				options: [errorOption],
			}));
		}
	};

	const onOptionSelected = (item: SelectOption) => {
		const option = state.options.find((x) => x.value === item?.value);
		onChange(option?.value || null);
	};

	useDebounce(handleLoadOptions, delay, [textValue]);

	return (
		<MuiAutocomplete
			isOptionEqualToValue={(option, value) =>
				option.value === value.value
			}
			filterOptions={(x) => x}
			autoHighlight
			freeSolo={!fixed}
			autoComplete={false}
			autoSelect
			clearOnEscape
			openOnFocus={autoOpen && !value}
			value={state.options.find((x) => x.value === value) || null}
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={state.options}
			loading={state.loading}
			onInputChange={(_event, newInputValue) => {
				setTextValue(newInputValue);
			}}
			renderOption={(props, option) => (
				<li {...props} key={`${option.value}`}>
					{option.label}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={mixedValue ? MixedFieldContent : label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					error={error}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						readOnly: readOnly,
						error: error,
						sx: {
							backgroundColor: mixedValue
								? lighten(theme.palette.primary.main, 0.9)
								: 'initial',
						},
					}}
				/>
			)}
		/>
	);
};
