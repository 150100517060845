import { JobRole } from '..';
import { JobRoleApproach } from './types';

export const createAffectedJobRoleApproach = (
	jobRole: JobRole,
	approachId: number
): Partial<JobRoleApproach> => {
	return {
		jobRoleId: jobRole.jobRoleId,
		changeItemId: jobRole.changeItemId,
		groupId: jobRole.groupId,
		approachId,
	};
};

export const createAffectedJobRoleApproaches = (
	jobRoles: JobRole[],
	approachIds: number[]
): Partial<JobRoleApproach>[] => {
	const approaches: Partial<JobRoleApproach>[] = [];

	jobRoles.forEach((jobRole) => {
		approachIds.forEach((approachId) => {
			approaches.push(createAffectedJobRoleApproach(jobRole, approachId));
		});
	});

	return approaches;
};
