import React from 'react';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';

// Monorepo
import { Entity, FieldValue, FieldDefinition } from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';

type Props = {
	definition: Record<string, FieldDefinition>;
	fields: Record<string, FieldValue>;
	onChange: (property: string, value: FieldValue) => void;
	showName?: boolean;
	mixedValues: string[];
	sx?: SxProps<Theme>;
};

export const ChangeItemForm = (props: Props) => {
	const {
		definition,
		fields,
		onChange,
		showName,
		mixedValues,
		sx = {},
	} = props;

	const handleOnChange = (property: string, value: FieldValue) => {
		console.log('onChange', property, value);
		onChange(property, value);
	};

	return (
		<Grid
			item
			sx={{
				position: 'relative',
				pr: 1,
				my: 1,
				'& .MuiGrid-item': {
					mb: 0.75,
				},
				...sx,
			}}
		>
			{showName === true && (
				<Grid item>
					<Field
						entity={Entity.ChangeItem}
						definition={definition.Name}
						required
						value={fields[definition.Name.property]}
						onChange={(value: FieldValue) =>
							handleOnChange(definition.Name.property, value)
						}
					/>
				</Grid>
			)}
			<Grid item>
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.ChangeImpact}
							value={fields[definition.ChangeImpact.property]}
							mixedValue={mixedValues.includes(
								definition.ChangeImpact.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.ChangeImpact.property,
									value
								)
							}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.Location}
							value={fields[definition.Location.property]}
							mixedValue={mixedValues.includes(
								definition.Location.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.Location.property,
									value
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.ResponsibleTeamMember}
							value={
								fields[
									definition.ResponsibleTeamMember.property
								]
							}
							mixedValue={mixedValues.includes(
								definition.ResponsibleTeamMember.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.ResponsibleTeamMember.property,
									value
								)
							}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.IdentifiedBy}
							value={fields[definition.IdentifiedBy.property]}
							mixedValue={mixedValues.includes(
								definition.IdentifiedBy.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.IdentifiedBy.property,
									value
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			{/* <Grid item>
				<Field
					entity={Entity.ChangeItem}
					definition={definition.ResponsibleTeamMember}
					value={fields[definition.ResponsibleTeamMember.property]}
					mixedValue={mixedValues.includes(
						definition.ResponsibleTeamMember.property
					)}
					onChange={(value: FieldValue) =>
						handleOnChange(
							definition.ResponsibleTeamMember.property,
							value
						)
					}
				/>
			</Grid> */}
			{/* <Grid item>
				<Field
					entity={Entity.ChangeItem}
					definition={definition.IdentifiedBy}
					value={fields[definition.IdentifiedBy.property]}
					mixedValue={mixedValues.includes(
						definition.IdentifiedBy.property
					)}
					onChange={(value: FieldValue) =>
						handleOnChange(definition.IdentifiedBy.property, value)
					}
				/>
			</Grid> */}
			<Grid item>
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.ChangeArea}
							value={fields[definition.ChangeArea.property]}
							mixedValue={mixedValues.includes(
								definition.ChangeArea.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.ChangeArea.property,
									value
								)
							}
						/>
					</Grid>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.ChangeCategory}
							value={fields[definition.ChangeCategory.property]}
							mixedValue={mixedValues.includes(
								definition.ChangeCategory.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.ChangeCategory.property,
									value
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container direction="row" wrap="wrap" spacing={1}>
					<Grid item sx={{ flexGrow: 1 }}>
						<Field
							entity={Entity.ChangeItem}
							definition={definition.Function}
							value={fields[definition.Function.property]}
							mixedValue={mixedValues.includes(
								definition.Function.property
							)}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.Function.property,
									value
								)
							}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.ChangeItem}
					definition={definition.Description}
					value={fields[definition.Description.property]}
					mixedValue={mixedValues.includes(
						definition.Description.property
					)}
					onChange={(value: FieldValue) =>
						handleOnChange(definition.Description.property, value)
					}
				/>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.ChangeItem}
					definition={definition.CurrentProcess}
					value={fields[definition.CurrentProcess.property]}
					mixedValue={mixedValues.includes(
						definition.CurrentProcess.property
					)}
					onChange={(value: FieldValue) =>
						handleOnChange(
							definition.CurrentProcess.property,
							value
						)
					}
				/>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.ChangeItem}
					definition={definition.FutureProcess}
					value={fields[definition.FutureProcess.property]}
					mixedValue={mixedValues.includes(
						definition.FutureProcess.property
					)}
					onChange={(value: FieldValue) =>
						handleOnChange(definition.FutureProcess.property, value)
					}
				/>
			</Grid>
		</Grid>
	);
};
