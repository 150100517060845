import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useResponsive = () => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const ltXs = useMediaQuery(theme.breakpoints.down('xs'));
    const ltSm = useMediaQuery(theme.breakpoints.down('sm'));
    const ltMd = useMediaQuery(theme.breakpoints.down('md'));
    const ltLg = useMediaQuery(theme.breakpoints.down('lg'));
    const ltXl = useMediaQuery(theme.breakpoints.down('xl'));

    return {
        theme,
        xs,
        sm,
        md,
        lg,
        xl,
        ltXs,
        ltSm,
        ltMd,
        ltLg,
        ltXl,
    }
}