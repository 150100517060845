import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

// Icons
// import Icon from '@mdi/react';
// import { mdiPlusCircleOutline } from '@mdi/js';

// Monorepo
import { Project, ProjectView } from '@constituenthub/common';

// Components
import { MainLayout } from '../shell';
import { Drawer, CaptionDivider, NothingHere, Loading } from '../common';
import { ProjectDetail } from './ProjectDetail';
import { AddProjectDialog } from './AddProjectDialog';
import { ProjectTitle } from './ProjectTile';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch } from '../../store';
import { clearProject } from '../../store/project';

type ListProps = {
	items: ProjectView[];
	caption?: string;
	onOpenClick: (project: Project) => void;
	onOpenItemsClick: (project: Project) => void;
	onOpenCardsClick: (project: Project) => void;
	onDetailsClick: (project: Project) => void;
	onFavoriteClick: (project: Project) => void;
};

const ProjectList = (props: ListProps) => {
	const {
		items,
		caption,
		onOpenClick,
		onOpenItemsClick,
		onOpenCardsClick,
		onDetailsClick,
		onFavoriteClick,
	} = props;
	return (
		<>
			<Grid item>{!!caption && <CaptionDivider text={caption} />}</Grid>
			<Grid item sx={{ p: 2 }}>
				<Grid container direction="row" wrap="wrap" spacing={2}>
					{orderBy(items, 'name').map((item) => (
						<Grid item key={item.projectId}>
							<ProjectTitle
								project={item}
								onOpenClick={onOpenClick}
								onOpenItemsClick={onOpenItemsClick}
								onOpenCardsClick={onOpenCardsClick}
								onDetailsClick={onDetailsClick}
								onFavoriteClick={onFavoriteClick}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export const ProjectPage = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { api } = useAppContext();
	const dispatch = useAppDispatch();
	const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [projects, setProjects] = useState<ProjectView[]>([]);
	const [, setError] = useState<Error>();

	const selectedProjectId = params.projectId
		? parseInt(params.projectId)
		: null;

	const handleOpenProject = (project: Project) => {
		navigate(`/project/${project.projectId}/items`);
	};

	const handleOpenProjectActionCards = (project: Project) => {
		navigate(`/project/${project.projectId}/actions`);
	};

	const handleOpenProjectDetails = (project: Project) => {
		navigate(`/projectdetail/${project.projectId}`);
	};

	const handleToggleFavorite = async (project: Project) => {
		try {
			const updated = await api.project.updateProject({
				...project,
				favorite: project.favorite === true ? false : true,
			});
			toast(`${updated.name} updated.`);
			setProjects((s) => {
				const ns = [...s];
				const index = ns.findIndex(
					(x) => x.projectId === updated.projectId
				);
				if (index !== -1) {
					ns.splice(index, 1, { ...ns[index], ...updated });
				}
				return ns;
			});
		} catch (error) {
			toast.error(`Could not update the project: ${error}`);
		}
	};

	const handleProjectUpdated = (project: Project) => {
		setProjects((s) => {
			const ns = [...s];
			const index = ns.findIndex(
				(x) => x.projectId === project.projectId
			);
			if (index !== -1) {
				ns.splice(index, 1, { ...ns[index], ...project });
			}
			return ns;
		});
	};

	const loadProjects = async () => {
		try {
			const data = await api.project.listProjects();
			setProjects(data);
		} catch (err) {
			setError(err as Error);
		} finally {
			setHasLoaded(true);
		}
	};

	useEffect(() => {
		loadProjects();
		dispatch(clearProject());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleProjectCreated = () => {
		setIsAddProjectOpen(false);
		loadProjects();
	};

	const favorites = projects.filter((x) => x.favorite === true);
	const others = projects.filter((x) => x.favorite !== true);

	return (
		<MainLayout>
			<Toolbar>
				<Button
					variant="outlined"
					onClick={() => setIsAddProjectOpen(true)}
				>
					Add Project
				</Button>
			</Toolbar>
			<Drawer
				open={!!selectedProjectId}
				name="ProjectPage"
				align="right"
				scrollable
				toolbar
				minWidth={450}
				variant={!!selectedProjectId ? 'permanent' : 'persistent'}
				content={
					selectedProjectId ? (
						<ProjectDetail
							projectId={selectedProjectId}
							onProjectUpdated={handleProjectUpdated}
							onClose={() => navigate('/')}
						/>
					) : null
				}
			>
				<Box
					sx={{
						position: 'relative',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						p: 2,
					}}
				>
					<Grid container direction="column" flexGrow={1}>
						<Loading enabled={!hasLoaded} />
						{hasLoaded && projects.length === 0 && (
							<NothingHere>
								<Box sx={{ p: 2, textAlign: 'center' }}>
									<Typography component="div" variant="body1">
										It looks like you don't have any
										projects yet.
									</Typography>
								</Box>
								<Box sx={{ p: 2, textAlign: 'center' }}>
									<Button
										variant="outlined"
										onClick={() =>
											setIsAddProjectOpen(true)
										}
									>
										Add your first project
									</Button>
								</Box>
							</NothingHere>
						)}
						{hasLoaded && (
							<>
								<ProjectList
									items={favorites}
									caption={
										favorites.length > 0
											? 'Favorite Projects'
											: undefined
									}
									onOpenClick={handleOpenProject}
									onOpenItemsClick={handleOpenProject}
									onOpenCardsClick={
										handleOpenProjectActionCards
									}
									onDetailsClick={handleOpenProjectDetails}
									onFavoriteClick={handleToggleFavorite}
								/>
								<ProjectList
									items={others}
									caption={
										favorites.length > 0 &&
										others.length > 0
											? 'More Projects'
											: undefined
									}
									onOpenClick={handleOpenProject}
									onOpenItemsClick={handleOpenProject}
									onOpenCardsClick={
										handleOpenProjectActionCards
									}
									onDetailsClick={handleOpenProjectDetails}
									onFavoriteClick={handleToggleFavorite}
								/>
							</>
						)}
					</Grid>
				</Box>
			</Drawer>
			<AddProjectDialog
				open={isAddProjectOpen}
				onClose={() => setIsAddProjectOpen(false)}
				onProjectCreated={handleProjectCreated}
			/>
		</MainLayout>
	);
};
