
// Monorepo
import { FieldDefinition, FieldValue, FieldsModel } from "@constituenthub/common";

export const cleanFields = (
	fields: Record<string, FieldValue>
): Record<string, FieldValue> => {
	const data: Record<string, FieldValue> = {};

	Object.keys(fields).forEach((f) => {
		if (fields[f] !== null && fields[f] !== undefined && fields[f] !== '') {
			data[f] = fields[f];
		}
	});

	return data;
};

export const mergeFields = (
    definition: Record<string, FieldDefinition>,
    excludeProperties: string[],
    mixedValues: string[],
    fields: Record<string, FieldValue>,
    changes: Record<string, FieldValue>
): Record<string, FieldValue> | null => {
    const source = cleanFields(fields);
    const target = cleanFields(changes);
    
    const data: Record<string, FieldValue> = {};
	let hasUpdates = false;
    Object.keys(definition).forEach((key) => {
        const d = definition[key];
        if (!excludeProperties.includes(d.property) && !mixedValues.includes(d.property)) {
            if (source[d.property] || target[d.property]) {
                if (source[d.property] !== target[d.property]) {
                    hasUpdates = true;
                }
                if (
                    target[d.property] !== null ||
                    target[d.property] !== undefined ||
                    target[d.property] !== ''
                ) {
                    data[d.property] = target[d.property];
                }
            }
        }
	});

	return hasUpdates ? data : null;
}

export const mergeChanges = (
    definition: Record<string, FieldDefinition>,
    excludeProperties: string[],
    mixedValues: string[],
	items: FieldsModel[],
	changes: Record<string, FieldValue>
) => {
	const data: FieldsModel[] = [];
	items.forEach((item) => {
		const update = mergeFields(definition, excludeProperties, mixedValues, item.fields, changes);
		if (update) {
			data.push({
				...item,
                fields: {
                    ...item.fields,
                    ...update,
                },
			});
		}
	});

	return data;
};