import { SxProps, Theme } from '@mui/system';
import MuiIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Icons
import Icon from '@mdi/react';

type IconButtonProps = {
	title: string;
	path: string;
	onClick: () => void;
	disabled?: boolean;
	size?: number;
	sx?: SxProps<Theme>;
	variant?: 'small' | 'medium' | 'large';
	edge?: 'start' | 'end';
	color?: string;
};

export const IconButton = (props: IconButtonProps) => {
	const {
		title,
		path,
		size = 1,
		edge,
		disabled = false,
		onClick,
		sx = {},
		variant = 'small',
		color,
	} = props;

	return (
		<Tooltip title={title || ''}>
			<span>
				<MuiIconButton
					size={variant}
					edge={edge}
					onClick={onClick}
					disabled={disabled}
					sx={sx}
				>
					<Icon path={path} size={size} color={color} />
				</MuiIconButton>
			</span>
		</Tooltip>
	);
};
