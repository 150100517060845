import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import Box from '@mui/material/Box';

type Props = {
	name?: string;
	children: React.ReactNode;
	sx?: SxProps<Theme>;
	fill?: boolean;
	scroll?: boolean;
};

const fillSx: SxProps<Theme> = {
	flexGrow: 1,
	height: '100%',
	overflow: 'hidden',
};

const scrollSx: SxProps<Theme> = {
	...fillSx,
	overflowY: 'auto',
};

export const FlexColumn = ({
	name,
	children,
	fill,
	scroll,
	sx = {},
}: Props) => {
	let style: SxProps<Theme> = {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
	};

	if (fill) {
		style = {
			...style,
			...fillSx,
		};
	}

	if (scroll) {
		style = {
			...style,
			...scrollSx,
		};
	}

	return (
		<Box
			data-component={name}
			sx={{
				...style,
				...sx,
			}}
		>
			{children}
		</Box>
	);
};
