import React from 'react';
import Button from '@mui/material/Button';

// Monorepo
import {
	Entity,
	FieldValue,
	Note,
	NoteFieldDefinitions,
} from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { Field } from '../fields/Field';
import { useAppContext } from '../../contexts/AppContext';

type State = {
	working: boolean;
	error?: any;
	content: string;
};

type Props = {
	note: Note;
	onClose: () => void;
	onNoteUpdated: (note: Note) => void;
	onNoteDeleted: (note: Note) => void;
};

export const EditNoteDialog = ({
	note,
	onClose,
	onNoteUpdated,
	onNoteDeleted,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({
		working: false,
		content: note.content || '',
	});

	const handleClose = () => {
		onClose();
	};

	const handleDeleteNote = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			await api.note.removeNote(note.noteId);
			onNoteDeleted(note);
			// setState((s) => ({ ...s, working: false }));
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleUpdateNote = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const updated = await api.note.updateNote({
				...note,
				content: state.content,
			});
			onNoteUpdated(updated);
			// setState((s) => ({ ...s, working: false }));
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	return (
		<ModalDialog
			open={true}
			onClose={handleClose}
			label="Edit Note"
			minWidth={600}
			padding={2}
			actions={
				<>
					{!state.error && (
						<Button
							variant="contained"
							color="error"
							onClick={handleDeleteNote}
							disabled={state.working}
						>
							Delete
						</Button>
					)}
					{!state.error && <span style={{ flexGrow: 1 }} />}
					{!state.error && (
						<Button
							variant="contained"
							color="primary"
							onClick={handleUpdateNote}
							disabled={state.working}
						>
							Update
						</Button>
					)}
					{!!state.error && (
						<Button
							variant="contained"
							onClick={() =>
								setState((s) => ({ ...s, error: undefined }))
							}
						>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={state.working} text="Working..." />
				<ErrorMessage error={state.error} sx={{ minWidth: '320px' }} />
				{!state.error && !state.working && (
					<Field
						entity={Entity.Note}
						required
						definition={NoteFieldDefinitions.Content}
						value={state.content}
						onChange={(value: FieldValue) =>
							setState((s) => ({
								...s,
								content: value as string,
							}))
						}
					/>
				)}
			</>
		</ModalDialog>
	);
};
