import { useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { groupBy, intersection } from 'lodash';

// Monorepo
import {
	Entity,
	JobRole,
	Group,
	createAffectedJobRoles,
	FieldDefinition,
	ChangeItem,
} from '@constituenthub/common';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch, useAppSelector } from '../../store';

import {
	selectRoles,
	selectRoleGroups,
	selectSelectedGroups,
	setClearRolesState,
	setRoles,
	setRolesAdded,
	setRolesRemoved,
	setRolesUpdated,
	setRolesSelectedToggleAll,
	setRolesSelectedToggle,
	setRolesSelected,
	selectSelectedRoles,
	setRoleGroups,
} from '../../store/roles';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';

export interface JobRolesControllerHook {
	definition: Record<string, FieldDefinition>;
	roles: JobRole[];
	groups: Group[];
	selectedJobRoles: JobRole[];
	selectedGroups: Group[];
	addGroups: (groups: Group[]) => Promise<JobRole[]>;
	deleteSelectedGroups: () => Promise<void>;
	toggleAllSelected: () => void;
	onSelectNone: () => void;
	isGroupSelected: (groupId: number) => boolean;
	onToggleGroupSelected: (group: Group) => void;
	onToggleSingleGroupSelected: (group: Group) => void;
	onJobRolesUpdated: (updates: JobRole[]) => Promise<JobRole[]>;
}

export const useJobRolesController = (
	selectedChangeItems: ChangeItem[]
): JobRolesControllerHook => {
	// Should not change except on reload or navigate
	const { api } = useAppContext();
	const dispatch = useAppDispatch();

	const definition = useFieldDefinitions(Entity.JobRole);

	// Changes frequently
	const groups = useAppSelector(selectRoleGroups);
	const roles = useAppSelector(selectRoles);
	const selectedGroupIds = useAppSelector(selectSelectedGroups);
	const selectedJobRoles = useAppSelector(selectSelectedRoles);

	const addGroups = useCallback(
		async (groupsToAdd: Group[]) => {
			const groupIds = groupsToAdd.map((x) => x.groupId);
			const data = createAffectedJobRoles(
				groupIds,
				selectedChangeItems.map((x) => x.changeItemId)
			);
			try {
				const results = await api.jobrole.createJobRoles(data);
				dispatch(setRolesAdded(results));
				toast(`Added ${results.length} Affected Job Role(s)`);
				return results;
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		[api.jobrole, dispatch, selectedChangeItems]
	);

	const deleteSelectedGroups = useCallback(async () => {
		try {
			await api.jobrole.deleteJobRoles(
				selectedJobRoles.map((x) => x.jobRoleId)
			);
			dispatch(setRolesRemoved(selectedJobRoles));
			toast(`Deleted ${selectedJobRoles.length} Affected Job Roles`);
		} catch (error) {
			toast.error(`Failed to delete the selected Job Roles`);
		}
	}, [api.jobrole, dispatch, selectedJobRoles]);

	const updateSelectedJobRoles = useCallback(
		async (updates: JobRole[]) => {
			try {
				const updated = await api.jobrole.updateJobRoles(updates);
				dispatch(setRolesUpdated(updated));
				toast(`Updated ${updated.length} Affected Job Roles`);
				return updated;
			} catch (error) {
				// Allow the UI to handle
				throw error;
			}
		},
		[api.jobrole, dispatch]
	);

	const loadJobRoles = useCallback(
		async (ids: number[]) => {
			try {
				const results = await api.jobrole.listJobRoles(ids);
				dispatch(setRoles(results));
			} catch (error) {
				console.log(error);
			}
		},
		[api.jobrole, dispatch]
	);

	useEffect(() => {
		if (selectedChangeItems.length > 0) {
			loadJobRoles(selectedChangeItems.map((x) => x.changeItemId));
		} else {
			dispatch(setClearRolesState());
		}
	}, [dispatch, loadJobRoles, selectedChangeItems]);

	const loadGroups = useCallback(
		async (ids: number[]) => {
			try {
				const results = await api.group.listGroups(ids);
				dispatch(setRoleGroups(results));
			} catch (error) {
				toast.error(`Failed to load associated groups`);
			}
		},
		[api.group, dispatch]
	);

	useEffect(() => {
		// Find the groups that are common across all selected change items
		const data = Object.values(groupBy(roles, 'changeItemId')).map((x) =>
			x.map((y) => y.groupId)
		);
		const groupIds = intersection(...data);

		if (groupIds.length > 0) {
			loadGroups(groupIds);
		} else {
			setRoleGroups([]);
		}
	}, [loadGroups, roles]);

	return {
		definition,
		roles,
		groups,
		addGroups,
		selectedJobRoles,
		selectedGroups: groups.filter((x) =>
			selectedGroupIds.includes(x.groupId)
		),
		deleteSelectedGroups,
		toggleAllSelected: () => dispatch(setRolesSelectedToggleAll()),
		isGroupSelected: (groupId) => selectedGroupIds.includes(groupId),
		onToggleGroupSelected: (group: Group) =>
			dispatch(setRolesSelectedToggle(group.groupId)),
		onToggleSingleGroupSelected: (group: Group) =>
			dispatch(setRolesSelected([group.groupId])),
		onSelectNone: () => dispatch(setRolesSelected([])),
		onJobRolesUpdated: updateSelectedJobRoles,
	};
};
