import * as React from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// Icons
import Icon from '@mdi/react';

// Monorepo
import {
	ActionCardView,
	ActionCardStages,
	ActionCardStatus,
	Entity,
	FieldValue,
	formatDate,
} from '@constituenthub/common';

import {
	ICON_CHANGE,
	ICON_COMPLETE,
	ICON_GROUPS,
	ICON_INPROGRESS,
	ICON_ONGOING,
	ICON_TODO,
} from '../../lib';
import { useAppSelector } from '../../store';
import { selectApproaches } from '../../store/application';

type Props = {
	action: ActionCardView;
	onOpenCard: () => void;
	showSelection?: boolean;
	isSelected?: boolean;
	onToggleSelect?: () => void;
};

const getEntityIcon = (entity?: string): string => {
	if (entity === Entity.ChangeItem) return ICON_CHANGE;
	if (entity === Entity.JobRoleApproach) return ICON_GROUPS;
	return ICON_TODO;
};

const getStageIcon = (stage?: FieldValue): string => {
	if (stage === ActionCardStages.InProgress) return ICON_INPROGRESS;
	if (stage === ActionCardStages.Ongoing) return ICON_ONGOING;
	if (stage === ActionCardStages.Complete) return ICON_COMPLETE;
	return ICON_TODO;
};

const getStatusColor = (status?: FieldValue): string => {
	if (status === ActionCardStatus.Green) return 'green';
	if (status === ActionCardStatus.Yellow) return 'orange';
	if (status === ActionCardStatus.Red) return 'red';
	return 'rgba(0,0,0,0.3)';
};

export const ActionCardTile = ({
	action,
	showSelection,
	isSelected,
	onToggleSelect,
	onOpenCard,
}: Props) => {
	const approaches = useAppSelector(selectApproaches);

	const startDate = formatDate(action.fields.start as string) || 'TBD';
	const endDate =
		action.fields?.stage === ActionCardStages.Complete
			? formatDate(action.fields.complete as string) || 'TBD'
			: formatDate(action.fields.end as string) || 'TBD';

	const dateDescription = !!action.fields.complete
		? `${startDate} - ${endDate}`
		: startDate;

	const color = getStatusColor(action.fields.status);

	const approachName = action.fields.approach
		? approaches.find((x) => x.approachId === action.fields.approach)?.name
		: '';

	// let name: string = action.fields.name as string;

	// if (action.entity === Entity.ChangeItem && action.changeItemName) {
	// 	name = `${action.changeItemName} - ${action.fields.name}`;
	// }

	// if (action.entity === Entity.JobRoleApproach && action.changeItemName) {
	// 	name = `${action.changeItemName} - ${action.fields.name}`;
	// }

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
			sx={{
				p: 1,
				borderRadius: 2,
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
				border: `1px solid ${color}`,
				borderLeft: `6px solid ${color}`,
				backgroundColor: '#ffffff',
			}}
		>
			<Grid item>
				<Grid
					container
					direction="row"
					wrap="nowrap"
					alignItems="center"
					spacing={1}
					sx={{ pt: showSelection === true ? 0 : 1.5 }}
				>
					{showSelection === true && onToggleSelect && (
						<Checkbox
							checked={isSelected}
							onChange={onToggleSelect}
						/>
					)}

					{showSelection !== true && (
						<span
							style={{
								paddingRight: '0px',
								paddingLeft: '10px',
								top: '2px',
								position: 'relative',
							}}
						>
							<Icon
								path={getEntityIcon(action.entity)}
								size={1}
							/>
						</span>
					)}

					<Typography
						variant="caption"
						fontWeight="bold"
						sx={{
							flexGrow: 1,
							pl: showSelection === true ? 0 : 1.25,
						}}
					>
						{action.entity === Entity.ActionCard
							? 'Ad-Hoc Action Card'
							: action.entity || 'Ad-Hoc'}
					</Typography>
					<span
						style={{
							paddingRight: '4px',
							paddingLeft: '8px',
							top: '2px',
							position: 'relative',
						}}
					>
						<Tooltip title={`${action.fields.stage}`}>
							<Icon
								path={getStageIcon(action.fields.stage)}
								size={0.75}
							/>
						</Tooltip>
					</span>
				</Grid>
			</Grid>
			<Grid
				item
				sx={{
					cursor: 'pointer',
					textAlign: 'right',
					whiteSpace: 'nowrap',
				}}
				onClick={() => onOpenCard()}
			>
				<Typography
					variant="caption"
					overflow="hidden"
					textOverflow="ellipsis"
				>
					{dateDescription}
				</Typography>
			</Grid>
			{action.fields.name && (
				<Grid
					item
					sx={{ cursor: 'pointer', p: 1 }}
					onClick={() => onOpenCard()}
				>
					{action.changeItemName && (
						<Typography
							variant="body2"
							component="p"
							sx={{ pr: 1 }}
						>
							<strong>{action.changeItemName}</strong>
						</Typography>
					)}
					{action.groupName && (
						<Typography
							variant="body2"
							component="span"
							sx={{ pr: 1 }}
						>
							{action.groupName}
						</Typography>
					)}
					{approachName && (
						<Typography
							variant="body2"
							component="span"
							color="primary"
							sx={{ pr: 1 }}
						>
							{approachName}
						</Typography>
					)}
					{action.entity !== Entity.JobRoleApproach &&
						action.fields.name && (
							<Typography
								variant="body2"
								component="p"
								sx={{ pr: 1 }}
							>
								{action.fields.name}
							</Typography>
						)}
				</Grid>
			)}
		</Grid>
	);
};
