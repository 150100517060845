export const HEADER_ACCOUNT = 'ChangeImpactPlusAccountId';
export const HEADER_PROJECT = 'ChangeImpactPlusProjectId';

export interface DataModel {
	createdBy: string;
	createdAt: string;
	updatedAt: string;
}

export interface DataModelArchivable extends DataModel {
	archivedAt: string;
}

export interface DataModelDeletable extends DataModel {
	deletedAt: string;
}

export interface AccountDataModel extends DataModel {
	accountId: number;
}

export interface ProjectDataModel extends AccountDataModel {
	projectId: number;
}

export interface ProjectDataModelOptional extends AccountDataModel {
	projectId?: number;
}

export interface ParentModel extends AccountDataModel {
	entity: string;
	parentId: number;
}

export enum Entity {
	User = 'User',
	Organization = 'Organization',
	Approach = 'Approach',
	Project = 'Project',
	Group = 'Group',
	ChangeItem = 'Change Item',
	JobRole = 'Job Role',
	JobRoleApproach = 'Job Role Approach',
	ActionCard = 'Action Card',
	Note = 'Note',
	Attachment = 'Attachment',
}
