import React from 'react';
import Grid from '@mui/material/Grid';

type NothingHereProps = {
	children: React.ReactNode;
};

export const NothingHere = (props: NothingHereProps) => {
	const { children } = props;
	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				position: 'relative',
				width: '100%',
				height: '100%',
				flexGrow: 1,
			}}
			spacing={2}
		>
			<Grid item>{children}</Grid>
		</Grid>
	);
};
