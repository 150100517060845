import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';

// Icons
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';

// Monorepo
import { Approach } from '@constituenthub/common';

type Props = {
	approaches: Approach[];
	selectedApproaches: number[];
	onToggleSelected: (id: number) => void;
};

export const SelectApproachList = (props: Props) => {
	const { approaches, selectedApproaches, onToggleSelected } = props;
	const theme = useTheme();
	return (
		<List dense>
			{approaches.map((approach) => (
				<ListItem
					key={approach.approachId}
					disableGutters
					button
					selected={selectedApproaches.includes(approach.approachId)}
					onClick={() => onToggleSelected(approach.approachId)}
				>
					<ListItemButton>
						<ListItemIcon>
							<Icon
								path={
									selectedApproaches.includes(
										approach.approachId
									)
										? mdiCheckboxMarked
										: mdiCheckboxBlankOutline
								}
								size={1}
								color={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							primary={approach.name}
							secondary={approach.description}
						/>
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
};
