import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { SxProps, Theme } from '@mui/system';

// Icons
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

// Monorepo
import { FieldValue } from '@constituenthub/common';

// Components
import { FlexRow } from './FlexRow';
import { ButtonField, ButtonFieldEvent } from './ButtonField';
import { AddTextValue } from './AddTextValue';
import { orderBy } from 'lodash';

export type ListOption = {
	primary: string;
	secondary?: string;
	value: FieldValue;
	icon?: string;
};

type Props = {
	caption: string;
	primary: string;
	secondary?: string;
	tip?: string;
	icon?: string;
	sx?: SxProps<Theme>;
	value: FieldValue;
	multiselect?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	onChange: (value: FieldValue) => void;
	options: ListOption[];
	addValuePlaceholder?: string;
};

export const ButtonFieldList = ({
	caption,
	primary,
	secondary,
	tip,
	icon,
	value,
	multiselect,
	readOnly,
	mixedValue,
	onChange,
	options: initialOptions,
	sx = {},
	addValuePlaceholder,
}: Props) => {
	const [options, setOptions] = React.useState<ListOption[]>([]);
	const [fieldEvent, setFieldEvent] = React.useState<ButtonFieldEvent>();
	const [selection, setSelection] = React.useState<Record<string, boolean>>(
		{}
	);

	const closePopup = () => {
		if (
			fieldEvent &&
			fieldEvent.name === 'opened' &&
			!!fieldEvent.onCloseCallback
		) {
			fieldEvent.onCloseCallback();
		}
	};

	const handleClearValue = () => {
		setSelection({});
		onChange(null);
		if (!multiselect) {
			closePopup();
		}
	};

	const getSelectedValues = React.useCallback(() => {
		const keys = Object.keys(selection).filter(
			(x) => selection[x] === true
		);
		return orderBy(keys);
	}, [selection]);

	const handleChange = (val: FieldValue) => {
		if (multiselect) {
			const key = `${val}`;
			setSelection((s) => {
				const ns = { ...s };
				ns[key] = !ns[key];
				return ns;
			});
		} else {
			onChange(val);
			closePopup();
		}
	};

	const handleOnAddValue = (val: string | null) => {
		const key = `${val}`;
		const exists = options.find((x) => x.value === val);
		if (exists && multiselect) {
			// Only unique values allowed, select it if exists
			if (val && selection[val] !== true) {
				setSelection((s) => {
					const ns = { ...s };
					ns[key] = true;
					return ns;
				});
			}
			return;
		}

		if (val) {
			setOptions((s) => {
				const ns = [...s];
				ns.push({ primary: val, value: val });
				return orderBy(ns, ['value'], ['asc']);
			});
		}

		if (multiselect) {
			setSelection((s) => {
				const ns = { ...s };
				ns[key] = true;
				return ns;
			});
		} else {
			console.log('onChange', val);
			onChange(val);
			closePopup();
		}
	};

	const selectedValue = getSelectedValues().join(', ') || null;

	React.useEffect(() => {
		if (multiselect && value !== selectedValue) {
			console.log(
				'ButtonFieldList:useEffect',
				caption,
				value,
				selectedValue
			);
			onChange(selectedValue);
		}
	}, [multiselect, selectedValue, value, onChange, caption]);

	React.useEffect(() => {
		setOptions(initialOptions);
	}, [initialOptions]);

	return (
		<ButtonField
			caption={caption}
			primary={primary}
			secondary={secondary}
			tip={tip}
			icon={icon}
			sx={sx}
			mixedValue={mixedValue}
			readOnly={readOnly}
			onEvent={setFieldEvent}
		>
			<Box
				sx={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				}}
			>
				<Box
					sx={{
						pt: -1,
						pb: 1,
						pl: 3,
						pr: 2,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<span style={{ color: 'transparent' }}>´</span>
					<Typography
						flexGrow={1}
						variant="body1"
						fontWeight={600}
						color="primary"
						tabIndex={-1}
					>
						{multiselect ? 'Select options' : 'Select an option'}
					</Typography>
					<Tooltip title="Close">
						<IconButton size="small" onClick={closePopup}>
							<Icon path={mdiClose} size={1} />
						</IconButton>
					</Tooltip>
				</Box>
				<Divider />
				<List sx={{ minWidth: 200, maxWidth: 500 }}>
					{options.map((option) => (
						<ListItem
							key={option.primary}
							button
							onClick={() => handleChange(option.value)}
							selected={option.value === value}
						>
							{option.icon && (
								<ListItemIcon sx={{ minWidth: 36 }}>
									<Icon path={option.icon} size={1} />
								</ListItemIcon>
							)}
							<ListItemText
								primary={option.primary}
								secondary={option.secondary}
							/>
							{multiselect && (
								<ListItemSecondaryAction>
									<Checkbox
										checked={
											selection[`${option.value}`] ===
											true
										}
										onClick={() =>
											handleChange(option.value)
										}
									/>
								</ListItemSecondaryAction>
							)}
						</ListItem>
					))}
				</List>
				{addValuePlaceholder && (
					<>
						<Divider />
						<AddTextValue
							placeholder={addValuePlaceholder}
							onAddValue={handleOnAddValue}
						/>
					</>
				)}
				<Divider />
				<FlexRow sx={{ p: 1 }}>
					<span style={{ flexGrow: 1 }} />
					<Button onClick={handleClearValue}>Clear Value</Button>
				</FlexRow>
			</Box>
		</ButtonField>
	);
};
