import React, { useEffect, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

// Components
import { useAppContext } from './AppContext';
import { Splash, ErrorMessage } from '../features/common';

// Lib
import { useAppDispatch, useAppSelector } from '../store';
import {
	loadProject,
	loadProjectError,
	selectProject,
	selectProjectError,
	selectProjectTheme,
} from '../store/project';

export const ProjectContext = React.createContext<undefined>(undefined);

export interface ProjectContextProps {
	children: React.ReactNode;
}

const getProjectId = (path: string) => {
	if (!path || !path.startsWith('/project/')) return null;
	return parseInt(path.split('/')[2], 10);
};

export const ProjectContextProvider = (props: ProjectContextProps) => {
	const { api } = useAppContext();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const project = useAppSelector(selectProject);
	const theme = useAppSelector(selectProjectTheme);
	const projectError = useAppSelector(selectProjectError);
	const projectId = getProjectId(location.pathname);
	api.setProjectHeader(projectId || 0);
	const { children } = props;

	const loadData = useCallback(
		async (pid: number) => {
			try {
				const [project, groups] = await Promise.all([
					api.project.getProject(pid),
					api.group.listGroups(),
				]);
				dispatch(loadProject({ project, groups }));
			} catch (error) {
				dispatch(loadProjectError(error));
			}
		},
		[dispatch, api]
	);

	useEffect(() => {
		if (projectId && projectId !== project?.projectId) {
			loadData(projectId);
		}
	}, [projectId, project, loadData]);

	return (
		<ProjectContext.Provider value={undefined}>
			{projectError && (
				<Splash hideLinks={true}>
					<ErrorMessage
						error={projectError}
						onRetry={() => window.location.replace('/')}
					/>
				</Splash>
			)}
			{!projectError && (
				<ThemeProvider theme={theme}>{children}</ThemeProvider>
			)}
		</ProjectContext.Provider>
	);
};
