import * as React from 'react';
import Grid from '@mui/material/Grid';

// Components
import { BatchActionCardDialog } from '../cards/BatchActionCardDialog';

// Lib
import { JobRoleApproachesHook } from './useJobRoleApproachesController';
import { ActionCardTile } from '../cards/ActionCardTile';
import { TaskDialog } from '../cards/TaskDialog';

type Props = {
	controller: JobRoleApproachesHook;
};

export const AffectedJobRolesApproachList = (props: Props) => {
	const { controller } = props;
	const { jobRoleApproaches } = controller;
	const [openCardDetail, setOpenCardDetail] = React.useState<number>();
	const [openCardDetailBatch, setOpenCardDetailBatch] =
		React.useState<number[]>();

	return (
		<>
			{jobRoleApproaches.map((item) => (
				<Grid item key={item.jobRoleApproachId}>
					<ActionCardTile
						action={item.action}
						showSelection={true}
						isSelected={controller.isApproachSelected(
							item.jobRoleApproachId
						)}
						onToggleSelect={() =>
							controller.onToggleSelectedApproach(
								item.jobRoleApproachId
							)
						}
						onOpenCard={() => {
							if (
								controller.selectedJobRoleApproaches.length > 1
							) {
								setOpenCardDetailBatch(
									controller.selectedJobRoleApproaches.map(
										(x) => x.actionId
									)
								);
							} else {
								setOpenCardDetail(item.actionId);
							}
						}}
					/>
				</Grid>
			))}
			{!!openCardDetailBatch && (
				<BatchActionCardDialog
					title={`${controller.selectedJobRoleApproaches.length} Affected Job Roles`}
					open={true}
					onClose={() => setOpenCardDetailBatch(undefined)}
					onContinue={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(
							setOpenCardDetailBatch(undefined)
						);
					}}
					showApproach={false}
					showName={false}
					actionIds={openCardDetailBatch}
				/>
			)}
			{!!openCardDetail && (
				<TaskDialog
					onClose={() => setOpenCardDetail(undefined)}
					onContinue={async () => {
						// await controller.onUpdateApproaches();
						return Promise.resolve(setOpenCardDetail(undefined));
					}}
					actionId={openCardDetail as number}
				/>
			)}
		</>
	);
};
