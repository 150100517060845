import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import { toast } from 'react-toastify';

// Monorepo
import {
	Project,
	Flag,
	getProjectTheme,
	PaletteName,
	Hue,
	PaletteIndex,
} from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { ProjectForm } from './ProjectForm';
import { useAppContext } from '../../contexts/AppContext';
import baseTheme from '../shell/baseTheme';

type AddProjectDialogProps = {
	open: boolean;
	onClose: () => void;
	onProjectCreated: (project: Project) => void;
};

const isProjectValid = (project: Partial<Project>) => {
	if (!project.name) return false;
	if (!project.palette) return false;
	if (!project.hue) return false;
	return true;
};

const defaultProject: Partial<Project> = {
	palette: PaletteIndex['Blue'].name,
	hue: Hue.Hue700,
};

export const AddProjectDialog = (props: AddProjectDialogProps) => {
	const { api } = useAppContext();
	const { open, onClose, onProjectCreated } = props;
	const [project, setProject] = useState<Partial<Project>>(defaultProject);
	const [flags, setFlags] = useState<Flag[]>([]);
	const [error, setError] = useState<Error>();
	const [isWorking, setIsWorking] = useState(false);

	const handleClose = () => {
		setError(undefined);
		onClose();
	};

	const handleCreateProject = async () => {
		setIsWorking(true);
		try {
			const newProject = await api.project.createProject(project);
			toast(`${project.name} Created`);
			setProject(defaultProject);
			onProjectCreated(newProject);
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsWorking(false);
		}
	};

	const projectTheme = getProjectTheme(
		project.palette as PaletteName,
		project.hue as Hue
	) as Theme;

	return (
		<ThemeProvider
			theme={createTheme({ ...baseTheme, ...projectTheme } as Theme)}
		>
			<ModalDialog
				open={open}
				onClose={handleClose}
				label="Add Project"
				actions={
					<>
						{!error && (
							<Button
								variant="contained"
								onClick={handleCreateProject}
								disabled={isWorking || !isProjectValid(project)}
							>
								Create Project
							</Button>
						)}
						{!!error && (
							<Button
								variant="contained"
								onClick={() => setError(undefined)}
							>
								Try Again
							</Button>
						)}
					</>
				}
				padding={2}
			>
				<>
					{!!isWorking && (
						<Loading enabled={true} text="Working..." />
					)}
					{!error && !isWorking && (
						<ProjectForm
							project={project}
							flags={flags}
							onChange={setProject}
							onFlagsChange={setFlags}
						/>
					)}
					{!isWorking && (
						<ErrorMessage
							error={error}
							sx={{ minWidth: '320px' }}
						/>
					)}
				</>
			</ModalDialog>
		</ThemeProvider>
	);
};
