import { SxProps, Theme } from '@mui/system';
import MuiIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

// Icons
import Icon from '@mdi/react';
import { TOOLBAR_HEIGHT } from '../../lib';

type AppBarButtonProps = {
	title: string;
	path?: string;
	icon?: React.ReactNode;
	onClick: () => void;
	disabled?: boolean;
	hidden?: boolean;
	selected?: boolean;
	sx?: SxProps<Theme>;
};

export const AppBarButton = (props: AppBarButtonProps) => {
	const {
		title,
		path,
		icon,
		disabled = false,
		hidden = false,
		selected = false,
		onClick,
		sx = {},
	} = props;

	if (hidden) return null;

	return (
		<Box
			sx={{
				display: hidden ? 'none' : 'flex',
				height: `${TOOLBAR_HEIGHT}px`,
				alignItems: 'center',
				justifyContent: 'center',
				borderBottom: selected
					? '4px solid #fff'
					: '4px solid transparent',
				borderBottomColor: selected
					? 'primary.contrastText'
					: 'transparent',
			}}
		>
			<Tooltip title={title || ''}>
				<span>
					<MuiIconButton
						size="small"
						onClick={onClick}
						disabled={disabled}
						sx={{ ...sx, position: 'relative', top: '3px' }}
					>
						{!!path && <Icon path={path} size={1} />}
						{!!icon && <>{icon}</>}
					</MuiIconButton>
				</span>
			</Tooltip>
		</Box>
	);
};
