import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

export const RoleSettings = () => {
	return (
		<>
			<Toolbar>
				<span>Roles</span>
			</Toolbar>
			<Divider />
			<Box sx={{ pl: 3 }}>
				<p>Roles</p>
			</Box>
		</>
	);
};
