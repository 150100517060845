import {
	UserAccountContext,
	User,
	AddUserRequest,
} from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class UserApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listUsers(): Promise<User[]> {
		const response = await this.client.get<User[]>(`/user`);
		return response.result;
	}

	public async add(data: AddUserRequest): Promise<User> {
		const response = await this.client.post<AddUserRequest, User>(
			`/user`,
			data
		);
		return response.result;
	}

	public async get(userId: string): Promise<User> {
		const response = await this.client.get<User>(`/user/${userId}`);
		return response.result;
	}

	public async remove(userId: string): Promise<any> {
		const response = await this.client.del(`/user/${userId}`);
		return response.result;
	}

	public async update(user: User): Promise<User> {
		const response = await this.client.put<User, User>(
			`/user/${user.userId}`,
			user
		);
		return response.result;
	}

	public async getUser(): Promise<UserAccountContext> {
		const response = await this.client.get<UserAccountContext>(
			`/user/profile`
		);
		return response.result;
	}

	public async getAccount(accountId: number): Promise<UserAccountContext> {
		const response = await this.client.get<UserAccountContext>(
			`/user/profile/account/${accountId}`
		);
		return response.result;
	}

	public async getUserAccountPermissions(): Promise<string[]> {
		const response = await this.client.get<string[]>(
			`/user/permissions/account`
		);
		return response.result;
	}

	public async getUserProjectPermissions(): Promise<string[]> {
		const response = await this.client.get<string[]>(
			`/user/permissions/project`
		);
		return response.result;
	}
}
