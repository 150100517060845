import { FieldTypes, JobRoleFieldDefinitions } from '..';
import {
	AccountDataModel,
	ProjectDataModel,
	ParentModel,
	DataModelDeletable,
	Entity,
} from '../common';
import { FieldDefinition, FieldsModel } from '../fields';

export interface ActionCard
	extends AccountDataModel,
		ProjectDataModel,
		ParentModel,
		DataModelDeletable,
		FieldsModel {
	actionId: number;
}

export interface ActionCardView extends ActionCard {
	changeItemName?: string;
	changeItemId?: number;
	groupName?: string;
	groupId?: number;
}

export const ActionCardStages = {
	ToDo: 'To Do',
	InProgress: 'In Progress',
	Ongoing: 'Ongoing',
	Complete: 'Complete',
};

export const ActionCardStatus = {
	None: 'None',
	Green: 'Green',
	Yellow: 'Yellow',
	Red: 'Red',
};

export const ActionCardDefinitions: Record<string, FieldDefinition> = {
	Approach: {
		entity: Entity.ActionCard,
		property: 'approach',
		type: FieldTypes.Approach,
		label: 'Approach',
		system: true,
	},
	AssignedTo: {
		entity: Entity.ActionCard,
		property: 'assignedto',
		type: FieldTypes.User,
		label: 'Assigned To',
		system: true,
	},
	Start: {
		entity: Entity.ActionCard,
		property: 'start',
		type: FieldTypes.Date,
		label: 'Start',
		system: true,
	},
	End: {
		entity: Entity.ActionCard,
		property: 'end',
		type: FieldTypes.Date,
		label: 'End',
		system: true,
	},
	Complete: {
		entity: Entity.ActionCard,
		property: 'complete',
		type: FieldTypes.Date,
		label: 'Complete',
		system: true,
	},
	Position: {
		entity: Entity.ActionCard,
		property: 'position',
		type: FieldTypes.Integer,
		label: 'Position',
		system: true,
		managed: true,
	},
	Stage: {
		entity: Entity.ActionCard,
		property: 'stage',
		label: 'Stage',
		type: FieldTypes.SelectList,
		options: [
			{ label: ActionCardStages.ToDo, value: ActionCardStages.ToDo },
			{
				label: ActionCardStages.InProgress,
				value: ActionCardStages.InProgress,
			},
			{
				label: ActionCardStages.Ongoing,
				value: ActionCardStages.Ongoing,
			},
			{
				label: ActionCardStages.Complete,
				value: ActionCardStages.Complete,
			},
		],
		system: true,
	},
	Status: {
		entity: Entity.ActionCard,
		property: 'status',
		label: 'Status',
		type: FieldTypes.SelectList,
		options: [
			{ label: ActionCardStatus.None, value: ActionCardStatus.None },
			{ label: ActionCardStatus.Green, value: ActionCardStatus.Green },
			{ label: ActionCardStatus.Yellow, value: ActionCardStatus.Yellow },
			{ label: ActionCardStatus.Red, value: ActionCardStatus.Red },
		],
		system: true,
	},
	TeamMember: {
		entity: Entity.ActionCard,
		property: 'teamMember',
		type: FieldTypes.Text,
		label: 'Team Member',
		system: true,
		autoComplete: true,
	},
	Name: {
		entity: Entity.ActionCard,
		property: 'name',
		type: FieldTypes.Text,
		label: 'Task Short Name',
		system: true,
	},
	Description: {
		entity: Entity.ActionCard,
		property: 'description',
		type: FieldTypes.Content,
		label: 'Description',
		system: true,
	},
	Participants: {
		entity: Entity.ActionCard,
		property: 'participants',
		type: FieldTypes.Content,
		label: 'Participants',
		system: false,
	},
	Result: {
		entity: Entity.ActionCard,
		property: 'result',
		type: FieldTypes.Content,
		label: 'Result',
		system: false,
	},
};
