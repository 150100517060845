import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';

// Monorepo
import { FieldDefinition, parseFilterFromUrl } from '@constituenthub/common';
import { FilterDescription } from '../view/FilterDescription';

type Props = {
	definition: Record<string, FieldDefinition>;
};

export const ReportFilters = ({ definition }: Props) => {
	const location = useLocation();
	const filter = parseFilterFromUrl(location.search);

	if (!filter || filter.length === 0) return null;
	return (
		<>
			<Box sx={{ p: 2 }}>
				<Typography variant="body2" fontWeight={600}>
					Filter Criteria:
				</Typography>
				<FilterDescription filter={filter} definition={definition} />
			</Box>
			<Divider
				sx={{
					borderWidth: 1,
					borderColor: '#000',
				}}
			/>
		</>
	);
};
