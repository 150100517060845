import * as React from 'react';
import { SxProps, Theme } from '@mui/system';

// Components
import { ButtonFieldList } from '../common/ButtonFieldList';

// Lib
import { ICON_APPROACHES } from '../../lib';
import { useAppSelector } from '../../store';
import { selectApproaches } from '../../store/application';

type Props = {
	caption?: string;
	sx?: SxProps<Theme>;
	value: number | null;
	icon?: string;
	readOnly?: boolean;
	mixedValue?: boolean;
	onChange: (value: number) => void;
};

export const ButtonFieldApproach = ({
	caption,
	value,
	onChange,
	icon,
	readOnly,
	mixedValue,
	sx = {},
}: Props) => {
	const approaches = useAppSelector(selectApproaches);
	const selected = approaches.find((x) => x.approachId === value);
	const options = approaches.map((item) => ({
		value: item.approachId,
		primary: item.name,
		secondary: item.description,
	}));

	return (
		<ButtonFieldList
			caption={caption || 'Approach'}
			primary={selected ? selected.name : 'Select Approach'}
			secondary={selected ? selected.description : undefined}
			icon={icon || ICON_APPROACHES}
			onChange={(value) => onChange(value as number)}
			value={value}
			options={options}
			readOnly={readOnly}
			mixedValue={mixedValue}
			sx={sx}
		/>
	);
};
