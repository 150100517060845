import * as React from 'react';
import List from '@mui/material/List';

// Monorepo
import { Note } from '@constituenthub/common';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { ErrorMessage, FlexColumn, Loading } from '../common';
import { NoteListItem } from './NoteListItem';
import { SxProps, Theme } from '@mui/system';
import { orderBy } from 'lodash';
import { AddNote } from './AddNote';
import { EditNoteDialog } from './EditNoteDialog';

type Props = {
	parentId?: number;
	entity?: string;
	onNoteCount?: (count: number) => void;
	sx?: SxProps<Theme>;
};

type State = {
	loaded: boolean;
	working: boolean;
	items: Note[];
	error?: any;
};

const initialState: State = {
	loaded: false,
	working: true,
	items: [],
};

export const NoteContainer = ({
	parentId,
	entity,
	onNoteCount,
	sx = {},
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);
	const [editNote, setEditNote] = React.useState<Note>();

	const loadItems = React.useCallback(
		async (entity: string, parentId: number) => {
			setState((s) => ({
				...s,
				working: true,
				loaded: false,
				error: undefined,
			}));
			try {
				const notes = await api.note.listNotes(entity, parentId);
				setState((s) => ({
					...s,
					working: false,
					loaded: true,
					items: notes,
				}));
			} catch (error) {
				console.error(error);
				setState((s) => ({
					...s,
					working: false,
					loaded: true,
					error: error,
				}));
			}
		},
		[api.note]
	);

	const handleAddNote = React.useCallback(async (note: Note) => {
		setState((s) => {
			const items = [...s.items];
			items.push(note);
			return {
				...s,
				items: orderBy(items, ['createdAt'], ['desc']),
			};
		});
	}, []);

	const handleNoteDeleted = (note: Note) => {
		setState((s) => {
			const items = [...s.items];
			const index = items.findIndex((x) => x.noteId === note.noteId);
			if (index !== -1) {
				items.splice(index, 1);
			}
			return {
				...s,
				items: orderBy(items, ['createdAt'], ['desc']),
			};
		});
		setEditNote(undefined);
	};

	const handleNoteUpdated = (note: Note) => {
		setState((s) => {
			const items = [...s.items];
			const index = items.findIndex((x) => x.noteId === note.noteId);
			if (index !== -1) {
				items.splice(index, 1, note);
			}
			return {
				...s,
				items: orderBy(items, ['createdAt'], ['desc']),
			};
		});
		setEditNote(undefined);
	};

	React.useEffect(() => {
		if (parentId && entity) {
			loadItems(entity, parentId);
		}
	}, [parentId, entity, loadItems]);

	React.useEffect(() => {
		if (onNoteCount) {
			onNoteCount(state.items.length);
		}
	}, [onNoteCount, state.items.length]);

	if (!entity || !parentId) {
		console.log('NoteContainer returning null');
		return null;
	}

	return (
		<FlexColumn name="note-container" fill sx={sx}>
			<FlexColumn fill scroll sx={{ height: 'calc(100% - 120px)' }}>
				<ErrorMessage
					error={state.error}
					onRetry={() => loadItems(entity, parentId)}
				/>
				<Loading text="Loading..." enabled={state.working} />
				<List
					sx={{
						display:
							state.loaded && !!state.items.length
								? 'initial'
								: 'none',
					}}
				>
					{state.items.map((item) => (
						<NoteListItem
							key={item.noteId}
							note={item}
							onEditNote={setEditNote}
						/>
					))}
				</List>
			</FlexColumn>
			<AddNote
				entity={entity}
				parentId={parentId}
				onNoteAdded={handleAddNote}
				disabled={!state.loaded || state.working}
			/>
			{!!editNote && (
				<EditNoteDialog
					note={editNote}
					onClose={() => setEditNote(undefined)}
					onNoteDeleted={handleNoteDeleted}
					onNoteUpdated={handleNoteUpdated}
				/>
			)}
		</FlexColumn>
	);
};
