import * as React from 'react';
import { ActionCard } from '@constituenthub/common';
import { useAppContext } from '../../contexts/AppContext';
import { useAppSelector } from '../../store';
import { selectActionCards } from '../../store/actions';

export const useActionCards = () => {
	const { api } = useAppContext();
	const actionCards = useAppSelector(selectActionCards);

	const findActionCard = React.useCallback(
		async (actionId: number): Promise<ActionCard | undefined> => {
			const actionCard = actionCards.find((x) => x.actionId === actionId);
			if (actionCard) return actionCard;

			try {
				return api.action.getActionCard(actionId);
			} catch (error) {
				console.error(error);
				return undefined;
			}
		},
		[actionCards, api.action]
	);

	return {
		actionCards,
		findActionCard,
	};
};
