import React from 'react';
import { orderBy } from 'lodash';

// Monorepo
import { Group } from '@constituenthub/common';

// Components
import {
	Table,
	useCheckboxSelectionRenderer,
	useTextRenderer,
	useRowHoverPlugin,
} from '../table';

type Props = {
	groups: Group[];
	isGroupSelected: (id: number) => boolean;
	onToggleGroupSelected: (group: Group) => void;
	onToggleSingleGroupSelected: (group: Group) => void;
	onToggleAllSelected: () => void;
	selectionCount: number;
};

export const AffectedJobRoleTable = (props: Props) => {
	const {
		groups,
		isGroupSelected,
		onToggleGroupSelected,
		onToggleAllSelected,
		onToggleSingleGroupSelected,
		selectionCount,
	} = props;

	const selectionColumn = useCheckboxSelectionRenderer({
		isChecked: (props) => isGroupSelected(props.getId() as number),
		toggleChecked: (props) => onToggleGroupSelected(props.row),
		isAllChecked: () =>
			selectionCount !== 0 && selectionCount === groups.length,
		toggleAllChecked: () => onToggleAllSelected(),
	});

	const name = useTextRenderer({
		field: 'name',
		label: 'Job Role',
		sort: 'off',
		width: 430,
		onDetailClick: (props) => onToggleSingleGroupSelected(props.row),
	});

	const rowHover = useRowHoverPlugin();

	const rows = orderBy(groups, ['name'], ['asc']);

	return (
		<Table
			rows={rows}
			getId={(group: Group) => group.groupId}
			columns={[selectionColumn, name]}
			plugins={[rowHover]}
			onScrollBottom={() => {}}
			rowHeight={50}
		/>
	);
};
