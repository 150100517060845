import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

// import { Auth } from 'aws-amplify';

// Monorepo
import {
	User,
	validateEmail,
	validatePhoneNumber,
} from '@constituenthub/common';

// Components
import { ProfileForm } from './ProfileForm';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { ErrorMessage, FlexColumn, Loading } from '../common';
import { Button } from '@mui/material';
import { useAppDispatch } from '../../store';
import { updateUser } from '../../store/application';

export const ProfileSettings = () => {
	const { api, user, onUserUpdated } = useAppContext();
	const dispatch = useAppDispatch();
	const [changes, setChanges] = React.useState<Partial<User>>({});
	const [error, setError] = React.useState<any>();
	const [working, setWorking] = React.useState(false);

	const handleChange = (data: Partial<User>) => {
		setChanges(data);
	};

	const handleSaveChanges = async () => {
		setWorking(true);
		try {
			const updated = await api.user.update(changes as User);
			dispatch(updateUser(updated));
			setWorking(false);
			onUserUpdated(updated);
			setChanges({});
		} catch (err) {
			setError(err);
			setWorking(false);
		}
	};

	React.useEffect(() => {
		if (user) {
			setChanges(user);
		}
	}, [user]);

	const isValid =
		validateEmail(changes.email) &&
		validatePhoneNumber(changes.phone) &&
		!!changes.first &&
		!!changes.last;

	return (
		<>
			<Toolbar>
				<span>Profile Settings</span>
			</Toolbar>
			<Divider />
			<FlexColumn fill scroll sx={{ p: 2 }}>
				<ErrorMessage error={error} />
				<Loading enabled={working} text="Working..." />
				{!!user && !error && !working && (
					<ProfileForm user={changes} onChange={handleChange} />
				)}
				<Toolbar>
					{/* <Button
						variant="contained"
						onClick={async () => {
							const result = await Auth.currentSession();
							const token = result.getIdToken();
							const groups = token.payload['cognito:groups'];
							console.log(groups);
						}}
					>
						Test
					</Button> */}
					<span style={{ flexGrow: 1 }} />
					{!error && (
						<Button
							variant="contained"
							onClick={handleSaveChanges}
							disabled={!isValid || working}
						>
							Save Changes
						</Button>
					)}
					{!!error && (
						<Button
							variant="contained"
							onClick={() => setError(undefined)}
						>
							Try Again
						</Button>
					)}
				</Toolbar>
			</FlexColumn>
		</>
	);
};
