// Monorepo
import { encodeFilter, FieldValue, Filter } from '@constituenthub/common';
import { uniq } from 'lodash';

// Lib
import { ApiClientInterface } from './ApiClient';

export default class FieldValueApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async uniqueValues(
		entity: string,
		property: string,
		filter?: Filter
	): Promise<FieldValue[]> {
		const url = filter
			? `/fieldvalue/${entity}/${property}?filter=${encodeFilter(filter)}`
			: `/fieldvalue/${entity}/${property}`;

		const response = await this.client.get<FieldValue[]>(url);
		return uniq(response.result);
	}
}
