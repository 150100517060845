export enum Hue {
    Hue100 = '100',
    Hue200 = '200',
    Hue300 = '300',
    Hue400 = '400',
    Hue500 = '500',
    Hue600 = '600',
    Hue700 = '700',
    Hue800 = '800',
    Hue900 = '900',
}

export enum TextColor {
    Light = '#ffffff',
    Dark = '#000000',
}

export type PaletteName =
    'Amber' |
    'Blue' |
    'Blue Grey' |
    'Brown' |
    'Cyan' |
    'Deep Orange' |
    'Deep Purple' |
    'Green' |
    'Grey' |
    'Indigo' |
    'Light Blue' |
    'Light Green' |
    'Lime' |
    'Orange' |
    'Pink' |
    'Purple' |
    'Red' |
    'Teal' |
    'Yellow';

export const AvailablePaletteNames: PaletteName[] = [
    'Amber',
    'Blue',
    'Blue Grey',
    'Brown',
    'Cyan',
    'Deep Orange',
    'Deep Purple',
    'Green',
    'Light Blue',
    'Light Green',
    'Lime',
    'Orange',
    'Pink',
    'Purple',
    'Red',
    'Teal',
    'Yellow',
];

export interface PaletteColor {
    key: Hue;
    color: string;
    text: TextColor;
}

export interface Palette {
    name: PaletteName;
    colors: PaletteColor[];
}

export const materialPalette: Record<PaletteName, Palette> = {
    'Amber': {
        name: 'Amber',
        colors: [
            { key: Hue.Hue900, color: '#ff6f00', text: TextColor.Light },
            { key: Hue.Hue800, color: '#ff8f00', text: TextColor.Light },
            { key: Hue.Hue700, color: '#ffa000', text: TextColor.Light },
            { key: Hue.Hue600, color: '#ffb300', text: TextColor.Light },
            { key: Hue.Hue500, color: '#ffc107', text: TextColor.Light },
            { key: Hue.Hue400, color: '#ffca28', text: TextColor.Light },
            { key: Hue.Hue300, color: '#ffd54f', text: TextColor.Light },
            { key: Hue.Hue200, color: '#ffe082', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#ffecb3', text: TextColor.Dark },
        ],
    },
    'Blue': {
        name: 'Blue',
        colors: [
            { key: Hue.Hue900, color: '#0D47A1', text: TextColor.Light },
            { key: Hue.Hue800, color: '#1565C0', text: TextColor.Light },
            { key: Hue.Hue700, color: '#1976D2', text: TextColor.Light },
            { key: Hue.Hue600, color: '#1E88E5', text: TextColor.Light },
            { key: Hue.Hue500, color: '#2196F3', text: TextColor.Light },
            { key: Hue.Hue400, color: '#42A5F5', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#64B5F6', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#90CAF9', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#BBDEFB', text: TextColor.Dark },
        ],
    },
    'Blue Grey': {
        name: 'Blue Grey',
        colors: [
            { key: Hue.Hue900, color: '#263238', text: TextColor.Light },
            { key: Hue.Hue800, color: '#37474f', text: TextColor.Light },
            { key: Hue.Hue700, color: '#455a64', text: TextColor.Light },
            { key: Hue.Hue600, color: '#546e7a', text: TextColor.Light },
            { key: Hue.Hue500, color: '#607d8b', text: TextColor.Light },
            { key: Hue.Hue400, color: '#78909c', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#90a4ae', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#b0bec5', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#cfd8dc', text: TextColor.Dark },
        ],
    },
    'Brown': {
        name: 'Brown',
        colors: [
            { key: Hue.Hue900, color: '#3E2723', text: TextColor.Light },
            { key: Hue.Hue800, color: '#4E342E', text: TextColor.Light },
            { key: Hue.Hue700, color: '#5D4037', text: TextColor.Light },
            { key: Hue.Hue600, color: '#6D4C41', text: TextColor.Light },
            { key: Hue.Hue500, color: '#795548', text: TextColor.Light },
            { key: Hue.Hue400, color: '#8D6E63', text: TextColor.Light },
            { key: Hue.Hue300, color: '#A1887F', text: TextColor.Light },
            { key: Hue.Hue200, color: '#BCAAA4', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#D7CCC8', text: TextColor.Dark },
        ],
    },
    'Cyan': {
        name: 'Cyan',
        colors: [
            { key: Hue.Hue900, color: '#006064', text: TextColor.Light },
            { key: Hue.Hue800, color: '#00838F', text: TextColor.Light },
            { key: Hue.Hue700, color: '#0097A7', text: TextColor.Light },
            { key: Hue.Hue600, color: '#00ACC1', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#00BCD4', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#26C6DA', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#4DD0E1', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#80DEEA', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#B2EBF2', text: TextColor.Dark },
        ],
    },
    'Deep Orange': {
        name: 'Deep Orange',
        colors: [
            { key: Hue.Hue900, color: '#bf360c', text: TextColor.Light },
            { key: Hue.Hue800, color: '#d84315', text: TextColor.Light },
            { key: Hue.Hue700, color: '#e64a19', text: TextColor.Light },
            { key: Hue.Hue600, color: '#f4511e', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#ff5722', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#ff7043', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#ff8a65', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#ffab91', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#ffccbc', text: TextColor.Dark },
        ],
    },
    'Deep Purple': {
        name: 'Deep Purple',
        colors: [
            { key: Hue.Hue900, color: '#311b92', text: TextColor.Light },
            { key: Hue.Hue800, color: '#4527a0', text: TextColor.Light },
            { key: Hue.Hue700, color: '#512da8', text: TextColor.Light },
            { key: Hue.Hue600, color: '#5e35b1', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#673ab7', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#7e57c2', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#9575cd', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#b39ddb', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#d1c4e9', text: TextColor.Dark },
        ],
    },
    'Green': {
        name: 'Green',
        colors: [
            { key: Hue.Hue900, color: '#1b5e20', text: TextColor.Light },
            { key: Hue.Hue800, color: '#2e7d32', text: TextColor.Light },
            { key: Hue.Hue700, color: '#388e3c', text: TextColor.Light },
            { key: Hue.Hue600, color: '#43a047', text: TextColor.Light },
            { key: Hue.Hue500, color: '#4caf50', text: TextColor.Light },
            { key: Hue.Hue400, color: '#66bb6a', text: TextColor.Light },
            { key: Hue.Hue300, color: '#81c784', text: TextColor.Light },
            { key: Hue.Hue200, color: '#a5d6a7', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#c8e6c9', text: TextColor.Dark },
        ],
    },
    'Grey': {
        name: 'Grey',
        colors: [
            { key: Hue.Hue900, color: '#212121', text: TextColor.Light },
            { key: Hue.Hue800, color: '#424242', text: TextColor.Light },
            { key: Hue.Hue700, color: '#616161', text: TextColor.Light },
            { key: Hue.Hue600, color: '#757575', text: TextColor.Light },
            { key: Hue.Hue500, color: '#9e9e9e', text: TextColor.Light },
            { key: Hue.Hue400, color: '#bdbdbd', text: TextColor.Light },
            { key: Hue.Hue300, color: '#e0e0e0', text: TextColor.Light },
            { key: Hue.Hue200, color: '#eeeeee', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#f5f5f5', text: TextColor.Dark },
        ],
    },
    'Indigo': {
        name: 'Indigo',
        colors: [
            { key: Hue.Hue900, color: '#1a237e', text: TextColor.Light },
            { key: Hue.Hue800, color: '#283593', text: TextColor.Light },
            { key: Hue.Hue700, color: '#303f9f', text: TextColor.Light },
            { key: Hue.Hue600, color: '#3949ab', text: TextColor.Light },
            { key: Hue.Hue500, color: '#3f51b5', text: TextColor.Light },
            { key: Hue.Hue400, color: '#5c6bc0', text: TextColor.Light },
            { key: Hue.Hue300, color: '#7986cb', text: TextColor.Light },
            { key: Hue.Hue200, color: '#9fa8da', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#c5cae9', text: TextColor.Dark },
        ],
    },
    'Light Blue': {
        name: 'Light Blue',
        colors: [
            { key: Hue.Hue900, color: '#01579b', text: TextColor.Light },
            { key: Hue.Hue800, color: '#0277bd', text: TextColor.Light },
            { key: Hue.Hue700, color: '#0288d1', text: TextColor.Light },
            { key: Hue.Hue600, color: '#039be5', text: TextColor.Light },
            { key: Hue.Hue500, color: '#03a9f4', text: TextColor.Light },
            { key: Hue.Hue400, color: '#29b6f6', text: TextColor.Light },
            { key: Hue.Hue300, color: '#4fc3f7', text: TextColor.Light },
            { key: Hue.Hue200, color: '#81d4fa', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#b3e5fc', text: TextColor.Dark },
        ],
    },
    'Light Green': {
        name: 'Light Green',
        colors: [
            { key: Hue.Hue900, color: '#33691E', text: TextColor.Light },
            { key: Hue.Hue800, color: '#558B2F', text: TextColor.Light },
            { key: Hue.Hue700, color: '#689F38', text: TextColor.Light },
            { key: Hue.Hue600, color: '#7CB342', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#8BC34A', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#9CCC65', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#AED581', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#C5E1A5', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#DCEDC8', text: TextColor.Dark },
        ],
    },
    'Lime': {
        name: 'Lime',
        colors: [
            { key: Hue.Hue800, color: '#9E9D24', text: TextColor.Dark },
            { key: Hue.Hue700, color: '#AFB42B', text: TextColor.Dark },
            { key: Hue.Hue600, color: '#C0CA33', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#CDDC39', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#D4E157', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#DCE775', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#E6EE9C', text: TextColor.Dark },
        ],
    },
    'Orange': {
        name: 'Orange',
        colors: [
            { key: Hue.Hue900, color: '#e65100', text: TextColor.Light },
            { key: Hue.Hue800, color: '#ef6c00', text: TextColor.Light },
            { key: Hue.Hue700, color: '#f57c00', text: TextColor.Light },
            { key: Hue.Hue600, color: '#fb8c00', text: TextColor.Light },
            { key: Hue.Hue500, color: '#ff9800', text: TextColor.Light },
            { key: Hue.Hue400, color: '#ffa726', text: TextColor.Light },
            { key: Hue.Hue300, color: '#ffb74d', text: TextColor.Light },
            { key: Hue.Hue200, color: '#ffcc80', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#ffe0b2', text: TextColor.Dark },
        ],
    },
    'Pink': {
        name: 'Pink',
        colors: [
            { key: Hue.Hue900, color: '#880e4f', text: TextColor.Light },
            { key: Hue.Hue800, color: '#ad1457', text: TextColor.Light },
            { key: Hue.Hue700, color: '#c2185b', text: TextColor.Light },
            { key: Hue.Hue600, color: '#d81b60', text: TextColor.Light },
            { key: Hue.Hue500, color: '#e91e63', text: TextColor.Light },
            { key: Hue.Hue400, color: '#ec407a', text: TextColor.Light },
            { key: Hue.Hue300, color: '#f06292', text: TextColor.Light },
            { key: Hue.Hue200, color: '#f48fb1', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#f8bbd0', text: TextColor.Dark },
        ],
    },
    'Purple': {
        name: 'Purple',
        colors: [
            { key: Hue.Hue900, color: '#4A148C', text: TextColor.Light },
            { key: Hue.Hue800, color: '#6A1B9A', text: TextColor.Light },
            { key: Hue.Hue700, color: '#7B1FA2', text: TextColor.Light },
            { key: Hue.Hue600, color: '#8E24AA', text: TextColor.Light },
            { key: Hue.Hue500, color: '#9C27B0', text: TextColor.Light },
            { key: Hue.Hue400, color: '#AB47BC', text: TextColor.Light },
            { key: Hue.Hue300, color: '#BA68C8', text: TextColor.Light },
            { key: Hue.Hue200, color: '#CE93D8', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#E1BEE7', text: TextColor.Dark },
        ],
    },
    'Red': {
        name: 'Red',
        colors: [
            { key: Hue.Hue900, color: '#b71c1c', text: TextColor.Light },
            { key: Hue.Hue800, color: '#c62828', text: TextColor.Light },
            { key: Hue.Hue700, color: '#d32f2f', text: TextColor.Light },
            { key: Hue.Hue600, color: '#e53935', text: TextColor.Light },
            { key: Hue.Hue500, color: '#f44336', text: TextColor.Light },
            { key: Hue.Hue400, color: '#ef5350', text: TextColor.Light },
            { key: Hue.Hue300, color: '#e57373', text: TextColor.Light },
            { key: Hue.Hue200, color: '#ef9a9a', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#ffcdd2', text: TextColor.Dark },
        ],
    },
    'Teal': {
        name: 'Teal',
        colors: [
            { key: Hue.Hue900, color: '#004D40', text: TextColor.Light },
            { key: Hue.Hue800, color: '#00695C', text: TextColor.Light },
            { key: Hue.Hue700, color: '#00796B', text: TextColor.Light },
            { key: Hue.Hue600, color: '#00897B', text: TextColor.Light },
            { key: Hue.Hue500, color: '#009688', text: TextColor.Light },
            { key: Hue.Hue400, color: '#26A69A', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#4DB6AC', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#80CBC4', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#B2DFDB', text: TextColor.Dark },
        ],
    },
    'Yellow': {
        name: 'Yellow',
        colors: [
            { key: Hue.Hue900, color: '#f57f17', text: TextColor.Light },
            { key: Hue.Hue800, color: '#f9a825', text: TextColor.Dark },
            { key: Hue.Hue700, color: '#fbc02d', text: TextColor.Dark },
            { key: Hue.Hue600, color: '#fdd835', text: TextColor.Dark },
            { key: Hue.Hue500, color: '#ffeb3b', text: TextColor.Dark },
            { key: Hue.Hue400, color: '#ffee58', text: TextColor.Dark },
            { key: Hue.Hue300, color: '#fff176', text: TextColor.Dark },
            { key: Hue.Hue200, color: '#fff59d', text: TextColor.Dark },
            { key: Hue.Hue100, color: '#fff9c4', text: TextColor.Dark },
        ],
    },
};

export const PaletteIndex = materialPalette;

export interface PaletteValue {
    color: string;
    text: string;
}

export const getPaletteValue = (palette: PaletteName, hue: Hue): PaletteValue => {
    const p = PaletteIndex[palette];
    const h = p.colors.find(x => x.key === hue);
    return {
        color: h?.color || '#ffffff',
        text: h?.text || '#000000',
    }
}

export const mapPaletteTheme = (primary: PaletteValue, secondary: PaletteValue): any => {
    return {
        palette: {
            primary: {
                main: primary.color,
                contrastText: primary.text,
            },
            secondary: {
                main: secondary.color,
                contrastText: secondary.text,
            },
        }
    }
}

export const getProjectTheme = (palette?: PaletteName | null, hue?: Hue | null): any => {
    if (palette && hue) {
        const primary = getPaletteValue(palette, hue);
        const secondary = getPaletteValue(palette, Hue.Hue400);
        return mapPaletteTheme(primary, secondary);
    }
    const primary = getPaletteValue('Indigo', Hue.Hue500);
    const secondary = getPaletteValue('Grey', Hue.Hue700);
    return mapPaletteTheme(primary, secondary);
}