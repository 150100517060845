export const Permission = {

    ListProjects: 'List Projects',
    CreateProject: 'Create Project',
    UpdateProject: 'Update Project',
    RenameProject: 'Rename Project',
    ArchiveProject: 'Archive Project',
    DeleteProject: 'Delete Project',

    ListGroups: 'List Groups',
    CreateGroup: 'Create Group',
    UpdateGroup: 'Update Group',
    RenameGroup: 'Rename Group',
    ArchiveGroup: 'Archive Group',
    DeleteGroup: 'Delete Group',

    ListApproaches: 'List Approaches',
    CreateApproach: 'Create Approach',
    UpdateApproach: 'Update Approach',
    RenameApproach: 'Rename Approach',
    ArchiveApproach: 'Archive Approach',
    DeleteApproach: 'Delete Approach',
};