import React from 'react';
import { HeaderRenderProps } from '../types';

interface HeaderBaseProps extends HeaderRenderProps {
	children: React.ReactNode;
	onKeyPress?: (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => void;
	onKeyDown?: (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => void;
	onKeyUp?: (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => void;
	onFocus?: (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: HeaderRenderProps
	) => void;
	onBlur?: (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: HeaderRenderProps
	) => void;
	onClick?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => void;
	onDoubleClick?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => void;
	onMouseOver?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => void;
	onMouseOut?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => void;
}

export const HeaderBase = (props: HeaderBaseProps) => {
	const {
		onKeyPress,
		onKeyDown,
		onKeyUp,
		onFocus,
		onBlur,
		onClick,
		onDoubleClick,
		onMouseOver,
		onMouseOut,
	} = props;
	return (
		<div
			tabIndex={0}
			style={props.style}
			onKeyPress={(event) =>
				onKeyPress
					? onKeyPress(event, props)
					: props.api.onKeyPress(event, props)
			}
			onKeyDown={(event) =>
				onKeyDown
					? onKeyDown(event, props)
					: props.api.onKeyDown(event, props)
			}
			onKeyUp={(event) =>
				onKeyUp
					? onKeyUp(event, props)
					: props.api.onKeyUp(event, props)
			}
			onFocus={(event) =>
				onFocus
					? onFocus(event, props)
					: props.api.onFocus(event, props)
			}
			onBlur={(event) =>
				onBlur ? onBlur(event, props) : props.api.onBlur(event, props)
			}
			onClick={(event) =>
				onClick
					? onClick(event, props)
					: props.api.onClick(event, props)
			}
			onDoubleClick={(event) =>
				onDoubleClick
					? onDoubleClick(event, props)
					: props.api.onDoubleClick(event, props)
			}
			onMouseOver={(event) =>
				onMouseOver
					? onMouseOver(event, props)
					: props.api.onMouseOver(event, props)
			}
			onMouseOut={(event) =>
				onMouseOut
					? onMouseOut(event, props)
					: props.api.onMouseOut(event, props)
			}
		>
			{props.children}
		</div>
	);
};
