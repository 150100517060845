import { encodeFilter } from './filters';
import { hasBooleanValue, hasValue } from './validations';
import { Filter } from './filters';

export interface ListOptions {
	limit?: number;
	offset?: number;
	count?: boolean;
}

export interface FilterListOptions {
	filter?: Filter;
}

export interface IdsListOptions {
	items?: number[];
}

export interface ActionCardListOptions
	extends ListOptions,
		FilterListOptions,
		IdsListOptions {
	stage?: string;
}

export interface ChangeItemListOptions extends ListOptions, FilterListOptions {
	groupby?: string[];
	orderby?: string[];
}

const builtInOptions = ['limit', 'offset', 'count', 'filter', 'items'];

export const buildUrlListOptions = (baseUrl: string, options: any): string => {
	const search: string[] = [];

	if (options.limit && hasValue(options.limit)) {
		search.push(`limit=${options.limit}`);
	}

	if (options.offset && hasValue(options.offset)) {
		search.push(`limit=${options.offset}`);
	}

	if (options.count && hasBooleanValue(options.count)) {
		search.push(`count=true`);
	}

	if (options.filter && hasValue(options.filter)) {
		search.push(`filter=${encodeFilter(options.filter)}`);
	}

	if (options.items && hasValue(options.items) && options.items.length > 0) {
		search.push(`items=${options.items.join(',')}`);
	}

	Object.keys(options)
		.filter((key) => !builtInOptions.includes(key))
		.forEach((key) => {
			const val = options[key];
			if (hasValue(val)) {
				search.push(`${key}=${val}`);
			}
		});

	if (search.length > 0) {
		return `${baseUrl}?${search.join('&')}`;
	} else {
		return baseUrl;
	}
};
