import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';

// Icons
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';

// Monorepo
import { Group } from '@constituenthub/common';

type Props = {
	groups: Group[];
	selectedGroups: number[];
	onToggleSelected: (id: number) => void;
};

export const SelectGroupList = (props: Props) => {
	const { groups, selectedGroups, onToggleSelected } = props;
	const theme = useTheme();
	return (
		<List dense>
			{groups.map((group) => (
				<ListItem
					key={group.groupId}
					disableGutters
					button
					selected={selectedGroups.includes(group.groupId)}
					onClick={() => onToggleSelected(group.groupId)}
				>
					<ListItemButton>
						<ListItemIcon>
							<Icon
								path={
									selectedGroups.includes(group.groupId)
										? mdiCheckboxMarked
										: mdiCheckboxBlankOutline
								}
								size={1}
								color={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							primary={group.name}
							secondary={group.description}
						/>
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
};
