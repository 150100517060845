import { appendFileSync } from 'fs';
import { isNumber, forEach } from 'lodash';

// Lib
import { FieldTypes, FieldDefinition } from './fields';
import { hasBooleanValue, hasValue } from './validations';

export const FilterOperators = {
	HasValue: 'Has Value',
	HasNoValue: 'Has No Value',
	Equals: 'Equals',
	NotEquals: 'Does Not Equal',
	StartsWith: 'Starts With',
	EndsWith: 'Ends With',
	Contains: 'Contains',
	GreaterThan: 'Greater Than',
	GreaterThanOrEqualTo: 'Greater Than Or Equal To',
	LessThan: 'Less Than',
	LessThanOrEqualTo: 'Less Than Or Equal To',
	CalculatedAs: 'Is Calculated',
} as const;

export type FilterOperator =
	| 'Has Value'
	| 'Has No Value'
	| 'Equals'
	| 'Does Not Equal'
	| 'Starts With'
	| 'Ends With'
	| 'Contains'
	| 'Greater Than'
	| 'Greater Than Or Equal To'
	| 'Less Than'
	| 'Less Than Or Equal To'
	| 'Is Calculated';

export interface FilterItem {
	field: string;
	op: FilterOperator;
	val: string | number | boolean | null;
}

export type Filter = FilterItem[][];
export type FilterValidation = boolean[][];

export const FieldFilterOperators = {
	User: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
	],
	Approach: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
	],
	'Select List': [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
	],
	Text: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
		FilterOperators.StartsWith,
		FilterOperators.EndsWith,
		FilterOperators.Contains,
	],
	Content: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
		FilterOperators.StartsWith,
		FilterOperators.EndsWith,
		FilterOperators.Contains,
	],
	Integer: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
		FilterOperators.GreaterThan,
		FilterOperators.GreaterThanOrEqualTo,
		FilterOperators.LessThan,
		FilterOperators.LessThanOrEqualTo,
	],
	Decimal: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
		FilterOperators.GreaterThan,
		FilterOperators.GreaterThanOrEqualTo,
		FilterOperators.LessThan,
		FilterOperators.LessThanOrEqualTo,
	],
	'Yes/No': [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
	],
	Date: [
		FilterOperators.HasValue,
		FilterOperators.HasNoValue,
		FilterOperators.Equals,
		FilterOperators.NotEquals,
		FilterOperators.GreaterThan,
		FilterOperators.GreaterThanOrEqualTo,
		FilterOperators.LessThan,
		FilterOperators.LessThanOrEqualTo,
		FilterOperators.CalculatedAs,
	],
} as const;

export const FieldFilterOperatorDefault = {
	User: FilterOperators.Equals,
	Approach: FilterOperators.Equals,
	'Select List': FilterOperators.Equals,
	Text: FilterOperators.Equals,
	Content: FilterOperators.Contains,
	Integer: FilterOperators.Equals,
	Decimal: FilterOperators.Equals,
	'Yes/No': FilterOperators.Equals,
	Date: FilterOperators.CalculatedAs,
} as const;

export const parseFilterFromUrl = (search: string): Filter | undefined => {
	if (!search) return undefined;
	let params = new URLSearchParams(search);
	let filterRaw = params.get('filter');
	return filterRaw ? decodeFilter(filterRaw) : undefined;
};

export const decodeFilter = (value: string): Filter | undefined => {
	return JSON.parse(decodeURIComponent(value));
};

export const encodeFilter = (filter: Filter): string => {
	return encodeURIComponent(JSON.stringify(filter));
};

export const createPropertySearchFilter = (
	property: string,
	search: string
): Filter => {
	return [
		[
			{
				field: property,
				op: FilterOperators.StartsWith,
				val: search,
			},
		],
	];
};

export const addFilterAndCondition = (
	filter: Filter,
	item: FilterItem
): Filter => {
	const data = [...filter];
	data.push([item]);
	console.log('addFilterAndCondition', data);
	return data;
};

export const validateFilterItem = (
	definition: Record<string, FieldDefinition>,
	item: FilterItem
): boolean => {
	if (!item.field) return false;
	if (!item.op) return false;

	const def = Object.values(definition).find(
		(x) => x.property === item.field
	);
	if (!def) return false;

	if (def.type === FieldTypes.Text && !hasValue(item.val)) return false;
	if (def.type === FieldTypes.Content && !hasValue(item.val)) return false;
	if (def.type === FieldTypes.SelectList && !hasValue(item.val)) return false;
	if (def.type === FieldTypes.User && !hasValue(item.val)) return false;
	if (def.type === FieldTypes.Approach && !hasValue(item.val)) return false;
	if (def.type === FieldTypes.Date && !hasValue(item.val)) return false;

	if (def.type === FieldTypes.Integer && !isNumber(item.val)) return false;
	if (def.type === FieldTypes.Decimal && !isNumber(item.val)) return false;

	if (def.type === FieldTypes.YesNo && !hasBooleanValue(item.val))
		return false;

	return true;
};

export const validateFilters = (
	definition: Record<string, FieldDefinition>,
	filter: Filter | undefined
): FilterValidation => {
	if (!filter) return [];
	const validation: FilterValidation = [];
	filter.forEach((and) => {
		const ors: boolean[] = [];
		and.forEach((or) => {
			ors.push(validateFilterItem(definition, or));
		});
		validation.push(ors);
	});
	return validation;
};

export const isFilterValid = (validation: FilterValidation): boolean => {
	if (validation.length === 0) return false;
	return validation.every((a) => a.length > 0 && a.every((o) => o === true));
};

export const getValidFiltersUpTo = (
	definition: Record<string, FieldDefinition>,
	filter: Filter,
	conditionIndex: number,
	itemIndex: number
): Filter | undefined => {
	const data: Filter = [];
	let invalid = false;

	if (conditionIndex === 0 && itemIndex === 0) return undefined;

	forEach(filter, (and, cindex) => {
		const ors: FilterItem[] = [];

		// We only want previous valid filters
		if (cindex < conditionIndex) {
			forEach(and, (or, iindex) => {
				console.log(`Evaluating '${or.field}'`);
				if (iindex < itemIndex) {
					if (validateFilterItem(definition, or)) {
						console.log(`Adding '${or.field}' = '${or.val}'`);
						ors.push(or);
					} else {
						// exit for
						invalid = true;
						return false;
					}
				}
			});

			if (invalid === true) {
				// exit for
				return false;
			}

			if (ors.length > 0) {
				data.push(ors);
			}
		}
	});

	return data.length > 0 ? data : undefined;
};
