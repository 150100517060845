import * as React from 'react';

// Monorepo
import {
	ActionCard,
	ActionCardStages,
	ActionCardView,
	ChangeItem,
	Entity,
	FieldValue,
	getUTCDateNow,
	Group,
	toDateTimeString,
} from '@constituenthub/common';

// Lib
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { useActionCards } from './useActionCards';
import { useChangeItems } from '../changeitems/useChangeItems';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	actionId: number;
};

type State = {
	loaded: boolean;
	working: boolean;
	action: Partial<ActionCardView>;
	changeItem?: ChangeItem;
	group?: Group;
	error?: any;
};

const initialState: State = {
	loaded: false,
	working: false,
	action: { fields: {} },
};

export const useTaskCardController = ({ actionId }: Props) => {
	const { api } = useAppContext();
	const cardDefinition = useFieldDefinitions(Entity.ActionCard);
	const [state, setState] = React.useState<State>(initialState);
	const { findActionCard } = useActionCards();
	const { findChangeItem } = useChangeItems();

	const [actionFields, setActionFields] = React.useState<
		Record<string, FieldValue>
	>({});

	const [changeItemFields, setChangeItemFields] = React.useState<
		Record<string, FieldValue>
	>({});

	const handleActionCardChange = (property: string, value: FieldValue) => {
		if (property === cardDefinition.Stage.property) {
			let complete = actionFields.complete;
			if (value === ActionCardStages.Complete && !actionFields.complete) {
				complete = toDateTimeString(getUTCDateNow());
			}
			if (
				value !== ActionCardStages.Complete &&
				!!actionFields.complete
			) {
				complete = null;
			}
			setActionFields((s) => {
				const fields = { ...s };
				fields[property] = value;
				fields.complete = complete;
				return fields;
			});
			return;
		}

		if (property === cardDefinition.Complete.property) {
			let stage = actionFields.stage;
			let end = actionFields.end;

			if (!value && actionFields.stage === ActionCardStages.Complete) {
				stage = ActionCardStages.ToDo;
			}

			if (!!value) {
				stage = ActionCardStages.Complete;
			}

			if (!end && !!value) {
				end = value;
			}

			setActionFields((s) => {
				const fields = { ...s };
				fields[property] = value;
				fields.stage = stage;
				fields.end = end;
				return fields;
			});
			return;
		}

		setActionFields((s) => {
			const fields = { ...s };
			fields[property] = value;
			return fields;
		});
	};

	const handleChangeItemChange = (property: string, value: FieldValue) => {
		setChangeItemFields((s) => {
			const fields = { ...s };
			fields[property] = value;
			return fields;
		});
	};

	const handleResetError = () => {
		setState((s) => ({ ...s, error: undefined }));
	};

	const deleteActionCard = React.useCallback(async () => {
		// setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			await api.action.removeActionCard(actionId);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	}, [actionId, api.action]);

	const saveAdHocChanges = React.useCallback(async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));

		const actionUpdates: Partial<ActionCardView> = {
			...state.action,
			fields: {
				...actionFields,
			},
		};

		try {
			await api.action.updateActionCard(actionUpdates as ActionCard);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	}, [actionFields, api.action, state.action]);

	const saveChanges = React.useCallback(async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));

		const actionUpdates: Partial<ActionCardView> = {
			...state.action,
			fields: {
				...actionFields,
			},
		};

		const itemUpdates: Partial<ChangeItem> = {
			...state.changeItem,
			fields: {
				...changeItemFields,
			},
		};

		try {
			await Promise.all([
				api.action.updateActionCard(actionUpdates as ActionCard),
				api.changeitem.updateChangeItem(itemUpdates as ChangeItem),
			]);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	}, [
		actionFields,
		api.action,
		api.changeitem,
		changeItemFields,
		state.action,
		state.changeItem,
	]);

	React.useEffect(() => {
		findActionCard(actionId)
			.then((action) => {
				if (action) {
					setState((s) => ({ ...s, loaded: true, action }));
				} else {
					setState((s) => ({
						...s,
						loaded: true,
						error: new Error('Could not load action card'),
					}));
				}
			})
			.catch((error) => {
				setState((s) => ({ ...s, loaded: true, error }));
			});
	}, [findActionCard, actionId]);

	React.useEffect(() => {
		if (state.action.changeItemId && !state.changeItem) {
			findChangeItem(state.action.changeItemId)
				.then((changeItem) => {
					if (changeItem) {
						setState((s) => ({ ...s, loaded: true, changeItem }));
					} else {
						setState((s) => ({
							...s,
							loaded: true,
							error: new Error('Could not load change item'),
						}));
					}
				})
				.catch((error) => {
					setState((s) => ({ ...s, loaded: true, error }));
				});
		}
	}, [findActionCard, findChangeItem, state.action, state.changeItem]);

	React.useEffect(() => {
		setActionFields(state.action.fields || {});
	}, [state.action]);

	React.useEffect(() => {
		setChangeItemFields(state.changeItem?.fields || {});
	}, [state.changeItem]);

	return {
		error: state.error,
		loaded: state.loaded,
		working: state.working,
		action: state.action,
		changeItem: state.changeItem,
		group: state.group,
		actionFields,
		changeItemFields,
		onActionCardChange: handleActionCardChange,
		onChangeItemChange: handleChangeItemChange,
		handleResetError,
		saveChanges:
			!state.action.entity || state.action.entity === Entity.ActionCard
				? saveAdHocChanges
				: saveChanges,
		deleteActionCard,
	};
};
