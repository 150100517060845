// Lib
import { DataModel, DataModelDeletable, Entity } from '../common';
import { AccountAssociation, Account } from '../account/types';
import { FieldDefinition, FieldTypes } from '../fields';

/*
 * Represents the authenticated user
 */
export interface AuthUser {
	sub: string;
}

/*
 * Represents the user received from Cognito
 */
export interface CognitoUser {
	sub: string | null;
	username: string;
	status: string;
	attributes?: {
		Name: string | undefined;
		Value?: string;
	}[];
}

/*
 * Represents the user in the database
 */
export interface User {
	userId: string;
	email: string;
	phone: string;
	first: string;
	middle: string;
	last: string;
	img: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
}

export interface UserRole extends DataModel, DataModelDeletable {
	roleId: number;
	name: string;
}

/**
 * Represents the account in current user's context
 */
export interface UserAccountContext {
	user: User;
	account: Account;
	accounts: AccountAssociation[];
	permissions: string[];
}

/**
 * Represents the selected project in current user's context
 */
export interface UserProjectContext {
	permissions: string[];
}

/**
 * Create user request
 */
export interface AddUserRequest {
	user: Partial<User>;
	temp: string;
	roleId: number;
	accountId: number;
}

export const UserFieldDefinitions: Record<string, FieldDefinition> = {
	Email: {
		entity: Entity.User,
		property: 'email',
		label: 'Email Address',
		type: FieldTypes.Text,
		system: true,
	},
	Phone: {
		entity: Entity.User,
		property: 'phone',
		label: 'Phone Number',
		type: FieldTypes.Text,
		system: true,
	},
	First: {
		entity: Entity.User,
		property: 'first',
		label: 'First Name',
		type: FieldTypes.Text,
		system: true,
	},
	Middle: {
		entity: Entity.User,
		property: 'middle',
		label: 'Middle Name',
		type: FieldTypes.Text,
		system: true,
	},
	Last: {
		entity: Entity.User,
		property: 'last',
		label: 'Last Name',
		type: FieldTypes.Text,
		system: true,
	},
};
