import * as React from 'react';

// Monorepo
import {
	FieldDefinition,
	// createPropertySearchFilter,
} from '@constituenthub/common';

// Components
import FieldTextAutoComplete from './FieldTextAutoComplete';
import { TextField, SelectOption, errorOption } from '../common';
// import { useAppContext } from '../../contexts/AppContext';
// import { ApplicationEvent } from '../../lib/ApplicationEvent';
// import { orderBy } from 'lodash';

type FieldTextProps = {
	entity: string;
	definition: FieldDefinition;
	label: string;
	value: string;
	onChange: (value: string | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	placeholder?: string;
};

// type OptionsState = {
// 	options: SelectOption[];
// 	loaded: boolean;
// };

export const FieldText = (props: FieldTextProps) => {
	// const { api } = useAppContext();
	const {
		entity,
		definition,
		label,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
		placeholder,
	} = props;

	// const [optionsState, setOptionsState] = React.useState<OptionsState>({
	// 	options: [],
	// 	loaded: false,
	// });

	// const loadOptions = React.useCallback(
	// 	async (prop: string, ent: string) => {
	// 		try {
	// 			const items = await api.fieldvalue.uniqueValues(ent, prop);
	// 			const data = items.map((x) => ({ label: `${x}`, value: x }));
	// 			setOptionsState({ options: data, loaded: true });
	// 		} catch (error) {
	// 			setOptionsState({ options: [errorOption], loaded: true });
	// 		}
	// 	},
	// 	[api.fieldvalue]
	// );

	// React.useEffect(() => {
	// 	if (!readOnly && definition.autoComplete === true) {
	// 		loadOptions(definition.property, entity);
	// 	}
	// }, [
	// 	definition.property,
	// 	entity,
	// 	readOnly,
	// 	definition.autoComplete,
	// 	loadOptions,
	// ]);

	// const onOptionAdded = React.useCallback(
	// 	(option: SelectOption) => {
	// 		if (
	// 			option &&
	// 			optionsState.loaded &&
	// 			!optionsState.options.map((x) => x.value).includes(option.value)
	// 		) {
	// 			setOptionsState((s) => ({
	// 				...s,
	// 				options: orderBy(
	// 					[...optionsState.options, option],
	// 					['label'],
	// 					['asc']
	// 				),
	// 			}));
	// 		}
	// 	},
	// 	[optionsState.loaded, optionsState.options]
	// );

	// React.useEffect(() => {
	// 	ApplicationEvent.Receive<SelectOption>(
	// 		`field-option-added-${definition.entity}-${definition.property}`,
	// 		(event) => {
	// 			if (event.payload.data) onOptionAdded(event.payload.data);
	// 		}
	// 	);
	// }, [definition.entity, definition.property, onOptionAdded]);

	if (!readOnly && definition.autoComplete === true) {
		return (
			<FieldTextAutoComplete
				label={label}
				value={value}
				onChange={onChange}
				error={error}
				required={required}
				disabled={disabled}
				placeholder={placeholder}
				entity={entity}
				definition={definition}
				readOnly={readOnly}
				mixedValue={mixedValue}
			/>
		);
	}

	return (
		<TextField
			label={label}
			value={value}
			onChange={onChange}
			error={error}
			required={required}
			disabled={disabled}
			readOnly={readOnly}
			mixedValue={mixedValue}
			placeholder={placeholder}
		/>
	);
};
