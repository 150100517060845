import React from 'react';
import MuiTextField from '@mui/material/TextField';

// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import OutlinedInput from '@mui/material/OutlinedInput';

import { useTheme } from '@mui/material/styles';
import { lighten, SxProps, Theme } from '@mui/system';

// Monorepo
import { MixedFieldContent } from '@constituenthub/common';

type EditorProps = {
	label: string;
	initialValue: any;
	onChange: (value: any) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	placeholder?: string;
	sx?: SxProps<Theme>;
};

export const Editor = (props: EditorProps) => {
	const theme = useTheme();
	const {
		label,
		initialValue,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
		placeholder,
		sx = {},
	} = props;

	return (
		<MuiTextField
			fullWidth
			size="small"
			margin="dense"
			variant="outlined"
			autoComplete="off"
			onFocus={(event) => event.target.select()}
			InputLabelProps={{ shrink: true }}
			InputProps={{ readOnly, sx }}
			label={label}
			placeholder={mixedValue ? MixedFieldContent : placeholder}
			error={error}
			disabled={disabled}
			required={required}
			value={initialValue || ''}
			onChange={(event) => onChange(event.target.value)}
			multiline
			minRows={5}
			sx={{
				backgroundColor: mixedValue
					? lighten(theme.palette.primary.main, 0.9)
					: 'initial',
				// '& .MuiInputBase-root': {
				// 	position: 'relative',
				// 	height: '100%',
				// 	flexGrow: 1,
				// 	// overflowY: 'auto',
				// },
				// '& textarea': {
				// 	position: 'relative',
				// 	height: '100%',
				// 	flexGrow: 1,
				// 	overflowY: 'auto',
				// },
				...sx,
			}}
			inputProps={
				{
					// sx: {
					// 	position: 'relative',
					// 	height: '100%',
					// 	flexGrow: 1,
					// 	// overflowY: 'auto',
					// },
				}
			}
		/>
	);
};
