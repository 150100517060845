// import Joi from 'joi';
import {
	Account,
	ActionCard,
	FieldsModel,
	FieldTypes,
	ProjectDataModel,
} from '..';
import { AccountDataModel, DataModelDeletable, Entity } from '../common';
import { FieldDefinition } from '../fields';

export interface Approach extends AccountDataModel, DataModelDeletable {
	approachId: number;
	name: string;
	description: string;
	position: number;
}

export interface JobRoleApproach
	extends AccountDataModel,
		ProjectDataModel,
		DataModelDeletable,
		FieldsModel {
	jobRoleApproachId: number;
	jobRoleId: number;
	changeItemId: number;
	groupId: number;
	approachId: number;
	actionId: number;
}

export interface JobRoleApproachAction extends JobRoleApproach {
	action: ActionCard;
}

// export const projectSchema = Joi.object({
//     name: Joi.string().alphanum().min(3).max(200).required(),
//     description: Joi.string().max(30),
//     favorite: Joi.bool().required(),
//     palette: Joi.string().required(),
//     hue: Joi.string().required(),
// });

export const ApproachFieldDefinitions: Record<string, FieldDefinition> = {
	Name: {
		entity: Entity.Approach,
		property: 'name',
		label: 'Name',
		type: FieldTypes.Text,
		system: true,
	},
	Description: {
		entity: Entity.Approach,
		property: 'description',
		label: 'Description',
		type: FieldTypes.Content,
		system: true,
	},
};

export const JobRoleApproachFieldDefinitions: Record<string, FieldDefinition> =
	{
		Result: {
			entity: Entity.JobRoleApproach,
			property: 'result',
			label: 'Result',
			type: FieldTypes.Content,
			system: true,
		},
	};
