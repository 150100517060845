import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import { flatten, uniq } from 'lodash';

// Icons
import { mdiDotsHorizontalCircleOutline } from '@mdi/js';

// Monorepo
import { FilterOperators } from '@constituenthub/common';

// Components
import { ButtonFieldList, ListOption } from '../common/ButtonFieldList';

// Lib
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	caption: string;
	entity: string;
	property: string;
	value: string | null;
	multiselect?: boolean;
	mixedValue?: boolean;
	onChange: (value: string) => void;
	sx?: SxProps<Theme>;
};

export const ButtonFieldValues = ({
	caption,
	entity,
	property,
	value,
	multiselect,
	mixedValue,
	onChange,
	sx = {},
}: Props) => {
	const { api } = useAppContext();
	const [options, setOptions] = React.useState<ListOption[]>([]);

	const loadValues = React.useCallback(
		async (ent: string, prop: string) => {
			try {
				const filter = [
					[
						{
							field: prop,
							op: FilterOperators.HasValue,
							val: '',
						},
					],
				];
				const results = (await api.fieldvalue.uniqueValues(
					ent,
					prop,
					filter
				)) as string[];
				if (multiselect) {
					const splits = uniq(
						flatten(results.map((result) => result.split(','))).map(
							(x) => x.trim()
						)
					);
					const items = splits.map((item) => ({
						value: item,
						primary: item,
					}));
					setOptions(items);
				} else {
					const items = results.map((item) => ({
						value: item,
						primary: item,
					}));
					setOptions(items);
				}
			} catch (error) {
				setOptions([{ primary: 'Error loading values', value: null }]);
			}
		},
		[api.fieldvalue, multiselect]
	);

	React.useEffect(() => {
		loadValues(entity, property);
	}, [loadValues, entity, property]);

	return (
		<ButtonFieldList
			caption={caption}
			primary={value || 'Select a value'}
			onChange={(value) => onChange(value as string)}
			icon={mdiDotsHorizontalCircleOutline}
			value={value}
			mixedValue={mixedValue}
			options={options}
			sx={sx}
			multiselect={multiselect}
			addValuePlaceholder="Add value"
		/>
	);
};
