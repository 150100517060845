import { User } from '@constituenthub/common';
import * as React from 'react';
import { useAppContext } from '../contexts/AppContext';
import { useAppSelector } from '../store';
import { selectUsers } from '../store/application';

export const useUser = (userId?: string) => {
	const { api } = useAppContext();
	const [user, setUser] = React.useState<User>();
	const users = useAppSelector(selectUsers);

	const getUser = React.useCallback(
		async (id: string) => {
			const found = users.find((x) => x.userId === id);
			if (found) {
				setUser(found);
			} else {
				if (id.length !== 36) {
					setUser(undefined);
				} else {
					try {
						const result = await api.user.get(id);
						setUser(result);
					} catch (error) {
						setUser(undefined);
					}
				}
			}
		},
		[api.user, users]
	);

	React.useEffect(() => {
		if (users.length > 0 && userId) {
			getUser(userId);
		} else {
			setUser(undefined);
		}
	}, [getUser, userId, users]);

	return { user };
};
