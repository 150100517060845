import React from 'react';

// Components
import { HeaderRenderer } from '../HeaderRenderer';
import { DateRenderer } from './DateRenderer';

// Lib
import {
	DataColumn,
	RendererApi,
	CellRenderProps,
	HeaderRenderProps,
	SortDirection,
} from '../../types';

export const DateRendererName = 'Date Renderer';

export interface Options extends DataColumn {
	onToggleSort?: (direction: SortDirection) => void;
}

export interface TextRendererApi extends RendererApi {}

export const useDateRenderer = (options: Options): TextRendererApi => {
	const { field, label, width, sort } = options;

	const renderHeader = (props: HeaderRenderProps) => {
		return (
			<HeaderRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				onToggleSort={options.onToggleSort}
				{...props}
				align="right"
			/>
		);
	};

	const renderCell = (props: CellRenderProps) => {
		return (
			<DateRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				{...props}
			/>
		);
	};

	return {
		name: DateRendererName,
		type: 'Data',
		field,
		label,
		width,
		sort,
		renderHeader,
		renderCell,
	};
};
