import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';

// Monorepo
import { Note } from '@constituenthub/common';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { ErrorMessage, Loading } from '../common';

// Lib
import { AddTextValue } from '../common/AddTextValue';

type Props = {
	parentId?: number;
	entity?: string;
	onNoteAdded: (note: Note) => void;
	sx?: SxProps<Theme>;
	disabled?: boolean;
};

type State = {
	working: boolean;
	error?: any;
};

const initialState: State = {
	working: false,
};

export const AddNote = ({
	entity,
	parentId,
	onNoteAdded,
	sx = {},
	disabled,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);

	const handleAddNote = async (content: string | null) => {
		if (entity && parentId && content) {
			setState((s) => ({ ...s, working: true }));
			try {
				const note = { content };
				const result = await api.note.createNote(
					entity,
					parentId,
					note
				);
				onNoteAdded(result);
				setState((s) => ({ ...s, working: false }));
			} catch (error) {
				console.error(error);
				setState((s) => ({ ...s, working: false, error }));
			}
		}
	};

	if (!entity || !parentId) {
		console.log('AddNote returning null');
		return null;
	}

	return (
		<Box
			sx={{
				// position: 'relative',
				// display: 'flex',
				// flexDirection: 'column',
				// flexGrow: 1,
				pr: 1,
				...sx,
			}}
		>
			<AddTextValue
				placeholder="Add Note"
				onAddValue={handleAddNote}
				multiline
				disabled={disabled}
				sx={{
					display: !state.working && !state.error ? 'flex' : 'none',
				}}
			/>
			<ErrorMessage
				error={state.error}
				onRetry={() => setState((s) => ({ ...s, error: undefined }))}
			/>
			<Loading enabled={state.working} text="Working..." />
		</Box>
	);
};
