/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, Auth } from 'aws-amplify';

export interface ApiClientResponse<T> {
	method: string;
	path: string;
	status: number;
	statusText: string;
	request: any;
	headers: any;
	result: T;
}

export interface ApiClientInterface {
	setAccountHeader: (accountId: number) => void;
	setProjectHeader: (projectId: number) => void;

	get: <TResult>(url: string) => Promise<ApiClientResponse<TResult>>;

	post: <TBody, TResult>(
		url: string,
		body: TBody
	) => Promise<ApiClientResponse<TResult>>;

	put: <TBody, TResult>(
		url: string,
		body: TBody
	) => Promise<ApiClientResponse<TResult>>;

	patch: <TBody, TResult>(
		url: string,
		body: TBody
	) => Promise<ApiClientResponse<TResult>>;

	del: <TResult, TBody = undefined>(
		url: string,
		body?: TBody
	) => Promise<ApiClientResponse<TResult>>;
}

/*
 * Handles the AXIOS error response
 */
export class ApiClientResponseError extends Error {
	public message: string;

	public method: string;

	public path: string;

	public request: any;

	public status: number;

	public headers: any;

	constructor(response: any) {
		super();
		Object.setPrototypeOf(this, new.target.prototype);
		Error.captureStackTrace(this);
		console.log('ApiClientResponseError', response);
		this.name = 'ApiClientResponseError';
		this.message = response?.data
			? response.data.message
			: response.statusText || 'Unknown Error';
		this.status = response?.data?.status || response?.status || 500;
		this.method = response?.config?.method || 'ANY';
		this.path = response?.config?.url;
		this.request = response?.config?.data;
		this.headers = response?.headers;
	}
}

const getResponseResult = <TResult>(
	response: any
): ApiClientResponse<TResult> => {
	if (!response) throw new Error('Invalid response');
	return {
		method: response.config.method,
		path: response.config.path,
		status: response.status,
		statusText: response.statusText,
		request: response.config.data,
		headers: response.config.headers,
		result: response.data as TResult,
	};
};

export default class ApiClient implements ApiClientInterface {
	private apiName: string;

	private prefix: string;

	private accountId: number;

	private projectId: number;

	constructor(apiName: string, prefix: string) {
		this.apiName = apiName;
		this.prefix = prefix;
		this.accountId = 0;
		this.projectId = 0;
	}

	private async getHeaders() {
		const token = `Bearer ${(await Auth.currentSession())
			.getIdToken()
			.getJwtToken()}`;
		// console.log('Authorization: ', token);
		return {
			Authorization: token,
			ChangeImpactPlusAccountId: `${this.accountId}`,
			ChangeImpactPlusProjectId: `${this.projectId}`,
		};
	}

	public setAccountHeader(accountId: number) {
		this.accountId = accountId;
	}

	public setProjectHeader(projectId: number) {
		this.projectId = projectId;
	}

	public async get<TResult>(
		url: string
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.get(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			throw new ApiClientResponseError((error as any)?.response);
			// const err = error as Error;
			// console.error(`ApiClient:GET(${url})`, err);
			// const apiError = new ApiClientResponseError(
			// 	err.message,
			// 	'get',
			// 	url,
			// 	null,
			// 	headers,
			// 	err
			// );
			// throw apiError;
		}
	}

	public async post<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.post(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			throw new ApiClientResponseError((error as any)?.response);
			// const err = error as Error;
			// console.error(`ApiClient:POST(${url})`, (error as any)?.response);
			// const apiError = new ApiClientResponseError(
			// 	err.message,
			// 	'post',
			// 	url,
			// 	null,
			// 	headers,
			// 	err
			// );
			// throw apiError;
		}
	}

	public async put<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.put(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			throw new ApiClientResponseError((error as any)?.response);
			// const err = error as Error;
			// console.error(`ApiClient:PUT(${url})`, err);
			// const apiError = new ApiClientResponseError(
			// 	err.message,
			// 	'put',
			// 	url,
			// 	null,
			// 	headers,
			// 	err
			// );
			// throw apiError;
		}
	}

	public async patch<TBody, TResult>(
		url: string,
		body: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.patch(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			throw new ApiClientResponseError((error as any)?.response);
			// const err = error as Error;
			// console.error(`ApiClient:PUT(${url})`, err);
			// const apiError = new ApiClientResponseError(
			// 	err.message,
			// 	'put',
			// 	url,
			// 	null,
			// 	headers,
			// 	err
			// );
			// throw apiError;
		}
	}

	public async del<TResult, TBody>(
		url: string,
		body?: TBody
	): Promise<ApiClientResponse<TResult>> {
		const headers = await this.getHeaders();
		try {
			const response = await API.del(
				this.apiName,
				`/${this.prefix}${url}`,
				{
					response: true,
					headers,
					body,
				}
			);
			return getResponseResult<TResult>(response);
		} catch (error) {
			throw new ApiClientResponseError((error as any)?.response);
			// const err = error as Error;
			// console.error(`ApiClient:DEL(${url})`, err);
			// const apiError = new ApiClientResponseError(
			// 	err.message,
			// 	'del',
			// 	url,
			// 	null,
			// 	headers,
			// 	err
			// );
			// throw apiError;
		}
	}
}

export const ApplicationApiClient = new ApiClient('api', 'api');
