import React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';

// Monorepo
import { MixedFieldContent } from '@constituenthub/common';

type TextFieldProps = {
	label: string;
	value?: string | null;
	onChange: (value: string | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	multiline?: number;
	placeholder?: string;
};

export const TextField = (props: TextFieldProps) => {
	const theme = useTheme();

	const {
		label,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
		multiline,
		placeholder,
	} = props;

	return (
		<MuiTextField
			fullWidth
			size="small"
			margin="dense"
			variant="outlined"
			autoComplete="off"
			onFocus={(event) => event.target.select()}
			InputLabelProps={{ shrink: true }}
			InputProps={{ readOnly }}
			label={label}
			placeholder={mixedValue ? MixedFieldContent : placeholder}
			error={error}
			disabled={disabled}
			required={required}
			value={value || ''}
			onChange={(event) => onChange(event.target.value)}
			multiline={!!multiline && multiline > 0}
			minRows={!!multiline ? multiline : undefined}
			sx={{
				backgroundColor: mixedValue
					? lighten(theme.palette.primary.main, 0.9)
					: 'initial',
			}}
		/>
	);
};
