import { Hub } from 'aws-amplify';

const invalidChannels = [
	'core',
	'auth',
	'api',
	'analytics',
	'interactions',
	'pubsub',
	'storage',
	'xr',
	'datastore',
];
export declare type EventEnvelope<T> = {
	channel: string;
	payload: EventPayload<T>;
	source: string;
	patternInfo?: string[];
};

export declare type EventPayload<T> = {
	event: string;
	data?: T;
	message?: string;
};

export type Listener<T> = (data: EventEnvelope<T>) => void;

export class ApplicationEvent {
	private static validateChannel(event: string): void {
		if (invalidChannels.includes(event)) {
			throw new Error(`The channel '${event}' is reserved`);
		}
	}

	public static Send<T>(event: string, data: T) {
		ApplicationEvent.validateChannel(event);
		Hub.dispatch(event, { event, data });
	}

	public static Receive<T>(channel: string, listener: Listener<T>) {
		Hub.listen(channel, listener);
	}

	//** Dispatchers */

	public static NewEntity<T>(entity: string, data: T) {
		const event = `${entity}-created`;
		Hub.dispatch(event, { event, data, message: 'created' });
	}

	public static NewMultipleEntities<T>(entity: string, data: T[]) {
		const event = `multiple-${entity}-created`;
		Hub.dispatch(event, { event, data, message: 'created' });
	}

	public static UpdateEntity<T>(entity: string, data: T) {
		const event = `${entity}-updated`;
		Hub.dispatch(event, { event, data, message: 'updated' });
	}

	public static UpdateMultipleEntities<T>(entity: string, data: T[]) {
		const event = `multiple-${entity}-updated`;
		Hub.dispatch(event, { event, data, message: 'updated' });
	}

	public static RemoveEntity<T>(entity: string, data: T) {
		const event = `${entity}-removed`;
		Hub.dispatch(event, { event, data, message: 'removed' });
	}

	public static RemoveMultipleEntities<T>(entity: string, data: T[]) {
		const event = `multiple-${entity}-removed`;
		Hub.dispatch(event, { event, data, message: 'removed' });
	}

	//** Listeners */

	public static EntityCreated<T>(entity: string, listener: Listener<T>) {
		const event = `${entity}-created`;
		Hub.listen(event, listener);
	}

	public static MultipleEntityCreated<T>(
		entity: string,
		listener: Listener<T>
	) {
		const event = `multiple-${entity}-created`;
		Hub.listen(event, listener);
	}

	public static EntityChanged<T>(entity: string, listener: Listener<T>) {
		const event = `${entity}-updated`;
		Hub.listen(event, listener);
	}

	public static MultipleEntityChanged<T>(
		entity: string,
		listener: Listener<T>
	) {
		const event = `multiple-${entity}-updated`;
		Hub.listen(event, listener);
	}

	public static EntityRemoved<T>(entity: string, listener: Listener<T>) {
		const event = `${entity}-removed`;
		Hub.listen(event, listener);
	}

	public static MultipleEntityRemoved<T>(
		entity: string,
		listener: Listener<T>
	) {
		const event = `multiple-${entity}-removed`;
		Hub.listen(event, listener);
	}
}
