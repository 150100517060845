import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type ProgressProps = {
	show: boolean;
	total: number;
	current: number;
};

export const Progress = (props: ProgressProps) => {
	const { show, total, current } = props;

	if (!show) return null;

	const value = total > 0 ? Math.round((current / total) * 100) : 0;

	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress variant="determinate" value={value} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography
					variant="caption"
					component="div"
					color="textSecondary"
				>
					{value}%
				</Typography>
			</Box>
		</Box>
	);
};
