import React from 'react';

// Monorepo
import {
	Entity,
	User,
	UserFieldDefinitions,
	FieldValue,
	validateEmail,
	validatePhoneNumber,
} from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';
import { FlexColumn, FlexRow } from '../common';

type Props = {
	user: Partial<User>;
	onChange: (data: Partial<User>) => void;
};

export const ProfileForm = ({ user, onChange }: Props) => {
	const handleChange = (prop: string, value: FieldValue) => {
		onChange({ ...user, [prop]: value });
	};

	return (
		<FlexColumn>
			<FlexRow>
				<FlexRow sx={{ flexGrow: 1, mx: 1, my: 1 }}>
					<Field
						entity={Entity.User}
						required
						definition={UserFieldDefinitions.Email}
						value={user.email}
						onChange={(value: FieldValue) =>
							handleChange('email', value)
						}
						error={!validateEmail(user.email)}
					/>
				</FlexRow>
				<FlexRow sx={{ flexGrow: 1, mx: 1, my: 1 }}>
					<Field
						entity={Entity.User}
						required
						definition={UserFieldDefinitions.Phone}
						value={user.phone}
						onChange={(value: FieldValue) =>
							handleChange('phone', value)
						}
						error={!validatePhoneNumber(user.phone)}
					/>
				</FlexRow>
			</FlexRow>
			<FlexRow>
				<FlexRow sx={{ flexGrow: 1, mx: 1, my: 1 }}>
					<Field
						entity={Entity.User}
						required
						definition={UserFieldDefinitions.First}
						value={user.first}
						onChange={(value: FieldValue) =>
							handleChange('first', value)
						}
						error={!user.first}
						sx={{ flexGrow: 1 }}
					/>
				</FlexRow>
				<FlexRow sx={{ flexGrow: 1, mx: 1, my: 1 }}>
					<Field
						entity={Entity.User}
						definition={UserFieldDefinitions.Middle}
						value={user.middle}
						onChange={(value: FieldValue) =>
							handleChange('middle', value)
						}
						sx={{ flexGrow: 1 }}
					/>
				</FlexRow>
				<FlexRow sx={{ flexGrow: 1, mx: 1, my: 1 }}>
					<Field
						entity={Entity.User}
						required
						definition={UserFieldDefinitions.Last}
						value={user.last}
						onChange={(value: FieldValue) =>
							handleChange('last', value)
						}
						error={!user.last}
						sx={{ flexGrow: 1 }}
					/>
				</FlexRow>
			</FlexRow>
		</FlexColumn>
	);
};
