import React, { useState, useEffect } from 'react';
import { SxProps } from '@mui/system';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import { useDebounce } from 'react-use';

// Monorepo
import {
	Project,
	Flag,
	getProjectTheme,
	PaletteName,
	Hue,
} from '@constituenthub/common';

// Components
import { ErrorMessage, Loading, IconButton } from '../common';
import { ProjectForm } from './ProjectForm';
import { useAppContext } from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import { mdiClose } from '@mdi/js';
import baseTheme from '../shell/baseTheme';

type ProjectDetailProps = {
	projectId: number;
	onProjectUpdated: (project: Project) => void;
	onClose?: () => void;
	sx?: SxProps<Theme>;
};

export const ProjectDetail = (props: ProjectDetailProps) => {
	const { projectId, onProjectUpdated, onClose } = props;
	const { api } = useAppContext();
	// const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const selectedTabIndex = 0;
	const [project, setProject] = useState<Project>();
	const [changes, setChanges] = useState<Partial<Project>>({});
	const [flags, setFlags] = useState<Flag[]>([]);
	const [error, setError] = useState<Error>();

	const updateProject = async () => {
		try {
			if (project && Object.keys(changes).length > 0) {
				const data = await api.project.updateProject({
					...project,
					...changes,
				});
				setProject(data);
				onProjectUpdated(data);
				toast(`${data.name} Updated`);
			}
		} catch (err) {
			setError(err as Error);
		}
	};

	const loadProject = async () => {
		try {
			const data = await api.project.getProject(projectId);
			setProject(data);
			setChanges({});
			if (error) {
				setError(undefined);
			}
		} catch (err) {
			setError(err as Error);
		}
	};

	useDebounce(updateProject, 1000, [changes]);

	useEffect(() => {
		loadProject();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectId]);

	const projectTheme = getProjectTheme(
		project?.palette as PaletteName,
		project?.hue as Hue
	) as Theme;

	return (
		<ThemeProvider
			theme={createTheme({ ...baseTheme, ...projectTheme } as Theme)}
		>
			<AppBar position="relative">
				<Toolbar
					variant="dense"
					sx={{
						position: 'relative',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '100%',
					}}
				>
					<span style={{ flexGrow: 1 }}>
						{project?.name || 'Loading...'}
					</span>
					{!!onClose && (
						<IconButton
							onClick={onClose}
							path={mdiClose}
							title="Close"
							variant="small"
							edge="end"
							color={projectTheme.palette.primary.contrastText}
						/>
					)}
				</Toolbar>
			</AppBar>
			{/* <Tabs
				value={selectedTabIndex}
				onChange={(_event, index) => setSelectedTabIndex(index)}
				aria-label="Project Details"
			>
				<Tab label="Details" />
				<Tab label="Approaches" />
				<Tab label="Groups" />
			</Tabs> */}
			<Grid container direction="column" flexGrow={1} sx={{ p: 2 }}>
				<ErrorMessage error={error} />
				{!error && !project && (
					<Loading enabled={true} text="Loading" />
				)}
				{!error && !!project && (
					<>
						{selectedTabIndex === 0 && (
							<ProjectForm
								project={{ ...project, ...changes }}
								flags={flags}
								onChange={setChanges}
								onFlagsChange={setFlags}
							/>
						)}
						{/* {selectedTabIndex === 1 && (
							<NothingHere>
								<Typography variant="caption">None</Typography>
							</NothingHere>
						)}
						{selectedTabIndex === 2 && (
							<NothingHere>
								<Typography variant="caption">None</Typography>
							</NothingHere>
						)} */}
					</>
				)}
			</Grid>
		</ThemeProvider>
	);
};
