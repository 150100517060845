import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system'

// Lib
import { CellPlugin } from "../../types";

export const useRowHoverPlugin = (): CellPlugin => {
    const theme = useTheme();
    const [index, setIndex] = useState<number>();

    return {
        getCellStyle: (style, props) => {
            let ns = { ...style };
            if (props.rowIndex === index) {
                ns.backgroundColor = lighten(theme.palette.primary.main, 0.9);
            }
            return ns;
        },
        onMouseOver: (event, props) => {
            setIndex(props.rowIndex);
        },
        onMouseOut: (event, props) => {
            setIndex(undefined);
        }
    }
}