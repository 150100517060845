import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { get } from 'lodash';

// Icons
import Icon from '@mdi/react';
import { mdiChevronRight, mdiPlusCircleOutline } from '@mdi/js';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps, CellProps } from '../../types';

interface Props extends CellRenderProps {
	isEditing: boolean;
	editValue: string;
	onChange: (props: CellProps, value: string) => void;
	onComplete: (
		props: CellProps,
		value: string,
		keyboardEvent: React.KeyboardEvent<HTMLDivElement> | null
	) => void;
	onCancelEdit: (props: CellProps) => void;
	onAddClick?: (props: CellProps) => void;
	onDetailClick?: (props: CellProps) => void;
	onDoubleClick?: (
		event: React.MouseEvent<HTMLDivElement>,
		props: CellProps
	) => void;
	inputRef: React.RefObject<HTMLInputElement>;
}

const noop = () => {};

const Input = styled('input')(({ theme }) => ({
	marginLeft: theme.spacing(-1),
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	outline: 'none',
	border: `1px solid ${theme.palette.primary.main}`,
	fontSize: theme.typography.body2.fontSize,
	width: '100%',
}));

export const TextRenderer = (props: Props) => {
	const {
		isEditing,
		onChange,
		onComplete,
		onCancelEdit,
		onAddClick,
		onDetailClick,
		onDoubleClick,
		inputRef,
		editValue,
	} = props;

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' || event.key === 'Tab') {
			onComplete(props, editValue, event);
		}
		if (event.key === 'Escape') {
			onCancelEdit(props);
		}
	};

	useEffect(() => {
		if (isEditing && inputRef.current) {
			inputRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	if (props.getId() === -1 && onAddClick) {
		return (
			<CellBase
				{...props}
				style={props.style}
				onClick={() => onAddClick(props)}
			>
				<Button
					startIcon={<Icon path={mdiPlusCircleOutline} size={0.75} />}
				>
					Add Item
				</Button>
			</CellBase>
		);
	}

	return (
		<CellBase
			onKeyUp={isEditing ? noop : props.api.onKeyUp}
			onKeyDown={isEditing ? noop : props.api.onKeyDown}
			onKeyPress={isEditing ? noop : props.api.onKeyPress}
			onClick={isEditing ? noop : props.api.onClick}
			onFocus={isEditing ? noop : props.api.onFocus}
			onBlur={isEditing ? noop : props.api.onBlur}
			onDoubleClick={
				isEditing
					? noop
					: !!onDoubleClick
					? (e) => onDoubleClick(e, props)
					: noop
			}
			{...props}
			style={props.style}
		>
			{!isEditing && (
				<>
					<span
						style={{
							flexGrow: 1,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{get(props.row, props.column.field, '')}
					</span>
					{!!onDetailClick && (
						<IconButton
							size="small"
							edge="end"
							onClick={() => onDetailClick(props)}
						>
							<Icon path={mdiChevronRight} size={1} />
						</IconButton>
					)}
				</>
			)}
			{!!isEditing && (
				<Input
					ref={inputRef}
					type="text"
					value={editValue}
					onFocus={(e) => {
						e.target.select();
						if (inputRef.current) {
							inputRef.current.focus();
						}
					}}
					onChange={(e) =>
						onChange ? onChange(props, e.target.value) : {}
					}
					onBlur={(e) => onComplete(props, editValue, null)}
					onKeyDown={handleKeyDown}
				/>
			)}
		</CellBase>
	);
};
