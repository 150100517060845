import { ChangeItem } from '@constituenthub/common';
import { useAppContext } from '../../contexts/AppContext';
import { useAppSelector } from '../../store';
import { selectItems } from '../../store/items';

export const useChangeItems = () => {
	const { api } = useAppContext();
	const changeItems = useAppSelector(selectItems);

	const findChangeItem = async (
		changeItemId: number
	): Promise<ChangeItem | undefined> => {
		const changeItem = changeItems.find(
			(x) => x.changeItemId === changeItemId
		);
		if (changeItem) return changeItem;

		try {
			return api.changeitem.getChangeItem(changeItemId);
		} catch (error) {
			console.error(error);
			return undefined;
		}
	};

	return {
		changeItems,
		findChangeItem,
	};
};
