import * as React from 'react';
import Button from '@mui/material/Button';
import { Divider, useTheme } from '@mui/material';

import {
	mdiCalendar,
	mdiCalendarBlank,
	mdiCalendarCheck,
	mdiCalendarClock,
	mdiOpenInNew,
	mdiViewColumn,
	mdiDotsHorizontalCircleOutline,
} from '@mdi/js';

// Monorepo
import {
	ActionCard,
	ActionCardStages,
	Entity,
	FieldValue,
	formatDateTime,
	getUTCDateNow,
	toDateTimeString,
} from '@constituenthub/common';

// Components
import {
	ModalDialog,
	ErrorMessage,
	Loading,
	FlexRow,
	FlexColumn,
} from '../common';

import { ButtonFieldDate } from '../common/ButtonFieldDate';
import { ButtonFieldList } from '../common/ButtonFieldList';
import { ButtonFieldApproach } from '../approaches/ButtonFieldApproach';
import { ButtonFieldValues } from '../fields/ButtonFieldValues';

// Lib
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { ButtonFieldUsers } from '../fields/ButtonFieldUsers';
import { TaskForm } from './TaskForm';
import { useAppContext } from '../../contexts/AppContext';

const NoValueText = 'Select a value';

type Props = {
	title?: string;
	children?: React.ReactNode;
	defaults?: Record<string, FieldValue>;
	bypassCreate: boolean;
	onClose: () => void;
	onContinue: (card: Partial<ActionCard>) => Promise<any>;
};

type State = {
	loaded: boolean;
	working: boolean;
	action: Partial<ActionCard>;
	error?: any;
};

const initialState: State = {
	loaded: false,
	working: false,
	action: { fields: {} },
};

export const AddTaskDialog = ({
	title = 'Add Action Card',
	children,
	defaults = {},
	bypassCreate,
	onClose,
	onContinue,
}: Props) => {
	const theme = useTheme();
	const { api, user } = useAppContext();
	const cardDefinition = useFieldDefinitions(Entity.ActionCard);
	const [state, setState] = React.useState<State>(initialState);
	const [actionFields, setActionFields] = React.useState<
		Record<string, FieldValue>
	>({
		...defaults,
		stage: defaults.stage || ActionCardStages.ToDo,
		assignedto: defaults.assignedto || user?.userId,
	});

	const handleAction = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			if (bypassCreate !== true) {
				const action = await api.action.createActionCard({
					fields: actionFields,
					entity: Entity.ActionCard,
				});
				return onContinue(action);
			} else {
				return onContinue({
					fields: actionFields,
				});
			}
		} catch (error) {
			console.error(error);
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const onActionCardChange = (property: string, value: FieldValue) => {
		if (property === cardDefinition.Stage.property) {
			let complete = actionFields.complete;
			if (value === ActionCardStages.Complete && !actionFields.complete) {
				complete = toDateTimeString(getUTCDateNow());
			}
			if (
				value !== ActionCardStages.Complete &&
				!!actionFields.complete
			) {
				complete = null;
			}
			setActionFields((s) => {
				const fields = { ...s };
				fields[property] = value;
				fields.complete = complete;
				return fields;
			});
			return;
		}

		if (property === cardDefinition.Complete.property) {
			let stage = actionFields.stage;
			let end = actionFields.end;

			if (!value && actionFields.stage === ActionCardStages.Complete) {
				stage = ActionCardStages.ToDo;
			}

			if (!!value) {
				stage = ActionCardStages.Complete;
			}

			if (!end && !!value) {
				end = value;
			}

			setActionFields((s) => {
				const fields = { ...s };
				fields[property] = value;
				fields.stage = stage;
				fields.end = end;
				return fields;
			});
			return;
		}

		setActionFields((s) => {
			const fields = { ...s };
			fields[property] = value;
			return fields;
		});
	};

	const handleResetError = () => {
		setState((s) => ({ ...s, error: undefined }));
	};

	return (
		<>
			<ModalDialog
				open={true}
				onClose={onClose}
				label={title}
				fullScreen={true}
				actions={
					<>
						<span style={{ flexGrow: 1 }} />
						{!state.error && (
							<Button
								variant="contained"
								onClick={
									!!children
										? () =>
												onContinue({
													fields: actionFields,
												})
										: handleAction
								}
								disabled={state.working}
							>
								Continue
							</Button>
						)}
						{!!state.error && (
							<Button
								variant="contained"
								onClick={handleResetError}
							>
								Try Again
							</Button>
						)}
					</>
				}
			>
				<>
					<Loading enabled={state.working} text="Working..." />
					<ErrorMessage
						error={state.error}
						sx={{
							width: '100%',
							display: 'flex',
							flexGrow: 1,
							p: 4,
						}}
					/>

					<FlexColumn
						fill
						sx={{
							display:
								!state.error && !state.working
									? 'flex'
									: 'none',
						}}
					>
						<FlexColumn
							name="header"
							sx={{
								borderBottom: `1px solid ${theme.palette.divider}`,
								p: 1,
							}}
						>
							<FlexRow fill>
								<ButtonFieldUsers
									caption="Assigned To"
									value={
										(actionFields?.[
											cardDefinition.AssignedTo.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.AssignedTo.property,
											val
										)
									}
								/>
								<ButtonFieldValues
									caption="Team Member"
									entity={Entity.ActionCard}
									property={
										cardDefinition.TeamMember.property
									}
									value={
										(actionFields?.[
											cardDefinition.TeamMember.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.TeamMember.property,
											val
										)
									}
								/>
								<span style={{ flexGrow: 1 }} />
								<ButtonFieldDate
									caption="Start"
									primary={
										actionFields?.[
											cardDefinition.Start.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.Start
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.Start.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Start.property,
											val
										)
									}
								/>
								<ButtonFieldDate
									caption="End"
									primary={
										actionFields?.[
											cardDefinition.End.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.End
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.End.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.End.property,
											val
										)
									}
								/>
								<ButtonFieldDate
									caption="Complete"
									primary={
										actionFields?.[
											cardDefinition.Complete.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.Complete
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.Complete.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Complete.property,
											val
										)
									}
								/>
								<ButtonFieldList
									caption="Stage"
									primary={
										(actionFields?.[
											cardDefinition.Stage.property
										] as string) || NoValueText
									}
									icon={mdiViewColumn}
									value={
										(actionFields?.[
											cardDefinition.Stage.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Stage.property,
											val
										)
									}
									options={[
										{
											value: 'To Do',
											primary: 'ToDo',
											icon: mdiCalendar,
										},
										{
											value: 'In Progress',
											primary: 'InProgress',
											icon: mdiCalendarClock,
										},
										{
											value: 'Complete',
											primary: 'Complete',
											icon: mdiCalendarCheck,
										},
										{
											value: 'Ongoing',
											primary: 'Ongoing',
											icon: mdiCalendarBlank,
										},
									]}
								/>
							</FlexRow>
							<FlexRow fill sx={{ py: 1 }}>
								<ButtonFieldValues
									caption="Participants"
									entity={Entity.ActionCard}
									multiselect
									property={
										cardDefinition.Participants.property
									}
									value={
										(actionFields?.[
											cardDefinition.Participants.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Participants
												.property,
											val
										)
									}
								/>
								<span style={{ flexGrow: 1 }} />
							</FlexRow>
						</FlexColumn>
						<FlexRow fill sx={{ flexGrow: 1 }} nowrap>
							<FlexColumn fill name="content">
								<FlexColumn sx={{ p: 1 }}>
									<ButtonFieldApproach
										value={
											(actionFields?.[
												cardDefinition.Approach.property
											] as number) || null
										}
										icon={
											state.action.entity !==
											Entity.JobRoleApproach
												? mdiDotsHorizontalCircleOutline
												: mdiOpenInNew
										}
										readOnly={
											state.action.entity ===
											Entity.JobRoleApproach
										}
										onChange={(val) =>
											onActionCardChange(
												cardDefinition.Approach
													.property,
												val
											)
										}
										sx={{ flexGrow: 1 }}
									/>
								</FlexColumn>
								<Divider />
								<FlexColumn fill>
									<FlexColumn
										scroll
										sx={{
											p: 2,
											pl: 3,
											pt: 3,
										}}
									>
										<TaskForm
											fields={actionFields || {}}
											onChange={onActionCardChange}
											mixedValues={[]}
											showName={true}
										/>
									</FlexColumn>
								</FlexColumn>
							</FlexColumn>
							{!!children && (
								<FlexColumn
									fill
									name="sidebar"
									sx={{
										minWidth: '450px',
										width: '450px',
										maxWidth: '450px',
										borderLeft: `1px solid ${theme.palette.divider}`,
									}}
								>
									<FlexColumn fill>{children}</FlexColumn>
								</FlexColumn>
							)}
						</FlexRow>
					</FlexColumn>
				</>
			</ModalDialog>
		</>
	);
};
