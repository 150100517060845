import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Monorepo
import { ChangeItem, ActionCard } from '@constituenthub/common';

// Components
import { ErrorMessage, Loading, NothingHere } from '../common';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { ActionCardTile } from '../cards/ActionCardTile';
import { TaskDialog } from '../cards/TaskDialog';
import { ApplicationEvent } from '../../lib/ApplicationEvent';

type Props = {
	selectedChangeItems: ChangeItem[];
	onActionsCount?: (count: number) => void;
};

type State = {
	status: 'idle' | 'loading' | 'loaded' | 'error';
	error?: Error;
	actions: ActionCard[];
};

const initialState: State = {
	status: 'idle',
	actions: [],
};

export const ChangeItemActionsList = ({
	selectedChangeItems,
	onActionsCount,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>(initialState);
	const [historic, setHistoric] = React.useState(false);
	const [openCardDetail, setOpenCardDetail] = React.useState<number>();

	const loadActions = React.useCallback(async () => {
		setState((s) => ({ ...s, status: 'loading' }));
		try {
			const data = await api.changeItemAction.listActionCards(
				selectedChangeItems.map((x) => x.changeItemId),
				historic
			);
			setState((s) => ({
				...s,
				status: 'loaded',
				actions: data,
				error: undefined,
			}));
		} catch (error) {
			setState((s) => ({ ...s, status: 'error', error: error as Error }));
		}
	}, [api.changeItemAction, historic, selectedChangeItems]);

	React.useEffect(() => {
		loadActions();
	}, [loadActions]);

	React.useEffect(() => {
		ApplicationEvent.Receive<ActionCard[]>(
			'RefreshSelectedChangeItemCards',
			loadActions
		);
	}, [loadActions]);

	React.useEffect(() => {
		if (!historic && !!onActionsCount) {
			onActionsCount(state.actions.length);
		}
	}, [onActionsCount, state.actions, historic]);

	return (
		<>
			<Toolbar>
				<span style={{ flexGrow: 1 }}></span>
				<FormControlLabel
					control={
						<Checkbox
							checked={historic}
							onChange={(_event, checked) => setHistoric(checked)}
						/>
					}
					label="View Historic Actions"
				/>
			</Toolbar>
			<Grid
				container
				direction="column"
				flexGrow={1}
				wrap="nowrap"
				spacing={2}
				sx={{
					position: 'relative',
					height: '100%',
					overflow: 'hidden',
					overflowY: 'auto',
					px: 2,
					pl: 3,
				}}
			>
				<Loading
					enabled={state.status === 'loading'}
					text="Working..."
				/>
				<ErrorMessage
					error={state.error}
					sx={{ minWidth: '320px' }}
					onRetry={() => loadActions()}
				/>
				{state.actions.map((action) => (
					<Grid item key={action.actionId}>
						<ActionCardTile
							action={action}
							onOpenCard={() =>
								setOpenCardDetail(action.actionId)
							}
						/>
					</Grid>
				))}
				{state.status === 'loaded' && state.actions.length === 0 && (
					<NothingHere>
						{historic && (
							<Typography variant="caption">
								There is no history at this time
							</Typography>
						)}
						{!historic && (
							<Typography variant="caption">
								There are no current actions
							</Typography>
						)}
					</NothingHere>
				)}
			</Grid>
			{!!openCardDetail && (
				<TaskDialog
					onClose={() => setOpenCardDetail(undefined)}
					onContinue={async () => {
						await loadActions();
						return Promise.resolve(setOpenCardDetail(undefined));
					}}
					actionId={openCardDetail as number}
				/>
			)}
		</>
	);
};
