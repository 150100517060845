import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

// Monorepo
import { Group, Flag, ValidationError } from '@constituenthub/common';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { useAppSelector } from '../../store';
import { selectGroups } from '../../store/project';

const validateGroup = (
	existingGroups: Group[],
	group: Partial<Group>
): ValidationError[] => {
	const errors: ValidationError[] = [];
	if (!group.name) {
		errors.push({ name: 'name', error: 'Name is required' });
	}
	const existing = existingGroups.find(
		(x) => x.name.toLowerCase() === group.name?.toLowerCase()
	);
	if (existing) {
		errors.push({
			name: 'name',
			error: 'Name is not unique. Select existing name from the Groups list.',
		});
	}
	return errors;
};

type Props = {
	onGroupCreated: (group: Group) => void;
};

type State = {
	isWorking: boolean;
	group: Partial<Group>;
	flags: Flag[];
	error?: Error;
};

const initialState: State = {
	isWorking: false,
	group: {},
	flags: [],
};

export const useEditGroup = (props: Props) => {
	const { api } = useAppContext();
	const { onGroupCreated } = props;
	const [state, setState] = useState<State>(initialState);
	const existingGroups = useAppSelector(selectGroups);

	const onCreateGroup = useCallback(async () => {
		setState((s) => ({ ...s, isWorking: true, error: undefined }));
		try {
			const newGroup = await api.group.createGroup(state.group);
			toast(`${newGroup.name} Created`);
			setState((s) => ({
				...s,
				isWorking: false,
				error: undefined,
				group: {},
			}));
			onGroupCreated(newGroup);
		} catch (err) {
			setState((s) => ({ ...s, isWorking: false, error: err as Error }));
		}
	}, [api.group, onGroupCreated, state.group]);

	const onGroupChanged = useCallback((group: Partial<Group>) => {
		setState((s) => ({ ...s, group }));
	}, []);

	const onResetError = useCallback(() => {
		setState((s) => ({ ...s, error: undefined }));
	}, []);

	const validationErrors = validateGroup(existingGroups, state.group);

	return {
		group: state.group,
		isWorking: state.isWorking,
		error: state.error,
		flags: state.flags,
		validationErrors,
		isGroupValid: () => validationErrors.length === 0,
		onCreateGroup,
		onGroupChanged,
		onFlagsChange: () => {},
		onResetError,
	};
};
