/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTheme, Theme } from '@mui/material/styles';

// Monorepo
import {
	Project,
	getProjectTheme,
	PaletteName,
	Hue,
	Group,
} from '@constituenthub/common';

// Lib
import type { RootState } from '.';
import baseTheme from '../features/shell/baseTheme';

const getTheme = (project: Project): Theme => {
	const projectTheme = getProjectTheme(
		project.palette as PaletteName,
		project.hue as Hue
	) as Theme;

	const theme = createTheme({
		...baseTheme,
		...projectTheme,
	});
	return theme;
};

export interface ProjectState {
	project: Project | null;
	groups: Group[];
	error?: any;
}

const initialState: ProjectState = {
	project: null,
	groups: [],
};

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		loadProject: (
			state,
			action: PayloadAction<{ project: Project; groups: Group[] }>
		) => {
			state.project = action.payload.project;
			state.groups = action.payload.groups;
		},
		loadProjectError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		clearProject: (state) => {
			state.project = null;
			state.error = undefined;
			state.groups = [];
		},
	},
});

export const { loadProject, loadProjectError, clearProject } =
	projectSlice.actions;

export const selectProject = (state: RootState) => state.project.project;

export const selectProjectError = (state: RootState) => state.project.error;

export const selectProjectTheme = (state: RootState) =>
	state.project.project
		? getTheme(state.project.project)
		: createTheme(baseTheme);

export const selectGroups = (state: RootState) => state.project.groups;

export default projectSlice.reducer;
