import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, ListItemSecondaryAction } from '@mui/material';
import { toast } from 'react-toastify';

// Icons
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';

// Components
import { Drawer } from '../common';
import { ApproachDetail } from './ApproachDetail';

// Lib
import { TOOLBAR_HEIGHT } from '../../lib';
import { useAppDispatch, useAppSelector } from '../../store';
import { addApproach, selectApproaches } from '../../store/application';
import { useAppContext } from '../../contexts/AppContext';

export const ApproachSettings = () => {
	const { api } = useAppContext();
	const dispatch = useAppDispatch();
	const approaches = useAppSelector(selectApproaches);
	const [selectedId, setSelectedId] = React.useState<number>();
	const selectedApproach = approaches.find(
		(x) => x.approachId === selectedId
	);

	const handleAddApproach = async () => {
		try {
			const added = await api.approach.add({
				name: 'New Approach',
				description: '',
			});
			dispatch(addApproach(added));
			toast('Approach Added');
			setSelectedId(added.approachId);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				height: `calc(100% - ${TOOLBAR_HEIGHT * 3}px)`,
				overflow: 'hidden',
			}}
		>
			<Toolbar>
				<span style={{ flexGrow: 1 }}>Approaches</span>
				<Button variant="text" onClick={handleAddApproach}>
					Add Approach
				</Button>
			</Toolbar>
			<Divider />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					position: 'relative',
					height: `100%`,
					overflowY: 'auto',
					pb: 6,
				}}
			>
				<Drawer
					align="right"
					content={
						!!selectedApproach && (
							<ApproachDetail
								approach={selectedApproach}
								onClose={() => setSelectedId(undefined)}
								sx={{ p: 2 }}
							/>
						)
					}
					name="ApproachDetail"
					minWidth={500}
					scrollable
					toolbar
					variant="persistent"
					open={!!selectedId}
				>
					<List>
						{approaches.map((item) => (
							<ListItem
								key={item.approachId}
								button
								onClick={() => setSelectedId(item.approachId)}
							>
								<ListItemText
									primary={item.name}
									secondary={item.description}
								/>
								<ListItemSecondaryAction
									sx={{ cursor: 'grab' }}
								>
									<Icon path={mdiDrag} size={1} />
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</Drawer>
			</Box>
		</Box>
	);
};
