import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import {
	Entity,
	Project,
	ProjectFieldDefinitions,
	FieldValue,
	Flag,
	ValidationError,
} from '@constituenthub/common';

// Components
import { ColorPalette } from '../common/ColorPalette';
import { Field } from '../fields/Field';
// import { FlagsField } from '../flags/FlagsField';

type ProjectFormProps = {
	project: Partial<Project>;
	onChange: (project: Partial<Project>) => void;
	flags: Flag[];
	onFlagsChange: (items: Flag[]) => void;
	errors?: ValidationError[];
};

export const ProjectForm = (props: ProjectFormProps) => {
	const { project, onChange, errors = [] } = props;

	const handleChange = (prop: string, value: FieldValue) => {
		onChange({ ...project, [prop]: value });
	};

	// const handleFlagsChange = (items: Flag[]) => {
	// 	onFlagsChange(items);
	// };

	const handleColorChange = (palette: string, hue: string) => {
		onChange({
			...project,
			palette,
			hue,
		});
	};

	return (
		<Grid container direction="column" flexGrow={1} spacing={2}>
			<Grid item>
				<Field
					entity={Entity.Project}
					required
					definition={ProjectFieldDefinitions.Name}
					value={project.name}
					onChange={(value: FieldValue) =>
						handleChange('name', value)
					}
					error={!!errors.find((x) => x.name === 'name')}
				/>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.Project}
					definition={ProjectFieldDefinitions.Description}
					value={project.description}
					onChange={(value: FieldValue) =>
						handleChange('description', value)
					}
				/>
			</Grid>
			{/* <Grid item>
				<FlagsField
					label="Flags"
					parentId={null}
					value={flags}
					onChange={(items: Flag[]) => handleFlagsChange(items)}
				/>
			</Grid> */}
			<Grid item>
				<ColorPalette
					palette={project.palette}
					hue={project.hue}
					onChange={(palette: string, hue: string) =>
						handleColorChange(palette, hue)
					}
				/>
			</Grid>
		</Grid>
	);
};
