import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';

// Lib
import { CellPlugin, CellProps } from '../../types';

export interface RowSelectPluginOptions {
	onRowClick?: (props: CellProps) => void;
	isRowSelected: (props: CellProps) => boolean;
}

export const useRowSelectPlugin = (
	options: RowSelectPluginOptions
): CellPlugin => {
	const theme = useTheme();
	const { onRowClick, isRowSelected } = options;

	return {
		getCellStyle: (style, props) => {
			let ns = { ...style };
			if (isRowSelected(props)) {
				ns.backgroundColor = lighten(theme.palette.primary.main, 0.8);
			}
			return ns;
		},
		onClick: (event, props) => {
			if (onRowClick) {
				onRowClick(props);
			}
		},
	};
};
