import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

// Share
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';

// Components
import { LogoLarge } from './LogoLarge';

const SocialIcons = () => {
	return (
		<Grid
			container
			direction="row"
			wrap="wrap"
			alignItems="center"
			justifyContent="center"
			spacing={2}
			sx={{
				my: 4,
				ml: { xs: 'unset', md: 2 },
				justifyContent: { xs: 'center', md: 'flex-start' },
				alignItems: { xs: 'center', md: 'flex-start' },
			}}
		>
			<Grid item>
				<LinkedinIcon size={32} round={true} />
			</Grid>
			<Grid item>
				<TwitterIcon size={32} round={true} />
			</Grid>
			<Grid item>
				<FacebookIcon size={32} round={true} />
			</Grid>
		</Grid>
	);
};

const Links = () => {
	return (
		<Grid
			container
			direction="row"
			wrap="wrap"
			spacing={1}
			sx={{
				justifyContent: { xs: 'center', md: 'flex-start' },
				alignItems: { xs: 'center', md: 'flex-start' },
				flexDirection: { xs: 'row', md: 'column' },
				ml: { xs: 'unset', md: 1 },
			}}
		>
			<Grid item>
				<Link
					href="https://changeimpactplus.com/"
					sx={{ mx: 2, whiteSpace: 'nowrap' }}
				>
					Home
				</Link>
			</Grid>
			<Grid item>
				<Link
					href="https://constituenthub.com/"
					sx={{ mx: 2, whiteSpace: 'nowrap' }}
				>
					Constituent Hub
				</Link>
			</Grid>
			<Grid item>
				<Link
					href="https://changeimpactplus.com/privacy"
					sx={{ mx: 2, whiteSpace: 'nowrap' }}
				>
					Privacy Policy
				</Link>
			</Grid>
			<Grid item>
				<Link
					href="https://changeimpactplus.com/terms"
					sx={{ mx: 2, whiteSpace: 'nowrap' }}
				>
					Terms of Service
				</Link>
			</Grid>
		</Grid>
	);
};

type SplashProps = {
	hideLinks?: boolean;
	children: React.ReactNode;
};

export const Splash = ({
	children,
	hideLinks: offline = false,
}: SplashProps) => {
	const iPadPro97 = useMediaQuery(
		'(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)'
	);

	return (
		<Grid
			id="login"
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				height: '100vh',
				width: '100%',
			}}
		>
			<Paper
				elevation={0}
				sx={{
					backgroundColor: '#ffffff',
					position: 'relative',
					top: iPadPro97 ? '-5%' : 'unset',
					width: {
						xs: '100%',
						sm: '90%',
						md: '95%',
						lg: '80%',
						xl: '60%',
					},
					height: {
						xs: '100%',
						sm: '100%',
						md: '65%',
						lg: '80%',
						xl: '60%',
					},
					padding: { xs: 1, sm: 1, lg: 3 },
					borderRadius: { xs: 0, md: 4 },
					overflowX: 'hidden',
					overflowY: { xs: 'auto' },
				}}
			>
				<Grid
					container
					direction="column"
					sx={{
						flexDirection: { xs: 'column', md: 'row' },
						flexWrap: { xs: 'nowrap', md: 'wrap' },
						height: '100%',
						position: 'relative',
						flexGrow: 1,
					}}
				>
					<Grid
						item
						sx={{
							flexGrow: { xs: 'unset', md: 1 },
						}}
					>
						<Grid
							container
							direction="column"
							sx={{
								display: 'flex',
								height: '100%',
								position: 'relative',
								flexGrow: 1,
							}}
						>
							<Grid
								item
								sx={{
									position: 'relative',
									height: { xs: '45px' },
									marginTop: { xs: 3 },
									marginLeft: { xs: 0, sm: 0, md: 3 },
									textAlign: {
										xs: 'center',
										md: 'unset',
									},
								}}
							>
								<LogoLarge />
							</Grid>
							<Box
								sx={{
									flexGrow: 1,
									display: { xs: 'none', md: 'flex' },
								}}
							/>
							{offline === false && (
								<Grid
									item
									sx={{
										display: { xs: 'none', md: 'flex' },
									}}
								>
									<Links />
								</Grid>
							)}
							{offline === false && (
								<Grid
									item
									sx={{
										display: { xs: 'none', md: 'flex' },
									}}
								>
									<SocialIcons />
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid
						item
						sx={{
							flexGrow: { xs: 'unset', md: 1 },
							marginTop: { xs: 3 },
						}}
					>
						{children}
					</Grid>
					{offline === false && (
						<Grid
							item
							sx={{
								display: { xs: 'flex', md: 'none' },
							}}
						>
							<SocialIcons />
						</Grid>
					)}
					{offline === false && (
						<Grid
							item
							sx={{
								display: { xs: 'flex', md: 'none' },
							}}
						>
							<Links />
						</Grid>
					)}
					<Grid
						item
						sx={{
							display: { xs: 'block', md: 'none' },
						}}
					>
						<p>&nbsp;</p>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};
