import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { SxProps, Theme } from '@mui/system';

// Icons
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';

type Props = {
	placeholder: string;
	multiline?: boolean;
	onAddValue: (value: string | null) => void;
	sx?: SxProps<Theme>;
	disabled?: boolean;
};

export const AddTextValue = ({
	placeholder,
	multiline,
	onAddValue,
	sx = {},
	disabled,
}: Props) => {
	const [change, setChange] = React.useState<string>('');

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onAddValue(change || null);
			setChange('');
		}
	};

	const handleOnAddValue = () => {
		onAddValue(change || null);
		setChange('');
	};

	return (
		<Box
			sx={{
				p: 1,
				...sx,
			}}
		>
			<OutlinedInput
				value={change}
				onChange={(event) => setChange(event.target.value)}
				placeholder={placeholder}
				multiline={multiline}
				rows={multiline ? 3 : undefined}
				fullWidth
				disabled={disabled}
				sx={{ p: 1 }}
				inputProps={{
					sx: { p: 1 },
					onKeyPress: handleKeyPress,
				}}
				tabIndex={-1}
				endAdornment={
					<InputAdornment position="end" sx={{ pr: 1 }}>
						<IconButton
							aria-label={placeholder}
							onClick={handleOnAddValue}
							edge="end"
						>
							<Icon path={mdiPlusCircleOutline} size={1} />
						</IconButton>
					</InputAdornment>
				}
			/>
		</Box>
	);
};
