import {
	AccountDataModel,
	ProjectDataModel,
	DataModelDeletable,
	ParentModel,
	Entity,
} from '../common';
import { FieldDefinition, FieldTypes } from '../fields';

export interface Attachment
	extends AccountDataModel,
		ProjectDataModel,
		ParentModel,
		DataModelDeletable {
	attachmentId: number;
	name: string;
	content: string;
	type: string;
	ext: string;
	path: string;
	size: number;
}

export const AttachmentFieldDefinitions: Record<string, FieldDefinition> = {
	Name: {
		entity: Entity.Attachment,
		property: 'name',
		label: 'Name',
		type: FieldTypes.Text,
		system: true,
	},
	Content: {
		entity: Entity.Attachment,
		property: 'content',
		label: 'Description',
		type: FieldTypes.Content,
		system: true,
	},
};
