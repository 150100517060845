import * as React from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { Attachment, Entity, FieldValue } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading, FlexColumn } from '../common';
import { Field } from '../fields/Field';

// Lib
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	attachment: Attachment;
	onClose: () => void;
	onAttachmentUpdated: (attachment: Attachment) => void;
	onAttachmentDeleted: (attachment: Attachment) => void;
};

type State = {
	working: boolean;
	attachment: Attachment;
	error?: any;
};

export const AttachmentDialog = ({
	attachment,
	onClose,
	onAttachmentUpdated,
	onAttachmentDeleted,
}: Props) => {
	const { api } = useAppContext();
	const definition = useFieldDefinitions(Entity.Attachment);
	const [state, setState] = React.useState<State>({
		working: false,
		attachment,
	});

	const handleOnChange = (property: string, value: FieldValue) => {
		setState((s) => ({
			...s,
			attachment: { ...s.attachment, [property]: value },
		}));
	};

	const handleSaveChanges = async () => {
		setState((s) => ({ ...s, working: true }));
		try {
			const updated = await api.attachment.updateAttachment(
				state.attachment
			);
			onAttachmentUpdated(updated);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleDeleteAttachment = async () => {
		setState((s) => ({ ...s, working: true }));
		try {
			await api.attachment.removeAttachment(
				state.attachment.attachmentId
			);
			onAttachmentDeleted(state.attachment);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error }));
		}
	};

	const handleResetError = () => {
		setState((s) => ({ ...s, error: undefined }));
	};

	return (
		<>
			<ModalDialog
				open={true}
				onClose={onClose}
				label="Edit Attachment"
				actions={
					<>
						{!state.error && (
							<Button
								variant="contained"
								color="error"
								onClick={handleDeleteAttachment}
								disabled={state.working}
							>
								Delete
							</Button>
						)}
						<span style={{ flexGrow: 1 }} />
						{!state.error && (
							<Button
								variant="contained"
								onClick={handleSaveChanges}
								disabled={state.working}
							>
								Save Changes
							</Button>
						)}
						{!!state.error && (
							<Button
								variant="contained"
								onClick={handleResetError}
							>
								Try Again
							</Button>
						)}
					</>
				}
			>
				<>
					<Loading enabled={state.working} text="Working..." />
					<ErrorMessage
						error={state.error}
						sx={{
							width: '100%',
							display: 'flex',
							flexGrow: 1,
							p: 4,
						}}
					/>
					<FlexColumn
						fill
						sx={{
							p: 2,
							display:
								!state.error && !state.working
									? 'flex'
									: 'none',
						}}
					>
						<Field
							entity={Entity.Attachment}
							definition={definition.Name}
							value={state.attachment.name}
							onChange={(value: FieldValue) =>
								handleOnChange(definition.Name.property, value)
							}
						/>
						<Field
							entity={Entity.Attachment}
							definition={definition.Content}
							value={state.attachment.content}
							onChange={(value: FieldValue) =>
								handleOnChange(
									definition.Content.property,
									value
								)
							}
						/>
					</FlexColumn>
				</>
			</ModalDialog>
		</>
	);
};
