import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import { mdiClose, mdiPrinter } from '@mdi/js';

export interface LayoutProps {
	children: React.ReactNode;
}

const fixHtmlElement = (e: HTMLElement) => {
	e.style.setProperty('position', 'relative');
	e.style.setProperty('display', 'block');
	e.style.setProperty('width', 'unset');
	e.style.setProperty('height', 'unset');
	e.style.setProperty('overflow', 'unset');
	e.style.setProperty('top', 'unset');
	e.style.setProperty('left', 'unset');
	e.style.setProperty('bottom', 'unset');
	e.style.setProperty('right', 'unset');
};

export const PrintLayout = (props: LayoutProps) => {
	const { children } = props;

	React.useEffect(() => {
		const root = window.document.getElementById('root');
		if (root) {
			fixHtmlElement(root);
			fixHtmlElement(window.document.body);
		}
	}, []);

	return (
		<>
			<Toolbar
				id="PrintLayoutToolbar"
				sx={{ borderBottom: '1px solid #ccc' }}
			>
				<span style={{ flexGrow: 1 }} />
				<IconButton onClick={() => window.print()}>
					<Icon path={mdiPrinter} size={1} />
				</IconButton>
				<IconButton onClick={() => window.close()}>
					<Icon path={mdiClose} size={1} />
				</IconButton>
			</Toolbar>
			<Box
				id="PrintLayoutContainer"
				style={{
					position: 'relative',
					backgroundColor: '#ffffff',
					display: 'block',
				}}
			>
				{children}
			</Box>
		</>
	);
};
