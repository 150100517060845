import React from 'react';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps } from '../../types';

interface Props extends CellRenderProps {}

const Chip = styled('div')(({ theme }) => ({
	padding: theme.spacing(0.5, 0.5),
	borderRadius: theme.spacing(1),
	outline: 'none',
	fontSize: theme.typography.caption.fontSize,
	fontWeight: 'bold',
	width: '80%',
	textAlign: 'center',
}));

export const ImpactRenderer = (props: Props) => {
	const value = get(props.row, props.column.field, '');
	let backgroundColor = 'initial';
	let color = '#000000';
	if (value === 'Low') {
		backgroundColor = '#fdd835';
	}
	if (value === 'Medium') {
		backgroundColor = '#ff9800';
		color = '#ffffff';
	}
	if (value === 'High') {
		backgroundColor = '#d32f2f';
		color = '#ffffff';
	}

	return (
		<CellBase
			{...props}
			onDoubleClick={() => {}}
			style={{
				...props.style,
				justifyContent: 'center',
			}}
		>
			{value && (
				<Chip
					sx={{
						backgroundColor,
						color,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{value}
				</Chip>
			)}
		</CellBase>
	);
};
