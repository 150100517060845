import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Storage } from 'aws-amplify';

// Icons
import Icon from '@mdi/react';
import {
	mdiImageOutline,
	mdiVideoOutline,
	mdiMicrosoftWord,
	mdiMicrosoftExcel,
	mdiMicrosoftPowerpoint,
	mdiFilePdfBox,
	mdiTextBoxOutline,
	mdiPaperclip,
	mdiFolderZipOutline,
	mdiPencilOutline,
	mdiAlertCircleOutline,
	mdiArrowDownThin,
	mdiArrowTopRightThick,
} from '@mdi/js';

// Monorepo
import {
	formatDateTime,
	Attachment,
	isImageAttachment,
	isVideoAttachment,
	BrowserSupportedTypes,
} from '@constituenthub/common';

// Lib
import { useUser } from '../../hooks/useUser';
import { useTheme } from '@mui/material';

type Props = {
	attachment: Attachment;
	onClick: (attachment: Attachment) => void;
	onEdit: (attachment: Attachment) => void;
};

// Alert if file is missing
// Icon for content type
// open / download
// edit / delete

// todo: file namne in s3 is wrong

const getIcon = (attachment: Attachment): string => {
	if (isImageAttachment(attachment.ext)) {
		return mdiImageOutline;
	}
	if (isVideoAttachment(attachment.ext)) {
		return mdiVideoOutline;
	}
	if (['doc', 'docm', 'docx'].includes(attachment.ext)) {
		return mdiMicrosoftWord;
	}
	if (
		['xla', 'xls', 'xlam', 'xlsb', 'xlsm', 'xlsx'].includes(attachment.ext)
	) {
		return mdiMicrosoftExcel;
	}
	if (['ppt', 'pptx'].includes(attachment.ext)) {
		return mdiMicrosoftPowerpoint;
	}
	if (['pdf'].includes(attachment.ext)) {
		return mdiFilePdfBox;
	}
	if (['txt'].includes(attachment.ext)) {
		return mdiTextBoxOutline;
	}
	if (
		['zip', '7z', 'deb', 'pkg', 'rar', 'arj', '.gz'].includes(
			attachment.ext
		)
	) {
		return mdiFolderZipOutline;
	}
	return mdiPaperclip;
};

export const fileExists = async (key: string): Promise<boolean> => {
	const results = await Storage.list(key, {
		level: 'public',
	});
	return results && results.length === 1;
};

export const AttachmentListItem = ({ attachment, onClick, onEdit }: Props) => {
	const theme = useTheme();
	const [urlExists, setUrlExists] = React.useState(true);
	const { user } = useUser(attachment.createdBy);
	const displayName = user ? `${user.first} ${user.last}` : '';

	React.useEffect(() => {
		fileExists(attachment.path)
			.then(setUrlExists)
			.catch((error) => {
				console.error(error);
				setUrlExists(false);
			});
	}, [attachment.path]);

	let tooltip = attachment.content || attachment.name;
	if (!urlExists) {
		tooltip = 'File is missing';
	}

	const isBrowserSupported = BrowserSupportedTypes.includes(attachment.ext);

	return (
		<Tooltip title={tooltip}>
			<ListItem onClick={() => onClick(attachment)}>
				<ListItemButton>
					<ListItemIcon sx={{ minWidth: '40px' }}>
						<Icon
							path={
								urlExists
									? getIcon(attachment)
									: mdiAlertCircleOutline
							}
							color={
								urlExists
									? theme.palette.primary.main
									: theme.palette.error.main
							}
							size={1}
						/>
						{!isBrowserSupported && (
							<Icon
								path={mdiArrowDownThin}
								size={0.75}
								color="black"
								style={{
									position: 'absolute',
									left: '32px',
									top: '26px',
								}}
							/>
						)}
					</ListItemIcon>
					<ListItemText
						primary={`${formatDateTime(
							attachment.createdAt
						)} by: ${displayName}`}
						secondary={attachment.name}
						primaryTypographyProps={{
							variant: 'caption',
							color: 'GrayText',
							style: { wordBreak: 'break-all' },
						}}
						secondaryTypographyProps={{
							variant: 'body2',
							color: 'CaptionText',
							style: { wordBreak: 'break-all' },
						}}
					/>
				</ListItemButton>
				<ListItemSecondaryAction>
					<IconButton onClick={() => onEdit(attachment)}>
						<Icon path={mdiPencilOutline} size={1} />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</Tooltip>
	);
};
