import React from 'react';

// Components
import { RowNumberCellRenderer } from './RowNumberCellRenderer';
import { RowNumberHeaderRenderer } from './RowNumberHeaderRenderer';

// Lib
import { RendererApi, CellRenderProps, HeaderRenderProps } from '../../types';

export const RowNumberRendererName = 'Row Number Renderer';

export interface RowNumberRendererApi extends RendererApi {}

export const useRowNumberRenderer = (): RowNumberRendererApi => {
	const renderHeader = (props: HeaderRenderProps) => {
		return (
			<RowNumberHeaderRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				{...props}
			/>
		);
	};

	const renderCell = (props: CellRenderProps) => {
		return (
			<RowNumberCellRenderer
				key={`${props.columnIndex}-${props.rowIndex}`}
				{...props}
			/>
		);
	};

	return {
		name: RowNumberRendererName,
		type: 'Action',
		field: '_rowNumber',
		label: 'Row Number',
		width: 50,
		sort: 'off',
		renderHeader,
		renderCell,
	};
};
