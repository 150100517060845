import React, { useEffect, useCallback } from 'react';

// Components
import { useAppContext } from './AppContext';
import { Splash, ErrorMessage } from '../features/common';

// Lib
import { useAppDispatch, useAppSelector } from '../store';
import {
	loadApplicationData,
	loadApplicationError,
	selectApplicationError,
} from '../store/application';

export const AppDataContext = React.createContext<undefined>(undefined);

export interface AppDataContextProps {
	children: React.ReactNode;
}

export const AppDataContextProvider = (props: AppDataContextProps) => {
	const { api } = useAppContext();
	const dispatch = useAppDispatch();
	const applicationError = useAppSelector(selectApplicationError);
	const { children } = props;

	const loadData = useCallback(async () => {
		try {
			const [users, approaches] = await Promise.all([
				api.user.listUsers(),
				api.approach.listApproaches(),
			]);
			dispatch(loadApplicationData({ users, approaches }));
		} catch (error) {
			dispatch(loadApplicationError(error));
		}
	}, [dispatch, api]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<AppDataContext.Provider value={undefined}>
			{applicationError && (
				<Splash hideLinks={true}>
					<ErrorMessage
						error={applicationError}
						onRetry={() => window.location.reload()}
					/>
				</Splash>
			)}
			{!applicationError && <>{children}</>}
		</AppDataContext.Provider>
	);
};
