// import Joi from 'joi';
import {
	AccountDataModel,
	ProjectDataModel,
	DataModelDeletable,
	Entity,
} from '../common';
import { FieldDefinition, FieldsModel, FieldTypes } from '../fields';

export interface ChangeItem
	extends AccountDataModel,
		ProjectDataModel,
		DataModelDeletable,
		FieldsModel {
	changeItemId: number;
}

// export const changeItemSchema = Joi.object({
//     name: Joi.string().alphanum().min(3).max(200).required(),
//     description: Joi.string(),
// });

export const ChangeItemFieldDefinitions: Record<string, FieldDefinition> = {
	Name: {
		entity: Entity.ChangeItem,
		property: 'name',
		type: FieldTypes.Text,
		label: 'Name',
		system: true,
	},
	ChangeImpact: {
		entity: Entity.ChangeItem,
		property: 'changeImpact',
		label: 'Change Impact',
		type: FieldTypes.SelectList,
		options: [
			{ label: 'Low', value: 'Low' },
			{ label: 'Medium', value: 'Medium' },
			{ label: 'High', value: 'High' },
		],
		system: true,
	},
	// The reset of these fields are configurable by the user
	// Therefor these are defaults and should not be referenced directly
	ResponsibleTeamMember: {
		entity: Entity.ChangeItem,
		property: 'teamMember',
		type: FieldTypes.User,
		label: 'Responsible Team Member',
	},
	Location: {
		entity: Entity.ChangeItem,
		property: 'location',
		type: FieldTypes.Text,
		label: 'Location',
		autoComplete: true,
	},
	IdentifiedBy: {
		entity: Entity.ChangeItem,
		property: 'identifiedBy',
		type: FieldTypes.Text,
		label: 'Identified By',
		autoComplete: true,
	},
	ChangeArea: {
		entity: Entity.ChangeItem,
		property: 'changeArea',
		type: FieldTypes.Text,
		label: 'Change Area',
		autoComplete: true,
	},
	ChangeCategory: {
		entity: Entity.ChangeItem,
		property: 'changeCategory',
		type: FieldTypes.Text,
		label: 'Change Category',
		autoComplete: true,
	},
	Function: {
		entity: Entity.ChangeItem,
		property: 'function',
		type: FieldTypes.Text,
		label: 'Function',
		autoComplete: true,
	},
	Description: {
		entity: Entity.ChangeItem,
		property: 'description',
		type: FieldTypes.Content,
		label: 'Description',
	},
	CurrentProcess: {
		entity: Entity.ChangeItem,
		property: 'currentProcess',
		type: FieldTypes.Content,
		label: 'Current Process',
	},
	FutureProcess: {
		entity: Entity.ChangeItem,
		property: 'futureProcess',
		type: FieldTypes.Content,
		label: 'Future Process',
	},
};
