// import Joi from 'joi';
import { FieldTypes } from '..';
import {
	AccountDataModel,
	DataModelArchivable,
	DataModelDeletable,
	Entity,
} from '../common';
import { FieldDefinition } from '../fields';

export interface Project
	extends AccountDataModel,
		DataModelArchivable,
		DataModelDeletable {
	projectId: number;
	name: string;
	description: string;
	favorite: boolean;
	palette: string;
	hue: string;
}

export interface ProjectView extends Project {
	changeItemCount: number;
	openActionCount: number;
}

// export const projectSchema = Joi.object({
//     name: Joi.string().alphanum().min(3).max(200).required(),
//     description: Joi.string().max(30),
//     favorite: Joi.bool().required(),
//     palette: Joi.string().required(),
//     hue: Joi.string().required(),
// });

export const ProjectFieldDefinitions: Record<string, FieldDefinition> = {
	Name: {
		entity: Entity.Project,
		property: 'name',
		label: 'Name',
		type: FieldTypes.Text,
		system: true,
	},
	Description: {
		entity: Entity.Project,
		property: 'description',
		label: 'Description',
		type: FieldTypes.Content,
		system: true,
	},
};
