import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import { useNavigate, useParams } from 'react-router-dom';

// Icons
import { mdiClose } from '@mdi/js';

// Monorepo
import {
	ActionCard,
	ChangeItem,
	encodeFilter,
	Entity,
	Filter,
} from '@constituenthub/common';

// Components
import { IconButton, MultipleAffected } from '../common';
import { ChangeItemBatchForm } from './ChangeItemBatchForm';
import { ChangeItemActionsList } from './ChangeItemActionsList';
import { AddChangeItemActionsDialog } from './AddChangeItemActionsDialog';

// Lib
import { ChangeItemsHook } from './useChangeItemsController';
import { AffectedJobRoles } from '../jobroles/AffectedJobRoles';
import { ICON_ADD_ACTION, TOOLBAR_HEIGHT } from '../../lib';
import { useAppSelector } from '../../store';
import { selectProject, selectProjectTheme } from '../../store/project';
import { NoteContainer } from '../notes/NoteContainer';
import { AttachmentContainer } from '../attachments/AttachmentContainer';
import { ApplicationEvent } from '../../lib/ApplicationEvent';

type Props = {
	controller: ChangeItemsHook;
	filter?: Filter;
};

export const ChangeItemDetail = ({ controller, filter }: Props) => {
	const project = useAppSelector(selectProject);
	const theme = useAppSelector(selectProjectTheme);
	const [isAddChangeItemCardsOpen, setIsAddChangeItemCardsOpen] =
		useState(false);
	const { selectedChangeItems, onClearSelectedChangeItems } = controller;

	const [noteCount, setNoteCount] = React.useState(0);
	const [attachmentCount, setAttachmentCount] = React.useState(0);
	const [jobRolesCount] = React.useState(0);
	const [, setActionsCount] = React.useState(0);

	const navigate = useNavigate();
	const params = useParams();
	const selectedTabIndex = params.tab ? parseInt(params.tab, 10) : 0;

	const handleTabChange = (index: number) => {
		const url = `/project/${project?.projectId}/items/${index}`;
		if (filter && filter.length > 0) {
			navigate(`${url}?filter=${encodeFilter(filter)}`);
		} else {
			navigate(url);
		}
	};

	const sidebarTitle =
		selectedChangeItems.length === 1
			? selectedChangeItems[0].fields.name
			: `${selectedChangeItems.length} Change Items Selected`;

	const selectedChangeItemId =
		selectedChangeItems.length === 1
			? selectedChangeItems[0].changeItemId
			: null;

	return (
		<>
			<AppBar position="relative">
				<Toolbar
					variant="dense"
					sx={{
						position: 'relative',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '100%',
					}}
				>
					<span
						style={{
							flexGrow: 1,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{sidebarTitle}
					</span>
					<IconButton
						onClick={() => setIsAddChangeItemCardsOpen(true)}
						path={ICON_ADD_ACTION}
						title="Add Action Cards for Selected Change Items"
						variant="small"
						edge="end"
						color={theme.palette.primary.contrastText}
						sx={{ px: 2 }}
						disabled={selectedChangeItems.length === 0}
					/>
					<IconButton
						onClick={onClearSelectedChangeItems}
						path={mdiClose}
						title="Close"
						variant="small"
						edge="end"
						color={theme.palette.primary.contrastText}
					/>
				</Toolbar>
			</AppBar>
			<Tabs
				value={selectedTabIndex}
				onChange={(_event, index) => handleTabChange(index)}
				aria-label="Change Item Details"
			>
				<Tab value={0} label="Detail" />
				<Tab
					value={1}
					label={
						<Badge
							max={99}
							badgeContent={jobRolesCount}
							color="primary"
							sx={{
								'& .MuiBadge-badge': {
									right: -6,
								},
							}}
						>
							<span>Job Roles</span>
						</Badge>
					}
				/>
				<Tab
					value={2}
					label={
						<Badge
							max={99}
							badgeContent={0}
							color="primary"
							sx={{
								'& .MuiBadge-badge': {
									right: -6,
								},
							}}
						>
							<span>Actions</span>
						</Badge>
					}
				/>
				<Tab
					disabled={!selectedChangeItemId}
					value={3}
					label={
						<Badge
							max={99}
							badgeContent={noteCount}
							color="primary"
							sx={{
								'& .MuiBadge-badge': {
									right: -6,
								},
							}}
						>
							<span>Notes</span>
						</Badge>
					}
				/>
				<Tab
					disabled={!selectedChangeItemId}
					value={4}
					label={
						<Badge
							max={99}
							badgeContent={attachmentCount}
							color="primary"
							sx={{
								'& .MuiBadge-badge': {
									right: -6,
								},
							}}
						>
							<span>Attachments</span>
						</Badge>
					}
				/>
			</Tabs>
			<Grid
				container
				direction="column"
				flexGrow={1}
				wrap="nowrap"
				data-component="ChangeItemDetail"
				sx={{
					position: 'relative',
					height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
					overflow: 'hidden',
				}}
			>
				<Box sx={{ p: 2, pb: 0 }}>
					<MultipleAffected
						hideSingle
						items={selectedChangeItems}
						message={`Changes will affect ${selectedChangeItems.length} selected Change Items`}
						getText={(item: ChangeItem) =>
							item.fields.name as string
						}
						getId={(item: ChangeItem) => item.changeItemId}
						onClick={(item: ChangeItem) =>
							controller.selectOneChangeItem(item.changeItemId)
						}
					/>
				</Box>
				{selectedTabIndex === 0 && (
					<Grid
						item
						sx={{
							p: 2,
							flexGrow: 1,
							position: 'relative',
							height: '100%',
							overflow: 'hidden',
						}}
					>
						<ChangeItemBatchForm controller={controller} />
					</Grid>
				)}
				{selectedTabIndex === 1 && (
					<AffectedJobRoles
						selectedChangeItems={controller.selectedChangeItems}
					/>
				)}
				{selectedTabIndex === 2 && (
					<ChangeItemActionsList
						selectedChangeItems={controller.selectedChangeItems}
						onActionsCount={setActionsCount}
					/>
				)}
				{selectedTabIndex === 3 && selectedChangeItemId && (
					<NoteContainer
						entity={Entity.ChangeItem}
						parentId={selectedChangeItemId}
						onNoteCount={setNoteCount}
					/>
				)}
				{selectedTabIndex === 4 && selectedChangeItemId && (
					<AttachmentContainer
						entity={Entity.ChangeItem}
						parentId={selectedChangeItemId}
						onAttachmentCount={setAttachmentCount}
						sx={{ px: 1 }}
					/>
				)}
			</Grid>
			{!!isAddChangeItemCardsOpen && (
				<AddChangeItemActionsDialog
					onClose={() => setIsAddChangeItemCardsOpen(false)}
					onContinue={(cards) => {
						ApplicationEvent.Send<ActionCard[]>(
							'RefreshSelectedChangeItemCards',
							cards
						);
						return Promise.resolve(
							setIsAddChangeItemCardsOpen(false)
						);
					}}
					changeItems={controller.selectedChangeItems}
				/>
			)}
		</>
	);
};
