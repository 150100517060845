import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

// Monorepo
import {
	ActionCard,
	ActionCardView,
	Entity,
	Filter,
	parseFilterFromUrl,
	actionCardFieldRules,
} from '@constituenthub/common';

// Lib
import { useAppContext } from '../../contexts/AppContext';
import { useAppDispatch } from '../../store';
import {
	actionCardMoved,
	actionCardReordered,
	loadActionCards,
	setClearActionCardState,
} from '../../store/actions';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';

export const useActionCardsController = () => {
	const location = useLocation();
	const [filter, setFilter] = useState<Filter>();

	// Should not change except on reload or navigate
	const { api } = useAppContext();
	// const project = useAppSelector(selectProject);

	const dispatch = useAppDispatch();
	const definition = useFieldDefinitions(Entity.ActionCard);

	//** CRUD */
	const onAddNewActionCard = useCallback(async () => {
		try {
			const item = await api.action.createActionCard({
				fields: { name: 'New Change Item' },
			});
			return item;
		} catch (err) {
			toast.error(
				`Unable to create a new item: ${(err as Error).message}`
			);
		}
	}, [api]);

	const onActionCardUpdated = useCallback(
		async (data: ActionCard) => {
			try {
				const item = await api.action.updateActionCard(
					actionCardFieldRules(data) as ActionCard
				);
				return item;
			} catch (err) {
				toast.error(`Unable to update item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	const onActionCardsUpdated = useCallback(
		async (data: ActionCard[]) => {
			try {
				const items = await api.action.updateActionCards(
					data.map(actionCardFieldRules) as ActionCard[]
				);
				return items;
			} catch (err) {
				toast.error(
					`Unable to update items: ${(err as Error).message}`
				);
			}
		},
		[api]
	);

	const onActionCardRemoved = useCallback(
		async (data: ActionCard) => {
			try {
				await api.action.removeActionCard(data.actionId);
			} catch (err) {
				toast.error(`Unable to remove item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	const onActionCardsRemoved = useCallback(
		async (data: ActionCard[]) => {
			try {
				await api.action.removeActionCards(data.map((x) => x.actionId));
			} catch (err) {
				toast.error(`Unable to remove item: ${(err as Error).message}`);
			}
		},
		[api]
	);

	//** Move */
	const onActionCardReordered = useCallback(
		async (sourceIndex: number, targetIndex: number, card: ActionCard) => {
			try {
				dispatch(
					actionCardReordered({
						sourceIndex,
						targetIndex,
						stage: card.fields.stage as string,
					})
				);
				return api.actionboard.reorder(card, sourceIndex, targetIndex);
			} catch (err) {
				toast.error(
					`Unable to reorder card: ${(err as Error).message}`
				);
			}
		},
		[api.actionboard, dispatch]
	);

	const onActionCardMoved = useCallback(
		async (
			card: ActionCard,
			sourceIndex: number,
			sourceStage: string,
			targetIndex: number,
			targetStage: string
		) => {
			try {
				dispatch(
					actionCardMoved({
						sourceIndex,
						sourceStage,
						targetIndex,
						targetStage,
					})
				);
				return api.actionboard.move(card, targetStage, targetIndex);
			} catch (err) {
				toast.error(`Unable to move card: ${(err as Error).message}`);
			}
		},
		[api.actionboard, dispatch]
	);

	const onLoadActionCards = useCallback(
		async (filter: Filter | undefined) => {
			try {
				const items = (await api.action.listActionCards({
					filter,
				})) as ActionCardView[];
				dispatch(loadActionCards(items));
			} catch (err) {
				toast.error(`Unable to load items: ${(err as Error).message}`);
				dispatch(setClearActionCardState());
			}
		},
		[api.action, dispatch]
	);

	const onLoadMoreActionCards = useCallback(async () => {
		return Promise.resolve();
	}, []);

	useEffect(() => {
		onLoadActionCards(filter);
	}, [filter, onLoadActionCards]);

	useEffect(() => {
		setFilter(parseFilterFromUrl(location.search));
	}, [location.search]);

	return {
		definition,
		onActionCardMoved,
		onActionCardReordered,
		onAddNewActionCard,
		onActionCardUpdated,
		onActionCardsUpdated,
		onActionCardRemoved,
		onActionCardsRemoved,
		onLoadMoreActionCards,
	};
};

export type ActionCardsHook = ReturnType<typeof useActionCardsController>;
