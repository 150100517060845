import React from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { View } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { ViewForm } from './ViewForm';

type Props = {
	open: boolean;
	onClose: () => void;
	onCreateView: (view: Partial<View>) => Promise<View>;
};

type State = {
	isWorking: boolean;
	view: Partial<View>;
	error?: Error;
};

export const SaveViewDialog = (props: Props) => {
	const { open, onClose, onCreateView } = props;
	const [state, setState] = React.useState<State>({
		isWorking: false,
		view: { name: 'Untitled View' },
	});

	const handleClose = () => {
		onClose();
	};

	const handleCreateView = async () => {
		setState((s) => ({ ...s, isWorking: true }));
		try {
			await onCreateView(state.view);
			setState((s) => ({ ...s, isWorking: false }));
			onClose();
		} catch (error) {
			setState((s) => ({
				...s,
				isWorking: false,
				error: error as Error,
			}));
		}
	};

	const handleViewChanged = (view: Partial<View>) => {
		setState((s) => ({ ...s, view }));
	};

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Save View"
			padding={2}
			actions={
				<>
					{!state.error && (
						<Button
							variant="contained"
							onClick={handleCreateView}
							disabled={state.isWorking}
						>
							Create View
						</Button>
					)}
					{!!state.error && (
						<Button variant="contained" onClick={handleCreateView}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={state.isWorking} text="Working..." />
				<ErrorMessage error={state.error} sx={{ minWidth: '320px' }} />
				{!state.error && !state.isWorking && (
					<ViewForm
						view={state.view}
						onChange={handleViewChanged}
						errors={[]}
					/>
				)}
			</>
		</ModalDialog>
	);
};
