// import Joi from 'joi';
import {
	AccountDataModel,
	ProjectDataModel,
	DataModelDeletable,
	Entity,
} from '../common';
import { FieldDefinition, FieldTypes } from '../fields';

export interface Group
	extends AccountDataModel,
		ProjectDataModel,
		DataModelDeletable {
	groupId: number;
	name: string;
	description: string;
}

// export const groupSchema = Joi.object({
//     name: Joi.string().alphanum().min(3).max(200).required(),
//     description: Joi.string(),
// });

export const GroupFieldDefinitions: Record<string, FieldDefinition> = {
	Name: {
		entity: Entity.Group,
		property: 'name',
		label: 'Name',
		type: FieldTypes.Text,
		system: true,
	},
	Description: {
		entity: Entity.Group,
		property: 'description',
		label: 'Description',
		type: FieldTypes.Content,
		system: true,
	},
};
