import React from 'react';
import { Routes as Routing, Route } from 'react-router-dom';

// Components
import { ProjectPage } from './features/projects/ProjectPage';
import { ChangeItemsPage } from './features/changeitems/ChangeItemsPage';
import { GroupPage } from './features/groups/GroupPage';
import { ActionCardsPage } from './features/cards/ActionCardsPage';
import { SettingsPage } from './features/settings/SettingsPage';

import { ProfileSettings } from './features/profile/ProfileSettings';
import { ApproachSettings } from './features/approaches/ApproachSettings';
import { FieldSettings } from './features/fields/FieldSettings';
import { OrganizationSettings } from './features/organization/OrganizationSettings';
import { UserSettings } from './features/organization/UserSettings';
import { RoleSettings } from './features/organization/RoleSettings';
import { SwitchOrganizationSettings } from './features/shell/SwitchOrganizationSettings';
import { ActionCardReportTable } from './features/cards/ActionCardReportTable';

export default function Routes() {
	return (
		<Routing>
			{/* Settings */}
			<Route path="/settings" element={<SettingsPage />}>
				<Route path="/settings/" element={<ProfileSettings />} />
				<Route
					path="/settings/organization"
					element={<OrganizationSettings />}
				/>
				<Route
					path="/settings/organization/roles"
					element={<RoleSettings />}
				/>
				<Route
					path="/settings/organization/users"
					element={<UserSettings />}
				/>
				<Route
					path="/settings/approaches"
					element={<ApproachSettings />}
				/>
				<Route path="/settings/fields" element={<FieldSettings />} />
				<Route
					path="/settings/switch"
					element={<SwitchOrganizationSettings />}
				/>
			</Route>

			{/* Project Detail */}
			<Route path="/projectdetail/:projectId" element={<ProjectPage />} />

			{/* Change Items */}
			<Route
				path="/project/:projectId/items"
				element={<ChangeItemsPage />}
			/>
			<Route
				path="/project/:projectId/items/:tab"
				element={<ChangeItemsPage />}
			/>

			{/* Groups */}
			<Route path="/project/:projectId/groups" element={<GroupPage />} />
			<Route
				path="/project/:projectId/groups/:groupId/:tab"
				element={<GroupPage />}
			/>

			{/* Action Cards */}
			<Route
				path="/project/:projectId/actions"
				element={<ActionCardsPage />}
			/>
			<Route
				path="/project/:projectId/actions/report"
				element={<ActionCardReportTable />}
			/>

			{/* Project Dashboard */}
			{/* 404 */}
			<Route path="*" element={<ProjectPage />} />
		</Routing>
	);
}
