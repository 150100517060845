import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

// Icons
import Icon from '@mdi/react';
import { mdiSort, mdiSortAscending, mdiSortDescending } from '@mdi/js';

// Components
import { HeaderBase } from './HeaderBase';

// Lib
import { HeaderRenderProps, SortDirection } from '../types';

interface Props extends HeaderRenderProps {
	onToggleSort?: (direction: SortDirection) => void;
	align:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'match-parent';
}

export const HeaderRenderer = (props: Props) => {
	const sortIconPath =
		props.column.sort === 'asc'
			? mdiSortAscending
			: props.column.sort === 'desc'
			? mdiSortDescending
			: mdiSort;

	const handleToggleSort = () => {
		if (props.onToggleSort) {
			props.onToggleSort(props.column.sort);
		}
	};

	const spanStyle: React.CSSProperties = {
		flexGrow: 1,
		textAlign: props.align,
	};

	return (
		<HeaderBase {...props} style={props.style}>
			<span style={spanStyle}>{props.column.label}</span>
			{props.column.sort !== 'off' && (
				<Tooltip title="Sort">
					<IconButton size="small" onClick={handleToggleSort}>
						<Icon path={sortIconPath} size={0.7} />
					</IconButton>
				</Tooltip>
			)}
		</HeaderBase>
	);
};
