import * as React from 'react';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import { Divider, Typography, useTheme } from '@mui/material';
import { lighten } from '@mui/system';

import {
	mdiCalendar,
	mdiCalendarBlank,
	mdiCalendarCheck,
	mdiCalendarClock,
	mdiOpenInNew,
	mdiViewColumn,
	mdiDotsHorizontalCircleOutline,
} from '@mdi/js';

// Monorepo
import { ActionCard, Entity, formatDateTime } from '@constituenthub/common';

// Components
import {
	ModalDialog,
	ErrorMessage,
	Loading,
	ConfirmDeleteDialog,
	FlexRow,
	FlexColumn,
} from '../common';

import { ButtonField } from '../common/ButtonField';
import { ButtonFieldDate } from '../common/ButtonFieldDate';
import { ButtonFieldList } from '../common/ButtonFieldList';
import { ButtonFieldApproach } from '../approaches/ButtonFieldApproach';
import { NoteContainer } from '../notes/NoteContainer';
import { ButtonFieldValues } from '../fields/ButtonFieldValues';

// Lib
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { ButtonFieldUsers } from '../fields/ButtonFieldUsers';
import { TaskForm } from './TaskForm';
import { ChangeItemForm } from '../changeitems/ChangeItemForm';
import { useTaskCardController } from './useTaskCardController';
import { ButtonTaskStatus } from './ButtonTaskStatus';
import { AttachmentContainer } from '../attachments/AttachmentContainer';

const NoValueText = 'Select a value';

type Props = {
	actionId: number;
	onClose: () => void;
	onDelete?: (card: ActionCard) => Promise<any>;
	onContinue: (card: ActionCard) => Promise<any>;
};

export const TaskDialog = ({
	actionId,
	onClose,
	onDelete,
	onContinue,
}: Props) => {
	const theme = useTheme();
	const cardDefinition = useFieldDefinitions(Entity.ActionCard);
	const changeItemDefinition = useFieldDefinitions(Entity.ChangeItem);
	const [confirmDelete, setConfirmDelete] = React.useState(false);
	const [detailTabIndex, setDetailTabIndex] = React.useState(0);
	const [auxTabIndex, setAuxTabIndex] = React.useState(0);
	const [noteCount, setNoteCount] = React.useState(0);
	const [attachmentCount, setAttachmentCount] = React.useState(0);

	const {
		action,
		changeItem,
		onActionCardChange,
		onChangeItemChange,
		working,
		loaded,
		error,
		actionFields,
		changeItemFields,
		saveChanges,
		handleResetError,
		deleteActionCard,
	} = useTaskCardController({ actionId });

	const handleAction = async () => {
		try {
			await saveChanges();
			setTimeout(() => {
				return onContinue(action as ActionCard);
			}, 500);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteAction = async () => {
		if (action && onDelete) {
			await deleteActionCard();
			setTimeout(() => {
				onDelete(action as ActionCard);
			}, 500);
		}
	};

	const handleOpenChangeItem = () => {};
	const handleOpenJobRole = () => {};

	let relatedContentEntity = Entity.ActionCard;
	let relatedContentParentId = action.actionId;
	let relatedContentItem = 'Action Card';

	if (detailTabIndex === 0) {
		if (action.entity === Entity.JobRoleApproach) {
			relatedContentEntity = Entity.JobRoleApproach;
			relatedContentParentId = action.parentId;
			relatedContentItem = `Job Role Approach`;
		}
	} else {
		relatedContentEntity = Entity.ChangeItem;
		relatedContentParentId = action.changeItemId;
		relatedContentItem = `Change Item: ${action.changeItemName}`;
	}

	console.log(detailTabIndex, action.entity);

	const lightColor = lighten(theme.palette.primary.main, 0.9);

	return (
		<>
			<ModalDialog
				open={true}
				onClose={onClose}
				label={(actionFields?.name as string) || 'Action Card Details'}
				fullScreen={true}
				actions={
					<>
						{!error && !!onDelete && (
							<Button
								variant="text"
								onClick={() => setConfirmDelete(true)}
								disabled={working}
								color="error"
							>
								Delete
							</Button>
						)}
						<span style={{ flexGrow: 1 }} />
						{!error && (
							<Button
								variant="contained"
								onClick={handleAction}
								disabled={working}
							>
								Continue
							</Button>
						)}
						{!!error && (
							<Button
								variant="contained"
								onClick={handleResetError}
							>
								Try Again
							</Button>
						)}
					</>
				}
			>
				<>
					<Loading enabled={working} text="Working..." />
					<ErrorMessage
						error={error}
						sx={{
							width: '100%',
							display: 'flex',
							flexGrow: 1,
							p: 4,
						}}
					/>

					<FlexColumn
						fill
						sx={{
							display: !error && !working ? 'flex' : 'none',
						}}
					>
						<FlexColumn
							name="header"
							sx={{
								borderBottom: `1px solid ${theme.palette.divider}`,
								p: 1,
							}}
						>
							<FlexRow fill>
								<ButtonFieldUsers
									caption="Assigned To"
									value={
										(actionFields?.[
											cardDefinition.AssignedTo.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.AssignedTo.property,
											val
										)
									}
								/>
								<ButtonFieldValues
									caption="Team Member"
									entity={Entity.ActionCard}
									property={
										cardDefinition.TeamMember.property
									}
									value={
										(actionFields?.[
											cardDefinition.TeamMember.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.TeamMember.property,
											val
										)
									}
								/>
								<span style={{ flexGrow: 1 }} />
								<ButtonFieldDate
									caption="Start"
									primary={
										actionFields?.[
											cardDefinition.Start.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.Start
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.Start.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Start.property,
											val
										)
									}
								/>
								<ButtonFieldDate
									caption="End"
									primary={
										actionFields?.[
											cardDefinition.End.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.End
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.End.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.End.property,
											val
										)
									}
								/>
								<ButtonFieldDate
									caption="Complete"
									primary={
										actionFields?.[
											cardDefinition.Complete.property
										]
											? formatDateTime(
													actionFields?.[
														cardDefinition.Complete
															.property
													] as string
											  )
											: NoValueText
									}
									value={
										(actionFields?.[
											cardDefinition.Complete.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Complete.property,
											val
										)
									}
								/>
								<ButtonFieldList
									caption="Stage"
									primary={
										(actionFields?.[
											cardDefinition.Stage.property
										] as string) || NoValueText
									}
									icon={mdiViewColumn}
									value={
										(actionFields?.[
											cardDefinition.Stage.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Stage.property,
											val
										)
									}
									options={[
										{
											value: 'To Do',
											primary: 'ToDo',
											icon: mdiCalendar,
										},
										{
											value: 'In Progress',
											primary: 'InProgress',
											icon: mdiCalendarClock,
										},
										{
											value: 'Complete',
											primary: 'Complete',
											icon: mdiCalendarCheck,
										},
										{
											value: 'Ongoing',
											primary: 'Ongoing',
											icon: mdiCalendarBlank,
										},
									]}
								/>
							</FlexRow>
							<FlexRow fill sx={{ py: 1 }}>
								<ButtonFieldValues
									caption="Participants"
									entity={Entity.ActionCard}
									multiselect
									property={
										cardDefinition.Participants.property
									}
									value={
										(actionFields?.[
											cardDefinition.Participants.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Participants
												.property,
											val
										)
									}
								/>
								<span style={{ flexGrow: 1 }} />
								<ButtonTaskStatus
									property={cardDefinition.Status.property}
									value={
										(actionFields?.[
											cardDefinition.Status.property
										] as string) || null
									}
									onChange={(val) =>
										onActionCardChange(
											cardDefinition.Status.property,
											val
										)
									}
								/>
							</FlexRow>
						</FlexColumn>
						<FlexRow fill sx={{ flexGrow: 1 }} nowrap>
							<FlexColumn fill name="content">
								<FlexColumn sx={{ p: 1 }}>
									{!!loaded &&
										(changeItem || action.groupName) && (
											<FlexRow>
												{changeItem && (
													<ButtonField
														caption="Change Item"
														primary={
															(changeItem?.fields
																.name as string) ||
															''
														}
														icon={mdiOpenInNew}
														onClick={
															handleOpenChangeItem
														}
														sx={{ flexGrow: 1 }}
													/>
												)}
												{action.groupName && (
													<ButtonField
														caption="Affected Job Role"
														primary={
															action.groupName
														}
														icon={mdiOpenInNew}
														onClick={
															handleOpenJobRole
														}
														sx={{ flexGrow: 1 }}
													/>
												)}
											</FlexRow>
										)}
									<ButtonFieldApproach
										value={
											(actionFields?.[
												cardDefinition.Approach.property
											] as number) || null
										}
										icon={
											action.entity !==
											Entity.JobRoleApproach
												? mdiDotsHorizontalCircleOutline
												: mdiOpenInNew
										}
										readOnly={
											action.entity ===
											Entity.JobRoleApproach
										}
										onChange={(val) =>
											onActionCardChange(
												cardDefinition.Approach
													.property,
												val
											)
										}
										sx={{ flexGrow: 1 }}
									/>
								</FlexColumn>
								<Divider />
								<FlexColumn fill>
									{changeItem && (
										<Tabs
											value={detailTabIndex}
											onChange={(_event, index) =>
												setDetailTabIndex(index)
											}
											aria-label="details"
											sx={{ pt: 2, pl: 1 }}
										>
											<Tab label="Task Detail" />
											<Tab label="Change Item Detail" />
										</Tabs>
									)}
									<FlexColumn
										scroll
										sx={{
											p: 2,
											pl: 3,
											pt: 3,
										}}
									>
										{detailTabIndex === 0 && (
											<TaskForm
												fields={actionFields || {}}
												onChange={onActionCardChange}
												mixedValues={[]}
												showName={
													action.entity !==
													Entity.JobRoleApproach
												}
											/>
										)}
										{detailTabIndex === 1 && changeItem && (
											<ChangeItemForm
												showName={
													action.entity !==
													Entity.JobRoleApproach
												}
												definition={
													changeItemDefinition
												}
												fields={changeItemFields}
												onChange={onChangeItemChange}
												mixedValues={[]}
											/>
										)}
									</FlexColumn>
								</FlexColumn>
							</FlexColumn>
							<FlexColumn
								fill
								name="sidebar"
								sx={{
									minWidth: '450px',
									width: '450px',
									maxWidth: '450px',
									backgroundColor: lightColor,
								}}
							>
								<FlexRow sx={{ px: 2, pt: 2 }}>
									<Typography
										variant="body2"
										color={theme.palette.primary.main}
										fontWeight="bold"
									>
										{relatedContentItem}
									</Typography>
								</FlexRow>

								<Tabs
									value={auxTabIndex}
									onChange={(_event, index) =>
										setAuxTabIndex(index)
									}
									aria-label="related items"
									sx={{ pt: 2 }}
								>
									<Tab
										value={0}
										label={
											<Badge
												max={99}
												badgeContent={noteCount}
												color="primary"
												sx={{
													'& .MuiBadge-badge': {
														right: -6,
													},
												}}
											>
												<span>Notes</span>
											</Badge>
										}
									/>
									<Tab
										value={1}
										label={
											<Badge
												max={99}
												badgeContent={attachmentCount}
												color="primary"
												sx={{
													'& .MuiBadge-badge': {
														right: -6,
													},
												}}
											>
												<span>Attachments</span>
											</Badge>
										}
									/>
								</Tabs>
								<FlexColumn fill>
									<NoteContainer
										entity={relatedContentEntity}
										parentId={relatedContentParentId}
										onNoteCount={setNoteCount}
										sx={{
											display:
												auxTabIndex === 0
													? 'initial'
													: 'none',
										}}
									/>
									<AttachmentContainer
										entity={relatedContentEntity}
										parentId={relatedContentParentId}
										onAttachmentCount={setAttachmentCount}
										sx={{
											p: 1,
											display:
												auxTabIndex === 1
													? 'initial'
													: 'none',
										}}
									/>
								</FlexColumn>
							</FlexColumn>
						</FlexRow>
					</FlexColumn>
				</>
			</ModalDialog>
			{onDelete && confirmDelete === true && (
				<ConfirmDeleteDialog
					open={confirmDelete === true}
					title="Delete Action Card"
					message={`Are you sure you want to delete this Action?`}
					onContinue={handleDeleteAction}
					onClose={() => setConfirmDelete(false)}
				/>
			)}
		</>
	);
};
