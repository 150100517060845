import {
	AccountDataModel,
	ProjectDataModelOptional,
	DataModelDeletable,
	ParentModel,
	Entity,
} from '../common';
import { FieldDefinition, FieldTypes } from '../fields';

export interface Note
	extends AccountDataModel,
		ProjectDataModelOptional,
		ParentModel,
		DataModelDeletable {
	noteId: number;
	content: string;
}

export const NoteFieldDefinitions: Record<string, FieldDefinition> = {
	Content: {
		entity: Entity.Note,
		property: 'content',
		label: 'Content',
		type: FieldTypes.Content,
		system: true,
	},
};
