import * as React from 'react';
import { SxProps, Theme } from '@mui/system';
import { mdiDotsHorizontalCircleOutline } from '@mdi/js';

// Components
import { ButtonFieldList } from '../common/ButtonFieldList';

// Lib
import { useAppSelector } from '../../store';
import { selectUsers } from '../../store/application';
import { User } from '@constituenthub/common';

type Props = {
	caption?: string;
	sx?: SxProps<Theme>;
	value: string | null;
	icon?: string;
	readOnly?: boolean;
	mixedValue?: boolean;
	onChange: (value: string) => void;
};

export const getDisplayName = (user: User): string => {
	const segs: string[] = [];
	if (user.first) {
		segs.push(user.first);
	}
	if (user.middle) {
		segs.push(user.middle);
	}
	if (user.last) {
		segs.push(user.last);
	}
	return segs.join(' ');
};

export const ButtonFieldUsers = ({
	caption,
	value,
	onChange,
	icon,
	readOnly,
	mixedValue,
	sx = {},
}: Props) => {
	const users = useAppSelector(selectUsers);
	const selected = users.find((x) => x.userId === value);
	const options = users.map((item) => ({
		value: item.userId,
		primary: getDisplayName(item),
		secondary: item.email,
	}));

	return (
		<ButtonFieldList
			caption={caption || 'User'}
			primary={selected ? getDisplayName(selected) : 'Select User'}
			secondary={selected ? selected.email : undefined}
			icon={icon || mdiDotsHorizontalCircleOutline}
			onChange={(value) => onChange(value as string)}
			value={value}
			mixedValue={mixedValue}
			options={options}
			readOnly={readOnly}
			sx={sx}
		/>
	);
};
