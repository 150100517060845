import React, { useState } from 'react';
import { orderBy } from 'lodash';

// Monorepo
import { Group } from '@constituenthub/common';

// Components
import {
	Table,
	useRowNumberRenderer,
	useTextRenderer,
	SortDirection,
	useRowHoverPlugin,
	useRowSelectPlugin,
} from '../table';

type Props = {
	items: Group[];
	onGroupSelected: (group: Group) => void;
	selectedGroupId: number | null;
};

export const GroupTable = (props: Props) => {
	const [sort, setSort] = useState<SortDirection>('asc');
	const { items, selectedGroupId, onGroupSelected } = props;

	const handleToggleSort = (direction: SortDirection) => {
		if (direction === 'asc') {
			setSort('desc');
		} else {
			setSort('asc');
		}
	};

	const handleLoadMoreResults = () => {
		console.log('handleLoadMoreResults');
	};

	const rowNumber = useRowNumberRenderer();
	const name = useTextRenderer({
		field: 'name',
		label: 'Group',
		sort,
		width: 400,
		onToggleSort: handleToggleSort,
	});

	const description = useTextRenderer({
		field: 'description',
		label: 'Description',
		sort: 'off',
		width: 400,
	});

	const rowHover = useRowHoverPlugin();
	const rowSelect = useRowSelectPlugin({
		isRowSelected: (props) => props.getId() === selectedGroupId,
		onRowClick: (props) => onGroupSelected(props.row),
	});

	const rows = orderBy(items, ['name'], [sort === 'asc' ? 'asc' : 'desc']);

	return (
		<Table
			rows={rows}
			getId={(group: Group) => group.groupId}
			columns={[rowNumber, name, description]}
			plugins={[rowHover, rowSelect]}
			onScrollBottom={handleLoadMoreResults}
			rowHeight={50}
		/>
	);
};
