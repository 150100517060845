import React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import {
	Entity,
	Approach,
	ApproachFieldDefinitions,
	FieldValue,
} from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';

type Props = {
	approach: Partial<Approach>;
	onChange: (data: Partial<Approach>) => void;
};

export const ApproachForm = ({ approach, onChange }: Props) => {
	const handleChange = (prop: string, value: FieldValue) => {
		onChange({ ...approach, [prop]: value });
	};

	return (
		<Grid container direction="column" flexGrow={1} spacing={2}>
			<Grid item>
				<Field
					entity={Entity.Approach}
					required
					definition={ApproachFieldDefinitions.Name}
					value={approach.name}
					onChange={(value: FieldValue) =>
						handleChange('name', value)
					}
				/>
			</Grid>
			<Grid item>
				<Field
					entity={Entity.Approach}
					definition={ApproachFieldDefinitions.Description}
					value={approach.description}
					onChange={(value: FieldValue) =>
						handleChange('description', value)
					}
				/>
			</Grid>
		</Grid>
	);
};
