import { ActionCard } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class ActionBoardApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async reorder(
		data: ActionCard,
		sourceIndex: number,
		targetIndex: number
	): Promise<void> {
		const response = await this.client.patch<ActionCard, void>(
			`/actionboard/reorder/${data.actionId}/${sourceIndex}/${targetIndex}`,
			data
		);
		return response.result;
	}

	public async move(
		data: ActionCard,
		stage: string,
		targetIndex: number
	): Promise<void> {
		const response = await this.client.patch<ActionCard, void>(
			`/actionboard/move/${data.actionId}/${stage}/${targetIndex}`,
			data
		);
		return response.result;
	}
}
