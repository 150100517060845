import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { mdiCogOutline } from '@mdi/js';

// Components
import { IconButton } from '../common';
import { AppBarButton } from '../common';
import { ToolbarLogo } from './ToolbarLogo';

// Lib
import {
	ICON_CHANGE,
	ICON_GROUPS,
	ICON_ACTIONCARDS,
	ICON_HELP,
} from '../../lib';
import { useAppSelector } from '../../store';
import { selectProject } from '../../store/project';

export const AppToolbar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const project = useAppSelector(selectProject);
	const projectId = !!project ? project.projectId : null;

	return (
		<AppBar>
			<Toolbar
				sx={{
					// paddingLeft: '27px !important',
					paddingLeft: '0px !important',
					'& button': {
						color: 'primary.contrastText',
					},
				}}
			>
				<ToolbarLogo project={project} />
				<span style={{ width: '40px' }} />
				<AppBarButton
					sx={{ mx: 1 }}
					title="Manage Change"
					path={ICON_CHANGE}
					onClick={() => navigate(`/project/${projectId}/items`)}
					hidden={!projectId}
					selected={location.pathname.includes('/items')}
				/>
				<AppBarButton
					sx={{ mx: 1 }}
					title="Manage Groups"
					path={ICON_GROUPS}
					onClick={() => navigate(`/project/${projectId}/groups`)}
					selected={location.pathname.includes('/groups')}
					hidden={!projectId}
				/>
				<AppBarButton
					sx={{ mx: 1 }}
					title="Action Cards"
					path={ICON_ACTIONCARDS}
					onClick={() => navigate(`/project/${projectId}/actions`)}
					selected={location.pathname.includes('/actions')}
					hidden={!projectId}
				/>
				<span style={{ flexGrow: 1 }} />
				<AppBarButton
					sx={{ mx: 1 }}
					title="Manage your Profile and Settings"
					path={mdiCogOutline}
					onClick={() => navigate('/settings')}
					selected={location.pathname.startsWith('/settings')}
				/>
				<IconButton title="Help" path={ICON_HELP} onClick={() => {}} />
			</Toolbar>
		</AppBar>
	);
};
