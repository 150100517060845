import React from 'react';
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// Icons
import Icon from '@mdi/react';
import { mdiStar, mdiStarOutline } from '@mdi/js';

// Monorepo
import {
	Project,
	ProjectView,
	getProjectTheme,
	PaletteName,
	Hue,
} from '@constituenthub/common';

// Components
import { IconButton } from '../common';

// Lib
import { ICON_APPROACHES, ICON_CHANGE } from '../../lib';
import baseTheme from '../shell/baseTheme';

type ProjectTileProps = {
	project: ProjectView;
	onOpenClick: (project: Project) => void;
	onOpenItemsClick: (project: Project) => void;
	onOpenCardsClick: (project: Project) => void;
	onDetailsClick: (project: Project) => void;
	onFavoriteClick: (project: Project) => void;
};

export const ProjectTitle = (props: ProjectTileProps) => {
	const {
		project,
		onOpenClick,
		onOpenItemsClick,
		onOpenCardsClick,
		onDetailsClick,
		onFavoriteClick,
	} = props;
	const openActionCount = project.openActionCount;
	const changeItemCount = project.changeItemCount;

	const projectTheme = getProjectTheme(
		project.palette as PaletteName,
		project.hue as Hue
	) as Theme;

	return (
		<ThemeProvider
			theme={createTheme({ ...baseTheme, ...projectTheme } as Theme)}
		>
			<Paper elevation={1} sx={{ width: '320px', maxWidth: '320px' }}>
				<Grid container direction="column">
					<Grid
						item
						sx={{
							px: 2,
							pt: 2,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							width: '300px',
						}}
					>
						<Toolbar variant="dense" disableGutters>
							<Typography
								variant="body1"
								fontWeight="bold"
								flexGrow={1}
							>
								{project.name}
							</Typography>
							<IconButton
								onClick={() => onFavoriteClick(project)}
								path={
									project.favorite === true
										? mdiStar
										: mdiStarOutline
								}
								title="Toggle Favorite"
								variant="small"
								color={
									project.favorite === true
										? projectTheme.palette.primary.main
										: undefined
								}
							/>
						</Toolbar>
					</Grid>
					<Grid
						item
						sx={{
							px: 2,
							pb: 2,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							width: '300px',
						}}
					>
						<Typography variant="caption">
							<Tooltip title={project.description || ''}>
								<span>&nbsp;{project.description}</span>
							</Tooltip>
						</Typography>
					</Grid>
					<Grid item>
						<List dense>
							<ListItem
								button
								onClick={() => onOpenItemsClick(project)}
							>
								<ListItemIcon sx={{ minWidth: '36px' }}>
									<Icon path={ICON_CHANGE} size={0.75} />
								</ListItemIcon>
								<ListItemText primary="Change Items" />
								<ListItemSecondaryAction>
									<Chip
										sx={{
											minWidth: '50px',
											fontWeight:
												changeItemCount > 0
													? 'bold'
													: 'normal',
										}}
										label={changeItemCount || 'none'}
										size="small"
										color={
											changeItemCount > 0
												? 'primary'
												: 'default'
										}
										onClick={() =>
											onOpenItemsClick(project)
										}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem
								button
								onClick={() => onOpenCardsClick(project)}
							>
								<ListItemIcon sx={{ minWidth: '36px' }}>
									<Icon path={ICON_APPROACHES} size={0.75} />
								</ListItemIcon>
								<ListItemText primary="Open Actions" />
								<ListItemSecondaryAction>
									<Chip
										sx={{
											minWidth: '50px',
											fontWeight:
												openActionCount > 0
													? 'bold'
													: 'normal',
										}}
										label={openActionCount || 'none'}
										size="small"
										color={
											openActionCount > 0
												? 'primary'
												: 'default'
										}
										onClick={() =>
											onOpenCardsClick(project)
										}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Grid>
					<Divider />
					<Grid item>
						<Toolbar variant="dense" disableGutters>
							<span style={{ flexGrow: 1 }} />
							<Button
								size="small"
								variant="text"
								onClick={() => onDetailsClick(project)}
								sx={{ mx: 1 }}
							>
								Details
							</Button>
							<Button
								size="small"
								variant="contained"
								onClick={() => onOpenClick(project)}
								sx={{ mx: 2 }}
							>
								Open
							</Button>
						</Toolbar>
					</Grid>
				</Grid>
			</Paper>
		</ThemeProvider>
	);
};
