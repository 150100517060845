import React, { useState } from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { Group } from '@constituenthub/common';

// Components
import {
	ModalDialog,
	ErrorMessage,
	Loading,
	MultipleAffected,
} from '../common';

type Props = {
	groups: Group[];
	open: boolean;
	onClose: () => void;
	onDeleteJobRoles: () => Promise<void>;
};

export const DeleteJobRolesDialog = (props: Props) => {
	const { groups, open, onClose, onDeleteJobRoles } = props;
	const [error, setError] = useState<Error>();
	const [isWorking, setIsWorking] = useState(false);

	const handleClose = () => {
		setError(undefined);
		onClose();
	};

	const handleDeleteItems = async () => {
		setIsWorking(true);
		try {
			await onDeleteJobRoles();
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsWorking(false);
		}
	};

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Delete Affected Job Roles"
			padding={2}
			actions={
				<>
					{!error && (
						<Button
							variant="contained"
							onClick={handleDeleteItems}
							disabled={isWorking}
						>
							Delete Affected Job Roles
						</Button>
					)}
					{!!error && (
						<Button
							variant="contained"
							onClick={() => setError(undefined)}
						>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={isWorking} text="Working..." />
				<ErrorMessage error={error} sx={{ minWidth: '320px' }} />
				{!error && !isWorking && (
					<MultipleAffected
						items={groups}
						message={`The following ${groups.length} Affected Job Roles will be deleted`}
						getText={(item: Group) => item.name}
						getId={(item: Group) => item.groupId}
						expandByDefault={true}
					/>
				)}
			</>
		</ModalDialog>
	);
};
