import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container, Draggable } from 'react-smooth-dnd';

// Icons
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';

// Monorepo
import { ActionCard } from '@constituenthub/common';

// Components
import { ActionCardTile } from './ActionCardTile';
import { ActionCardsHook } from './useActionCardsController';
import { useAppSelector } from '../../store';
import { selectActionCardsByStage } from '../../store/actions';
import { TOOLBAR_HEIGHT } from '../../lib';

type Props = {
	stage: string;
	controller: ActionCardsHook;
	openCard: (entity: string, parentId: number, actionId: number) => void;
};

export const CardColumn = ({ stage, controller, openCard }: Props) => {
	const cards = useAppSelector(selectActionCardsByStage(stage));

	const onCardDrop = async (
		stage: string,
		sourceIndex: number | null,
		targetIndex: number | null,
		card: ActionCard
	) => {
		if (
			stage === card.fields.stage &&
			targetIndex !== null &&
			sourceIndex !== null
		) {
			console.log(
				`Reordering ${card.actionId} from ${sourceIndex} to ${targetIndex}`
			);
			try {
				await controller.onActionCardReordered(
					sourceIndex,
					targetIndex,
					card
				);
			} catch (error) {
				console.error(error);
			}
		}

		if (stage !== card.fields.stage && targetIndex !== null) {
			console.log(
				`Moved ${card.actionId} from ${card.fields.stage} to ${stage} at ${targetIndex}`
			);
			try {
				const sourceIndex = cards.findIndex(
					(x) => x.actionId === card.actionId
				);
				if (sourceIndex !== -1) {
					await controller.onActionCardMoved(
						card,
						sourceIndex,
						card.fields.stage as string,
						targetIndex,
						stage
					);
				} else {
					console.warn('Could not find source item');
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<>
			<Toolbar disableGutters sx={{ pr: 1 }}>
				<span className="actioncard-column-drag-handle">
					<Icon path={mdiDrag} size={1} />
				</span>
				<Typography variant="caption" flexGrow={1}>
					{stage}
				</Typography>
			</Toolbar>
			<Container
				orientation="vertical"
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'nowrap',
					position: 'relative',
					height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
					overflow: 'hidden',
					flexGrow: 1,
					overflowY: 'auto',
				}}
				groupName="col"
				getChildPayload={(index) => cards[index]}
				onDrop={({ removedIndex, addedIndex, payload }) =>
					onCardDrop(stage, removedIndex, addedIndex, payload)
				}
				dragClass="actioncard-dragging"
				dropClass="actioncard-dropping"
				dropPlaceholder={{
					animationDuration: 150,
					showOnTop: true,
					className: 'drop-preview',
				}}
				animationDuration={200}
			>
				{cards.map((card) => (
					<Draggable
						key={card.actionId}
						className="actioncard-draggable"
					>
						<ActionCardTile
							action={card}
							onOpenCard={() =>
								openCard(
									card.entity,
									card.parentId,
									card.actionId
								)
							}
						/>
					</Draggable>
				))}
			</Container>
		</>
	);
};
