import React from 'react';
import Button from '@mui/material/Button';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { Typography } from '@mui/material';

type Props = {
	title: string;
	message: string;
	open: boolean;
	onClose: () => void;
	onContinue: () => Promise<void>;
};

type State = {
	isWorking: boolean;
	error?: Error;
};

export const ConfirmDeleteDialog = ({
	open,
	title,
	message,
	onClose,
	onContinue,
}: Props) => {
	const [state, setState] = React.useState<State>({
		isWorking: false,
	});

	const handleOnContinue = async () => {
		setState((s) => ({ isWorking: true, error: undefined }));
		try {
			await onContinue();
			setState((s) => ({ ...s, isWorking: false }));
			onClose();
		} catch (error) {
			setState((s) => ({ isWorking: false, error: error as Error }));
		}
	};

	return (
		<ModalDialog
			open={open}
			onClose={onClose}
			label={title}
			padding={2}
			actions={
				<>
					{!state.error && (
						<Button
							variant="contained"
							onClick={handleOnContinue}
							disabled={state.isWorking}
							color="error"
						>
							Delete
						</Button>
					)}
					{!!state.error && (
						<Button variant="contained" onClick={handleOnContinue}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={state.isWorking} text="Working..." />
				<ErrorMessage error={state.error} sx={{ minWidth: '320px' }} />
				{!state.error && !state.isWorking && (
					<Typography variant="body2">{message}</Typography>
				)}
			</>
		</ModalDialog>
	);
};
