import React, { useContext, useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Hub } from 'aws-amplify';

// Monorepo
import { User, Account, AccountAssociation } from '@constituenthub/common';

// Components
import { Login } from '../features/auth';

// Lib
import { api } from '../api';
import { Splash, Loading } from '../features/common';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from '../store';
import { useNavigate } from 'react-router-dom';

type CognitoResponse = {
	username?: string;
	attributes?: { sub?: string; email?: string };
};

interface AppState {
	username?: string;
	authId?: string;
	user: User | null;
	account: Account | null;
	accounts: AccountAssociation[];
	permissions: string[];
}

export interface ApplicationContextData extends AppState {
	api: typeof api;
	switchAccount: (accountId: number) => void;
	signin: (username: string, password: string) => Promise<void>;
	signout: () => void;
	onUserUpdated: (user: User) => void;
}

const initialAppState: AppState = {
	user: null,
	account: null,
	accounts: [],
	permissions: [],
};

const initialValue: ApplicationContextData = {
	...initialAppState,
	api,
	switchAccount: () => {},
	signin: () => Promise.resolve(),
	signout: () => {},
	onUserUpdated: () => {},
};

export const ApplicationContext =
	React.createContext<ApplicationContextData>(initialValue);

export interface AppContextProps {
	children: React.ReactNode;
}

let prevAuthState: string = '';

const parseResponse = (response: CognitoResponse) => {
	if (
		response &&
		response.username &&
		response.attributes &&
		response.attributes.sub &&
		response.attributes.email
	) {
		return [response.attributes.email, response.attributes.sub];
	}
	throw new Error('Invalid Response');
};

export const AppContext = (props: AppContextProps) => {
	const { children } = props;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [state, setState] = useState<AppState>(initialAppState);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState('');

	Auth.currentAuthenticatedUser().then(console.log);

	const onUserUpdated = (updated: User) => {
		setState((s) => ({ ...s, user: updated }));
	};

	const switchAccount = async (accountId: number) => {
		try {
			const response = await api.user.getAccount(accountId);
			const { account, permissions } = response;
			api.setAccountHeader(account.accountId);
			setState((s) => ({ ...s, account, permissions }));
		} catch (err) {
			setErrorMessage(`${err}`);
			setState((s) => ({
				...s,
				user: null,
				account: null,
				accounts: [],
				permissions: [],
			}));
		}
	};

	const signin = async (userId: string, password: string) => {
		setLoading(true);
		try {
			const response = await Auth.signIn(userId, password);
			console.log('Sign In Response', response);
		} catch (err) {
			setErrorMessage(`${err}`);
		} finally {
			setLoading(false);
		}
	};

	const signout = async () => {
		api.setAccountHeader(0);
		api.setProjectHeader(0);
		await Auth.signOut();
		setState({ ...initialAppState });
		navigate('/');
	};

	const loadProfile = useCallback(async () => {
		if (state.authId) {
			setLoading(true);
			try {
				const response = await api.user.getUser();
				const { user, account, accounts, permissions } = response;
				api.setAccountHeader(account.accountId);
				setState((s) => ({
					...s,
					user,
					account,
					accounts,
					permissions,
				}));
			} catch (err) {
				setState((s) => ({
					...s,
					user: null,
					account: null,
					accounts: [],
					permissions: [],
				}));
			} finally {
				setLoading(false);
			}
		}
	}, [state.authId]);

	useEffect(() => {
		return onAuthUIStateChange((nextAuthState, authData) => {
			if (nextAuthState !== prevAuthState) {
				if (nextAuthState === AuthState.SignedIn) {
					const [username, authId] = parseResponse(
						authData as CognitoResponse
					);
					setState((s) => ({
						...s,
						username,
						authId,
					}));
				}
				if (nextAuthState === AuthState.SignedOut) {
					setState({ ...initialAppState });
					api.setAccountHeader(0);
					api.setProjectHeader(0);
				}
			}
			// Prevent duplicates
			prevAuthState = nextAuthState;
		});
	}, [dispatch]);

	useEffect(() => {
		if (state.authId) {
			loadProfile();
		}
	}, [state.authId, loadProfile]);

	useEffect(() => {
		Hub.listen('auth', (data) => {
			if (data.payload.event === 'signIn_failure') {
				setErrorMessage(`Your username or password are incorrect`);
			}
		});
	}, []);

	return (
		<ApplicationContext.Provider
			value={{
				...state,
				api,
				switchAccount: switchAccount,
				signin,
				signout,
				onUserUpdated,
			}}
		>
			{loading && (
				<Splash>
					<Grid
						container
						direction="row"
						alignItems="center"
						spacing={2}
						sx={{
							position: 'relative',
							height: '90%',
						}}
					>
						<Loading enabled={true} text="Loading your profile" />
					</Grid>
				</Splash>
			)}
			{!loading && !state.authId && !state.user && !errorMessage && (
				<Login />
			)}
			{!loading && errorMessage && (
				<Splash>
					<h4>There was an issue with your sign in.</h4>
					<Grid
						container
						direction="row"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<Typography
								variant="body2"
								color="error"
								sx={{ pb: 4 }}
							>
								{errorMessage}
							</Typography>
							<Button
								variant="contained"
								onClick={() => setErrorMessage('')}
							>
								Try Again
							</Button>
						</Grid>
					</Grid>
				</Splash>
			)}
			{!loading && state.authId && !state.user && !errorMessage && (
				<Splash>
					<h4>We could not load your profile</h4>
					<Grid
						container
						direction="row"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<Button
								variant="contained"
								onClick={() => loadProfile()}
							>
								Try Again
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="info"
								onClick={() => signout()}
							>
								Signout
							</Button>
						</Grid>
					</Grid>
				</Splash>
			)}
			{!loading && state.authId && state.user && !errorMessage && (
				<>{children}</>
			)}
		</ApplicationContext.Provider>
	);
};

export const useAppContext = (): ApplicationContextData => {
	return useContext(ApplicationContext);
};
