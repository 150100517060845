import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Monorepo
import { Approach } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { SelectApproachList } from './SelectApproachList';
import { useSelection } from '../../hooks/useSelection';
import { useAppSelector } from '../../store';
import { selectApproaches } from '../../store/application';

type Props = {
	open: boolean;
	onClose: () => void;
	onApproachesSelected: (approaches: Approach[]) => Promise<any[]>;
};

type State = {
	working: boolean;
	error?: Error;
};

const initialState = {
	working: false,
};

export const SelectApproachesDialog = ({
	open,
	onClose,
	onApproachesSelected,
}: Props) => {
	const approaches = useAppSelector(selectApproaches);
	const [state, setState] = useState<State>(initialState);
	const selection = useSelection();

	const handleClose = () => {
		setState(initialState);
		selection.clearAll();
		onClose();
	};

	const handleResetError = () => {
		setState((s) => ({ ...s, error: undefined }));
	};

	const onSelectApproaches = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			const selectedIds = selection.getSelected();
			await onApproachesSelected(
				approaches.filter((x) => selectedIds.includes(x.approachId))
			);
			selection.clearAll();
			setState(initialState);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error: error as Error }));
		}
	};

	const hasErrors = !!state.error;
	const currentError = state.error;
	const isBusy = !!state.working || approaches.length === 0;

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Select Approaches"
			actions={
				<>
					{!hasErrors && (
						<Button
							variant="contained"
							onClick={onSelectApproaches}
							disabled={isBusy}
						>
							Select
						</Button>
					)}
					{hasErrors && (
						<Button variant="contained" onClick={handleResetError}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Grid
					container
					direction="column"
					flexGrow={1}
					wrap="nowrap"
					sx={{
						p: 2,
						height: '100%',
						overflow: 'hidden',
						overflowY: 'auto',
					}}
				>
					<Loading enabled={isBusy} text="Working..." />
					<ErrorMessage
						error={currentError}
						sx={{ minWidth: '320px' }}
					/>
					<SelectApproachList
						approaches={approaches}
						selectedApproaches={selection.getSelected()}
						onToggleSelected={selection.toggleSelected}
					/>
				</Grid>
			</>
		</ModalDialog>
	);
};
