import React, { useState } from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { JobRoleApproach, Approach } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { Typography } from '@mui/material';

type Props = {
	approaches: Approach[];
	jobRoleApproaches: JobRoleApproach[];
	open: boolean;
	onClose: () => void;
	onDeletedApproaches: () => Promise<void>;
};

export const DeleteJobRoleApproachesDialog = (props: Props) => {
	const { jobRoleApproaches, open, onClose, onDeletedApproaches } = props;
	const [error, setError] = useState<Error>();
	const [isWorking, setIsWorking] = useState(false);

	const handleClose = () => {
		setError(undefined);
		onClose();
	};

	const handleDeleteApproaches = async () => {
		setIsWorking(true);
		try {
			await onDeletedApproaches();
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsWorking(false);
		}
	};

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Delete Approaches"
			padding={2}
			actions={
				<>
					{!error && (
						<Button
							variant="contained"
							onClick={handleDeleteApproaches}
							disabled={isWorking}
						>
							Delete Approaches
						</Button>
					)}
					{!!error && (
						<Button
							variant="contained"
							onClick={() => setError(undefined)}
						>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={isWorking} text="Working..." />
				<ErrorMessage error={error} sx={{ minWidth: '320px' }} />
				{!error && !isWorking && (
					<>
						<Typography sx={{ p: 2 }} display="block">
							Are you sure you want to delete the{' '}
							{jobRoleApproaches.length} selected approach(es)?
						</Typography>
						<Typography sx={{ p: 2 }} display="block">
							Corresponding Action Cards will also be deleted.
						</Typography>
					</>
				)}
			</>
		</ModalDialog>
	);
};
