import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';

// Reducers
import applicationReducer from './application';
import projectReducer from './project';

import itemsReducer from './items';
import rolesReducer from './roles';
import actionsReducer from './actions';

const logger = createLogger();

// Store
export const store = configureStore({
	reducer: {
		application: applicationReducer,
		project: projectReducer,

		items: itemsReducer,
		roles: rolesReducer,
		actions: actionsReducer,
	},
	middleware: [logger],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
type AppDispatch = typeof store.dispatch;

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
