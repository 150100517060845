import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Monorepo
import {
	Entity,
	Group,
	GroupFieldDefinitions,
	FieldValue,
	Flag,
	ValidationError,
} from '@constituenthub/common';

// Components
import { Field } from '../fields/Field';
// import { FlagsField } from '../flags/FlagsField';

type GroupFormProps = {
	group: Partial<Group>;
	onChange: (group: Partial<Group>) => void;
	flags: Flag[];
	onFlagsChange: (items: Flag[]) => void;
	errors?: ValidationError[];
};

export const GroupForm = (props: GroupFormProps) => {
	const { group, onChange, errors } = props;

	const handleChange = (prop: string, value: FieldValue) => {
		onChange({ ...group, [prop]: value });
	};

	// const handleFlagsChange = (items: Flag[]) => {
	// 	onFlagsChange(items);
	// };

	const nameErrors = (errors || []).filter((x) => x.name === 'name');

	return (
		<Grid container direction="column" flexGrow={1} spacing={2}>
			<Grid item>
				<Field
					entity={Entity.Group}
					required
					definition={GroupFieldDefinitions.Name}
					value={group.name}
					onChange={(value: FieldValue) =>
						handleChange('name', value)
					}
					error={nameErrors.length > 0}
				/>
				{nameErrors.map((err) => (
					<Typography key={err.error} variant="caption" color="error" sx={{ pl: 1 }}>
						{err.error}
					</Typography>
				))}
			</Grid>
			<Grid item>
				<Field
					entity={Entity.Group}
					definition={GroupFieldDefinitions.Description}
					value={group.description}
					onChange={(value: FieldValue) =>
						handleChange('description', value)
					}
				/>
			</Grid>
			{/* <Grid item>
				<FlagsField
					label="Flags"
					parentId={null}
					value={flags}
					onChange={(items: Flag[]) => handleFlagsChange(items)}
				/>
			</Grid> */}
		</Grid>
	);
};
