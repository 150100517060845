import {
	AmplifyAuthContainer,
	AmplifyAuthenticator,
	AmplifySignIn,
	AmplifySignUp,
	AmplifyConfirmSignUp,
	AmplifyForgotPassword,
} from '@aws-amplify/ui-react';

export const Authenticator = () => (
	<AmplifyAuthContainer>
		<AmplifyAuthenticator usernameAlias="email" hideToast>
			<AmplifySignIn
				slot="sign-in"
				headerText="Welcome!"
				usernameAlias="email"
				hideSignUp
			>
				<div slot="header-subtitle">Sign in to your account.</div>
			</AmplifySignIn>
			<AmplifySignUp
				slot="sign-up"
				headerText="Create your account"
				usernameAlias="email"
			></AmplifySignUp>
			<AmplifyConfirmSignUp
				slot="confirm-sign-up"
				usernameAlias="email"
			></AmplifyConfirmSignUp>
			<AmplifyForgotPassword
				slot="forgot-password"
				usernameAlias="email"
			></AmplifyForgotPassword>
		</AmplifyAuthenticator>
	</AmplifyAuthContainer>
);
