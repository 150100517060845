import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';
import { isObject, isString } from 'lodash';
import { useDebounce } from 'react-use';

// Monorepo
import {
	FieldDefinition,
	MixedFieldContent,
	// createPropertySearchFilter,
} from '@constituenthub/common';

// Lib
import { SelectOption, errorOption } from '../common';
import { useAppContext } from '../../contexts/AppContext';

type FieldTextProps = {
	entity: string;
	definition: FieldDefinition;
	label: string;
	value: string;
	onChange: (value: string | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	placeholder?: string;
	readOnly?: boolean;
	mixedValue?: boolean;
};

type OptionsState = {
	options: SelectOption[];
	loaded: boolean;
};

export default function FieldTextAutoComplete({
	entity,
	definition,
	label,
	value,
	onChange,
	error,
	required,
	disabled,
	readOnly,
	mixedValue,
	placeholder,
}: FieldTextProps) {
	const theme = useTheme();
	const { api } = useAppContext();
	const [open, setOpen] = React.useState(false);

	const [optionsState, setOptionsState] = React.useState<OptionsState>({
		options: [],
		loaded: false,
	});
	// const [freeSoloText, setFreeSoloText] = React.useState('');

	const loading = open && optionsState.options.length === 0;

	const loadOptions = React.useCallback(
		async (prop: string, ent: string) => {
			try {
				const items = await api.fieldvalue.uniqueValues(ent, prop);
				const data = items.map((x) => ({ label: `${x}`, value: x }));
				setOptionsState({ options: data, loaded: true });
			} catch (error) {
				setOptionsState({ options: [errorOption], loaded: true });
			}
		},
		[api.fieldvalue]
	);

	const handleChange = (val: string | SelectOption | null) => {
		if (isString(val)) {
			onChange(val);
		} else if (isObject(val)) {
			const v = (val as SelectOption).value as string;
			console.log('FreeSoloText', v);
			onChange(v || null);
		} else {
			onChange(`${val || ''}` || null);
		}
	};

	React.useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		(async () => {
			if (active) {
				await loadOptions(definition.property, entity);
			}
		})();

		return () => {
			active = false;
		};
	}, [definition.property, entity, loadOptions, loading]);

	React.useEffect(() => {
		if (!open) {
			setOptionsState({ options: [], loaded: false });
		}
	}, [open]);

	console.log(`FieldTextAutoComplete: ${definition.property} = ${value}`);

	return (
		<Autocomplete
			fullWidth
			freeSolo
			autoSelect
			size="small"
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			isOptionEqualToValue={(option, val) => option.value === val.value}
			getOptionLabel={(option) => {
				if (isString(option)) {
					return option;
				} else {
					return (option as SelectOption).label;
				}
			}}
			value={value}
			options={optionsState.options}
			loading={loading}
			onChange={(e, option) => handleChange(option)}
			renderInput={(params) => (
				<TextField
					{...params}
					size="small"
					margin="dense"
					label={label}
					error={error}
					required={required}
					disabled={disabled}
					placeholder={mixedValue ? MixedFieldContent : placeholder}
					onChange={(e) => onChange(e.target.value || '')}
					sx={{
						backgroundColor: mixedValue
							? lighten(theme.palette.primary.main, 0.9)
							: 'initial',
					}}
				/>
			)}
		/>
	);
}
