import React from 'react';
import { SxProps, Theme } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

type ErrorMessageProps = {
	error?: any | null;
	sx?: SxProps<Theme>;
	onRetry?: () => void;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
	const { error, sx, onRetry } = props;
	if (!error) return null;

	const err = error as any;
	console.log('ErrorMessage', JSON.stringify(err));
	const message =
		err?.originalError?.message ||
		err?.message ||
		`${err}` ||
		'Unknown Error';
	const status = err?.originalError?.status || err?.status || 500;

	return (
		<Box sx={{ p: 2, ...sx }}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<Typography variant="h6">An error occurred</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						({status}) {message}
					</Typography>
				</Grid>
				{!!onRetry && (
					<Grid item>
						<Button
							size="small"
							variant="contained"
							onClick={onRetry}
						>
							Try Again
						</Button>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
