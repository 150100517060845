import React, { useState } from 'react';
import Draggable from 'react-draggable';

import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiDrawer from '@mui/material/Drawer';

// Lib
import { TOOLBAR_HEIGHT } from '../../lib';
import { useResponsive } from '../../hooks';

type DrawerProps = {
	name: string;
	align?: 'left' | 'right';
	scrollable?: boolean;
	toolbar?: boolean;
	open?: boolean;
	minWidth?: number;
	close?: () => void;
	responsive?: boolean;
	variant?: 'permanent' | 'persistent' | 'temporary';
	children: React.ReactNode;
	content: React.ReactNode;
	sx?: SxProps<Theme>;
};

export const Drawer = (props: DrawerProps) => {
	const ui = useResponsive();
	const [drawerWidth, setDrawerWidth] = useState(
		`${props.minWidth || 320}px`
	);
	const {
		variant = 'permanent',
		align = 'left',
		children,
		content,
		sx = {},
		toolbar = false,
		open = false,
		responsive = false,
		scrollable = false,
		minWidth = 320,
	} = props;

	let width = drawerWidth;
	let vari = variant;
	if (responsive && ui.ltMd) {
		vari = 'persistent';
		width = open ? '100%' : '0px';
	}
	if (variant === 'persistent' && !open) {
		width = '0px';
	}

	const handleWidthChange = (val: number) => {
		setDrawerWidth((s) => {
			const e = parseInt(s.replace('px', ''), 10);
			const w = align === 'left' ? e + val : e - val;
			return `${w < minWidth ? minWidth : w}px`;
		});
	};

	return (
		<Grid
			data-component="Drawer"
			container
			direction="row"
			sx={{
				position: 'relative',
				height: toolbar ? `calc(100% - ${TOOLBAR_HEIGHT}px)` : `100%`,
			}}
		>
			<Grid
				data-component="DrawerContent"
				item
				sx={{
					order: align === 'left' ? 1 : 3,
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					flexGrow: 1,
				}}
			>
				<MuiDrawer
					variant={vari}
					open={vari === 'permanent' ? true : open === true}
					anchor={align}
					sx={{
						width: width,
						flexShrink: 0,
						mt: toolbar ? `${TOOLBAR_HEIGHT}px` : 0,
						[`& .MuiDrawer-paper`]: {
							mt: toolbar
								? `${TOOLBAR_HEIGHT * 2}px`
								: `${TOOLBAR_HEIGHT}px`,
							width: width,
							boxSizing: 'border-box',
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							height: toolbar
								? `calc(100% - ${TOOLBAR_HEIGHT * 2}px)`
								: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
							overflow: 'hidden',
						},
					}}
				>
					<Box
						data-component="DrawerContentContainer"
						sx={{
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							flexGrow: 1,
							height: `100%`,
							overflow: 'hidden',
						}}
					>
						{content}
					</Box>
				</MuiDrawer>
			</Grid>
			<Grid
				data-component="DrawerChildren"
				item
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					backgroundColor: '#f6f6f6',
					position: 'relative',
					height: '100%',
					order: align === 'left' ? 3 : 1,
					overflowY: scrollable ? 'auto' : 'hidden',
					width: `calc(100% - ${width} - 8px)`,
					...sx,
				}}
			>
				{children}
			</Grid>
			<Draggable
				axis="x"
				position={{ x: 0, y: 0 }}
				onStop={(_e, data) => handleWidthChange(data.x)}
			>
				<Grid
					item
					sx={{
						order: 2,
						cursor: 'col-resize',
						position: 'relative',
						height: '100%',
						width: '8px',
						backgroundColor: 'transparent',
						zIndex: 1201,
						opacity: 1,
						display: vari === 'permanent' ? 'flex' : 'none',
						flexDirection: 'column',
						'&:hover': {
							backgroundColor: 'rgba(0,0,0,0.3)',
							opacity: 1,
							transition: ui.theme.transitions.create('*', {
								easing: ui.theme.transitions.easing.sharp,
								duration:
									ui.theme.transitions.duration.leavingScreen,
							}),
						},
						'&:active': {
							backgroundColor: 'rgba(0,0,0,0.3)',
							opacity: 1,
							transition: ui.theme.transitions.create('*', {
								easing: ui.theme.transitions.easing.sharp,
								duration:
									ui.theme.transitions.duration.leavingScreen,
							}),
						},
					}}
				>
					&nbsp;
				</Grid>
			</Draggable>
		</Grid>
	);
};
