import { Group } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class GroupApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listGroups(ids?: number[]): Promise<Group[]> {
		const response = ids
			? await this.client.get<Group[]>(`/group?items=${ids.join(',')}`)
			: await this.client.get<Group[]>(`/group`);
		return response.result;
	}

	public async createGroup(data: Partial<Group>): Promise<Group> {
		const response = await this.client.post<Partial<Group>, Group>(
			`/group`,
			data
		);
		return response.result;
	}

	public async getGroup(groupId: number): Promise<Group> {
		const response = await this.client.get<Group>(`/group/${groupId}`);
		return response.result;
	}

	public async isNameUnique(
		name: string,
		groupId?: number
	): Promise<boolean> {
		const response = await this.client.get<boolean>(
			`/group/validate/name/${name}/${groupId}`
		);
		return response.result;
	}

	public async updateGroup(data: Group): Promise<Group> {
		const response = await this.client.put<Group, Group>(
			`/group/${data.groupId}`,
			data
		);
		return response.result;
	}

	public async removeGroup(groupId: number): Promise<void> {
		await this.client.del<Group>(`/group/${groupId}`);
	}
}
