import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Monorepo
import { formatDateTime } from '@constituenthub/common';

type Props = {
	title: string;
};

export const ReportHeader = ({ title }: Props) => {
	return (
		<>
			<Box>
				<Typography variant="h5" fontWeight={600} align="center">
					{title}
				</Typography>
			</Box>
			<Box sx={{ pt: 1, pb: 2 }}>
				<Typography variant="body2" align="center">
					{formatDateTime(new Date())}
				</Typography>
			</Box>
			<Divider
				sx={{
					borderWidth: 1,
					borderColor: '#000',
				}}
			/>
		</>
	);
};
