import * as React from 'react';
import Grid from '@mui/material/Grid';

// Monorepo
import { ActionCard } from '@constituenthub/common';

// Components
import { ModalDialog, ErrorMessage, Loading } from '../common';
import { ActionCardBatchForm } from './ActionCardBatchForm';
import { useActionCardsController } from './useActionCardsController';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	title: string;
	open: boolean;
	onContinue: () => Promise<any>;
	onDelete?: () => Promise<any>;
	onClose: () => void;
	actionIds: number[];
	showName?: boolean;
	showApproach?: boolean;
};

type State = {
	working: boolean;
	error?: any;
};

export const BatchActionCardDialog = (props: Props) => {
	const {
		title,
		open,
		onContinue,
		onClose,
		showName,
		showApproach,
		actionIds,
	} = props;
	const { api } = useAppContext();
	const [state, setState] = React.useState<State>({ working: false });
	const controller = useActionCardsController();

	const [actions, setActions] = React.useState<ActionCard[]>([]);

	const getActions = React.useCallback(
		async (ids: number[]) => {
			try {
				setState((s) => ({ ...s, workiing: true, error: undefined }));
				const items = (await api.action.listActionCards({
					items: ids,
				})) as ActionCard[];
				setActions(items);
				setState((s) => ({ ...s, workiing: false }));
			} catch (error) {
				setState((s) => ({ ...s, workiing: false, error }));
			}
		},
		[api.action]
	);

	React.useEffect(() => {
		if (actionIds) {
			getActions(actionIds);
		}
	}, [actionIds, getActions]);

	return (
		<ModalDialog
			open={open}
			onClose={onClose}
			label={title}
			padding={2}
			fullScreen
		>
			<>
				<Loading enabled={state.working} text="Working..." />
				<ErrorMessage error={state.error} sx={{ minWidth: '320px' }} />
				{!state.error && !state.working && (
					<Grid
						container
						direction="column"
						wrap="nowrap"
						sx={{
							position: 'relative',
							height: '100%',
							overflow: 'hidden',
							flexGrow: 1,
						}}
					>
						<ActionCardBatchForm
							controller={controller}
							actions={actions}
							showName={showName}
							showApproach={showApproach}
							onChangesSaved={onContinue}
							sx={{
								height: `calc(100% - 64px)`,
							}}
						/>
					</Grid>
				)}
			</>
		</ModalDialog>
	);
};
