import React from 'react';

// Components
import { CellBase } from '../CellBase';

// Lib
import { CellRenderProps } from '../../types';

interface Props extends CellRenderProps {}

export const RowNumberCellRenderer = (props: Props) => {
	if (props.getId() === -1) {
		return (
			<CellBase {...props} style={props.style}>
				&nbsp;
			</CellBase>
		);
	}

	return (
		<CellBase
			{...props}
			onDoubleClick={() => {}}
			style={{
				...props.style,
				justifyContent: 'flex-end',
			}}
		>
			{props.rowIndex}&nbsp;
		</CellBase>
	);
};
