import { Project, ProjectView } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class ProjectApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listProjects(): Promise<ProjectView[]> {
		const response = await this.client.get<ProjectView[]>(`/project`);
		return response.result;
	}

	public async createProject(data: Partial<Project>): Promise<Project> {
		const response = await this.client.post<Partial<Project>, Project>(
			`/project`,
			data
		);
		return response.result;
	}

	public async getProject(projectId: number): Promise<Project> {
		const response = await this.client.get<Project>(
			`/project/${projectId}`
		);
		return response.result;
	}

	public async isNameUnique(
		name: string,
		projectId?: number
	): Promise<boolean> {
		const response = await this.client.get<boolean>(
			`/project/validate/name/${name}/${projectId}`
		);
		return response.result;
	}

	public async updateProject(data: Project): Promise<Project> {
		const response = await this.client.put<Project, Project>(
			`/project/${data.projectId}`,
			data
		);
		return response.result;
	}

	public async removeProject(projectId: number): Promise<void> {
		await this.client.del<Project>(`/project/${projectId}`);
	}
}
