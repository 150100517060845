import React from 'react';
import { SxProps, Theme } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Monorepo
import { Attachment } from '@constituenthub/common';

// Components
import { Progress, ErrorMessage } from '../common';

// Lib
import { useUpload } from './useUpload';

const uploadSx: SxProps<Theme> = {
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	p: 1,
	flexGrow: 1,
	border: '1px dashed #444444',
	cursor: 'pointer',
	'& span': {
		fontSize: 'typography.caption.fontSize',
	},
};

type UploadProps = {
	entity: string;
	parentId?: number;
	onUploadComplete: (attachments: Attachment[]) => void;
	onError: (error: any) => void;
	sx?: SxProps<Theme>;
};

export const Upload = ({
	entity,
	parentId,
	onUploadComplete,
	onError,
	sx = {},
}: UploadProps) => {
	const {
		getRootProps,
		getInputProps,
		status,
		totalUploadSize,
		currentUploadSize,
		error,
		reset,
	} = useUpload({ entity, parentId, onUploadComplete, onError });

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				...sx,
			}}
		>
			{status === 'uploading' && (
				<Box sx={uploadSx}>
					<Progress
						total={totalUploadSize}
						current={currentUploadSize}
						show={true}
					/>
					<Typography variant="caption">
						Please do not leave this page during the upload
					</Typography>
				</Box>
			)}
			{status === 'idle' && (
				<Box sx={uploadSx} {...getRootProps()}>
					<input {...getInputProps()} />
					<Typography variant="caption">Upload Files</Typography>
				</Box>
			)}
			{status === 'error' && (
				<Box sx={uploadSx}>
					<ErrorMessage error={error} onRetry={reset} />
				</Box>
			)}
		</Box>
	);
};
