import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';

// Icons
import {
	mdiClose,
	mdiPlusCircleOutline,
	mdiTrashCanOutline,
	mdiContentCopy,
} from '@mdi/js';

// Monorepo
import {
	encodeFilter,
	Entity,
	Filter,
	parseFilterFromUrl,
	View,
} from '@constituenthub/common';

// Components
import { MainLayout } from '../shell';
import { Drawer, NothingHere, IconButton } from '../common';
import { ChangeItemDetail } from './ChangeItemDetail';
import { ChangeItemList } from './ChangeItemList';
import { DeleteChangeItemsDialog } from './DeleteChangeItemsDialog';
import { CopyChangeItemsDialog } from './CopyChangeItemsDialog';
import { TOOLBAR_HEIGHT } from '../../lib';
import { FilterButton } from '../filters/FilterButton';
import { EditFilterDialog } from '../filters/EditFilterDialog';
import { useFieldDefinitions } from '../../hooks/useFieldDefinitions';
import { ViewButton } from '../view/ViewButton';

// Lib
import { useChangeItemsController } from './useChangeItemsController';
import { useAppContext } from '../../contexts/AppContext';

type DialogState = {
	key: string;
	parentId?: number;
	actionId?: number;
};

export const ChangeItemsPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const definition = useFieldDefinitions(Entity.ChangeItem);
	const { api } = useAppContext();
	const controller = useChangeItemsController();
	const hasSelection = controller.changeItemSelectionCount > 0;
	const items = controller.changeItems;
	const hasItems = items.length > 0;
	const [dialog, setDialog] = React.useState<DialogState>();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showCopyDialog, setShowCopyDialog] = useState(false);

	const filter = parseFilterFromUrl(location.search);

	const clearFilters = () => {
		setTimeout(() => {
			navigate(location.pathname);
		});
	};

	const handleOpenView = (view: View) => {
		const url =
			view && view.data.filter
				? `${location.pathname}?filter=${encodeFilter(
						view.data.filter as Filter
				  )}`
				: location.pathname;
		setTimeout(() => {
			navigate(url);
		});
	};

	const handleApplyFilters = (data: Filter | undefined) => {
		const url =
			data && data.length > 0
				? `${location.pathname}?filter=${encodeFilter(data)}`
				: location.pathname;
		setDialog(undefined);
		setTimeout(() => {
			navigate(url);
		});
	};

	const openFilters = () => {
		setDialog({ key: 'EditFilters' });
	};

	const getFilterCount = async (filter: Filter): Promise<number> => {
		try {
			console.log('filter changed', filter);
			const count = await api.changeitem.listChangeItems({
				filter,
				count: true,
			});
			return count as number;
		} catch (error) {
			console.error(error);
			return Promise.resolve(0);
		}
	};

	return (
		<MainLayout>
			<Toolbar>
				<ViewButton
					definition={definition}
					feature={Entity.ChangeItem}
					data={{ filter }}
					onViewClick={handleOpenView}
					sx={{ pr: 2 }}
					canSave={filter !== undefined}
					noSaveMessage="Apply some filters to save a view"
				/>
				<FilterButton
					filter={filter}
					onClear={clearFilters}
					onClick={openFilters}
				/>
				<span style={{ flexGrow: 1 }} />
			</Toolbar>
			<Drawer
				open={hasSelection}
				name="ChangeItemPage"
				align="right"
				scrollable
				toolbar
				minWidth={530}
				variant={hasSelection ? 'permanent' : 'persistent'}
				content={
					hasSelection ? (
						<ChangeItemDetail
							controller={controller}
							filter={filter}
						/>
					) : null
				}
			>
				{!!hasItems && (
					<Toolbar variant="dense">
						<IconButton
							path={mdiClose}
							size={1}
							variant="small"
							title="Clear Selected"
							disabled={!hasSelection}
							onClick={() =>
								controller.onClearSelectedChangeItems()
							}
							sx={{ ml: 0.7, mr: 2 }}
						/>
						<Typography variant="body2" flexGrow={1}>
							{controller.changeItemSelectionCount} Items Selected
						</Typography>
						<IconButton
							path={mdiPlusCircleOutline}
							size={0.9}
							variant="small"
							title="Add Change Item"
							onClick={() => controller.onAddNewChangeItem()}
							sx={{ mx: 1 }}
						/>
						<IconButton
							path={mdiContentCopy}
							size={0.8}
							variant="small"
							title="Copy Selected Change Items"
							disabled={!hasSelection}
							onClick={() => setShowCopyDialog(true)}
							sx={{ mx: 1 }}
						/>
						<IconButton
							path={mdiTrashCanOutline}
							size={1}
							variant="small"
							title="Delete Selected Change Items"
							disabled={!hasSelection}
							onClick={() => setShowDeleteDialog(true)}
							sx={{ mx: 1 }}
						/>
					</Toolbar>
				)}
				<Box
					data-component="ChangeItemPageContent"
					sx={{
						position: 'relative',
						width: '100%',
						height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						px: 2,
						pb: 2,
					}}
				>
					<>
						{controller.loaded && items.length === 0 && (
							<NothingHere>
								<Box sx={{ p: 2, textAlign: 'center' }}>
									{filter === undefined && (
										<Typography
											component="div"
											variant="body1"
										>
											It looks like you don't have any
											Change Items yet.
										</Typography>
									)}
									{filter !== undefined && (
										<Typography
											component="div"
											variant="body1"
										>
											The current filter does not yield
											any results.
										</Typography>
									)}
								</Box>
								{filter === undefined && (
									<Box sx={{ p: 2, textAlign: 'center' }}>
										<Button
											variant="outlined"
											onClick={() =>
												controller.onAddNewChangeItem()
											}
										>
											Add your first Change Item
										</Button>
									</Box>
								)}
							</NothingHere>
						)}
						{!!hasItems && (
							<ChangeItemList controller={controller} />
						)}
					</>
				</Box>
			</Drawer>
			{dialog && dialog.key === 'EditFilters' && (
				<EditFilterDialog
					filter={filter}
					definition={definition}
					entity={Entity.ChangeItem}
					onClose={() => setDialog(undefined)}
					onContinue={handleApplyFilters}
					onGetFilterCount={getFilterCount}
				/>
			)}
			<DeleteChangeItemsDialog
				items={controller.selectedChangeItems}
				open={showDeleteDialog}
				onClose={() => setShowDeleteDialog(false)}
				onItemsDeleted={() => setShowDeleteDialog(false)}
			/>
			<CopyChangeItemsDialog
				items={controller.selectedChangeItems}
				open={showCopyDialog}
				onClose={() => setShowCopyDialog(false)}
				onItemsCopied={(copies) => {
					controller.selectChangeItems(
						copies.map((x) => x.changeItemId)
					);
					setShowCopyDialog(false);
				}}
			/>
		</MainLayout>
	);
};
