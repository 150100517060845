import React from 'react';
import MuiAutocomplete, {
	AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';

// Monorepo
import { MixedFieldContent } from '@constituenthub/common';

export type SelectOption = {
	label: string;
	value: string | number | boolean | null | undefined;
};

export const errorOption: SelectOption = {
	label: 'Error Loading Options',
	value: null,
};

type AutoCompleteProps = {
	label?: string;
	required?: boolean;
	disabled?: boolean;
	error?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
	emptyOption?: boolean;
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: SelectOption,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
	value?: string | number | boolean | null | undefined;
	onChange: (value: string | number | boolean | null) => void;
	options: SelectOption[];
};

export const AutoComplete = (props: AutoCompleteProps) => {
	const {
		label,
		required = false,
		disabled = false,
		error = false,
		readOnly = false,
		mixedValue = false,
		value,
		onChange,
		options,
		renderOption,
	} = props;
	const theme = useTheme();

	const onOptionSelected = (item: SelectOption | null | undefined) => {
		const option = options.find((x) => x.value === item?.value);
		onChange(option?.value || null);
	};

	const onTextInputChanged = (value?: string | null) => {
		onChange(value || '');
	};

	return (
		<MuiAutocomplete
			isOptionEqualToValue={(option, value) =>
				option.value === value.value
			}
			autoHighlight
			freeSolo={true}
			autoComplete={false}
			disabled={disabled || readOnly}
			autoSelect={false}
			clearOnEscape
			openOnFocus={false}
			value={options.find((x) => x.value === value) || null}
			onChange={(_event, option: any) => onOptionSelected(option)}
			options={
				options.length ? options : [{ label: 'loading', value: null }]
			}
			renderOption={renderOption}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={mixedValue ? MixedFieldContent : label}
					autoComplete="off"
					variant="outlined"
					size="small"
					margin="dense"
					fullWidth
					required={required}
					disabled={disabled}
					error={error}
					onChange={(event) => onTextInputChanged(event.target.value)}
					onFocus={(event) => event.target.select()}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						autoComplete: 'off',
						required: required,
						disabled: disabled,
						readOnly: readOnly,
						error: error,
						sx: {
							backgroundColor: mixedValue
								? lighten(theme.palette.primary.main, 0.9)
								: 'initial',
						},
					}}
				/>
			)}
		/>
	);
};
