import React, { useState } from 'react';
import Button from '@mui/material/Button';

// Monorepo
import { ChangeItem } from '@constituenthub/common';

// Components
import {
	ModalDialog,
	ErrorMessage,
	Loading,
	MultipleAffected,
} from '../common';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
	items: ChangeItem[];
	open: boolean;
	onClose: () => void;
	onItemsDeleted: () => void;
};

type State = {
	working: boolean;
	error?: Error;
};

const initialState = {
	working: false,
};

export const DeleteChangeItemsDialog = ({
	items,
	open,
	onClose,
	onItemsDeleted,
}: Props) => {
	const { api } = useAppContext();
	const [state, setState] = useState<State>(initialState);

	const handleClose = () => {
		setState(initialState);
		onClose();
	};

	const handleResetError = () => {
		setState((s) => ({ ...s, error: undefined }));
	};

	const handleDeleteItems = async () => {
		setState((s) => ({ ...s, working: true, error: undefined }));
		try {
			await api.changeitem.deleteChangeItems(
				items.map((x) => x.changeItemId)
			);
			onItemsDeleted();
			setState(initialState);
		} catch (error) {
			setState((s) => ({ ...s, working: false, error: error as Error }));
		}
	};

	return (
		<ModalDialog
			open={open}
			onClose={handleClose}
			label="Delete Change Items"
			padding={2}
			actions={
				<>
					{!state.error && (
						<Button
							variant="contained"
							onClick={handleDeleteItems}
							disabled={state.working}
						>
							Delete Items
						</Button>
					)}
					{!!state.error && (
						<Button variant="contained" onClick={handleResetError}>
							Try Again
						</Button>
					)}
				</>
			}
		>
			<>
				<Loading enabled={state.working} text="Working..." />
				<ErrorMessage error={state.error} sx={{ minWidth: '320px' }} />
				{!state.error && !state.working && (
					<MultipleAffected
						items={items}
						message={`The following ${items.length} Change Items will be deleted`}
						getText={(item: ChangeItem) =>
							item.fields.name as string
						}
						getId={(item: ChangeItem) => item.changeItemId}
						expandByDefault={true}
					/>
				)}
			</>
		</ModalDialog>
	);
};
