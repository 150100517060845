/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Monorepo
import { ChangeItem } from '@constituenthub/common';

// Lib
import type { RootState } from '.';

export interface State {
	items: ChangeItem[];
	selectedItems: Record<number, boolean>;
}

const initialState: State = {
	items: [],
	selectedItems: {},
};

export const slice = createSlice({
	name: 'items',
	initialState,
	reducers: {
		setClearItemsState: (state) => {
			state.items = [];
			state.selectedItems = {};
		},
		setItemsSelected: (state, action: PayloadAction<number[]>) => {
			const selected: Record<number, boolean> = {};
			action.payload.forEach((id) => {
				selected[id] = true;
			});
			state.selectedItems = selected;
		},
		setItemsSelectedToggleAll: (state) => {
			const selected: Record<number, boolean> = {};
			if (
				Object.keys(state.selectedItems).length !== state.items.length
			) {
				state.items.forEach((item) => {
					selected[item.changeItemId] = true;
				});
			}
			state.selectedItems = selected;
		},
		setItemsSelectedToggle: (state, action: PayloadAction<number>) => {
			const selected = { ...state.selectedItems };
			if (selected[action.payload]) {
				delete selected[action.payload];
			} else {
				selected[action.payload] = true;
			}
			state.selectedItems = selected;
		},
		setItems: (state, action: PayloadAction<ChangeItem[]>) => {
			state.items = action.payload;
		},
		setItemsAdded: (state, action: PayloadAction<ChangeItem[]>) => {
			const selected: Record<number, boolean> = {};
			action.payload.forEach((item) => {
				state.items.push(item);
				selected[item.changeItemId] = true;
			});
			state.selectedItems = selected;
		},
		setItemsUpdated: (state, action: PayloadAction<ChangeItem[]>) => {
			const updates = action.payload;
			updates.forEach((item) => {
				const index = state.items.findIndex(
					(x) => x.changeItemId === item.changeItemId
				);
				if (index !== -1) {
					state.items.splice(index, 1, item);
				} else {
					state.items.push(item);
				}
			});
		},
		setItemsRemoved: (state, action: PayloadAction<ChangeItem[]>) => {
			const removes = action.payload;
			removes.forEach((item) => {
				const index = state.items.findIndex(
					(x) => x.changeItemId === item.changeItemId
				);
				if (index !== -1) {
					state.items.splice(index, 1);
				}
			});
		},
		setItemsRemovedById: (state, action: PayloadAction<number[]>) => {
			const removes = action.payload;
			removes.forEach((id) => {
				const index = state.items.findIndex(
					(x) => x.changeItemId === id
				);
				if (index !== -1) {
					state.items.splice(index, 1);
				}
			});
		},
	},
});

export const {
	setClearItemsState,
	setItemsSelected,
	setItemsSelectedToggleAll,
	setItemsSelectedToggle,
	setItems,
	setItemsAdded,
	setItemsUpdated,
	setItemsRemoved,
	setItemsRemovedById,
} = slice.actions;

export const selectItems = (state: RootState) => state.items.items;
export const selectSelectedItems = (state: RootState) =>
	state.items.selectedItems;

export default slice.reducer;
