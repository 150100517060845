import {
	mdiDelta,
	mdiAccountGroupOutline,
	mdiBullseyeArrow,
	mdiClipboardCheckMultipleOutline,
	mdiAccountCircleOutline,
	mdiHelpCircleOutline,
	mdiHomeCityOutline,
	mdiFormTextbox,
	mdiDomain,
	mdiClipboardPlayOutline,
	mdiClipboardCheckOutline,
	mdiClipboardTextOutline,
	mdiClipboardClockOutline,
	mdiClipboardPlusOutline,
} from '@mdi/js';

export const ICON_CHANGE = mdiDelta;
export const ICON_GROUPS = mdiAccountGroupOutline;
export const ICON_APPROACHES = mdiBullseyeArrow;
export const ICON_ACTIONCARDS = mdiClipboardCheckMultipleOutline;
export const ICON_PROFILE = mdiAccountCircleOutline;
export const ICON_HELP = mdiHelpCircleOutline;
export const ICON_PROJECT = mdiHomeCityOutline;

export const ICON_FIELD = mdiFormTextbox;
export const ICON_ORGANIZATION = mdiDomain;

export const ICON_ADD_ACTION = mdiClipboardPlusOutline;
export const ICON_TODO = mdiClipboardClockOutline;
export const ICON_INPROGRESS = mdiClipboardPlayOutline;
export const ICON_ONGOING = mdiClipboardTextOutline;
export const ICON_COMPLETE = mdiClipboardCheckOutline;
