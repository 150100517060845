import React from 'react';
import { MultiGrid, AutoSizer, GridCellProps } from 'react-virtualized';
import { useTheme } from '@mui/material';

// Lib
import {
	DataId,
	RendererApi,
	HeaderRenderProps,
	CellProps,
	CellRenderProps,
	CellPlugin,
} from './types';

// Styles
import {
	DEFAULT_ROW_HEIGHT,
	getBottomLeftGridStyle,
	getBottomRightGridStyle,
	getContainerStyle,
	getMultiGridStyle,
	getTopLeftGridStyle,
	getTopRightGridStyle,
	getHeaderStyle,
	getCellStyle,
} from './styles';

type TableProps = {
	rows: any[];
	getId: (data: any) => DataId;
	columns: RendererApi[];
	onScrollBottom: () => void;
	rowHeight?: number;
	plugins?: CellPlugin[];
	show?: boolean;
};

export const Table = (props: TableProps) => {
	const theme = useTheme();
	const {
		rows,
		getId,
		columns,
		onScrollBottom,
		rowHeight = DEFAULT_ROW_HEIGHT,
		plugins = [],
		show = true,
	} = props;

	// Cell Events
	const onKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onKeyPress ? plugin.onKeyPress(event, props) : {}
		);
	};

	const onKeyDown = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onKeyDown ? plugin.onKeyDown(event, props) : {}
		);
	};

	const onKeyUp = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onKeyUp ? plugin.onKeyUp(event, props) : {}
		);
	};

	const onFocus = (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onFocus ? plugin.onFocus(event, props) : {}
		);
	};
	const onBlur = (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onBlur ? plugin.onBlur(event, props) : {}
		);
	};

	const onClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onClick ? plugin.onClick(event, props) : {}
		);
	};
	const onDoubleClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onDoubleClick ? plugin.onDoubleClick(event, props) : {}
		);
	};
	const onMouseOver = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onMouseOver ? plugin.onMouseOver(event, props) : {}
		);
	};
	const onMouseOut = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: CellRenderProps
	) => {
		plugins.forEach((plugin) =>
			plugin.onMouseOut ? plugin.onMouseOut(event, props) : {}
		);
	};

	// Header Events
	const onHeaderKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};

	const onHeaderKeyDown = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};

	const onHeaderKeyUp = (
		event: React.KeyboardEvent<HTMLDivElement>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};

	const onHeaderFocus = (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};
	const onHeaderBlur = (
		event: React.FocusEvent<HTMLDivElement, Element>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};

	const onHeaderClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};
	const onHeaderDoubleClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};
	const onHeaderMouseOver = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};
	const onHeaderMouseOut = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		props: HeaderRenderProps
	) => {
		// console.log(event, props);
	};

	const getHeaderProps = (props: GridCellProps): HeaderRenderProps => {
		const { columnIndex, isScrolling, isVisible, style } = props;
		let headerStyle = getHeaderStyle(style, theme);
		return {
			columnIndex,
			rowIndex: 0,
			isScrolling,
			isVisible,
			style: headerStyle,
			column: columns[props.columnIndex],
			columns,
			getId: (data) => getId(data),
			rows,
			api: {
				onKeyPress: onHeaderKeyPress,
				onKeyDown: onHeaderKeyDown,
				onKeyUp: onHeaderKeyUp,
				onFocus: onHeaderFocus,
				onBlur: onHeaderBlur,
				onClick: onHeaderClick,
				onDoubleClick: onHeaderDoubleClick,
				onMouseOver: onHeaderMouseOver,
				onMouseOut: onHeaderMouseOut,
			},
		};
	};

	const getCellProps = (props: GridCellProps): CellProps => {
		const { columnIndex, rowIndex, isScrolling, isVisible, style } = props;
		const row = rows[rowIndex - 1];
		let cellStyle = getCellStyle(style, theme);
		return {
			columnIndex,
			rowIndex,
			isScrolling,
			isVisible,
			style: cellStyle,
			column: columns[props.columnIndex],
			columns,
			getId: () => getId(row),
			rows,
			row,
		};
	};

	const getCellRenderProps = (props: GridCellProps): CellRenderProps => {
		const cellProps = getCellProps(props);

		let cellStyle = cellProps.style;
		plugins.forEach((plugin) => {
			cellStyle = plugin.getCellStyle(cellStyle, cellProps);
		});

		return {
			...cellProps,
			style: cellStyle,
			api: {
				onKeyPress,
				onKeyDown,
				onKeyUp,
				onFocus,
				onBlur,
				onClick,
				onDoubleClick,
				onMouseOver,
				onMouseOut,
			},
		};
	};

	return (
		<AutoSizer
			style={{
				display: show === true ? 'flex' : 'none',
			}}
		>
			{({ height, width }) => (
				<MultiGrid
					height={height}
					width={width}
					fixedColumnCount={
						columns.filter((x) => x.type === 'Action').length
					}
					fixedRowCount={1}
					columnCount={columns.length}
					columnWidth={(index) => columns[index.index].width}
					rowCount={rows.length + 1}
					rowHeight={rowHeight}
					cellRenderer={(props) => {
						const column = columns[props.columnIndex];
						if (props.rowIndex === 0) {
							return column.renderHeader(getHeaderProps(props));
						}
						return column.renderCell(getCellRenderProps(props));
					}}
					onSectionRendered={({ rowStopIndex }) => {
						if (rowStopIndex + 2 === rows.length) {
							onScrollBottom();
						}
					}}
					enableFixedColumnScroll
					enableFixedRowScroll
					hideTopRightGridScrollbar
					hideBottomLeftGridScrollbar
					style={getMultiGridStyle(theme)}
					containerStyle={getContainerStyle(theme)}
					styleTopLeftGrid={getTopLeftGridStyle(theme)}
					styleTopRightGrid={getTopRightGridStyle(theme)}
					styleBottomLeftGrid={getBottomLeftGridStyle(theme)}
					styleBottomRightGrid={getBottomRightGridStyle(theme)}
				/>
			)}
		</AutoSizer>
	);
};
