import { Note } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class NoteApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listNotes(entity: string, parentId: number): Promise<Note[]> {
		const response = await this.client.get<Note[]>(
			`/note/${entity}/${parentId}`
		);
		return response.result;
	}

	public async createNote(
		entity: string,
		parentId: number,
		data: Partial<Note>
	): Promise<Note> {
		const response = await this.client.post<Partial<Note>, Note>(
			`/note/${entity}/${parentId}`,
			data
		);
		return response.result;
	}

	public async getNote(noteId: number): Promise<Note> {
		const response = await this.client.get<Note>(`/note/${noteId}`);
		return response.result;
	}

	public async updateNote(data: Note): Promise<Note> {
		const response = await this.client.put<Note, Note>(
			`/note/${data.noteId}`,
			data
		);
		return response.result;
	}

	public async removeNote(noteId: number): Promise<void> {
		await this.client.del<Note>(`/note/${noteId}`);
	}
}
