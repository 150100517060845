/* eslint-disable no-console */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

// Monorepo
import { JobRole, Group } from '@constituenthub/common';

// Lib
import type { RootState } from '.';

export interface State {
	roles: JobRole[];
	groups: Group[];
	selectedGroups: Record<number, boolean>;
}

const initialState: State = {
	roles: [],
	groups: [],
	selectedGroups: {},
};

export const slice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		setClearRolesState: (state) => {
			state.roles = [];
			state.groups = [];
			state.selectedGroups = {};
		},
		setRolesSelected: (state, action: PayloadAction<number[]>) => {
			const selected: Record<number, boolean> = {};
			action.payload.forEach((id) => {
				selected[id] = true;
			});
			state.selectedGroups = selected;
		},
		setRolesSelectedToggleAll: (state) => {
			const selected: Record<number, boolean> = {};
			if (Object.keys(state.selectedGroups).length === 0) {
				state.groups.forEach((group) => {
					selected[group.groupId] = true;
				});
			}
			state.selectedGroups = selected;
		},
		setRolesSelectedToggle: (state, action: PayloadAction<number>) => {
			const selected = { ...state.selectedGroups };
			if (selected[action.payload]) {
				delete selected[action.payload];
			} else {
				selected[action.payload] = true;
			}
			state.selectedGroups = selected;
		},
		setRoles: (state, action: PayloadAction<JobRole[]>) => {
			state.roles = action.payload;
			state.groups = [];
			state.selectedGroups = {};
		},
		setRoleGroups: (state, action: PayloadAction<Group[]>) => {
			state.groups = action.payload;
		},
		setRolesAdded: (state, action: PayloadAction<JobRole[]>) => {
			const selected: Record<number, boolean> = {};
			action.payload.forEach((role) => {
				state.roles.push(role);
				selected[role.groupId] = true;
			});
			state.selectedGroups = selected;
		},
		setRolesUpdated: (state, action: PayloadAction<JobRole[]>) => {
			const updates = action.payload;
			const roles = [...state.roles];
			updates.forEach((role) => {
				const index = roles.findIndex(
					(x) => x.jobRoleId === role.jobRoleId
				);
				if (index !== -1) {
					roles.splice(index, 1, role);
				}
			});
			state.roles = roles;
			state.selectedGroups = { ...state.selectedGroups };
		},
		setRolesRemoved: (state, action: PayloadAction<JobRole[]>) => {
			const removes = action.payload;
			const selected = {
				...state.selectedGroups,
			};
			removes.forEach((role) => {
				const index = state.roles.findIndex(
					(x) => x.jobRoleId === role.jobRoleId
				);
				if (index !== -1) {
					state.roles.splice(index, 1);
					delete selected[role.groupId];
				}
			});
			state.selectedGroups = selected;
		},
	},
});

export const {
	setClearRolesState,
	setRolesSelected,
	setRolesSelectedToggleAll,
	setRolesSelectedToggle,
	setRoles,
	setRoleGroups,
	setRolesAdded,
	setRolesUpdated,
	setRolesRemoved,
} = slice.actions;

export const selectRoles = (state: RootState) => state.roles.roles;

export const selectRoleGroups = (state: RootState) => state.roles.groups;

export const selectGroupSelection = (state: RootState) =>
	state.roles.selectedGroups;

export const selectSelectedGroups = createSelector(
	selectGroupSelection,
	(selection) =>
		Object.keys(selection)
			.map((x) => parseInt(x, 10))
			.filter((x) => selection[x] === true)
);

export const selectSelectedRoles = createSelector(
	selectRoles,
	selectSelectedGroups,
	(roles, groupIds) => roles.filter((x) => groupIds.includes(x.groupId))
);

export default slice.reducer;
