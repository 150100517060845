import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { SxProps, Theme } from '@mui/system';

// Icons
import Icon from '@mdi/react';
import { mdiClose, mdiDotsHorizontalCircleOutline } from '@mdi/js';

// Monorepo
import {
	formatDateTime,
	fromDateTimeString,
	toDateTimeString,
} from '@constituenthub/common';

// Components
import { ButtonField, ButtonFieldEvent } from './ButtonField';
import { FlexRow } from './FlexRow';
import { DateTime } from 'luxon';

type Props = {
	caption: string;
	primary: string;
	secondary?: string;
	tip?: string;
	sx?: SxProps<Theme>;
	mixedValue?: boolean;
	value: string | null;
	onChange: (value: string | null) => void;
};

export const ButtonFieldDate = ({
	caption,
	primary,
	secondary,
	tip,
	value,
	onChange,
	mixedValue,
	sx = {},
}: Props) => {
	const [fieldEvent, setFieldEvent] = React.useState<ButtonFieldEvent>();

	const handleClearValue = () => {
		onChange(null);
		closePopup();
	};

	const closePopup = () => {
		if (
			fieldEvent &&
			fieldEvent.name === 'opened' &&
			!!fieldEvent.onCloseCallback
		) {
			fieldEvent.onCloseCallback();
		}
	};

	const handleChange = (value: DateTime | null) => {
		console.log('ButtonFieldDate:handleChange', value);
		if (value && value.isValid) {
			onChange(toDateTimeString(value.toJSDate()));
		} else {
			onChange(null);
		}
		if (
			fieldEvent &&
			fieldEvent.name === 'opened' &&
			!!fieldEvent.onCloseCallback
		) {
			fieldEvent.onCloseCallback();
		}
	};

	return (
		<ButtonField
			caption={caption}
			primary={primary}
			secondary={secondary}
			tip={tip}
			icon={mdiDotsHorizontalCircleOutline}
			sx={sx}
			mixedValue={mixedValue}
			onEvent={setFieldEvent}
		>
			<Box
				sx={{
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				}}
			>
				<Box
					sx={{
						pt: -1,
						pb: 1,
						pl: 3,
						pr: 2,
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<Typography
						flexGrow={1}
						variant="body1"
						fontWeight={600}
						color="primary"
					>
						{value ? formatDateTime(value) : 'Select a date'}
					</Typography>
					<Tooltip title="Close">
						<IconButton size="small" onClick={closePopup}>
							<Icon path={mdiClose} size={1} />
						</IconButton>
					</Tooltip>
				</Box>
				<Divider />
				<StaticDatePicker
					displayStaticWrapperAs="desktop"
					openTo="day"
					value={fromDateTimeString(value)}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
				/>
				<Divider />
				<FlexRow sx={{ p: 1 }}>
					<span style={{ flexGrow: 1 }} />
					<Button onClick={handleClearValue}>Clear Value</Button>
				</FlexRow>
			</Box>
		</ButtonField>
	);
};
