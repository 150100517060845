import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

// Monorepo
import { JobRole } from '@constituenthub/common';

// Lib
import { JobRolesControllerHook } from './useJobRolesController';
import { useBatchEditFields } from '../fields/useBatchEditFields';
import { AffectedJobRolesForm } from './AffectedJobRolesForm';

type Props = {
	controller: JobRolesControllerHook;
};

export const AffectedJobRolesBatchForm = (props: Props) => {
	const { definition, selectedJobRoles, onJobRolesUpdated } =
		props.controller;

	const {
		changes,
		mixedValues,
		onChange,
		loadItems,
		hasChanges,
		getUpdates,
	} = useBatchEditFields({
		definition,
		excludeProperties: [],
	});

	const saveChanges = () => {
		const updates = getUpdates();
		onJobRolesUpdated(updates as JobRole[]);
	};

	useEffect(() => {
		loadItems(selectedJobRoles);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedJobRoles]);

	const hasBeenChanged = hasChanges();

	return (
		<Grid
			container
			direction="column"
			flexGrow={1}
			spacing={2}
			wrap="nowrap"
			sx={{
				position: 'relative',
				height: '100%',
				overflow: 'hidden',
				'& .MuiGrid-item': {
					mb: 0.75,
				},
			}}
		>
			<AffectedJobRolesForm
				definition={definition}
				fields={changes}
				onChange={onChange}
				mixedValues={mixedValues}
			/>
			<Grid item>
				<Toolbar variant="dense" disableGutters>
					<span style={{ flexGrow: 1 }} />
					<Button
						variant="contained"
						disabled={!hasBeenChanged}
						onClick={() => saveChanges()}
					>
						Save Changes
					</Button>
				</Toolbar>
			</Grid>
		</Grid>
	);
};
