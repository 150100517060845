import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiTooltip, {
	TooltipProps,
	tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { SxProps, Theme, useTheme } from '@mui/system';

type Props = {
	caption: string;
	primary: string;
	secondary?: string;
	tip?: string;
	sx?: SxProps<Theme>;
};

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	padding: theme.spacing(1, 2, 2, 2),
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	[`& .${tooltipClasses.tooltip}`]: {
		fontSize: theme.typography.body2.fontSize,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
}));

export const DataField = (props: Props) => {
	const theme = useTheme();
	const { caption, primary, secondary, tip, sx } = props;
	return (
		<Tooltip title={tip || ''} enterDelay={200}>
			<Box sx={{ ...sx }}>
				<Typography
					variant="caption"
					color={theme.palette.primary.main}
					sx={{ display: 'block' }}
				>
					{caption}
				</Typography>
				<Typography variant="body2" sx={{ display: 'block' }}>
					{primary}
				</Typography>
				{secondary && (
					<Typography variant="caption" sx={{ display: 'block' }}>
						{secondary}
					</Typography>
				)}
			</Box>
		</Tooltip>
	);
};
