import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import { toast } from 'react-toastify';

// Monorepo
import { Attachment, createAttachmentFromFile } from '@constituenthub/common';
import { useAppContext } from '../../contexts/AppContext';
import { useAppSelector } from '../../store';
import { selectProject } from '../../store/project';

export type UseUploadProps = {
	entity: string;
	parentId?: number;
	onUploadComplete: (attachments: Attachment[]) => void;
	onError: (error: any) => void;
};

export type UploadStatus = 'idle' | 'uploading' | 'complete' | 'error';

export type UseUploadHook = {
	getRootProps: any;
	getInputProps: any;
	totalUploadSize: number;
	currentUploadSize: number;
	status: UploadStatus;
	error?: any;
	reset: () => void;
};

type UploadState = {
	totalUploadSize: number;
	currentUploadSize: number;
	error?: any;
	status: UploadStatus;
	uploads: Record<string, UploadProgress>;
};

const initialState = {
	totalUploadSize: 0,
	currentUploadSize: 0,
	status: 'idle' as UploadStatus,
	uploads: {},
};

type UploadProgress = {
	id: string;
	total: number;
	current: number;
};

export const useUpload = ({
	entity,
	parentId,
	onUploadComplete,
	onError,
}: UseUploadProps): UseUploadHook => {
	const { api, account } = useAppContext();
	const project = useAppSelector(selectProject);
	const accountId = account?.accountId;
	const projectId = project?.projectId;

	const [state, setState] = useState<UploadState>(initialState);

	const handleProcessAttachment = useCallback(
		async (file: File): Promise<Attachment> => {
			if (!accountId || !projectId || !entity || !parentId) {
				throw new Error(
					'Cannot process attachments because the required information is unavailable'
				);
			}

			const data = createAttachmentFromFile(file);
			const attachment = await api.attachment.createAttachment(
				entity,
				parentId,
				data
			);

			const result = await Storage.put(attachment.path, file, {
				level: 'public',
				contentType: attachment.type,
				errorCallback: (err) => {
					throw err;
				},
				progressCallback: (progress: ProgressEvent) => {
					setState((s) => {
						const ns = { ...s };
						ns.uploads[file.name].current = progress.loaded;
						ns.currentUploadSize = 0;
						Object.values(ns.uploads).forEach(
							(x) => (ns.currentUploadSize += x.current)
						);
						return ns;
					});
				},
			});
			console.log('Uploaded File', result.key);
			toast.info(`Uploaded: ${attachment.name}`);
			return attachment;
		},
		[api.attachment, accountId, entity, parentId, projectId]
	);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			if (accountId && projectId && entity && parentId) {
				try {
					let totalUploadSize = 0;
					const uploads: Record<string, UploadProgress> = {};
					acceptedFiles.forEach((file) => {
						totalUploadSize += file.size;
						uploads[file.name] = {
							id: file.name,
							total: file.size,
							current: 0,
						};
					});
					setState({
						...initialState,
						totalUploadSize,
						uploads,
						status: 'uploading',
					});

					const attachments = await Promise.all(
						acceptedFiles.map(handleProcessAttachment)
					);

					setState((s) => ({
						...s,
						status: 'idle',
					}));

					onUploadComplete(attachments);
				} catch (error) {
					console.log(error);
					setState((s) => ({
						...s,
						status: 'error',
						error,
					}));
					onError(error);
				}
			}
		},
		[
			accountId,
			entity,
			parentId,
			projectId,
			handleProcessAttachment,
			onUploadComplete,
			onError,
		]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		noKeyboard: true,
	});

	return {
		getRootProps,
		getInputProps,
		totalUploadSize: state.totalUploadSize,
		currentUploadSize: state.currentUploadSize,
		status: state.status,
		error: state.error,
		reset: () => setState(initialState),
	};
};
