import React from 'react';
import { SxProps, Theme, useTheme } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

type CaptionDividerProps = {
	text: string;
	sx?: SxProps<Theme>;
};

export const CaptionDivider = ({ text, sx }: CaptionDividerProps) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				p: 1,
				my: 1,
				...sx,
			}}
		>
			<Typography
				variant="caption"
				sx={{
					color: theme.palette.primary.main,
				}}
			>
				{text}
			</Typography>
			<Divider />
		</Box>
	);
};
