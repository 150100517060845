import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

// Icons
import Icon from '@mdi/react';
import { mdiClose, mdiLogoutVariant, mdiMenu } from '@mdi/js';

// Components
import { Drawer } from '../common';
import { MainLayout } from '../shell';

// Lib
import { ICON_PROFILE, ICON_ORGANIZATION, ICON_APPROACHES } from '../../lib';
import { useAppContext } from '../../contexts/AppContext';

export const SettingsPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { signout, user, account, permissions } = useAppContext();
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

	console.log('Pernmissions', permissions);

	return (
		<MainLayout>
			<Toolbar sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
				<IconButton
					size="small"
					edge="start"
					onClick={() => setIsSidebarOpen(true)}
					sx={{
						display: {
							xs: 'inherit',
							sm: 'inherit',
							md: 'none',
						},
						mr: 1,
					}}
				>
					<Icon path={mdiMenu} size={1} />
				</IconButton>
				<Typography variant="body2" fontWeight="bold">
					{account?.name}: {user?.first} {user?.last}
				</Typography>
			</Toolbar>
			<Drawer
				name="Profile"
				open={isSidebarOpen}
				close={() => setIsSidebarOpen(false)}
				scrollable
				toolbar
				responsive
				content={
					<>
						<Toolbar>
							<Typography variant="body2" flexGrow={1}>
								Settings
							</Typography>
							<IconButton
								size="small"
								edge="end"
								onClick={() => setIsSidebarOpen(false)}
								sx={{
									display: {
										xs: 'inherit',
										sm: 'inherit',
										md: 'none',
									},
								}}
							>
								<Icon path={mdiClose} size={1} />
							</IconButton>
						</Toolbar>
						<Divider />
						<List>
							<ListItem
								disableGutters
								button
								selected={location.pathname === '/settings'}
								onClick={() => navigate('/settings')}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon path={ICON_PROFILE} size={1} />
									</ListItemIcon>
									<ListItemText primary="Profile Details" />
								</ListItemButton>
							</ListItem>
							<Divider />
							{/* <ListItem
								disableGutters
								button
								selected={
									location.pathname ===
									'/settings/organization'
								}
								onClick={() =>
									navigate('/settings/organization')
								}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon
											path={ICON_ORGANIZATION}
											size={1}
										/>
									</ListItemIcon>
									<ListItemText primary="Organization Details" />
								</ListItemButton>
							</ListItem> */}
							{/* <ListItem
								disableGutters
								button
								selected={
									location.pathname ===
									'/settings/organization/roles'
								}
								onClick={() =>
									navigate('/settings/organization/roles')
								}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon
											path={ICON_ORGANIZATION}
											size={1}
										/>
									</ListItemIcon>
									<ListItemText primary="Roles &amp; Permissions" />
								</ListItemButton>
							</ListItem> */}
							{/* <ListItem
								disableGutters
								button
								selected={
									location.pathname ===
									'/settings/organization/users'
								}
								onClick={() =>
									navigate('/settings/organization/users')
								}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon
											path={ICON_ORGANIZATION}
											size={1}
										/>
									</ListItemIcon>
									<ListItemText primary="Users" />
								</ListItemButton>
							</ListItem> */}
							<Divider />
							<ListItem
								disableGutters
								button
								selected={
									location.pathname === '/settings/approaches'
								}
								onClick={() => navigate('/settings/approaches')}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon path={ICON_APPROACHES} size={1} />
									</ListItemIcon>
									<ListItemText primary="Approaches" />
								</ListItemButton>
							</ListItem>
							{/* <ListItem
								disableGutters
								button
								selected={
									location.pathname === '/settings/fields'
								}
								onClick={() => navigate('/settings/fields')}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon path={ICON_FIELD} size={1} />
									</ListItemIcon>
									<ListItemText primary="Fields" />
								</ListItemButton>
							</ListItem> */}
						</List>
						<span style={{ flexGrow: 1 }} />
						<Divider />
						<List>
							{/* <ListItem
								disableGutters
								button
								selected={
									location.pathname === '/settings/switch'
								}
								onClick={() => navigate('/settings/switch')}
							>
								<ListItemButton>
									<ListItemIcon>
										<Icon
											path={ICON_ORGANIZATION}
											size={1}
										/>
									</ListItemIcon>
									<ListItemText primary="Switch Organization" />
								</ListItemButton>
							</ListItem> */}
							<ListItem disableGutters button onClick={signout}>
								<ListItemButton>
									<ListItemIcon>
										<Icon
											path={mdiLogoutVariant}
											size={1}
										/>
									</ListItemIcon>
									<ListItemText primary="Sign Out" />
								</ListItemButton>
							</ListItem>
						</List>
					</>
				}
			>
				<Outlet />
			</Drawer>
		</MainLayout>
	);
};
