import { Attachment } from './types';

export const SupportedImageTypes = [
	'png',
	'jpg',
	'jpeg',
	'jfif',
	'pjpeg',
	'pjp',
	'svg',
	'webp',
	'gif',
	'avif',
	'apng',
];

export const SupportedVideoTypes = [
	'mp4',
	'avi',
	'mov',
	'webm',
	'3gp',
	'mpg',
	'mpeg',
	'ogg',
];

export const SupportedMediaTypes = [
	...SupportedImageTypes,
	...SupportedVideoTypes,
];

export const BrowserSupportedTypes = [
	...SupportedImageTypes,
	...SupportedVideoTypes,
	'pdf',
	'txt',
	'html',
	'htm',
	'url',
];

export type MediaType = 'image' | 'video' | 'file';

export type StorageQueryStatus = 'idle' | 'loading' | 'complete' | 'error';

export type StorageUploadStatus = 'idle' | 'uploading' | 'complete' | 'error';

export type StorageItem = {
	key: string;
	name: string;
	size: number;
};

export const mapStorageItem = (item: any): StorageItem => {
	const segments = item.key.split('/');
	const name = segments[segments.length - 1];

	return {
		key: item.key,
		size: item.size,
		name,
	};
};

export const isMediaAttachment = (extension: string): boolean => {
	if (!extension) return false;
	return SupportedMediaTypes.includes(extension.toLowerCase());
};

export const isImageAttachment = (extension: string): boolean => {
	if (!extension) return false;
	return SupportedImageTypes.includes(extension.toLowerCase());
};

export const isVideoAttachment = (extension: string): boolean => {
	if (!extension) return false;
	return SupportedVideoTypes.includes(extension.toLowerCase());
};

export const createAttachmentPath = (
	accountId: number,
	projectId: number,
	attachmentId: string,
	extension: string
): string => {
	return `${accountId}/${projectId}/${attachmentId}.${extension}`;
};

export const createAttachmentFromFile = (file: File): Partial<Attachment> => {
	const s = file.name.split('.');
	const ext = s.pop()?.toLowerCase();

	const attachment: Partial<Attachment> = {
		name: file.name,
		size: file.size,
		type: file.type,
		ext,
	};

	return attachment;
};
