import React from 'react';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { lighten } from '@mui/system';

// Components
// import { TextField } from '../common';
import {
	fromDateTimeString,
	toDateTimeString,
	MixedFieldContent,
} from '@constituenthub/common';
import { DateTime } from 'luxon';

type Props = {
	label: string;
	value: string;
	onChange: (value: string | null) => void;
	error?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	mixedValue?: boolean;
};

export const FieldDate = (props: Props) => {
	const {
		label,
		value,
		onChange,
		error,
		required,
		disabled,
		readOnly,
		mixedValue,
	} = props;
	const theme = useTheme();

	const handleChange = (newValue: DateTime | null) => {
		// console.log('Date Changed', newValue);
		const val = toDateTimeString(newValue?.toJSDate());
		// console.log(`Changing from ${newValue} to ${val}`);
		onChange(val);
	};

	const dt = fromDateTimeString(value);
	// console.log(`Date Value = `, dt);

	return (
		<DesktopDatePicker
			label={label}
			inputFormat="MM/dd/yyyy"
			value={dt}
			onChange={handleChange}
			readOnly={readOnly}
			disabled={disabled}
			renderInput={(params) => (
				<TextField
					fullWidth
					size="small"
					margin="dense"
					variant="outlined"
					onFocus={(event) => event.target.select()}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{ readOnly }}
					placeholder={mixedValue ? MixedFieldContent : label}
					error={error}
					disabled={disabled}
					required={required}
					sx={{
						backgroundColor: mixedValue
							? lighten(theme.palette.primary.main, 0.9)
							: 'initial',
					}}
					{...params}
				/>
			)}
		/>
	);
};
