import { ActionCard, ChangeItem } from '@constituenthub/common';
import { ApiClientInterface } from './ApiClient';

export default class ChangeItemActionApi {
	private client: ApiClientInterface;

	constructor(client: ApiClientInterface) {
		this.client = client;
	}

	public async listActionCards(
		ids: number[],
		historic?: boolean
	): Promise<ActionCard[]> {
		const response = await this.client.get<ActionCard[]>(
			`/changeitemaction?items=${ids?.join(',')}&historic=${
				historic || false
			}`
		);
		return response.result;
	}

	public async createActionCards(
		action: Partial<ActionCard>,
		changeItems: ChangeItem[]
	): Promise<ActionCard[]> {
		const response = await this.client.post<
			{ action: Partial<ActionCard>; changeItems: ChangeItem[] },
			ActionCard[]
		>(`/changeitemaction`, { action, changeItems });
		return response.result;
	}
}
