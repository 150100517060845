// import Joi from 'joi';
import { FieldTypes } from '..';
import {
	AccountDataModel,
	ProjectDataModel,
	DataModelDeletable,
	Entity,
} from '../common';
import { FieldDefinition, FieldsModel } from '../fields';

export interface JobRole
	extends AccountDataModel,
		ProjectDataModel,
		DataModelDeletable,
		FieldsModel {
	jobRoleId: number;
	changeItemId: number;
	groupId: number;
}

// export const groupSchema = Joi.object({
//     name: Joi.string().alphanum().min(3).max(200).required(),
//     description: Joi.string(),
// });

export const JobRoleFieldDefinitions: Record<string, FieldDefinition> = {
	Impact: {
		entity: Entity.JobRole,
		property: 'impact',
		label: 'Impact',
		type: FieldTypes.SelectList,
		options: [
			{ label: 'Low', value: 'Low' },
			{ label: 'Medium', value: 'Medium' },
			{ label: 'High', value: 'High' },
		],
		system: true,
	},
	Reaction: {
		entity: Entity.JobRole,
		property: 'reaction',
		type: FieldTypes.Content,
		label: 'Reaction',
		system: true,
	},
	Response: {
		entity: Entity.JobRole,
		property: 'response',
		type: FieldTypes.Content,
		label: 'Planned Response',
		system: true,
	},
};
