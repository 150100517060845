// Monorepo
import {
	ActionCardDefinitions,
	ApproachFieldDefinitions,
	ChangeItemFieldDefinitions,
	GroupFieldDefinitions,
	ProjectFieldDefinitions,
	FieldDefinition,
	Entity,
	JobRoleFieldDefinitions,
	JobRoleApproachFieldDefinitions,
	NoteFieldDefinitions,
	AttachmentFieldDefinitions,
} from '@constituenthub/common';

export const useFieldDefinitions = (
	entity: Entity
): Record<string, FieldDefinition> => {
	if (entity === Entity.ActionCard) {
		return ActionCardDefinitions;
	}
	if (entity === Entity.Approach) {
		return ApproachFieldDefinitions;
	}
	if (entity === Entity.ChangeItem) {
		return ChangeItemFieldDefinitions;
	}
	if (entity === Entity.Group) {
		return GroupFieldDefinitions;
	}
	if (entity === Entity.Project) {
		return ProjectFieldDefinitions;
	}
	if (entity === Entity.JobRole) {
		return JobRoleFieldDefinitions;
	}
	if (entity === Entity.JobRoleApproach) {
		return JobRoleApproachFieldDefinitions;
	}
	if (entity === Entity.Note) {
		return NoteFieldDefinitions;
	}
	if (entity === Entity.Attachment) {
		return AttachmentFieldDefinitions;
	}
	throw new Error(`Unsupported Entity '${entity}'`);
};
