// Lib
import { Entity } from '../common';
import { JobRoleApproach } from '../approach';
import {
	ActionCard,
	ActionCardDefinitions,
	ActionCardStages,
	ActionCardStatus,
} from './types';
import { FieldValue } from '../fields';
import { toDateTimeString } from '../../dates';
import { ChangeItem, ChangeItemFieldDefinitions } from '../changeitem';
import { getUTCDateNow } from '../..';

export const getDefaultActionCardFields = (
	userId: string,
	dt: Date
): Record<string, FieldValue> => {
	return {
		[ActionCardDefinitions.AssignedTo.property]: userId,
		[ActionCardDefinitions.Start.property]: toDateTimeString(dt),
		[ActionCardDefinitions.Stage.property]: ActionCardStages.ToDo,
		[ActionCardDefinitions.Status.property]: ActionCardStatus.None,
	};
};

export const createActionFromJobRoleApproach = (
	userId: string,
	dt: Date,
	approach: Partial<JobRoleApproach>
): Partial<ActionCard> => {
	const action: Partial<ActionCard> = {
		accountId: approach.accountId,
		projectId: approach.projectId,
		entity: Entity.JobRoleApproach,
		parentId: approach.jobRoleApproachId,
		fields: {
			...getDefaultActionCardFields(userId, dt),
			approach: approach.approachId,
		},
	};
	return action;
};

export const createActionFromChangeItem = (
	userId: string,
	dt: Date,
	item: Partial<ChangeItem>
): Partial<ActionCard> => {
	let name = (item.fields as any)[ChangeItemFieldDefinitions.Name.property];
	let teamMember = (item.fields as any)[
		ChangeItemFieldDefinitions.ResponsibleTeamMember.property
	];
	const action: Partial<ActionCard> = {
		accountId: item.accountId,
		projectId: item.projectId,
		entity: Entity.ChangeItem,
		parentId: item.changeItemId,
		fields: {
			...getDefaultActionCardFields(teamMember || userId, dt),
			name,
		},
	};
	return action;
};

export const actionCardFieldRules = (
	action: Partial<ActionCard>
): Partial<ActionCard> => {
	let fields = { ...action.fields };
	if (!fields) fields = {};

	if (fields.stage === ActionCardStages.Complete && !fields.complete) {
		fields.complete = toDateTimeString(getUTCDateNow());
	}

	if (!!fields.complete) {
		fields.stage = ActionCardStages.Complete;
	}

	return { ...action, fields };
};

export const actionCardFieldEditRules = (
	action: Partial<ActionCard>
): Partial<ActionCard> => {
	let fields = { ...action.fields };
	if (!fields) fields = {};

	if (!fields.start) {
		fields.start = toDateTimeString(getUTCDateNow());
	}

	if (!fields.stage) {
		fields.stage = ActionCardStages.ToDo;
	}

	if (fields.stage === ActionCardStages.Complete && !fields.complete) {
		fields.complete = toDateTimeString(getUTCDateNow());
	}

	if (!!fields.complete && fields.stage !== ActionCardStages.Complete) {
		fields.stage = ActionCardStages.Complete;
	}

	return { ...action, fields };
};
