import { forEach, orderBy } from 'lodash';
import React from 'react';
import { useDebounce } from 'react-use';

// Monorepo
import {
	Filter,
	FilterItem,
	FieldDefinition,
	FieldFilterOperators,
	FieldFilterOperatorDefault,
	FilterOperator,
	FilterValidation,
	validateFilters,
	validateFilterItem,
	isFilterValid,
	FieldTypes,
	formatDateTime,
} from '@constituenthub/common';

// Components
import { useAppContext } from '../../contexts/AppContext';
import { SelectOption, errorOption } from '../common';

type Props = {
	filter: Filter;
	entity: string;
	definition: Record<string, FieldDefinition>;
	validation: FilterValidation;
	onChange: (
		working: Filter | undefined,
		validation: FilterValidation
	) => void;
	onGetFilterCount: (data: Filter) => Promise<number>;
};

type GetOptionProps = {
	item: FilterItem;
	conditionIndex: number;
	itemIndex: number;
	search?: string;
};

type ItemChangedProps = {
	item: FilterItem;
	conditionIndex: number;
	itemIndex: number;
	value: any;
};

export const getValidFiltersUpTo = (
	definition: Record<string, FieldDefinition>,
	filter: Filter,
	conditionIndex: number,
	itemIndex: number
): Filter | undefined => {
	const data: Filter = [];
	let invalid = false;

	console.log('getValidFiltersUpTo', filter, conditionIndex, itemIndex);

	if (conditionIndex === 0) {
		console.log('getValidFiltersUpTo - skipped');
		return undefined;
	}

	forEach(filter, (and, cindex) => {
		const ors: FilterItem[] = [];

		// We only want previous valid filters
		if (cindex < conditionIndex) {
			console.log('getValidFiltersUpTo - cindex < conditionIndex');

			forEach(and, (or, iindex) => {
				console.log(`getValidFiltersUpTo - Evaluating '${or.field}'`);
				// if (iindex < itemIndex) {
				if (validateFilterItem(definition, or)) {
					console.log(
						`getValidFiltersUpTo - Adding '${or.field}' = '${or.val}'`
					);
					ors.push(or);
				} else {
					// exit for
					console.log('getValidFiltersUpTo - exit invalid');
					invalid = true;
					return false;
				}
				// }
			});

			if (invalid === true) {
				// exit for
				console.log('getValidFiltersUpTo - exit invalid');
				return false;
			}

			if (ors.length > 0) {
				data.push(ors);
			}
		}
	});

	console.log('getValidFiltersUpTo - final = ', data);
	return data.length > 0 ? data : undefined;
};

const getFieldDefinition = (
	definition: Record<string, FieldDefinition>,
	field: string
): FieldDefinition | undefined => {
	const def = Object.values(definition).find((x) => x.property === field);
	return def;
};

export const useFilterEditorController = ({
	filter,
	entity,
	definition,
	onChange,
	onGetFilterCount,
	validation,
}: Props) => {
	const { api } = useAppContext();

	const handleQueryCount = React.useCallback(async () => {
		if (isFilterValid(validation)) {
			onGetFilterCount(filter);
		}
	}, [filter, onGetFilterCount, validation]);

	useDebounce(handleQueryCount, 500, [filter, validation]);

	const onFieldChange = (props: ItemChangedProps) => {
		const data = [...filter];
		data[props.conditionIndex][props.itemIndex].field = props.value;
		data[props.conditionIndex][props.itemIndex].op = getDefaultOperator(
			props.value
		);
		data[props.conditionIndex][props.itemIndex].val = null;
		onChange(data, validateFilters(definition, data));
	};

	const getFieldOptions = (props: GetOptionProps): SelectOption[] => {
		const options = Object.keys(definition)
			.filter((x) => definition[x].managed !== true)
			.filter((x) =>
				!!props.search
					? definition[x].label.includes(props.search)
					: true
			)
			.map((x) => ({
				label: definition[x].label,
				value: definition[x].property,
			}));
		return orderBy(options, ['label']);
	};

	const onOperatorChange = (props: ItemChangedProps) => {
		const data = [...filter];
		data[props.conditionIndex][props.itemIndex].op = props.value;
		data[props.conditionIndex][props.itemIndex].val = null;
		onChange(data, validateFilters(definition, data));
	};

	const getOperatorOptions = (props: GetOptionProps): SelectOption[] => {
		const field = getFieldDefinition(definition, props.item.field);
		if (field) {
			const ops = FieldFilterOperators[field.type];
			return ops
				.map((x) => ({ label: x, value: x }))
				.filter((x) =>
					props.search ? x.label.includes(props.search) : true
				);
		}
		return [];
	};

	const onValueChange = (props: ItemChangedProps) => {
		const data = [...filter];
		data[props.conditionIndex][props.itemIndex].val = props.value;
		onChange(data, validateFilters(definition, data));
	};

	const onLoadValues = async (
		props: GetOptionProps
	): Promise<SelectOption[]> => {
		const item = filter[props.conditionIndex][props.itemIndex];
		if (!item.field || !item.op) return [];

		const field = getFieldDefinition(definition, props.item.field);

		const data = getValidFiltersUpTo(
			definition,
			filter,
			props.conditionIndex,
			props.itemIndex
		);

		try {
			const items = await api.fieldvalue.uniqueValues(
				entity,
				item.field,
				data
			);
			if (field?.type === FieldTypes.Date) {
				return items.map((x) => ({
					label: `${formatDateTime(x as string)}`,
					value: x,
				}));
			} else {
				return items.map((x) => ({ label: `${x}`, value: x }));
			}
		} catch (error) {
			console.error(error);
			return [errorOption];
		}
	};

	const addAndCondition = (index: number) => {
		const data = [...filter];
		const item: FilterItem = {
			field: '',
			op: 'Equals',
			val: '',
		};
		data.push([item]);
		onChange(data, validateFilters(definition, data));
	};

	const addOrCondition = (andIndex: number, index: number) => {
		const data = [...filter];
		const item: FilterItem = {
			field: '',
			op: 'Equals',
			val: '',
		};
		data[andIndex].push(item);
		onChange(data, validateFilters(definition, data));
	};

	const removeCondition = (andIndex: number, index: number) => {
		const data = [...filter];
		if (index === 0) {
			data.splice(andIndex, 1);
		} else {
			data[andIndex].splice(index, 1);
		}
		onChange(data, validateFilters(definition, data));
	};

	const getDefaultOperator = (property: string): FilterOperator => {
		const field = getFieldDefinition(definition, property);
		if (field) {
			return FieldFilterOperatorDefault[field.type] as FilterOperator;
		}
		return '' as FilterOperator;
	};

	return {
		validation,
		onFieldChange,
		getFieldOptions,
		onOperatorChange,
		getOperatorOptions,
		onValueChange,
		onLoadValues,
		addAndCondition,
		addOrCondition,
		removeCondition,
		getDefaultOperator,
	};
};

export type FilterEditorControllerHook = {
	validation: FilterValidation;
	onFieldChange: (props: ItemChangedProps) => void;
	getFieldOptions: (props: GetOptionProps) => SelectOption[];
	onOperatorChange: (props: ItemChangedProps) => void;
	getOperatorOptions: (props: GetOptionProps) => SelectOption[];
	onValueChange: (props: ItemChangedProps) => void;
	onLoadValues: (props: GetOptionProps) => Promise<SelectOption[]>;
	addAndCondition: (index: number) => void;
	addOrCondition: (andIndex: number, index: number) => void;
	removeCondition: (andIndex: number, index: number) => void;
	getDefaultOperator: (property: string) => string;
};
